import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const initialState = { }

const getServerState = req => {
  if (!req) return {}

  return {}
}

const configureStore = req => {
  const state = {
    ...initialState,
    ...getServerState(req)
  }

  const store = createStore(
    rootReducer,
    state,
    composeEnhancers(applyMiddleware(thunk)),
  )

  return store
}

export default configureStore
