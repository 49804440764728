import React from 'react'

const FullWidthAbsolute = ({
  position = '',
  top,
  bottom,
  left,
  right,
  children
}) => {
  if (top) position += `top: ${top};`
  if (bottom) position += `bottom: ${bottom};`
  if (left) position += `left: ${left};`
  if (right) position += `right: ${right};`

  return (
    <div
      css={`
        position: relative;
        z-index: -2;
      `}
    >
      <div
        css={`
          width: 100vw;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          top: 0px;
        `}
      >
        <div
          css={`
            position: absolute;
            ${position}
          `}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default FullWidthAbsolute
