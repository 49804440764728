import React from 'react'
// import {desktop} from './LandingPageCommercial'
const desktop = 'min-width:1600px'

export const Title = ({title, isBackgroundDark}) => {
  return (
    <h3
      css={`
        color: ${isBackgroundDark ? '#FFFFFF' : '#323232'};
        font-family: ReplicaBold;
        font-size: 1.25rem;
        margin-bottom: 29px;
        letter-spacing: 0;
        line-height: 30px;
        max-width: 99%;
        /* @media (${desktop}) {
          font-size: 1.35rem;
        } */
      `}
    >
      {title}
    </h3>
  )
}
