import React from 'react'
import { css } from 'styled-components'
import { useHistory } from 'react-router'
import theme from 'lib/theme'

const CardHeader = ({ left, right }) => {
  return (
    <div
      css={`
        height: 78px;
        padding: 0 20px;
        border-bottom: 2px solid ${theme.colors.light.paper};
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <div>{!!left && left}</div>
      {!!right && (
        <div
          css={`
            min-width: 140px;
            flex-shrink: 0;
            text-align: right;
          `}
        >
          {right}
        </div>
      )}
    </div>
  )
}

const CardBody = ({ header, aside, children, ...props }) => {
  return (
    <div
      css={`
        padding: 24px;
        flex: 1;
      `}
      {...props}
    >
      <div>{!!header && header}</div>
      <div
        css={`
          display: flex;
        `}
      >
        {!!aside && <div>Aside</div>}
        <div
          css={`
            width: 100%;
          `}
        >
          {!!children && children}
        </div>
      </div>
    </div>
  )
}

const CardFooter = ({ left, right, center }) => {
  return (
    <div
      css={`
        height: 70px;
        padding: 0 20px;
        border-top: 2px solid ${theme.colors.light.paper};
        display: flex;
        align-items: center;
        justify-content: space-between;
        display: flex;
        align-items: center;
      `}
    >
      <div>{!!left && left}</div>
      <div>{!!center && center}</div>
      <div
        css={`
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        {!!right && right}
      </div>
    </div>
  )
}


// TODO: move this into re-usable component or helpers folder
export const isAncestorInteractive = (el, options = {}) => {
  const { checkUntil } = options

  if (el.tagName === 'A' || el.tagName === 'BUTTON' || !!el.dataset.clickable) {
    return true
  }

  if (el === checkUntil || !el.parentElement) {
    return false
  }

  return isAncestorInteractive(el.parentElement, options)
}

// the hover effect on cards is distracting when you scroll, so we disable it
// when you scroll & reinstate the effect when user's move their mouse
let _showHoverEffect = true
const hoverEffectListeners = {}
const listenToHover = f => {
  const k = Date.now().toString() + Math.random().toString()
  hoverEffectListeners[k] = f
  return () => delete hoverEffectListeners[k]
}
window.addEventListener(
  'scroll',
  () => {
    if (!_showHoverEffect) return
    _showHoverEffect = false
    for (const k in hoverEffectListeners) {
      hoverEffectListeners[k](_showHoverEffect)
    }
  },
  true
)
window.addEventListener('mousemove', () => {
  if (_showHoverEffect) return
  _showHoverEffect = true
  for (const k in hoverEffectListeners) {
    hoverEffectListeners[k](_showHoverEffect)
  }
})

const Card = ({
  css: extraCss,
  halfWidth,
  linkTo,
  center,
  children,
  onClick,
  ...props
}) => {
  const cardEl = React.useRef(null)
  const history = useHistory()
  const [showHoverEffect, setShowHoverEffect] = React.useState(
    linkTo ? _showHoverEffect : false
  )

  const linkToPredicate = !!linkTo
  React.useEffect(() => {
    if (!linkToPredicate) return
    const unlisten = listenToHover(showHoverEffect => {
      setShowHoverEffect(showHoverEffect)
    })
    return unlisten
  }, [linkToPredicate])

  return (
    <div
      ref={cardEl}
      css={`
        display: flex;
        flex-direction: column;
        background: ${theme.colors.white};
        border-radius: 4px;
        max-width: ${halfWidth ? '460px' : '940px'};
        width: 100%;
        margin-bottom: 20px;
        ${center
          ? css`
              margin-left: auto;
              margin-right: auto;
            `
          : css`
              margin-left: 10px;
              margin-right: 10px;
            `}
        ${!!showHoverEffect &&
        css`
            cursor: pointer;
            transition: background 100ms ease 0s;
            &:hover {
              box-shadow: 4px 16px 16px rgba(0,0,0,0.15);
            }
          `}

        @media only screen and (max-width: 1270px) {
          max-width: 100%;
        }

        ${extraCss}
      `}
      onClick={e => {
        if (!linkTo) return
        const interactive = isAncestorInteractive(e.target, {
          checkUntil: cardEl.current
        })
        const someTextHighlighted = !!window.getSelection().toString()
        if (interactive || someTextHighlighted) return

        if (onClick) {
          onClick()
        } else {
          history.push(linkTo)
        }
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export { Card, CardHeader, CardBody, CardFooter }
