import React from 'react'
import styled from 'styled-components'

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .25s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .25s;
    border-radius: 50%;
  }
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 41px;
  height: 23px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + ${Slider} {
    background-color: #F94687;
  }

  input:checked + ${Slider}:before {
    transform: translateX(18px);
  }
`

const ToggleSwitch = React.forwardRef(({onChange, checked}, ref) => {
  return (
    <Label>
      <input
        checked={checked}
        onChange={onChange}
        ref={ref}
        type="checkbox" />
      <Slider />
    </Label>
  )
})

export default ToggleSwitch
