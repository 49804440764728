import Cookies from 'js-cookie'
import uuid from 'uuid/v4'

const getSessionId = () => {
  if (!window.sessionStorage.getItem('sessionId')) {
    window.sessionStorage.setItem('sessionId', uuid())
  }
  return window.sessionStorage.getItem('sessionId')
}

// this enables us to connect events from before they've signed up to afterward
const getUserUUID = () => {
  if (!window.localStorage.getItem('uuid')) {
    window.localStorage.setItem('uuid', uuid())
  }
  return window.localStorage.getItem('uuid')
}

const getMetadata = async () => {
  const viewerId = JSON.parse(Cookies.get('userId') || 'null')
  const viewerUUID = getUserUUID()
  const sessionId = getSessionId()
  const timezoneOffset = new Date().toString().match(/[+-]\d+/)[0]
  const deviceTimestamp =
    new Date().toISOString().replace(/[A-Z]/g, ' ') + timezoneOffset
  return {viewerId, viewerUUID, sessionId, deviceTimestamp, timezoneOffset}
}

export default getMetadata
