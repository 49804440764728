import CustomBtn from 'components/shared/Button'
import {withStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Cookies from 'js-cookie'
// import {desktop} from './LandingPageCommercial'
const desktop = 'min-width:1600px'
declare const window: any

const apiUrl = window.env
  ? window.env.API_URL
  : process.env.API_URL
  ? process.env.API_URL
  : 'https://api-staging.researcher-app.com'

const regex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

export const DownloadMediaKit = (props) => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isdownloadMediaDialogOpen, setIsDownloadMediaDialogOpen] =
    useState(false)
  const handleDownloadMediaKit = () => {
    setIsDownloadMediaDialogOpen(true)
    setEmail('')
  }
  const closeDownloadMediaKit = () => {
    setIsDownloadMediaDialogOpen(false)
    setIsSubmitted(false)
    setEmail('')
  }

  const sendEmail = () => {
    try {
      setIsLoading(true)
      const serviceKey = !apiUrl.includes('staging')
        ? 'service:O]NKK04yhVwmE-S'
        : 'service:DpVCfHp->,fMzVp'
      const data: BodyInit = JSON.stringify({
        type: 'researcher_media_kit_email',
        to: email,
      })

      var requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          Authorization: serviceKey,
          'Content-Type': 'application/json',
        },
        body: data,
      }

      return fetch(`${apiUrl}/v4/emails`, requestOptions)
        .then((response) => response.text())
        .then(() => {
          setIsSubmitted(true)
        })
        .catch((error) => {
          console.log('error', error)
        })
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
      setIsSubmitted(false)
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <>
      <CustomBtn
        v2Primary
        css={`
          width: 203px;
          min-height: 50px;
          padding: 0 15px;
          text-align: center;
          margin: 10px 15px;
          border-radius: 4px;
          font-family: 'ReplicaBold';
          font-weight: bold;
          font-size: 1rem;
          background-color: hsl(337, 93.7%, 64.8%);
          color: hsl(0, 0%, 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          /* @media (${desktop}) {
            font-size: 1.1rem;
          } */

          &:hover {
            box-shadow: 0px 8px 9px 1px hsl(255, 99.5%, 18.4%, 0.19);
            cursor: pointer;
            background-color: hsl(337, 82.6%, 54.3%);
          }
        `}
        onClick={handleDownloadMediaKit}
        x-track-id="paper"
        x-track-click="true"
        //  children={undefined}
        primary={undefined}
        hover={undefined}
        active={undefined}
        focus={undefined}
      >
        Download Media Kit
      </CustomBtn>

      {isdownloadMediaDialogOpen && (
        <Dialog
          open
          onClose={closeDownloadMediaKit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Download Media kit</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {isSubmitted
                ? 'Media kit sent to your email. please check.'
                : 'To download media kit, please enter your email address here. We will send it on the entered email.'}
            </DialogContentText>
            {!isSubmitted && (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                color="secondary"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDownloadMediaKit} color="secondary">
              {isSubmitted ? 'OK' : 'Cancel'}
            </Button>
            {!isSubmitted && (
              <CustomBtn
                v2Primary
                css={`
                  padding: 0 15px;
                  text-align: center;
                  margin: 10px 15px;
                  border-radius: 4px;
                  font-family: 'Replica', Arial, sans-serif;
                  font-weight: bold;
                  font-size: 1rem;
                  background-color: hsl(337, 93.7%, 64.8%);
                  color: hsl(0, 0%, 100%);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-decoration: none;
                  /* @media (${desktop}) {
                    font-size: 1.1rem;
                  } */

                  &:hover {
                    box-shadow: 0px 8px 9px 1px hsl(255, 99.5%, 18.4%, 0.19);
                    cursor: pointer;
                    background-color: hsl(337, 82.6%, 54.3%);
                  }
                `}
                onClick={sendEmail}
                x-track-id="paper"
                x-track-click="true"
                //  children={undefined}
                primary={undefined}
                hover={undefined}
                active={undefined}
                focus={undefined}
                disabled={!regex.test(email) || isLoading}
              >
                Submit
              </CustomBtn>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
