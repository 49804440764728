import React from 'react'
import {makeStyles} from '@material-ui/core'
import {ConfigurableChipWrapper} from '../ConfigurableChipWrapper/ConfigurableChipWrapper'
import {MatchField} from 'components/FeedV2/interfaces/types'
import {getTempExcludedTermDisplayVal} from 'components/FeedV2/util/util'

const useStyles = makeStyles((theme) => ({
  addedSuggestions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
    flexWrap: 'wrap',
    // marginBottom: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    rowGap: theme.spacing(5),
    width: '90%',
    height: '200px',
    overflowY: 'auto',
    maxHeight: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    alignItems: 'center',
  },
}))

export const TemporaryExcludedItemList = ({addedSuggestions, onDelete}) => {
  const classes = useStyles()
  const handleDelete = (data, index) => {
    onDelete(data, index)
  }
  return (
    <div className={classes.addedSuggestions}>
      {addedSuggestions.map((addedSuggestion, i) => (
        <React.Fragment
          key={`TemporaryExcludedItemList ${addedSuggestion.terms + i}`}
        >
          <ConfigurableChipWrapper
            children={getTempExcludedTermDisplayVal(addedSuggestion)}
            //  isClickable
            filter={addedSuggestion.terms}
            //   onClick={() => {}}
            onDelete={(data) => {
              handleDelete(data, i)
            }}
            width={'159px'}
            // height={'38.2px'}
            isBoxShadow={false}
            isBorderRadius={false}
            borderValue={'1px solid #979797'}
            isBold={false}
            fontSize={'15px'}
            isWordBreak
          />
        </React.Fragment>
      ))}
    </div>
  )
}
