import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core'
import AutoComplete from '../autocomplete/autoComplete'
import {useDebounce} from 'use-debounce'
import {getPublicationList} from 'components/FeedV2/util/api'
import {
  transformGetJournalsApiResponse,
  transformGetKeywordsTopicApiResponse,
  TranslationKeys,
} from 'components/FeedV2/util/util'
import {
  excludeItem,
  updateSubmitBtnDisabledState,
} from 'store/actions/feedManager'
import {useDispatch, useSelector} from 'react-redux'
import {
  ExclusionArrayName,
  MatchField,
  Matchtype,
} from 'components/FeedV2/interfaces/types'
import {TemporaryExcludedItemList} from '../TemporaryExcludedItemList/TemporaryExcludedItemList'
import {AlreadySelected} from '../AlreadySelected/AlreadySelected'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(4),
    },
  },
  createEditPublication: {
    margin: '0 auto',
    overflow: 'hidden',
    height: '300px',
    width: '100%',
    position: 'relative',
  },
  title: {
    padding: theme.spacing(3, 0),
  },

  formGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5%',
  },
  autoCompleteForm: {
    width: '60%',
    margin: '0 auto',
  },
}))

type CreateEditPublicationProps = {
  searchedTerm?: string
}

export const CreateEditPublication = ({
  searchedTerm,
}: CreateEditPublicationProps) => {
  const {activeFeed} = useSelector((state: any) => state.feedManager)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [inputValue, setInputValue] = useState(searchedTerm || '')
  // Searching status (whether there is pending API request)
  const [isSearching, setIsSearching] = useState(true)
  const [debounceInputValue] = useDebounce(inputValue, 500)
  const [suggestions, setSuggestions] = useState([])
  const [addedSuggestions, setAddedSuggestions] = useState([])
  const [isAlreadySelected, setIsAlreadySelected] = useState(false)

  // Handle Change
  const handleChange = (e) => {
    setIsAlreadySelected(false)
    setInputValue(e.target.value)
  }

  // Effect for API call
  useEffect(
    () => {
      const getPublications = async () => {
        if (debounceInputValue) {
          setIsSearching(true)
          const results = await getPublicationList(debounceInputValue)
          setSuggestions(transformGetJournalsApiResponse(results))
          setIsSearching(false)
        } else {
          // setResults([])
          //  setIsSearching(false)
        }
      }

      getPublications() // run it, run it

      return () => {
        // this now gets called when the component unmounts
      }
    },
    [debounceInputValue] // Only call effect if debounced search term changes
  )

  // Handle Select
  const handleSelect = (selectedData) => {
    //  setInputValue(selectedData.key)
    setInputValue('')
    const findSuggestedItemInList = activeFeed.exclusions.publications
      .concat(addedSuggestions)
      .find(
        (addedSuggestion) =>
          addedSuggestion.displayValue === selectedData.displayValue
      )

    if (!findSuggestedItemInList) {
      setIsAlreadySelected(false)
      const {key, ...rest} = selectedData
      let payload = {
        match_field: MatchField.journalId,
        match_type: Matchtype.EXACT,
        terms: [key],
        ids: [],
        ...rest,
      }
      setAddedSuggestions([payload, ...addedSuggestions])
    } else {
      setIsAlreadySelected(true)
    }
  }

  useEffect(() => {
    dispatch(updateSubmitBtnDisabledState(addedSuggestions.length === 0))
  }, [dispatch, addedSuggestions.length])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(excludeItem(addedSuggestions, ExclusionArrayName.PUBLICATIONS))
  }

  const handeleDelete = (data, index) => {
    setAddedSuggestions(
      addedSuggestions.filter((addedSuggestion, ind) => ind !== index)
    )
  }

  return (
    // <Paper className={classes.paper}>
    <div className={classes.createEditPublication}>
      <form
        id="submit-form"
        className={classes.autoCompleteForm}
        onSubmit={handleSubmit}
      >
        <AutoComplete
          options={suggestions}
          inputValue={inputValue}
          handleChange={handleChange}
          handleSelect={handleSelect}
          clearSearch={() => setInputValue('')}
          isSearching={isSearching}
          placeholder={TranslationKeys.searchPublication}
        />
        {/* {isAlreadySelected && (
          <AlreadySelected msg={TranslationKeys.alreadySelected} />
        )} */}
      </form>
      <TemporaryExcludedItemList
        addedSuggestions={addedSuggestions}
        onDelete={(data, index) => handeleDelete(data, index)}
      />
    </div>
  )
}
