import React from 'react'
import styled from 'styled-components'
import HelpIcon from '@material-ui/icons/HelpOutline'
import {QuestionMarkIcon} from 'components/FeedV2/shared/QuestionIcon/QuestionIcon'

type HelpSectionProps = {
  onClick: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
  background-color: #f1f1f1;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 30px;
`

const Title = styled.h3`
  width: 68px;
  color: #f94687;
  font-family: Replica;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 15px;
  margin: 0 auto;
`

const Description = styled.p`
  color: #bfbfbf;
  font-family: Replica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: start;
  width: 218px;
  text-align: center;
`

export const HelpSection = ({onClick}: HelpSectionProps) => (
  <Container>
    <div style={{marginLeft: '150px'}}>
      <Title>KEYWORD</Title>
      <Description>term contained within a document</Description>
      <Description>
        <strong
          style={{
            color: 'black',
            fontWeight: '500',
          }}
        >
          eg:
        </strong>{' '}
        a keyword DNA search will include results where the term is contained in
        the document{' '}
      </Description>
    </div>
    <div>
      <Title>TOPIC</Title>
      <Description>
        list of terms that assign meaning to a document.
      </Description>
      <Description>
        <strong
          style={{
            color: 'black',
            fontWeight: '500',
          }}
        >
          eg:
        </strong>{' '}
        a Topic DNA search could also include related results such as mRNA.
      </Description>
    </div>
    <div style={{marginTop: '-12px'}}>
      <QuestionMarkIcon onClick={onClick} isAbsolutePosition={false} />
    </div>
  </Container>
)
