import React from 'react'
import { Helmet } from 'react-helmet'
import theme from 'lib/theme'
import Sidebar from 'components/AppSidebar'
import { useHistory } from 'react-router'

const AppLayout = ({ children }) => {
  const history = useHistory()
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      document.getElementById("scrolling-child").scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (
    <div
      css={`
        position: relative;
        display: grid;
        overflow-y: hidden;
        grid-template-columns: max-content 1fr;
        height: 100vh;
      `}
    >
      <Helmet>
        <title>Researcher | An App For Academics</title>
      </Helmet>

      <Sidebar />
      <div
        css={`
          background: ${theme.colors.paper};
          min-height: 100vh;
          overflow-y: scroll;
          min-width: 0;
        `}
        id="scrolling-child"
      >
        {children}
      </div>
    </div>
  )
}

export default AppLayout
