import React from 'react'
import styled from 'styled-components'

export const StyledTextInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > div:first-of-type {
    position: absolute;
    margin-left: 5px;
    pointer-events: none;
  }
`

export const Input = styled.input`
  font-size: 14px;
  line-height: 24px;
  font-family: 'Proxima Nova';
  font-weight: bold;
  color: rgb(50, 50, 50);
  border-bottom: 1px solid #bbbbbb;
  width: 100%;

  ${props => props.withIcon && 'padding-left: 30px;'}

  :focus {
    outline: none;
    border-bottom: 1px solid #f94687;
  }

  ::placeholder {
    color: rgb(50, 50, 50, 0.4);
  }
`

// TODO: refactor code to use this text input everywhere
const TextInput = React.forwardRef(({ icon, ...props }, ref) => {
  return (
    <StyledTextInput>
      {!!icon && icon}
      <Input withIcon={!!icon} type="text" {...props} ref={ref} />
    </StyledTextInput>
  )
})

export default TextInput
