import {CustomModalHeader, ModalSize} from 'components/FeedV2/CustomDialog'
import CustomModal from 'components/FeedV2/CustomDialog/CustomModal'
import {CustomModalFooter} from 'components/FeedV2/CustomDialog/CustomModalFooter'
import {ActiveFeed, LogicFlow, Term} from 'components/FeedV2/interfaces/api'
import {CreateEditAuthor} from 'components/FeedV2/shared/CreateEditAuthor/CreateEditAuthor'
import {
  CreateEditKeyword,
  MemoizedCreateEditKeyword,
} from 'components/FeedV2/shared/CreateEditKeyword.tsx/CreateEditKeyword'
import {
  BtnType,
  BtnVariant,
  CustomButton,
} from 'components/FeedV2/shared/CustomButton/CustomButton'
import {HelpDetails} from 'components/FeedV2/shared/HelpDetails/HelpDetails'
import {
  getFormModalTitle,
  getFormSubmitBtnConfig,
  TranslationKeys,
} from 'components/FeedV2/util/util'
import React from 'react'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {HelpSection} from '../HelpSection/HelpSection'
import {QueriesBuilder} from '../QueriesBuilder/QueriesBuilder'
import styled from 'styled-components'
import {MatchField} from 'components/FeedV2/interfaces/types'
import {
  removeIncludedKeywordsTopicsAuthors,
  updateConditionBetweenKeywordsTopicsAuthors,
} from 'store/actions/feedManager'
import {TermIcon} from 'components/FeedV2/shared/TermIcon/TermIcon'
import {AuthorIcon} from 'components/FeedV2/shared/AuthorIcon/AuthorIcon'

type IncludedSectionProps = {
  title: string
  subtitle: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10%;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
`

export const IncludedSection = () => {
  let {isSubmitBtnDisable, activeFeed} = useSelector(
    (state: any) => state.feedManager
  )

  const dispatch = useDispatch()

  const [createEditKeywordPayload, setCreateEditKeywordPayload] = useState({
    searchedTerm: null,
    matchField: null,
    selectedMatchType: null,
    index: null,
  })
  const [author, setAuthor] = useState({
    firstName: null,
    lastName: null,
    index: null,
  })

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  // searchedTerm?: string
  // matchField?: string
  // selectedMatchType?: Match
  activeFeed = activeFeed as ActiveFeed

  const [isCreateEditKeywordOpen, setCreateEditKeywordOpen] = useState(false)
  const [isCreateEditAuthorOpen, setCreateEditAuthorOpen] = useState(false)
  const openCreateEditKeyword = () => {
    setCreateEditKeywordOpen(true)
  }

  const openCreateEditAuthor = () => {
    setCreateEditAuthorOpen(true)
  }

  const closeEditCreateAuthor = () => {
    setCreateEditAuthorOpen(false)
    setAuthor(null)
  }

  const closeEditCreateKeyword = () => {
    setCreateEditKeywordOpen(false)
    setCreateEditKeywordPayload(null)
  }

  const handleAction = (btnName) => {
    setTimeout(() => {
      closeEditCreateAuthor()
    }, 100)
  }

  const handleAddKeywordOrTopicSaveClick = () => {
    setTimeout(() => {
      closeEditCreateKeyword()
    }, 100)
  }

  const handleQueryClick = (term: Term) => {
    const {match_field, match_type, sort_index, terms: filledTerm} = term
    if (['abstract', 'topic', 'default'].includes(term.match_field)) {
      setCreateEditKeywordPayload({
        searchedTerm: filledTerm[0],
        matchField: match_field,
        selectedMatchType: match_type,
        index: sort_index,
      })
      setCreateEditKeywordOpen(true)
    } else if (term.match_field === MatchField.author) {
      setAuthor({
        firstName: filledTerm[1] ? filledTerm[0] : '',
        lastName: filledTerm[1],
        index: sort_index,
      })
      setCreateEditAuthorOpen(true)
    }
  }

  const handleQueryDelete = (term: Term) => {
    dispatch(removeIncludedKeywordsTopicsAuthors(term))
  }

  const handleOperatorChange = (val: LogicFlow, term: Term) => {
    dispatch(updateConditionBetweenKeywordsTopicsAuthors(val, term))
  }

  return (
    <>
      <Container>
        <Section>
          {/* Add keyword */}

          {/* <img src={keywordIcon} alt="keywordIcon" /> */}
          <TermIcon />
          <CustomButton
            children={`${TranslationKeys.add} ${TranslationKeys.keywordTopic}`}
            onClick={openCreateEditKeyword}
            backgroundColor="transparent"
            width="234px"
            height="50px"
            btnVariant={BtnVariant.OUTLINED}
            type={BtnType.BUTTON}
          />
        </Section>

        <Section>
          {/* Add author */}
          {/* <img src={authorsIcon} alt="authorsIcon" /> */}
          <AuthorIcon />
          <CustomButton
            children={`${TranslationKeys.add}  ${TranslationKeys.author}`}
            onClick={openCreateEditAuthor}
            backgroundColor="transparent"
            width="234px"
            height="50px"
            btnVariant={BtnVariant.OUTLINED}
            type={BtnType.BUTTON}
          />
        </Section>
      </Container>

      {/* Help section */}
      {activeFeed.inclusions.length > 0 ? (
        <QueriesBuilder
          activeFeed={activeFeed}
          onClick={handleQueryClick}
          onDelete={handleQueryDelete}
          onOperatorChange={handleOperatorChange}
        />
      ) : (
        <HelpSection
          onClick={() => {
            setIsDetailsModalOpen(true)
          }}
        />
      )}

      {/* Keyword create edit flow */}
      {isCreateEditKeywordOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              title={getFormModalTitle(TranslationKeys.keywordTopic)}
              handleClose={closeEditCreateKeyword}
              size={ModalSize.sm}
              isHelpIcon
            />
          }
          // Consideration for multiple props
          body={<MemoizedCreateEditKeyword {...createEditKeywordPayload} />}
          size={ModalSize.sm}
          footer={
            <CustomModalFooter
              primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
              handleAction={handleAddKeywordOrTopicSaveClick}
              size={ModalSize.sm}
            />
          }
        />
      )}

      {/* Author create edit flow */}
      {isCreateEditAuthorOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              title={getFormModalTitle(TranslationKeys.author)}
              handleClose={closeEditCreateAuthor}
              size={ModalSize.sm}
            />
          }
          body={<CreateEditAuthor {...author} />}
          size={ModalSize.sm}
          footer={
            <CustomModalFooter
              primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
              handleAction={handleAction}
              size={ModalSize.sm}
            />
          }
        />
      )}

      {isDetailsModalOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              title={null}
              handleClose={() => setIsDetailsModalOpen(false)}
            />
          }
          body={<HelpDetails />}
          size={ModalSize.xl}
          footer={
            null
            // <CustomModalFooter
            //   primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
            //   handleAction={() => setIsDetailsModalOpen(false)}
            // />
          }
          isDividers={false}
        />
      )}

      {/* Listing Section for inclusions - that is keyword/topic + authors + and or logics */}
    </>
  )
}

export const memoizedCreateEditKeyword = React.memo(CreateEditKeyword)
