import React from 'react'
// import {desktop} from './LandingPageCommercial'
const desktop = 'min-width:1600px'
export const Heading = ({heading, isBackgroundDark}) => {
  return (
    <h1
      css={`
        color: ${isBackgroundDark ? '#FFFFFF' : '#323232'};
        font-family: ReplicaBold;
        font-size: 2.188rem;
        text-align: center;
        line-height: 47px;
        /* @media (${desktop}) {
          font-size: 2.198rem;
        } */
      `}
    >
      {heading}
    </h1>
  )
}

export const DetailedMsg = ({detailedMsg}) => {
  return (
    <p
      css={`
        font-family: Replica;
        font-size: 0.875rem;
        color: #888888;
        /* @media (${desktop}) {
          font-size: 0.885rem;
        } */
      `}
    >
      {detailedMsg}
    </p>
  )
}
