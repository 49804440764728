import React from 'react'
import theme from 'lib/theme'

const BackgroundStripe = ({
  color = theme.colors.paper,
  top = '0px',
  right = '0px'
}) => (
  <div
    css={`
      position: absolute;
      right: ${right};
      top: calc(50% + ${top});
      transform: translateY(-50%);
      z-index: -2;
    `}
  >
    <svg width={2106} height={2442}>
      <g fill={color}>
        <path d="M0 0l2036.083 1175.817L0 2360.907v24.21l2059.389-1195.842 23.372 13.499L0 2413.055V2442l2106-1225.807-.002-.001.002.001v-26.923L46.468 0zM93 0l2013 1162v-26.907L139.616 0z" />
      </g>
    </svg>
  </div>
)

export default BackgroundStripe
