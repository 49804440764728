import React from 'react'
import memoize from 'memoize-one'
import styled, {css} from 'styled-components'
import {FixedSizeList as List, areEqual} from 'react-window'
import Image from 'components/shared/Image'
import Spinner from './Spinner'
import {useEffect} from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import {useState} from 'react'

const SearchIcon = '/images/inputSearchIcon.svg'

export const StyledDropdown = styled.div`
  height: 24px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: end;
  user-select: none;
  transition: box-shadow 60ms;
  border-radius: 2px;
  padding: 0 5px;

  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #323232;
  }
`

export const DropdownItem = styled.li`
  padding: 6px 10px;
  border-radius: 1px;
  margin: 3px;
  cursor: default;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 200px;
  ${(props) => props.selected && 'background-color: #555555;'}
  ${(props) => props.color && `color: ${props.color};`}

  ${({selected, fixed}) =>
    !selected &&
    !fixed &&
    css`
      &:hover {
        background-color: #444444;
      }
    `}

  ${({fixed}) =>
    fixed &&
    css`
      position: absolute;
      z-index: 10;
      background-color: #323232;
      margin-top: 5px;
    `}
`

export const SearchItem = styled.div`
  padding: 6px 10px;
  cursor: default;
  user-select: none;
  position: absolute;
  z-index: 10;
  background-color: #323232;
  margin-top: 5px;
  width: ${({width}) => width};
  display: flex;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  & + ul {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

const useIsOpen = (initialState) => {
  const [isOpen, setIsOpen] = React.useState(initialState)
  const ref = React.useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return {ref, isOpen, setIsOpen}
}

const Row = React.memo(({data, index, style}) => {
  const {items, selectedIndex, itemClick, itemWidth} = data
  const item = items[index]

  return (
    <DropdownItem
      selected={selectedIndex === index}
      onClick={(e) => {
        e.preventDefault()
        itemClick(index)
      }}
      style={{
        ...style,
        width: `calc(${itemWidth} - 6px)`,
      }}
      x-track-id="dropdownItem"
      x-track-detail={JSON.stringify({dropdownItem: item.id})}
      x-track-click="true"
    >
      {item.name}
    </DropdownItem>
  )
}, areEqual)

const createItemData = memoize(
  (items, selectedIndex, itemClick, itemWidth) => ({
    items,
    selectedIndex,
    itemClick,
    itemWidth,
  })
)

const Dropdown = React.forwardRef(
  (
    {
      name,
      items,
      width,
      margin,
      disabled,
      onChange,
      searchable,
      startingValue,
      containerProps,
      dropdownMargin,
      placeholder,
      changeValue = true,
      scrollable = true,
      selectWidth = width,
      height = 200,
      onLabelClick,
      ...rest
    },
    ref
  ) => {
    const [value, setValue] = React.useState(startingValue || {})
    const [searchValue, setSearchValue] = React.useState('')
    const {isOpen, setIsOpen, ref: wrapperRef} = useIsOpen(false)
    const [filteredItems, setFilteredItems] = React.useState(items)
    const [visibleCount, setVisibleCount] = React.useState(10)
    let inputRef = ref || React.useRef(null)
    var responseData = []

    React.useEffect(() => {
      let filteredItems = items
      if (searchValue) {
        filteredItems = items.filter((item) => {
          return item.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        })
      }
      setFilteredItems(filteredItems)
    }, [searchValue, items])

    React.useEffect(() => {
      if (!isOpen) {
        setSearchValue('')
        setFilteredItems([...items])
        setVisibleCount(10)
      }
    }, [isOpen, items])

    const onSelectClick = (e) => {
      e && e.preventDefault()

      if (!disabled) {
        setIsOpen(!isOpen)
      }
    }

    const itemClick = (index) => {
      const item = filteredItems[index]
      setValue(item)
      onChange({value: item.id, name: item.__typename})
      setIsOpen(false)
    }

    const labelClick = (e) => {
      e.stopPropagation()

      if (e.target === inputRef.current && typeof onLabelClick === 'function') {
        onLabelClick()
      } else {
        onSelectClick(e)
      }
    }

    const selectedIndex = filteredItems.findIndex(({id}) => id === value.id)
    const itemData = createItemData(
      filteredItems,
      selectedIndex,
      itemClick,
      selectWidth
    )

    const ITEM_HEIGHT = 30
    const selectHeight =
      items.length === 0
        ? 75
        : ITEM_HEIGHT * items.length + 6 < height
        ? ITEM_HEIGHT * items.length + 6
        : height

    const visibleHeight = visibleCount * ITEM_HEIGHT

    const [data, setData] = useState([])
    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'
    const userId = Cookies.get('userId')
    const auth = Cookies.get('accessToken')

    useEffect(() => {
      axios
        .get(`${apiUrl}/v3/users/${userId}?auth=${auth}`)
        .then((res) => {
          var toolsData = []
          toolsData = res.data.tools

          for (let index = 0; index < toolsData.length; index++) {
            var element = toolsData[index]
            responseData.push(element)
            setData(element)
          }
        })
        .catch((err) => err)
    }, [])

    useEffect(() => {}, [data])
    return (
      <div
        ref={wrapperRef}
        css={`
          height: 100%;
          ${width && `width: ${width};`}
          ${margin && `margin: ${margin};`}
        `}
        {...containerProps}
      >
        <StyledDropdown open={isOpen} onClick={labelClick}>
          <span
            css={`
              font-size: 12px;
              position: absolute;
              width: 220px !important;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              height: 24px;
              padding-left: 10px;
              padding-right: 16px;
              align-items: center;
              display: flex;
              pointer-events: none;
            `}
          >
            {changeValue ? value.name || placeholder : placeholder}

            {data.name == 'zotero' &&
            value.name == 'Zotero' &&
            data.settings.oauth_token != '' ? (
              <span
                css={`
                  height: 7px !important;
                  width: 7px;
                  margin-left: 15px;
                  background-color: #329e4a;
                  border-radius: 50%;
                  display: inline-block;
                `}
              ></span>
            ) : (
              ''
            )}
          </span>

          <input
            readOnly
            css={`
              width: 100%;
              height: 100%;
              background: none;
              cursor: pointer;
              ${value.name && 'opacity: 0;'}

              &:focus {
                outline: none;
              }
            `}
            onClick={labelClick}
            value={value.id || ''}
            ref={inputRef}
            type="text"
            name={name}
          />
        </StyledDropdown>

        {searchable && isOpen && (
          <SearchItem width={width}>
            <Image
              css={`
                position: absolute;
              `}
              src={SearchIcon}
              width={10}
              height={10}
            />
            <input
              css={`
                width: 100%;
                height: 26px;
                background: none;
                color: #ffffff;
                font-weight: bold;
                font-size: 14px;
                margin-left: 18px;

                &:focus {
                  outline: none;
                }
              `}
              onChange={({target}) => setSearchValue(target.value)}
              placeholder="Search..."
              type="text"
              ref={(ref) => ref && ref.focus()}
            />
          </SearchItem>
        )}

        {scrollable ? (
          <ul
            css={`
              ${!isOpen && 'display: none;'}
              right: 181px;
              opacity: ${isOpen ? '1' : '0'};
              height: ${isOpen ? selectHeight : '0'}px;
              border-radius: 3px;
              background-color: #323232;
              color: #ffffff;
              font-size: 14px;
              font-family: 'Proxima Nova';
              position: absolute;
              margin-top: ${dropdownMargin || '5px'};
              margin-bottom: 15px;
              overflow-y: auto;
              z-index: 1;
              ${searchable && 'margin-top: 42px;'}
            `}
            x-track-id="dropdownItems"
            x-track-click="true"
          >
            {!items.length && <Spinner />}

            <List
              height={selectHeight}
              itemCount={filteredItems.length}
              itemData={itemData}
              itemSize={ITEM_HEIGHT}
              width={selectWidth}
            >
              {Row}
            </List>
          </ul>
        ) : (
          <ul
            css={`
              ${!isOpen && 'display: none;'}
              opacity: ${isOpen ? '1' : '0'};
              height: ${isOpen ? 'auto' : '0px'};
              max-height: ${selectHeight}px;
              border-radius: 3px;
              color: #ffffff;
              font-size: 14px;
              font-family: 'Proxima Nova';
              position: absolute;
              margin-top: ${dropdownMargin || '5px'};
              margin-bottom: 15px;
              z-index: 1;
              font-weight: bold;
              ${searchable && 'margin-top: 42px;'}
            `}
            x-track-id="dropdownItems"
            x-track-click="true"
          >
            {!items.length && <Spinner />}

            <div
              css={`
                display: grid;
                grid-auto-flow: column;
                grid-template-rows: repeat(13, auto);
                max-height: 420px;
                background-color: #323232;
                border-radius: 3px;
                cursor: default;
              `}
            >
              {filteredItems.slice(0, visibleCount).map((item, index) => {
                return (
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault()
                      itemClick(index)
                    }}
                    key={item.id}
                  >
                    {item.name}
                  </DropdownItem>
                )
              })}

              {visibleCount < filteredItems.length ? (
                <DropdownItem
                  color="#F94687"
                  onClick={(e) => {
                    e.preventDefault()
                    setVisibleCount(filteredItems.length)
                  }}
                >
                  More
                </DropdownItem>
              ) : (
                filteredItems.length > 12 && (
                  <DropdownItem
                    color="#F94687"
                    onClick={(e) => {
                      e.preventDefault()
                      setVisibleCount(10)
                    }}
                  >
                    Less
                  </DropdownItem>
                )
              )}
            </div>
          </ul>
        )}
      </div>
    )
  }
)

export default Dropdown
