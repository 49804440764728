import styled, {css} from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`

export const FilterTabs = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const FilterTab = styled.div`
  height: 50px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(68,55,133,0.2);
  font-size: 16px;
  font-weight: bold;
  font-family: 'Replica';
  padding: 0 17px;
  display: grid;
  align-items: center;
  grid-template-columns: ${({columns}) => columns || '1fr max-content max-content'};
  max-width: 100%;
  grid-gap: 45px;
  margin: 8px;

  ${({deleting}) => deleting && css`
    background: #534292;
    box-shadow: 0 2px 5px 0 rgba(68,55,133,0.2);
    color: #ffffff;
    grid-gap: 19px;
  `}

  ${({deleted}) => deleted && css`
    opacity: 0.7;
  `}
`

export const FilterTabName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const InteractiveRegion = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Replica';

  &:focus {
    outline: none;
  }
`

export const StyledFilterGroup = styled.div`
  background-color: #f7f7fa;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
  margin: 15px 0;

  label {
    font-size: 15px;
  }

  input[type='text'] {
    width: 100%;
    background-color: #f7f7fa;
    font-size: 15px;
    padding: 15px;
  }

  input:placeholder {
    color: rgba(0, 0, 0, 0.27);
  }
`

export const Message = styled.div`
  color: #323232;
  font-size: 16px;
  width: 246px;
  line-height: 18px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${InteractiveRegion} {
    margin-top: 10px;
  }
`
