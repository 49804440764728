import React from 'react'
import * as bioTechne from 'assets/images/commercial/partnerLogos/bioTechne.png'
import * as lgc from 'assets/images/commercial/partnerLogos/lgc.png'
import * as promega from 'assets/images/commercial/partnerLogos/promega.png'
import * as sinoBiological from 'assets/images/commercial/partnerLogos/sinoBiological.png'
import * as thermo from 'assets/images/commercial/partnerLogos/Thermo.png'
import * as addGene from 'assets/images/commercial/partnerLogos/add_gene.png'

const logosWithUrl = [
  {
    logo: thermo,
    url: 'https://www.thermofisher.com/?utm_source=researcherapp&utm_medium=landingpage&utm_campaign=logo',
  },
  {
    logo: bioTechne,
    url: 'https://www.bio-techne.com/?utm_source=researcherapp&utm_medium=landingpage&utm_campaign=logo',
  },
  {
    logo: promega,
    url: 'https://www.promega.co.uk/?utm_source=researcherapp&utm_medium=landingpage&utm_campaign=logo',
  },
  {
    logo: lgc,
    url: 'https://www.lgcgroup.com/?utm_source=researcherapp&utm_medium=landingpage&utm_campaign=logo',
  },
  {
    logo: addGene,
    url: 'https://www.addgene.org/?utm_source=researcherapp&utm_medium=landingpage&utm_campaign=logo',
  },
  {
    logo: sinoBiological,
    url: 'https://www.sinobiological.com/?utm_source=researcherapp&utm_medium=landingpage&utm_campaign=logo',
  },
]

export const Partners = () => {
  return (
    <div
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        gap: 58px;
        margin: 50px;
      `}
    >
      {logosWithUrl.map(({logo, url}, i) => (
        <div key={`commercialPartnerLogogs${i}`}>
          {' '}
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img
              src={logo}
              alt={logo}
              css={`
                height: auto;
                max-width: 100%;
              `}
            />
          </a>
        </div>
      ))}
    </div>
  )
}
