import React from 'react'
import styled, { css } from 'styled-components'

// TODO: update progress indicator percentages
// TODO: add cursor pointer to buttons, style button properly
// TODO: make button global
const Button = styled.button`
  width: ${props => props.width || '400px'};
  padding: 15px 0;
  border-radius: 3px;
  font-size: 16px;
  font-family: 'Replica';
  font-weight: bold;
  background: ${props => props.background || '#FDDEEA'};
  color: ${props => props.foreground || 'white'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin-right: 10px;
  }

  &:disabled {
    background: #f7f7fa !important;
    color: #cacad3 !important;
    cursor: auto;
  }

  &:active {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.6);
  }
`

const InputGroup = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 60px;
  align-items: center;

  a {
    color: #f94687;
  }

  & > *:not(:first-child) {
    margin-top: 20px;
  }
`

const BottomSection = styled.div`
  background: #f7f7fa;
  height: 60px;

  ${Button} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    color: #ffffff;
    background: #f94687;
  }
`

const Indicator = styled.div`
  width: ${props => props.progress || 0}%;
  min-height: 100%;
  background: #f94687;
`

const Wrapper = styled.div`
  width: ${props => props.width || '460px'};
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(68, 55, 133, 0.2);
  border-radius: 5px;
  margin: 15px 0;
`

const Heading = styled.h1`
  color: #323232;
  font-weight: bold;
  margin: 10px 0;
  font-size: 16px;
  width: 100%;

  :not(:first-of-type) {
    margin: 35px 0 10px 0;
  }
`

const StyledBack = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-top: 40px;
  font-weight: bold;
  font-size: 16px;

  img {
    margin-right: 8px;
  }
`

const FormErrorStyled = styled.span`
  display: block;
  padding-top: 3px;
  color: red;
  height: 16px;
  opacity: 1;
  width: 100%;
  font-size: 14px;

  &.fade-enter {
    opacity: 0;
    height: 0;
    transition: opacity 0.2s linear 0.2s, height 0.2s linear;
  }

  &.fade-enter-active {
    opacity: 1;
    height: 16px;
  }

  &.fade-exit {
    opacity: 1;
    height: 16px;
    transition: opacity 0.2s linear, height 0.2s linear 0.2s;
  }

  &.fade-exit-active {
    opacity: 0;
    height: 0;
  }
`

const FullSizeHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  flex: none;
`

const FormError = ({ error, children }) => {
  if (!error) error = children
  if (!error) return null
  if (error.graphQLErrors && error.graphQLErrors[0]) {
    error = error.graphQLErrors[0]
  }
  if (error.message) error = error.message
  if (error.details) error = error.details
  if (error.type && typeof error.type === 'string') {
    error = error.type[0].toUpperCase() + error.type.slice(1)
  }
  if (typeof error !== 'string') error = 'Something went wrong'
  return <FormErrorStyled>{error}</FormErrorStyled>
}

const Title = styled.h1`
  font-size: 22px;
  padding: 48px 0;
  font-family: Replica;
  text-align: center;
`

const ProgressIndicator = ({ progress }) => {
  return (
    <div
      css={`
        height: 3px;
        width: 100%;
        background: #E6E6E6;
        flex-shrink: 0;
      `}
    >
      <Indicator progress={progress} />
    </div>
  )
}

const canSubmit = (formState, errors) => !!Object.keys(errors).length
const didError = (formState, field, errors) =>
  errors[field] && formState.touched.indexOf(field) >= 0

export {
  Button,
  InputGroup,
  ContentSection,
  BottomSection,
  Indicator,
  Wrapper,
  Heading,
  StyledBack,
  FormError,
  Title,
  ProgressIndicator,
  FullSizeHeader,
  canSubmit,
  didError
}
