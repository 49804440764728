import {sendEvent} from 'lib/segmentHelperMethods'

export const feedCreateEdit = (
  feedCreateEditPayload: any,
  id?: string | number
) => {
  const filterEvent = {
    event: 'filter',
    event_page: 'filter_editor',
    event_type: id ? 'edit_update' : 'edit_add',
    event_action: 'complete',
    event_filter: +id || undefined,
    event_details: feedCreateEditPayload,
  }

  sendEvent(filterEvent)
}

export const manageFeedButtonClick = () => {
  const filterEvent = {
    event: 'filter',
    event_page: 'feed',
    event_type: 'edit_add',
    event_action: 'select',
  }

  sendEvent(filterEvent)
}

export const createEditFeedQuestionMarkIconClick = (id?: string | number) => {
  const filterEvent = {
    event: 'filter',
    event_page: 'filter_editor',
    event_type: 'question_mark',
    event_action: 'click',
    event_filter: +id || undefined,
  }

  sendEvent(filterEvent)
}
