import React from 'react'
import {css} from 'styled-components'
import {Button} from 'components/shared/ModalComponents'
import Image from 'components/shared/Image'

const SearchIcon = '/images/sidebarIconSearch.svg'

const SearchInput = ({initialValue, onSubmit}) => {
  const [inputValue, setInputValue] = React.useState(initialValue || '')

  React.useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  return (
    <form
      method="POST"
      css={`
        display: flex;
        align-items: center;
        ${!initialValue && 'padding: 0 20px;'}
      `}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(inputValue)
      }}
    >
      <div
        css={`
          ${initialValue &&
          css`
            height: 67px;
            width: 100%;
            border-bottom: 1px solid #ededf5;
            padding-left: 60px;
            padding-right: 80px;
          `};
          ${!initialValue &&
          css`
            height: 93px;
            border-radius: 5px;
            max-width: 940px;
            width: 100%;
            box-shadow: 0 7px 11px -3px rgba(0, 0, 0, 0.05);
            padding-right: 20px;
          `}
          background-color: #FFFFFF;
          align-items: center;
          display: flex;
          margin: 0 auto;
        `}
      >
        <Image
          css={`
            pointer-events: none;
            ${initialValue
              ? css`
                  margin-left: 20px;
                `
              : css`
                  margin-left: 40px;
                `}
            position: absolute;
          `}
          src={SearchIcon}
          height={21}
          width={21}
        />
        <input
          ref={(input) => input && input.focus()}
          value={inputValue}
          placeholder="Search papers..."
          css={`
            color: #323232;
            font-size: 22px;
            line-height: 27px;
            font-family: 'Replica';
            padding-left: 90px;
            padding-right: 20px;
            height: 100%;
            flex: 1;

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: #bbbbbb;
            }
          `}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
        />

        {initialValue ? (
          <Button
            width="140px"
            background="#F94687"
            x-track-id="searchButton"
            x-track-click="true"
            x-track-detail={JSON.stringify({searchValue: inputValue})}
          >
            Search
          </Button>
        ) : (
          <Button
            width="140px"
            background="#F94687"
            x-track-id="searchButton"
            x-track-click="true"
            x-track-detail={JSON.stringify({searchValue: inputValue})}
          >
            Search
          </Button>
        )}
      </div>
    </form>
  )
}

export default SearchInput
