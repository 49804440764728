export enum Matchtype {
  EXACT = 'exact',
  PHRASE = 'phrase',
  DEFAULT = 'default',
}
export enum MatchField {
  subjectId = 'subject_id',
  researchAreadId = 'research_area_id',
  journalId = 'journal_id',
  author = 'author',
  topic = 'topic',
  keyword = 'abstract',
}

export const matchFieldMap = {
  [MatchField.keyword]: 'keyword',
  [MatchField.topic]: 'topic',
}

const MatchFieldMapping = {
  keyword: 'abstract',
}

export enum LogicFlow {
  AND = 'AND',
  OR = 'OR',
}

export enum BtnText {
  SAVE = 'Save',
  DONE = 'Done',
  PREVIOUS = 'Previous',
  NEXT = 'Next',
  OK = 'OK',
  YES = 'Yes',
}

export enum ExclusionArrayName {
  KEYWORDSTOPIC = 'keywordsTopics',
  subjects = 'subjects',
  PUBLICATIONS = 'publications',
  AUTHORS = 'authors',
}
