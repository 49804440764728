import convert from 'server/datasourceConvertPrimitive'
import getMetadata from './trackMetadata'

const getEnvironment = () => {
  return window.location.href.includes('staging')
    ? 'test'
    : window.location.href.indexOf('pr-') == -1 &&
      window.location.href.indexOf('localhost') == -1
    ? 'production'
    : 'local'
}

export const getFeedType = (inputPath) => {
  const path = inputPath || document.location.pathname
  const subPaths = path.split('/')
  let feedType
  if (path.endsWith('all')) {
    feedType = 'all_feed'
  } else if (path.endsWith('mine')) {
    feedType = 'my_feeds'
  } else if (path.endsWith('publications')) {
    feedType = 'my_publications'
  } else if (
    subPaths &&
    subPaths[1] &&
    subPaths[2] &&
    path === '/feed/' + subPaths[2]
  ) {
    feedType = 'filtered_feed'
  } else if (
    subPaths &&
    subPaths[1] &&
    subPaths[2] &&
    subPaths[3] &&
    path === '/feed/journal/' + subPaths[3]
  ) {
    feedType = 'publication_feed'
  }

  return feedType
}

export const pathnameToFilter = (pathname) => {
  const match = pathname.match(/\/feed\/(\w+)/)
  if (!match) return undefined
  let feed = match[1]
  if (['all', 'publications', 'mine'].includes(feed)) return undefined
  if (feed === 'journal') {
    feed = pathname.split('/').pop()
  }
  return feed
}

export const getPagename = (pathname) => {
  pathname = pathname || document.location.pathname

  if (pathname === '/') return 'landing.main'
  if (pathname === '/advertise') return 'landing.publishers'
  if (pathname.startsWith('/discover')) return 'landing.discover'
  if (pathname.startsWith('/about')) return 'landing.about'
  if (pathname.startsWith('/authors')) return 'landing.authors'
  if (pathname.startsWith('/feed')) return 'feed'
  if (pathname.startsWith('/paper/')) return 'selected'
  if (pathname.startsWith('/feed/journal/')) return 'journal_feed'
  if (pathname.startsWith('/journals/following')) return 'my_journals'
  if (/^\/journals\/\w+\/\w+/.test(pathname)) {
    return 'all_journals_journals'
  }
  if (/^\/journals\/\w+/.test(pathname)) {
    return 'all_journals_subjects'
  }
  if (pathname.startsWith('/journals/')) {
    return 'all_journals_research_areas'
  }
  if (pathname.startsWith('/search')) return 'search'
  if (pathname.startsWith('/library')) return 'bookmarks'
  if (pathname.startsWith('/settings')) return 'settings'
  if (pathname.startsWith('/onboarding/journals')) return 'onboarding_journals'
  if (pathname.startsWith('/onboarding/about')) return 'onboarding_profile'
  if (pathname.startsWith('/onboarding/filter')) return 'onboarding_filter'
  if (pathname.startsWith('/onboarding')) return 'onboarding'
  if (pathname.startsWith('/login')) return 'login'
  if (pathname.startsWith('/signup/terms')) return 'gdpr'
  if (pathname.startsWith('/profile/me')) return 'user_profile'
  if (pathname.startsWith('/trending')) return 'trending.papers'

  return pathname.slice(1).replace(/\//g, '_')
}

export const sendEvent = async (event) => {
  const env = getEnvironment()
  const metadata = await getMetadata()
  const metadataPatch = {
    event_source: 'researcher.web',
    schema_version: 2.2,
    event_local_timestamp: metadata.timezoneOffset,
    event_user: convert.stripUndefinedValues({
      user_id: +metadata.viewerId || undefined,
      uuid: metadata.viewerUUID,
      session_id: metadata.sessionId,
      device_timestamp: metadata.deviceTimestamp,
      ...event.event_user,
    }),
  }

  const eventName = event.event
  event.event = undefined
  event = convert.stripUndefinedValues({
    ...event,
    ...metadataPatch,
    environment: env,
  })

  var eventFinal = {}

  Object.entries(event).map(([key, item]) => {
    if (typeof item === 'object' && item !== null) {
      eventFinal[key] = JSON.stringify(item)
    } else {
      eventFinal[key] = item
    }
  })

  console.log({eventFinal})

  try {
    window.analytics.track(eventName, eventFinal)
  } catch (err) {
    console.log(err)
  }
}
