import Cookies from 'js-cookie'

import {
  SET_SCROLL_POS,
  SET_OPEN_ACCESS,
  REFETCH_FEED,
} from '../mutation-types'

const initialState = {
  scrollPos: 0,
  openAccess: !!Cookies.get('openAccess'),
  performRefetch: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_POS:
      return {
        ...state,
        scrollPos: action.scrollPos,
      }

    case SET_OPEN_ACCESS:
      if (action.enabled) {
        Cookies.set('openAccess', 'true')
      } else {
        Cookies.remove('openAccess')
      }

      return {
        ...state,
        openAccess: action.enabled
      }

    case REFETCH_FEED:
      const newPerformRefetch = {...action.performRefetch}

      if (action.refetch) {
        newPerformRefetch[action.feedName] = true

        return {
          ...state,
          performRefetch: newPerformRefetch
        }
      } else {
        delete newPerformRefetch[action.feedName]

        return {
          ...state,
          scrollPos: 0,
          performRefetch: newPerformRefetch
        }
      }

    default:
      return state
  }
}
