import {CustomModalHeader, ModalSize} from 'components/FeedV2/CustomDialog'
import CustomModal from 'components/FeedV2/CustomDialog/CustomModal'
import {CustomModalFooter} from 'components/FeedV2/CustomDialog/CustomModalFooter'
import {CreateEditAuthor} from 'components/FeedV2/shared/CreateEditAuthor/CreateEditAuthor'
import {CreateEditKeyword} from 'components/FeedV2/shared/CreateEditKeyword.tsx/CreateEditKeyword'
import {
  BtnType,
  BtnVariant,
  CustomButton,
} from 'components/FeedV2/shared/CustomButton/CustomButton'
import {
  getFormSubmitBtnConfig,
  TranslationKeys,
} from 'components/FeedV2/util/util'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {CreateEditSubject} from 'components/FeedV2/shared/CreateEditSubject/CreateEditSubject'
import {CreateEditPublication} from 'components/FeedV2/shared/CreateEditPublication/CreateEditPublication'
import {ExcludedItemList} from './ExcludedItemsList'
import {ExclusionArrayName} from 'components/FeedV2/interfaces/types'
import {useDispatch} from 'react-redux'
import {removeExcludedItem} from 'store/actions/feedManager'
import {SubjectIcon} from 'components/FeedV2/shared/SubjectIcon/SubjectIcon'
import {PublicationIcon} from 'components/FeedV2/shared/PublicationIcon/PublicationIcon'
import {TermIcon} from 'components/FeedV2/shared/TermIcon/TermIcon'
import {AuthorIcon} from 'components/FeedV2/shared/AuthorIcon/AuthorIcon'

type ExcludedSectionProps = {
  title: string
  subtitle: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
  margin-bottom: 1em;
`

const ExcludedItemListWrapper = styled.div`
  height: '200px';
  overflow: 'auto';
  margin-top: 1em;
  margin-bottom: 1em;
`

// State considerations:
// which popup? Author|keyword|....
// what is the data for that open exclusion popup? or is it creation?
export const ExcludedSection = () => {
  const {isSubmitBtnDisable, activeFeed} = useSelector(
    (state: any) => state.feedManager
  )

  const dispatch = useDispatch()

  const {authors, keywordsTopics, publications, subjects} =
    activeFeed.exclusions

  const [isCreateEditKeywordOpen, setIsCreateEditKeywordOpen] = useState(false)
  const [isCreateEditAuthorOpen, setIsCreateEditAuthorOpen] = useState(false)
  const [isCreateEditSubjectOpen, setIsCreateEditSubjectOpen] = useState(false)
  const [isCreateEditPublicationOpen, setIsCreateEditPublicationOpen] =
    useState(false)

  const openCreateEditKeyword = () => {
    setIsCreateEditKeywordOpen(true)
  }

  const openCreateEditAuthor = () => {
    setIsCreateEditAuthorOpen(true)
  }

  const closeEditCreateAuthor = () => {
    setIsCreateEditAuthorOpen(false)
  }

  const closeEditCreateKeyword = () => {
    setIsCreateEditKeywordOpen(false)
  }

  const handleAction = async (cb) => {
    setTimeout(() => {
      cb()
    }, 100)
  }

  const closeCreateEditSubject = () => {
    setIsCreateEditSubjectOpen(false)
  }

  const openCreateEditSubject = () => {
    setIsCreateEditSubjectOpen(true)
  }

  const closeCreateEditPublication = () => {
    setIsCreateEditPublicationOpen(false)
  }

  const openCreateEditPublication = () => {
    setIsCreateEditPublicationOpen(true)
  }

  const onDeleteExcludedItem = (data, index, arrayName) => {
    dispatch(removeExcludedItem(arrayName, index))
  }

  const keywordTopicBtnMarkup = (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <p style={{lineHeight: '1.2'}}>{TranslationKeys.keywords}</p>
      <p style={{lineHeight: '1.2'}}>{TranslationKeys.topic}</p>
    </div>
  )

  return (
    <>
      {/* Add keyword */}
      <Container>
        <Section>
          {/* <img src={keywordIcon} alt="keywordIcon" /> */}
          <TermIcon />
          <CustomButton
            children={keywordTopicBtnMarkup}
            onClick={openCreateEditKeyword}
            backgroundColor="transparent"
            width="145px"
            height="50px"
            btnVariant={BtnVariant.OUTLINED}
            type={BtnType.BUTTON}
          />
        </Section>

        {/* Add Subjects */}
        <Section>
          {/* <img src={subjectsIcon} alt="subjectsIcon" /> */}
          <SubjectIcon />

          <CustomButton
            children={`${TranslationKeys.subjects}`}
            onClick={openCreateEditSubject}
            backgroundColor="transparent"
            width="145px"
            height="50px"
            btnVariant={BtnVariant.OUTLINED}
            type={BtnType.BUTTON}
          />
        </Section>

        {/* Add publication */}
        <Section>
          {/* <img src={ReactLogo} /> */}
          {/* <img src={publicationsIcon} alt="publicationsIcon" /> */}
          <PublicationIcon />
          <CustomButton
            children={`${TranslationKeys.publications}`}
            onClick={openCreateEditPublication}
            backgroundColor="transparent"
            width="145px"
            height="50px"
            btnVariant={BtnVariant.OUTLINED}
            type={BtnType.BUTTON}
          />
        </Section>

        {/* Add author */}
        <Section>
          {/* <img src={authorsIcon} alt="authorsIcon" /> */}
          <AuthorIcon />
          <CustomButton
            children={`${TranslationKeys.authors}`}
            onClick={openCreateEditAuthor}
            backgroundColor="transparent"
            width="145px"
            height="50px"
            btnVariant={BtnVariant.OUTLINED}
            type={BtnType.BUTTON}
          />
        </Section>
      </Container>
      <Container>
        <Section>
          <ExcludedItemListWrapper>
            <ExcludedItemList
              list={keywordsTopics}
              onDelete={(data, index) =>
                onDeleteExcludedItem(
                  data,
                  index,
                  ExclusionArrayName.KEYWORDSTOPIC
                )
              }
            />
            {/* {keywordsTopics.length > 0
              ? keywordsTopics.map((keywordOrTopic, i) => (
                  <div key={i + 'feed.id'}>
                    <ConfigurableChipWrapper
                      //   isClickable
                      children={keywordOrTopic.terms}
                      filter={keywordOrTopic}
                      onDelete={onDeleteKeywordOrTopic}
                      width={'145px'}
                    />
                  </div>
                ))
              : dummyPlaceholder} */}
          </ExcludedItemListWrapper>
        </Section>

        <Section>
          <ExcludedItemListWrapper>
            <ExcludedItemList
              list={subjects}
              onDelete={(data, index) =>
                onDeleteExcludedItem(data, index, ExclusionArrayName.subjects)
              }
            />
          </ExcludedItemListWrapper>
        </Section>

        {/* Add publication */}
        <Section>
          <ExcludedItemListWrapper>
            <ExcludedItemList
              list={publications}
              onDelete={(data, index) =>
                onDeleteExcludedItem(
                  data,
                  index,
                  ExclusionArrayName.PUBLICATIONS
                )
              }
            />
          </ExcludedItemListWrapper>
        </Section>

        {/* Add author */}
        <Section>
          <ExcludedItemListWrapper>
            <ExcludedItemList
              list={authors}
              onDelete={(data, index) =>
                onDeleteExcludedItem(data, index, ExclusionArrayName.AUTHORS)
              }
            />
          </ExcludedItemListWrapper>
        </Section>
      </Container>

      {/* Keyword create edit flow */}
      {isCreateEditKeywordOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              title={`${TranslationKeys.exclude} ${TranslationKeys.keywordTopic}`}
              handleClose={closeEditCreateKeyword}
              size={ModalSize.sm}
            />
          }
          // Consideration for multiple props
          body={<CreateEditKeyword isFromExcluded />}
          size={ModalSize.sm}
          footer={
            <CustomModalFooter
              primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
              handleAction={() => handleAction(closeEditCreateKeyword)}
              size={ModalSize.sm}
            />
          }
        />
      )}

      {/* Author create edit flow */}
      {isCreateEditAuthorOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              // title={getFormModalTitle(TranslationKeys.author)}
              title={`${TranslationKeys.exclude} ${TranslationKeys.author}`}
              handleClose={closeEditCreateAuthor}
              size={ModalSize.sm}
            />
          }
          body={
            <CreateEditAuthor firstName={''} lastName={''} isFromExcluded />
          }
          size={ModalSize.sm}
          footer={
            <CustomModalFooter
              primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
              handleAction={() => handleAction(closeEditCreateAuthor)}
              size={ModalSize.sm}
            />
          }
        />
      )}

      {isCreateEditSubjectOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              // title={getFormModalTitle(TranslationKeys.subjects)}
              title={`${TranslationKeys.exclude} ${TranslationKeys.subject}`}
              handleClose={closeCreateEditSubject}
              size={ModalSize.sm}
            />
          }
          // Consideration for multiple props
          body={<CreateEditSubject />}
          size={ModalSize.sm}
          footer={
            <CustomModalFooter
              primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
              handleAction={() => handleAction(closeCreateEditSubject)}
              size={ModalSize.sm}
            />
          }
        />
      )}
      {isCreateEditPublicationOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              // title={getFormModalTitle(TranslationKeys.searchPublication)}
              title={`${TranslationKeys.exclude} ${TranslationKeys.publication}`}
              handleClose={closeCreateEditPublication}
              size={ModalSize.sm}
            />
          }
          // Consideration for multiple props
          body={<CreateEditPublication />}
          size={ModalSize.sm}
          footer={
            <CustomModalFooter
              primaryBtnConfig={getFormSubmitBtnConfig(isSubmitBtnDisable)}
              handleAction={() => handleAction(closeCreateEditPublication)}
              size={ModalSize.sm}
            />
          }
        />
      )}
    </>
  )
}
