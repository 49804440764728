import './serviceworker'
import 'lib/trackEverythingStream'
import 'lib/trackAlooma'
import Layout from 'Layout'
import Cookies from 'js-cookie'
import { Provider } from 'react-redux';
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { BrowserRouter, useHistory } from 'react-router-dom'
import React from 'react'
import { hydrate } from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import { ApolloClient, InMemoryCache } from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import schemaIndex from 'server/schemaIndex.gql'
import schemaTypes from 'server/schemaTypes.gql'
import { VIEWER_PROFILE } from 'components/AheadOfTimeLoader'
import configureStore from './store';
import { changeHistory } from './store/actions/history'

const store = configureStore(window.__REDUX_STATE__);

window.isClient = true

Sentry.init({
  dsn:
    window.env.SENTRY_DSN ||
    'https://58dc42489d6945a8a80881fd9e0c44be@sentry.io/1806824',
  release: window.env.HEROKU_RELEASE_VERSION,
  isStaging: window.env.IS_STAGING,
  API_URL: window.env.API_URL
})

const client = new ApolloClient({
  typeDefs: [schemaIndex, schemaTypes],
  name: 'web-browser',
  version: '0.1.0',
  link: ApolloLink.from([
    onError(({ graphQLErrors }) => {
      for (let error of graphQLErrors) {
        if (error.message === 'Invalid access token') {
          Cookies.remove('userId')
          Cookies.remove('accessToken')
          Cookies.remove('accessTokenExpiry')
          Cookies.remove('refreshToken')
          client.resetStore()
        }
      }
    }),
    createHttpLink({
      uri: '/graphql',
      credentials: 'same-origin'
    }),
  ]),
  cache: new InMemoryCache({
    cacheRedirects: {
      Query: {
        viewer: (_, args, { getCacheKey }) => {
          const viewerId = JSON.parse(Cookies.get('userId') || 'null')
          return getCacheKey({ __typename: 'User', id: viewerId })
        },
        user: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'User', id: args.id }),
        journal: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'Journal', id: args.id }),
        paper: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'Paper', id: args.id }),
        v4Paper: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'V4Paper', id: args.id }),
        filter: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'Filter', id: args.id }),
        researchArea: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'ResearchArea', id: args.id }),
        subject: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'Subject', id: args.id }),
        university: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'University', id: args.id }),
        occupation: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: 'Occupation', id: args.id })
      }
    }
  }).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100
})

client
  .watchQuery({ query: VIEWER_PROFILE })
  .subscribe(({ data: { viewer } }) => {
    Sentry.configureScope((scope) => {
      scope.setUser(viewer)
    })
  })

window.apolloClient = client

// poll logged out status
setInterval(() => {
  const loggedIn = !!Cookies.get('userId')
  if (loggedIn) return
  const cacheData = client.cache.data.data
  const viewerCached = !!Object.keys(cacheData).find(k => k.startsWith('User'))
  if (viewerCached && !loggedIn) {
    Sentry.configureScope((scope) => {
      scope.setUser({})
    })
  }
}, 2000)

const NavigateBroadast = () => {
  const history = useHistory()
  React.useEffect(() => {
    const navigate = new Event('navigate')
    const unlisten = history.listen(() => {
      window.dispatchEvent(navigate)
      store.dispatch(changeHistory())
    })
    return unlisten
  }, [history])

  return null
}

hydrate(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        {/* <AheadOfTimeLoader /> */}
        <NavigateBroadast />
        <Layout />
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
