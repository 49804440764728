import React from 'react'
export const PublicationIcon = () => (
  // <?xml version="1.0" encoding="UTF-8"?>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="74"
    height="87"
    viewBox="0 0 74 87"
  >
    <defs>
      <path id="path-1" d="M0 86.96L73.749 86.96 73.749 0 0 0z"></path>
    </defs>
    <g
      id="Symbols"
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    >
      <g id="icon_publications">
        <g
          id="Group-5"
          stroke="#545454"
          strokeWidth="0.34"
          transform="translate(3.266 .17)"
        >
          <path
            id="Stroke-1"
            d="M61.95 76.165A43.134 43.134 0 0133.73 86.62 43.137 43.137 0 015.504 76.16"
          ></path>
          <path
            id="Stroke-3"
            d="M0 16.142C7.938 6.298 20.1 0 33.73 0c13.394 0 25.368 6.08 33.313 15.631"
          ></path>
        </g>
        <g id="Group-35">
          <path
            id="Stroke-6"
            stroke="#545454"
            strokeWidth="0.34"
            d="M36.874 29.837v42.287a4.202 4.202 0 00-4.202-4.202H.17V20.534h32.502a4.201 4.201 0 014.202 4.201v1.851"
          ></path>
          <path
            id="Stroke-8"
            stroke="#545454"
            strokeWidth="0.34"
            d="M36.874 76.326a4.202 4.202 0 01-4.202-4.202H2.635v-4.201"
          ></path>
          <path
            id="Stroke-10"
            stroke="#545454"
            strokeWidth="0.34"
            d="M67.655 67.922h5.923V20.534H41.076a4.201 4.201 0 00-4.202 4.201"
          ></path>
          <path
            id="Stroke-12"
            stroke="#545454"
            strokeWidth="0.34"
            d="M36.874 72.124a4.202 4.202 0 014.202-4.202h23.978"
          ></path>
          <path
            id="Stroke-14"
            stroke="#545454"
            strokeWidth="0.34"
            d="M36.874 76.326a4.202 4.202 0 004.202-4.202h30.037v-4.201"
          ></path>
          <path
            id="Stroke-16"
            stroke="#545454"
            strokeWidth="0.34"
            d="M5.779 54.155L31.265 54.155"
          ></path>
          <path
            id="Stroke-18"
            stroke="#545454"
            strokeWidth="0.34"
            d="M5.779 59.096L31.265 59.096"
          ></path>
          <path
            id="Stroke-20"
            stroke="#545454"
            strokeWidth="0.34"
            d="M41.93 31.788L67.417 31.788"
          ></path>
          <path
            id="Stroke-22"
            stroke="#545454"
            strokeWidth="0.34"
            d="M41.93 26.846L67.417 26.846"
          ></path>
          <path
            id="Stroke-24"
            stroke="#545454"
            strokeWidth="0.34"
            d="M41.93 36.729L67.417 36.729"
          ></path>
          <mask id="mask-2" fill="#fff">
            <use xlinkHref="#path-1"></use>
          </mask>
          <path
            id="Fill-26"
            fill="#545454"
            d="M7.187 48.433h23.537V26.846H7.187v21.587zm.34-.34h22.857V27.186H7.527v20.907z"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Fill-28"
            fill="#545454"
            d="M42.905 58.619h23.538V42.451H42.905v16.168zm.34-.339h22.857V42.792H43.245V58.28z"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Fill-29"
            fill="#545454"
            d="M18.955 31.137a3.251 3.251 0 100 6.502 3.251 3.251 0 000-6.502m0 .34a2.915 2.915 0 012.911 2.912 2.915 2.915 0 01-2.91 2.91 2.914 2.914 0 01-2.912-2.91 2.914 2.914 0 012.911-2.912"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Stroke-30"
            stroke="#545454"
            strokeWidth="0.34"
            d="M11.867 47.913V43.62s1.431-2.47 3.121-2.47c1.691 0 3.967 1.343 3.967 3.077"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Stroke-31"
            stroke="#545454"
            strokeWidth="0.34"
            d="M26.042 47.913V43.62s-1.43-2.47-3.12-2.47c-1.692 0-3.967 1.343-3.967 3.077"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Stroke-32"
            stroke="#545454"
            strokeWidth="0.34"
            d="M53.029 51.896c-5.185-.567-10.108 3.169-10.108 3.169"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Fill-33"
            fill="#545454"
            d="M57.406 46.329a4.588 4.588 0 00-4.588 4.588c0 .398.067.778.162 1.147.753.082 1.511.25 2.262.53A26.228 26.228 0 0060.8 53.98a4.557 4.557 0 001.194-3.063 4.588 4.588 0 00-4.588-4.588m0 .34a4.253 4.253 0 014.248 4.248c0 .98-.35 1.932-.989 2.7a25.81 25.81 0 01-5.304-1.341 9.628 9.628 0 00-2.104-.521 3.789 3.789 0 01-.099-.838 4.253 4.253 0 014.248-4.248"
            mask="url(#mask-2)"
          ></path>
          <path
            id="Stroke-34"
            stroke="#545454"
            strokeWidth="0.34"
            d="M60.8 53.795s2.885 1.836 5.412 1.27"
            mask="url(#mask-2)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
