import gql from 'graphql-tag'

const FeedPaper = gql`
  fragment FeedPaper on Paper {
    id
    title
    abstract
    publishedAt
    pdfUrl
    openAccessUrl
    authors {
      id
      name
    }
    journal {
      id
    }
    headlineImage {
      baseURL
    }
  }
`

const V4FeedPaper = gql`
  fragment V4FeedPaper on V4Paper {
    id
    title
    abstract
    htmlAbstract
    createdDate
    media {
      url
      html
      mimeType
    }
    settings {
      noAbstractHeader
      appendUserInfo
    }
    pdfUrl
    openAccessUrl
    authors
    contentType
    actionButton {
      text
    }
    journal {
      id
      name
      cover {
        baseURL
      }
    }
    headlineImage {
      baseURL
    }
  }
`

const FullPaper = gql`
  fragment FullPaper on Paper {
    id
    ...FeedPaper
    bookmarked
    doi
    paperUrl
    headlineImage {
      id
      base64
      baseURL
    }
    recommended(limit: 4) {
      id
      ...FeedPaper
    }
  }

  ${FeedPaper}
`

const FullProfile = gql`
  fragment FullProfile on User {
    id
    name
    avatar {
      base64
      baseURL
    }
    university {
      id
      name
    }
    occupation {
      id
      name
    }
    stats {
      filterCount
      bookmarkCount
      journalSubscriptionCount
      papersViewedCount
    }
  }
`
const FullJournal = gql`
  fragment FullJournal on Journal {
    id
    name
    subscribed
    cover {
      mostVibrantColor
      base64
      baseURL
    }
  }
`

const BookmarkResponse = gql`
  fragment BookmarkResponse on MutationResponse {
    status
    refetch {
      viewer {
        id
        library {
          id
        }
      }
      paper(id: $paper) {
        ...FullPaper
      }
    }
  }
  
  ${FullPaper}
`

export default { FeedPaper, FullProfile, FullJournal, BookmarkResponse, FullPaper, V4FeedPaper }
