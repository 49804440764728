import React from 'react'
import {Card, CardHeader, CardBody, CardFooter} from './shared/FeedCardBasicV2'
import theme from '../lib/theme'
import Link from './shared/Link'
import styled, {css} from 'styled-components'
import Cookies from 'js-cookie'
import {to64} from '../lib/base64'
import FeedCardPaper from 'components/shared/FeedCardPaperV2'
import Button from 'components/shared/Button'

const BookmarkIconPink = '/static-images/bookmarkIconPink.svg'
const BookmarkIconTick = '/static-images/bookmarkIconTick.svg'

const PromotedJournalCardContainer = ({children}) => {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        background: ${theme.colors.white};
        border-radius: 5px;
        max-width: 940px;
        margin-left: 10px;
        margin-bottom: 20px;

        @media only screen and (max-width: 1270px) {
          max-width: 100%;
        }
      `}
    >
      <div
        css={`
          padding: 24px;
          flex: 1;
        `}
      >
        <div
          css={`
            display: flex;
          `}
        >
          <div
            css={`
              width: 100%;
            `}
          >
            {!!children && children}
          </div>
        </div>
      </div>
    </div>
  )
}

const JournalPromotedCard = ({ad, adJournal}) => {
  const [journal, setJournal] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'
    var auth = Cookies.get('accessToken')
    const id =
      ad && ad.elements && ad.elements.length > 1 && ad.elements[3].content_ids
        ? ad.elements[3].content_ids[0]
        : ''

    fetch(`${apiUrl}/v3/journals/${id}?auth=${auth}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setJournal(result.data.journal || null)
      })
      .catch((error) => console.log('error', error))
  }, [])

  const content =
    ad &&
    ad.elements &&
    ad.elements.length > 1 &&
    ad.elements[1].content_description
      ? ad.elements[1].content_description
      : ''
  const buttonTextFromAd =
    ad &&
    ad.elements &&
    ad.elements.length > 1 &&
    ad.elements[3].content_description
      ? ad.elements[3].content_description
      : ''
  const buttonText = !journal
    ? 'Loading'
    : journal.subscribed
    ? 'Following'
    : buttonTextFromAd

  const handleClick = () => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({journal_ids: [journal.id]})
    setLoading(true)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }
    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'
    var auth = Cookies.get('accessToken')
    const userId = Cookies.get('userId')
    fetch(`${apiUrl}/v4/users/${userId}/journals?auth=${auth}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setJournal({...journal, subscribed: true})
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }

  return (
    <PromotedJournalCardContainer>
      <div
        css={`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={`
            width: 200px;
            height: 280px;
            border-radius: 5px;
            background-color: grey;
            background-image: url('${adJournal.image}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />
        <div
          css={`
            margin-left: 24px;
            flex: 1;
            display: flex;
            flex-direction: column;
          `}
        >
          <h3
            title={adJournal ? adJournal.name : 'Loading...'}
            css={`
              font-size: 22px;
              letter-spacing: 0;
              line-height: 27px;
              font-weight: 500;
            `}
            dangerouslySetInnerHTML={{
              __html: adJournal ? adJournal.name : 'Loading...',
            }}
          />
          <div
            css={`
              color: #9b9ba7;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 24px;
            `}
          >
            Promoted
          </div>
          <div
            css={`
              font-size: 14px;
              line-height: 24px;
              overflow: hidden;
              max-height: 168px;
              font-family: 'Replica';
              -webkit-font-smoothing: antialiased;
              margin-top: 8px;
            `}
            dangerouslySetInnerHTML={{__html: content}}
          />
          <Button
            primary
            css={`
              min-width: 0px;
              width: 120px;
              padding: 0px;
              margin: 16px 0;
            `}
            disabled={!journal || journal.subscribed || loading}
            x-track-id="advertisement"
            x-track-click="true"
            x-track-detail={JSON.stringify({
              campaign: ad.campaign_id,
            })}
            onClick={handleClick}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </PromotedJournalCardContainer>
  )
}

const StyledBookmarkButtton = styled.button`
  outline: none;
  border-radius: 100%;
  height: 42px;
  width: 42px;
  border: 1px solid #d7d7e0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(68, 55, 133, 0.2);
  pointer-events: cursor;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(68, 55, 133, 0.4);
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
`

const JournalPreview = ({journal}) => {
  if (!journal) return <></>

  const Comp = ({children}) => {
    return journal.id ? (
      <Link
        to={journal.id ? `/feed/journal/${journal.id}` : undefined}
        extraCss={`
      display: flex;
      align-items: center;
      color: ${theme.colors.blacka};
      text-decoration: none;
      line-height: 18px;
      &:hover {
        text-decoration: underline;
      }
    `}
      >
        {children}
      </Link>
    ) : (
      <div
        css={`
          display: flex;
          align-items: center;
          color: ${theme.colors.blacka};
          text-decoration: none;
          line-height: 18px;
        `}
      >
        {children}
      </div>
    )
  }

  return (
    <Comp>
      <img
        css={`
          border-radius: 100%;
          background: grey;
          flex: none;
        `}
        src={journal.image}
        width={30}
        alt={''}
        height={30}
      />
      <div
        css={`
          line-height: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 10px;
        `}
      >
        {journal.name}
        <div
          css={`
            color: #9b9ba7;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 24px;
          `}
        >
          Promoted
        </div>
      </div>
    </Comp>
  )
}

const setBookmarked = (bookmarked, id) => {
  const apiUrl = window.env
    ? window.env.API_URL
    : process.env.API_URL
    ? process.env.API_URL
    : 'https://api-staging.researcher-app.com'
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    bookmark: bookmarked,
    user_id: parseInt(Cookies.get('userId')),
    paper_ids: [id],
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  return fetch(
    `${apiUrl}/v3/bookmark?auth=${Cookies.get('accessToken')}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {})
    .catch((error) => {
      console.log('error', error)
    })
}

const BookmarkButton = ({paper, onClick}) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <StyledBookmarkButtton
      disabled={loading}
      onClick={
        onClick ||
        (async () => {
          setLoading(true)
          if (paper.bookmarked_date) {
            paper.bookmarked_date = undefined
            setBookmarked(false, paper.id)
              .then(() => {
                setLoading(false)
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          } else {
            paper.bookmarked_date = Date()
            setBookmarked(true, paper.id)
              .then(() => {
                setLoading(false)
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          }
        })
      }
      x-track-id="bookmark"
      x-track-click="true"
      x-track-detail={JSON.stringify({bookmarked: paper.bookmarked_date})}
      x-track-detail-on="paper"
    >
      <img
        css={paper.bookmarked_date && 'display: none;'}
        src={BookmarkIconPink}
        formats={['svg']}
        width={14}
        alt="Unbookmark paper"
        height={16}
      />
      <img
        css={!paper.bookmarked_date && 'display: none;'}
        src={BookmarkIconTick}
        formats={['svg']}
        width="28px"
        alt="Bookmark paper"
        height="28px"
      />
    </StyledBookmarkButtton>
  )
}

const FeedCardAd = ({
  ad,
  halfWidth = false,
  includeAbstract = true,
  fullHeight = true,
  includePDF = true,
  scrollable,
  includeHeadline = includeAbstract,
  footerItems,
  detail,
  journals,
  setJournals,
}) => {
  const [downloadedPaper, setdownloadedPaper] = React.useState(null)

  const format = 'webp'
  const params = {
    uri: ad.header_img_url,
    format: format,
    quality: 100,
    // width: 512,
    noCache: true,
  }
  var journal_image_url =
    '/image/' + to64(JSON.stringify(params)) + '.' + format
  var journal = ad.header_title
    ? {name: ad.header_title, image: journal_image_url}
    : null

  const title =
    ad &&
    ad.elements &&
    ad.elements.length > 1 &&
    ad.elements[1].content_description
      ? ad.elements[1].content_description
      : ''
  const image_url_local =
    ad && ad.elements && ad.elements.length > 2 && ad.elements[2].image_url
      ? ad.elements[2].image_url
      : ''
  params.uri = image_url_local
  var image_url = '/image/' + to64(JSON.stringify(params)) + '.' + format
  const url =
    ad && ad.elements && ad.elements.length > 3 && ad.elements[3].url
      ? ad.elements[3].url
      : ''
  const urlCopy =
    ad &&
    ad.elements &&
    ad.elements.length > 3 &&
    ad.elements[3].content_description
      ? ad.elements[3].content_description
      : ''
  const contentId =
    ad && ad.elements && ad.elements.length > 0 && ad.elements[0].content_ids
      ? ad.elements[0].content_ids[0]
      : null

  const paperPromotion = ad.type === 'promoted_paper'

  React.useState(() => {
    if (paperPromotion) {
      const makeRequest = async () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow',
        }

        const apiUrl = window.env
          ? window.env.API_URL
          : process.env.API_URL
          ? process.env.API_URL
          : 'https://api-staging.researcher-app.com'

        var auth = Cookies.get('accessToken')

        await fetch(
          `${apiUrl}/v4/papers/${contentId}?auth=${auth}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            var resultPapers = result.data.papers || []
            var paper = resultPapers[0]
            paper.campaignId = ad.campaign_id
            setdownloadedPaper(paper)
          })
          .catch((error) => {
            console.log('error', error)
          })
      }
      makeRequest()
    }
  }, [])

  return paperPromotion && downloadedPaper ? (
    <FeedCardPaper paper={downloadedPaper} />
  ) : ad.type === 'promoted_journal' ? (
    <JournalPromotedCard
      x-track-id="advertisement"
      x-track-detail={JSON.stringify({
        ...detail,
        campaign: ad.campaign_id,
      })}
      ad={ad}
      adJournal={journal}
      journals={journals}
      setJournals={setJournals}
    />
  ) : (
    <Card
      paper={ad}
      halfWidth={halfWidth}
      scrollable={scrollable}
      x-track-id="advertisement"
      x-track-detail={JSON.stringify({
        ...detail,
        campaign: ad.campaign_id,
      })}
      x-track-view="true"
    >
      <CardHeader left={<JournalPreview journal={journal} />} right={<></>} />
      <CardBody
        header={
          <>
            <h3
              title={title}
              className="paperTitle"
              css={`
                padding-top: 20px;
                padding-bottom: 20px;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 27px;
                font-weight: 500;
                ${halfWidth &&
                css`
                  flex: none;
                  max-width: 100%;
                `}
              `}
              dangerouslySetInnerHTML={{__html: title}}
            ></h3>
          </>
        }
      >
        <div
          css={`
            width: 100%;
            ${!fullHeight
              ? 'display: flex; justify-content: space-between;'
              : ''}
          `}
        >
          {(includeAbstract || includeHeadline) && !!image_url && (
            <a
              rel="noopener noreferrer nofollow"
              href={image_url}
              target="_blank"
              css={`
                margin-right: 20px;
                display: flex;
                min-width: 0;
                align-items: center;
                ${!fullHeight ? `background: url('${image_url}')` : ''};
                ${!fullHeight ? 'width: 256px;' : 'width: 100%'}
                ${!fullHeight ? 'min-height: 194px;' : ''}
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                img {
                  border: 1px solid ${theme.colors.metal};
                }
              `}
            >
              {fullHeight && (
                <img
                  height={fullHeight ? undefined : 0}
                  css={`
                    ${!fullHeight && 'height: 0px;'}
                    max-width: 80%;
                    margin: 0 auto;
                    max-height: 320px;
                  `}
                  alt="Headline"
                  src={image_url}
                />
              )}
            </a>
          )}
        </div>
      </CardBody>
      <CardFooter
        left={
          <div
            css={`
              display: flex;
            `}
          >
            {footerItems}
            {/* {!!paper.open_url && (
              <img
                css={`margin-left: 10px;`}
                src="/static-images/lock.svg"
                width="16"
                alt={"Open access"}
                height="21" />)} */}
          </div>
        }
        right={
          <>
            {!!(url && includePDF) && (
              <a
                css={`
                  border-radius: 3px;
                  background-color: #f94687;
                  color: white;
                  font-size: 16px;
                  font-weight: bold;
                  text-decoration: none;
                  padding: 16px 25px;
                  margin-right: 15px;

                  &:hover {
                    box-shadow: 0 2px 6px 0 rgba(68, 55, 133, 0.2);
                  }
                `}
                rel="noopener noreferrer nofollow"
                target="_blank"
                href={url}
                x-track-id="advertisement"
                x-track-click="true"
                x-track-detail={JSON.stringify({
                  ...detail,
                  campaign: ad.campaign_id,
                })}
              >
                {urlCopy}
              </a>
            )}
          </>
        }
      ></CardFooter>
    </Card>
  )
}

export default FeedCardAd
