import subscribe from 'lib/trackEverythingStream'
import getMetadata from 'lib/trackMetadata'
import convert from 'server/datasourceConvertPrimitive'
import Cookies from 'js-cookie'

const getPagename = (pathname) => {
  pathname = pathname || document.location.pathname

  if (pathname === '/') return 'landing.main'
  if (pathname === '/advertise') return 'landing.publishers'
  if (pathname.startsWith('/discover')) return 'landing.discover'
  if (pathname.startsWith('/about')) return 'landing.about'
  if (pathname.startsWith('/authors')) return 'landing.authors'
  if (pathname.startsWith('/feed')) return 'feed'
  if (pathname.startsWith('/paper/')) return 'selected'
  if (pathname.startsWith('/feed/journal/')) return 'journal_feed'
  if (pathname.startsWith('/journals/following')) return 'my_journals'
  if (/^\/journals\/\w+\/\w+/.test(pathname)) {
    return 'all_journals_journals'
  }
  if (/^\/journals\/\w+/.test(pathname)) {
    return 'all_journals_subjects'
  }
  if (pathname.startsWith('/journals/')) {
    return 'all_journals_research_areas'
  }
  if (pathname.startsWith('/search')) return 'search'
  if (pathname.startsWith('/library')) return 'bookmarks'
  if (pathname.startsWith('/settings')) return 'settings'
  if (pathname.startsWith('/onboarding/journals')) return 'onboarding_journals'
  if (pathname.startsWith('/onboarding/about')) return 'onboarding_profile'
  if (pathname.startsWith('/onboarding/filter')) return 'onboarding_filter'
  if (pathname.startsWith('/onboarding')) return 'onboarding'
  if (pathname.startsWith('/login')) return 'login'
  if (pathname.startsWith('/signup/terms')) return 'gdpr'
  if (pathname.startsWith('/profile/me')) return 'user_profile'
  if (pathname.startsWith('/trending')) return 'trending.papers'

  return pathname.slice(1).replace(/\//g, '_')
}

const pingApi = async (key) => {
  const apiUrl = window.env
    ? window.env.API_URL
    : process.env.API_URL
    ? process.env.API_URL
    : 'https://api-staging.researcher-app.com'
  var auth = Cookies.get('accessToken')
  if (!auth) return
  switch (key) {
    case 'active':
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      }

      await fetch(`${apiUrl}/v4/check_in?auth=${auth}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          //no need to do anything here, keep it silent
        })
        .catch((error) => console.log('error', error))
      break
    default:
      break
  }
}

export const sendAdEvent = async (eventData) => {
  var userId = Cookies.get('userId')
  if (!userId) return
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify({events: eventData}),
    headers: myHeaders,
  }

  await fetch(`/api/feed-event/${userId}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      //no need to do anything here, keep it silent
    })
    .catch((error) => console.log('error', error))
}

const getEnvironment = () => {
  return window.location.href.includes('staging')
    ? 'test'
    : window.location.href.indexOf('pr-') == -1 &&
      window.location.href.indexOf('localhost') == -1
    ? 'production'
    : 'local'
}
const pathnameToFilter = (pathname) => {
  const match = pathname.match(/\/feed\/(\w+)/)
  if (!match) return undefined
  let feed = match[1]
  if (['all', 'publications', 'mine'].includes(feed)) return undefined
  if (feed === 'journal') {
    feed = pathname.split('/').pop()
  }
  return feed
}

export const sendEvent = async (event) => {
  const env = getEnvironment()
  const metadata = await getMetadata()
  const metadataPatch = {
    event_source: 'researcher.web',
    schema_version: 2.2,
    event_local_timestamp: metadata.timezoneOffset,
    event_user: convert.stripUndefinedValues({
      user_id: +metadata.viewerId || undefined,
      uuid: metadata.viewerUUID,
      session_id: metadata.sessionId,
      device_timestamp: metadata.deviceTimestamp,
      ...event.event_user,
    }),
  }

  const eventName = event.event
  event.event = undefined
  event = convert.stripUndefinedValues({
    ...event,
    ...metadataPatch,
    environment: env,
  })

  var eventFinal = {}

  Object.entries(event).map(([key, item]) => {
    if (typeof item === 'object' && item !== null) {
      eventFinal[key] = JSON.stringify(item)
    } else {
      eventFinal[key] = item
    }
  })

  console.log({eventFinal})

  try {
    window.analytics.track(eventName, eventFinal)
  } catch (err) {
    console.log(err)
  }
}

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const advertisingView = ({type, pathname, path, detail}) => {
  const campaignId = detail ? detail.campaign : null
  let actionType
  if (type === 'click' && path.includes('advertisement')) {
    actionType = 'select'
  } else if (type === 'viewStart' && path.includes('advertisement')) {
    actionType = 'view'
  } else if (type === 'viewEnd' && path.includes('advertisement')) {
    actionType = 'view_end'
  }
  if (!actionType) return

  const isClick = actionType === 'select'
  if (campaignId) {
    sendAdEvent([
      {
        campaign_id: parseInt(campaignId),
        event_type_id: isClick ? 2 : 1,
        event_timestamp: Math.floor(new Date().getTime() / 1000),
      },
    ])
  }
}

const articleView = ({type, pathname, path, detail}) => {
  let actionType
  let campaignId = detail ? detail.campaignId : null
  const isStory = detail && detail.type && detail.type === 'story'

  if (type === 'click' && path.includes('paper')) {
    if (path.includes('bookmark')) {
      actionType = detail.bookmarked ? 'unbookmark' : 'bookmark'
    } else if (path.includes('link')) {
      actionType = 'link'
    } else if (path.includes('doi')) {
      actionType = 'link_doi'
    } else if (path.includes('openAccess')) {
      actionType = 'link_open'
    } else if (path.includes('pdf')) {
      actionType = 'pdf'
    } else if (isStory) {
      actionType = 'select'
    }
  } else if (type === 'viewStart' && path.includes('paper')) {
    actionType = 'view'
  } else if (type === 'viewEnd' && path.includes('paper')) {
    actionType = 'view_end'
  } else if (type === 'navigate' && detail.nextPathname.startsWith('/paper')) {
    //  actionType = 'select'
    campaignId = getParameterByName('campaignId')
  }
  if (!actionType) return

  let paperId = detail.paper
  if (!paperId && detail.nextPathname) {
    paperId = (detail.nextPathname.match(/\/paper\/(\w+)/) || [])[1]
  }

  const relatedPaperId =
    detail.relatedPaperId === paperId ? undefined : detail.relatedPaperId

  const articleViewEvent = convert.stripUndefinedValues({
    event_page: isStory
      ? 'story'
      : relatedPaperId
      ? 'related'
      : getPagename(pathname),
    event: 'article.view',
    event_view: detail.viewId || undefined,
    event_label: +paperId || undefined,
    event_filter:
      detail.filterId || pathnameToFilter(document.location.pathname),
    event_search: detail.searchValue,
    event_action: actionType,
    event_criteria: detail.eventCriteria,
    event_criteria_research_area: detail.researchAreaId,
    event_criteria_subject: !!detail.subjectId ? detail.subjectId : undefined,
    event_origin: convert.stripUndefinedValues({
      related_paper_id: +relatedPaperId || undefined,
      criteria_id: +relatedPaperId ? 'r_1_1' : undefined,
      criteria: +relatedPaperId ? 'related_paper' : undefined,
      search_term: detail.searchValue,
      source: detail.searchValue ? 'free_text' : undefined,
      order: detail.searchValue ? 'relevant' : undefined,
    }),
  })

  if (detail.feedType) {
    articleViewEvent.event_details = {
      feed_type: detail.feedType,
    }
  }

  const isClick = actionType === 'select'

  if (
    !articleViewEvent.event_origin.criteria &&
    !articleViewEvent.event_origin.search_term
  ) {
    delete articleViewEvent.event_origin
  }

  sendEvent(articleViewEvent)
  if (campaignId) {
    sendAdEvent([
      {
        campaign_id: parseInt(campaignId),
        event_type_id: isClick ? 2 : 1,
        event_timestamp: Math.floor(new Date().getTime() / 1000),
      },
    ])
  }
}

const journalView = ({type, pathname, detail, path}) => {
  let actionType
  if (type === 'click' && path.includes('journal') && path.includes('select')) {
    actionType = 'select'
  } else if (type === 'unfollowJournal') {
    actionType = 'unfollow'
  } else if (type === 'followJournal') {
    actionType = 'follow'
  } else if (type === 'viewStart' && path.includes('journal')) {
    actionType = 'view'
  } else if (type === 'viewEnd' && path.includes('journal')) {
    actionType = 'view_end'
  }
  if (!actionType) return

  const eventPage = getPagename(pathname)
  let [, , researchArea, subject] =
    (eventPage === 'journals.all' &&
      pathname !== '/journals/all' &&
      pathname.split('/')) ||
    []

  const journalViewEvent = {
    event_page: getPagename(pathname),
    event: 'journal.view',
    event_label: +detail.journal || undefined,
    event_search: undefined,
    event_subject: subject,
    event_research_areas: researchArea,
    event_action: actionType,
  }

  sendEvent(journalViewEvent)
}

const userProfileView = ({type, pathname, detail, path}) => {
  if (type === 'viewStart' && path.includes('userProfile')) {
  } else return

  const userViewEvent = {
    event_page: getPagename(pathname),
    event: 'user.view',
    event_label: +detail.user || undefined,
    event_action: 'view',
  }

  sendEvent(userViewEvent)
}

const userSelect = ({type, pathname, detail, path, meta}) => {
  if (
    type === 'submit' &&
    (path.includes('userProfile') || path.includes('onboardingAbout'))
  ) {
  } else return

  if (detail.formSubmission.role) {
    const userSelectEvent = {
      event_page: getPagename(pathname),
      event: 'user.profile_select',
      event_type: 'role_select',
      event_label: +detail.formSubmission.role || undefined,
      event_details: +meta.viewerId || undefined,
    }
    sendEvent(userSelectEvent)
  }

  if (detail.formSubmission.university) {
    const userSelectEvent = {
      event_page: getPagename(pathname),
      event: 'user.profile_select',
      event_type: 'affiliation_select',
      event_label: +detail.formSubmission.university || undefined,
      event_details: +meta.viewerId || undefined,
    }
    sendEvent(userSelectEvent)
  }
}

const active = ({type, pathname}) => {
  if (type !== 'active') return

  const activeEvent = {
    event_page: getPagename(pathname),
    event: 'app.active',
  }
  sendEvent(activeEvent)
  pingApi('active')
}

const inactive = ({type, pathname}) => {
  if (type !== 'inactive') return

  const inactiveEvent = {
    event_page: getPagename(pathname),
    event: 'app.inactive',
  }
  sendEvent(inactiveEvent)
}

const login = ({type, pathname}) => {
  if (type !== 'login') return

  const loginEvent = {
    event_page: getPagename(pathname),
    event: 'app.login',
  }
  sendEvent(loginEvent)
  pingApi('active')
}

const logout = ({type, pathname}) => {
  if (type !== 'logout') return

  const logoutEvent = {
    event_page: getPagename(pathname),
    event: 'app.logout',
  }

  sendEvent(logoutEvent)
}

const onboardingTerms = ({type, pathname, detail, path}) => {
  let ok
  if (type === 'submit' && path.includes('authTerms')) ok = true
  if (!ok) return

  const onboardingTermsEvent = {
    event: 'registration.onboarding.gdpr.agreed',
    event_user: {
      user_marketing: detail.formSubmission.marketing,
      user_gdpr: detail.formSubmission.gdpr,
    },
  }

  sendEvent(onboardingTermsEvent)
}

const onboardingStarted = ({type, pathname, detail, path}) => {
  let authType
  if (type === 'signup') authType = detail.authType
  if (!authType) return

  const onboardingStartedEvent = {
    event: 'registration.onboarding.started',
    event_user: {
      user_login_type: authType,
    },
  }

  sendEvent(onboardingStartedEvent)
}

let onboardingDetailAcc = {}
const onboardingStepFinished = ({type, pathname, detail}) => {
  if (type !== 'onboardingStepFinished') return
  Object.assign(onboardingDetailAcc, detail)

  const onboardingStepFinishedEvent = {
    event: 'registration.onboarding.step.finished',
    event_page: getPagename(pathname),
    event_step: +detail.step,
    event_step_data: (
      detail.researchAreas ||
      detail.subjects ||
      (detail.filter ? [detail.filter] : [])
    ).map((id) => +id),
  }

  if (!onboardingStepFinishedEvent.event_step_data.length) {
    delete onboardingStepFinishedEvent.event_step_data
  }

  sendEvent(onboardingStepFinishedEvent)

  if (detail.step !== '4') return

  if (!onboardingDetailAcc.subjects) onboardingDetailAcc.subjects = []
  if (!onboardingDetailAcc.journals) onboardingDetailAcc.journals = []
  if (!onboardingDetailAcc.researchAreas) onboardingDetailAcc.researchAreas = []

  const onboardingFinishedEvent = {
    event: 'registration.onboarding.finished',
    event_subject: onboardingDetailAcc.subjects.map((id) => +id),
    event_journal: onboardingDetailAcc.journals.map((id) => +id),
    event_research_area: onboardingDetailAcc.researchAreas.map((id) => +id),
    event_filter_ids: [+onboardingDetailAcc.filter].filter((f) => f),
    event_page: 'onboarding_filter',
  }

  onboardingDetailAcc = {}
  sendEvent(onboardingFinishedEvent)
}

const filterCreateStart = ({type, pathname, path}) => {
  if (type === 'viewStart' && path.includes('createFilterModal')) {
  } else return

  const filterEvent = {
    event: 'filter',
    event_page: 'filter_manager',
    event_type: 'edit_add',
    event_action: 'select',
  }

  sendEvent(filterEvent)
}

const filterAddTerm = ({type, pathname, path, pathElements}) => {
  if (
    type === 'click' &&
    path.includes('createFilterModal') &&
    path.includes('nextButton')
  ) {
  } else return

  const modal = pathElements[path.indexOf('createFilterModal')]
  const filterGroups = modal.querySelectorAll('*[x-track-id=filterGroup]')
  for (const filterGroup of filterGroups) {
    const filterType = filterGroup.querySelector(
      'input[name="filterType"]'
    ).value
    const filterTerm =
      filterType === 'KEYWORD'
        ? filterGroup.querySelector('input[name="filter"]').value
        : filterGroup.querySelector('input[name="firstname"]').value +
          ' ' +
          filterGroup.querySelector('input[name="lastname"]').value
    const suggestions = filterGroup.querySelectorAll(
      '*[x-track-id="filterSuggestions"] input'
    )
    const selected = []
    const notSelected = []
    for (const suggestion of suggestions) {
      const arr = suggestion.checked ? selected : notSelected
      arr.push(suggestion.name)
    }

    const filterAddTermEvent = {
      event: 'filter',
      event_page: 'filter_term_editor',
      event_type: 'create_term',
      event_action: undefined,
      event_filter: undefined,
      event_details: convert.stripUndefinedValues({
        filter_term: filterTerm,
        suggested_terms: {selected: selected, not_selected: notSelected},
      }),
    }

    if (filterTerm) {
      sendEvent(filterAddTermEvent)
    }
  }
}

const filterCreateFinish = ({type, pathname, detail, path}) => {
  if (type !== 'createFilter') return

  const filterEvent = {
    event: 'filter',
    event_page: 'filter_editor',
    event_type: 'edit_add',
    event_action: 'complete',
    event_filter: +detail.filter || undefined,
    event_details: detail.filterJSON,
  }

  sendEvent(filterEvent)
}

const filterSelect = ({type, pathname, detail, path}) => {
  if (type === 'click' && path.includes('filterListItem')) {
  } else return

  const filterSelectEvent = {
    event_page: getPagename(pathname),
    event: 'filter.select',
    event_filter: +detail.filter,
  }

  sendEvent(filterSelectEvent)
}

const sidebarNavigate = ({type, pathname, path}) => {
  if (type === 'click' && path.includes('sidebar')) {
  } else return

  const sidebarNavigateEvent = {
    event_page: getPagename(pathname),
    event: 'nav.main.click',
    event_indicator: false,
  }

  sendEvent(sidebarNavigateEvent)
}

const allJournalsResearchAreaClick = ({type, pathname, path, detail}) => {
  if (type === 'click' && path.includes('researchArea')) {
  } else return

  const allJournalsResearchAreaClickEvent = {
    event_page: getPagename(pathname),
    event: 'journal.research_area.click',
    event_research_area: +detail.researchArea || undefined,
  }

  sendEvent(allJournalsResearchAreaClickEvent)
}

const allJournalsSubjectClick = ({type, pathname, path, detail}) => {
  if (type === 'click' && path.includes('researchSubject')) {
  } else return

  const allJournalsSubjectClickEvent = {
    event_page: getPagename(pathname),
    event: 'journal.subject.click',
    event_subject: +detail.subject || undefined,
  }

  sendEvent(allJournalsSubjectClickEvent)
}

const search = ({type, pathname, detail, path}) => {
  if (type === 'click' && detail.searchValue && path.includes('searchButton')) {
  } else return

  const searchEvent = {
    event_page: getPagename(pathname),
    event: 'article.search',
    event_action: 'search',
    event_details: convert.stripUndefinedValues({
      search_term: detail.searchValue,
      source: 'free_text',
      type: 'keyword',
      order: 'relevant',
    }),
  }

  sendEvent(searchEvent)
}

const navigateManageData = ({type, pathname, detail, path}) => {
  if (type === 'navigate' && pathname.startsWith('/settings/data')) {
  } else return

  const navigateManageDataEvent = {
    event_page: getPagename(pathname),
    event: 'settings',
    event_action: undefined,
    event_setting: 'manage_data',
  }

  sendEvent(navigateManageDataEvent)
}

const navigateDeleteAccount = ({type, pathname, detail, path}) => {
  let actionType
  if (type === 'deleteAccount') {
    actionType = 'delete'
  } else if (type === 'viewStart' && path.includes('deleteAccount')) {
    actionType = 'start'
  }
  if (!actionType) return

  const navigateDeleteAccountEvent = {
    event_page: getPagename(pathname),
    event: 'settings',
    event_setting: 'request_delete_account',
    event_action: actionType,
    event_details: convert.stripUndefinedValues({
      reason: detail.deleteAccountReason,
    }),
  }

  sendEvent(navigateDeleteAccountEvent)
}

subscribe(articleView)
subscribe(journalView)
subscribe(advertisingView)
subscribe(userProfileView)
subscribe(userSelect)
subscribe(active)
subscribe(inactive)
subscribe(login)
subscribe(logout)
subscribe(onboardingTerms)
subscribe(onboardingStarted)
subscribe(onboardingStepFinished)
subscribe(filterCreateStart)
subscribe(filterAddTerm)
subscribe(filterCreateFinish)
subscribe(filterSelect)
subscribe(sidebarNavigate)
subscribe(allJournalsResearchAreaClick)
subscribe(allJournalsSubjectClick)
subscribe(search)
subscribe(navigateManageData)
subscribe(navigateDeleteAccount)
