import React from 'react'

import ModalGeneric from 'components/shared/ModalGeneric'
import Image from 'components/shared/Image'
import JournalCard, {JournalCardBasic} from 'components/shared/FeedCardJournal'
import gql from 'graphql-tag'
import Fragments from 'lib/appFragments'
import Feed from 'components/shared/FeedGeneric'
import Spinner from 'components/shared/Spinner'

const JournalIcon = '/static-images/icon_journal.svg'
const SearchIcon = '/images/sidebarIconSearch.svg'

const JOURNALS = gql`
  query JournalsOnboarding(
    $query: String
    $subjects: [ID!]
    $skip: Int
    $sort: JournalsSort
    $limit: Int
  ) {
    journals(
      query: $query
      sort: $sort
      subject_in: $subjects
      skip: $skip
      limit: $limit
    ) {
      ...FullJournal
    }
  }
  ${Fragments.FullJournal}
`
const JournalItem = ({data}) => <JournalCard noLink journal={data} />

const AddJournalModal = ({isOpen, onClose}) => {
  const [searchValue, setSearchValue] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)
  const searchInput = React.createRef(null)

  const onSearchSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)
  }

  React.useEffect(() => {
    if (submitted) setSubmitted(false)
  }, [submitted])

  const onSearchChange = (e) => {
    setSearchValue(e.target.value)
  }

  const searchForm = (
    <form
      onSubmit={onSearchSubmit}
      css={`
        margin-top: 32px;
        height: 45px;
      `}
    >
      <Image
        css={`
          position: absolute;
          margin-left: 5px;
          margin-top: 12px;
        `}
        src={SearchIcon}
        width={16}
        height={16}
      />
      <input
        ref={searchInput}
        value={searchValue}
        onChange={onSearchChange}
        css={`
          height: 100%;
          width: 365px;
          background: none;
          border-bottom: 1px solid #bbbbbb;
          color: #323232;
          font-family: 'Replica';
          font-size: 16px;
          padding-left: 35px;
          padding-right: 10px;
          transition: width 300ms;
          font-weight: bold;

          &:focus {
            outline: none;
          }
        `}
        placeholder="Search"
        type="text"
      />
    </form>
  )

  return (
    <ModalGeneric
      x-track-id="addJournalModal"
      x-track-view="true"
      contentWidth="870px"
      title="Add Publications"
      icon={<Image src={JournalIcon} width={18} height={18} />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Feed
        searchValue={submitted}
        query={JOURNALS}
        options={({skip, limit}) => ({
          variables: {
            skip,
            limit,
            query: searchValue,
            // subjects: searchValue ? undefined : subjects,
            sort: searchValue ? 'relevance_DESC' : undefined,
          },
          ssr: false,
        })}
        extractPath="journals"
        Loading={() => (
          <JournalCardBasic>
            <Spinner />
          </JournalCardBasic>
        )}
        Header={() => (
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <p>Choose which publications you want to follow:</p>

            {searchForm}
          </div>
        )}
        Footer={() => <></>}
        Item={JournalItem}
      />
    </ModalGeneric>
  )
}

export default AddJournalModal
