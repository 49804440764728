import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {Button, TextField} from '@material-ui/core'
import useForm from 'react-hook-form'

import {object, string} from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {
  excludeItem,
  includeKeywordsTopicsAuthors,
  updateIncludeKeywordTopicAuthor,
  updateSubmitBtnDisabledState,
} from 'store/actions/feedManager'
import {MatchField} from 'components/FeedV2/interfaces/api'
import {
  ExclusionArrayName,
  LogicFlow,
  Matchtype,
} from 'components/FeedV2/interfaces/types'
import {TranslationKeys} from 'components/FeedV2/util/util'

type CreateEditAuthorProps = {
  firstName?: string
  lastName?: string
  index?: number
  isFromExcluded?: boolean
}

export const CreateEditAuthor = ({
  firstName,
  lastName,
  index,
  isFromExcluded = false,
}: CreateEditAuthorProps) => {
  const dispatch = useDispatch()
  const {activeFeed} = useSelector((state: any) => state.feedManager)

  const schema = object().shape({
    firstName: string().notRequired(),
    lastName: string().required('Last Name is required'),
  })
  const {
    register,
    handleSubmit,
    errors,
    formState,
    // triggerValidation,
    // setFocus,
  } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  })
  const onSubmit = async (data) => {
    let payload = {
      match_field: MatchField.AUTHOR,
      match_type: Matchtype.EXACT,
      logic_flow:
        activeFeed && activeFeed?.inclusions?.length > 0
          ? activeFeed.inclusions[index]
            ? activeFeed.inclusions[index].logic_flow
            : LogicFlow.OR
          : LogicFlow.AND,
      sort_index: index != null ? index : activeFeed?.inclusions?.length,

      terms: [data.firstName, data.lastName],
    }

    if (isFromExcluded) {
      const duplicateAuthor = activeFeed.exclusions.authors.find(
        (author) =>
          JSON.stringify(author.terms) == JSON.stringify(payload.terms)
      )

      if (!duplicateAuthor) {
        const {logic_flow, sort_index, ...rest} = payload
        dispatch(excludeItem([rest], ExclusionArrayName.AUTHORS))
      }
    } else {
      if (payload.terms[0]) {
        payload.terms[0] = payload.terms[0].toUpperCase()
      }
      if (payload.terms[1]) {
        payload.terms[1] = payload.terms[1].toUpperCase()
      }
      if (index != null) {
        dispatch(updateIncludeKeywordTopicAuthor(payload))
      } else {
        dispatch(includeKeywordsTopicsAuthors([payload]))
      }
    }
  }

  const style = {
    margin: 'auto',
    maxWidth: '300px',
    height: '300px',
  }

  useEffect(() => {
    dispatch(updateSubmitBtnDisabledState(!formState.isValid))
  }, [dispatch, formState.isValid])

  return (
    <form id="submit-form" onSubmit={handleSubmit(onSubmit)} style={style}>
      <TextField
        defaultValue={firstName || ''}
        name="firstName"
        error={!!errors.firstname}
        label="First Name"
        helperText={errors.firstName ? errors.firstName.message : ''}
        type="text"
        inputRef={register}
        fullWidth
        onKeyPress={(e) =>
          e.key === TranslationKeys.enter && e.preventDefault()
        }
      />
      <TextField
        defaultValue={lastName || ''}
        name="lastName"
        error={
          formState.touched.includes('lastName') ? !!errors.lastName : null
        }
        label="Last Name"
        inputRef={register}
        helperText={
          errors.lastName && formState.touched.includes('lastName')
            ? errors.lastName.message
            : ''
        }
        type="text"
        fullWidth
        onKeyPress={(e) =>
          e.key === TranslationKeys.enter && e.preventDefault()
        }
      />
    </form>
  )
}
