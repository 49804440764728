import {
  getRequest,
  universalParserClient,
  postRequest,
  putRequest,
  patchRequest,
} from './apiClient'

export const getKeywordsTopicList = async (searchedTerm: string) => {
  let url = `/v4/thesaurus/autocomplete?term=${searchedTerm}`
  return await getRequest(`${url}`)
}

export const getPublicationList = async (searchedTerm: string) => {
  let url = `/v4/journals?search=${searchedTerm}`
  return await getRequest(`${url}`)
}

export const getSubjects = async (searchedTerm: string) => {
  let url = `/v4/subjects?name=${searchedTerm}`
  return await getRequest(`${url}`)
}

export const getContentTypes = async () => {
  return universalParserClient.get(`/api/paper/content_types`)
}

export const submitCreatedFeed = async (userId, payload) => {
  let url = `v4/users/${userId}/filters?`
  return postRequest(url, payload)
}

export const updateCreatedFeed = async (userId, filterId, payload) => {
  let url = `v4/users/${userId}/filters/${filterId}?`
  return patchRequest(url, payload)
}
