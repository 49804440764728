const to64 = str =>
  window
    .btoa(str)
    .replace('/', '_')
    .replace('+', '-')

const from64 = str => window.atob(str.replace('_', '/').replace('-', '+'))

export {to64, from64}
export default {to64, from64}
