const getValueBase = (o, k) => {
  if (!k.length) return o
  if (!o || typeof o !== 'object') return undefined
  const [kh, ...kt] = k
  return getValueBase(o[kh], kt)
}

const getValue = (o, k) => {
  if (!k) return o
  return getValueBase(o, k.split('.'))
}

export default getValue
