import React from 'react'
import {useSelector} from 'react-redux'
import {Route, Redirect} from 'react-router-dom'
import {Header, HeaderListItem} from 'components/shared/AppHeader'
import AppLayout from 'components/shared/AppLayout'
import MainFilterModal from 'components/FilterModal/Main'
import CreateEditFilterModal from 'components/FilterModal/CreateEdit'
import MainFeed from '../components/shared/MainFeed'
import Dropdown, {StyledDropdown} from 'components/DropdownNew'
import Cookies from 'js-cookie'
import {useHistory} from 'react-router-dom'
import AddJournalModal from 'components/AddJournalModal'
import Image from 'components/shared/Image'
import {Card} from 'components/shared/FeedCardBasicV2'
import Button from 'components/shared/Button'
import {NavigationLink} from 'pages/AppPageSettings'
import muiBtn from '@material-ui/core/Button'
import {useState} from 'react'
import {FeedManagerContainerWrapper} from 'components/FeedV2/FeedManagerContainerWrapper/FeedManagerContainerWrapper'
import {useEffect} from 'react'
import {setActiveFeed} from 'store/actions/feedManager'
import {manageFeedButtonClick} from 'components/FeedV2/util/segmentEvents'

const AddIcon = '/static-images/addIcon.png'
const MyPublicationLogo = '/static-images/logo_my_publications.svg'

const LiveEvent = ({paper}) => {
  return (
    <div
      href={paper.url}
      target="_blank"
      rel="noreferrer"
      css={`
        height: 93.69px;
        width: 263px;
        border-radius: 22px;
        background-color: #ffffff;
        display: flex;
        margin: 8px;
        padding: 8px;
        cursor: pointer;
        align-items: center;
        flex-direction: row;
      `}
      x-track-id="paper"
      x-track-detail={JSON.stringify({
        paper: paper.id,
        campaignId: paper.campaignId,
        type: 'story',
      })}
      x-track-click="true"
      x-track-view="true"
      onClick={() => window.open(paper.url)}
    >
      <img
        src={paper.journal_image_url}
        alt=""
        style={{flex: 'none'}}
        width="56"
        height="56"
        css={`
          border-radius: 50%;
          border: 2px solid rgb(249, 70, 135);
        `}
      />
      <div
        css={`
          display: flex;
          flex-direction: column;
          margin: 8px;
        `}
      >
        <div
          css={`
            width: fit-content;
            color: rgb(255, 255, 255);
            font-family: Replica;
            font-weight: bold;
            font-size: 10px;
            letter-spacing: 0px;
            border-radius: 4px;
            background-color: rgb(249, 70, 135);
            line-height: 12px;
            padding: 4px;
          `}
        >
          LIVE
        </div>
        <div
          css={`
            color: #323232;
            font-family: Replica;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-top: 8px;
            line-height: 17px;
          `}
        >
          {paper.title}
        </div>
      </div>
    </div>
  )
}

const LiveEventsWidget = () => {
  const [events, setEvents] = React.useState([])

  React.useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'
    var auth = Cookies.get('accessToken')
    fetch(`${apiUrl}/v4/live_events?auth=${auth}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        var papers = result.data.events
        papers = papers.filter((paper) => {
          return paper.end_date > new Date().getTime() / 1000
        })
        setEvents(papers)
      })
      .catch((error) => console.log('error', error))
  }, [])

  return (
    <>
      {events.length > 0 && (
        <div
          css={`
            background: transparent;
            height: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 16px;
            padding-bottom: 16px;
            margin-left: 32px;
            margin-bottom: -32px;
            border-radius: 5px;
          `}
        >
          <div
            css={`
              display: flex;
              flex-direction: column;
              width: 100%;
            `}
          >
            <div
              css={`
                display: flex;
                flex-wrap: wrap;
              `}
            >
              {events.map((item, index) => (
                <LiveEvent key={index} paper={item} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const AllFeedsDropdownItem = ({
  filters,
  setFilters,
  showOpenAccess,
  editFilterClick,
  activeFeed,
  setActiveFeed,
  filter,
  setSelectedJournals,
  setSelectedSubjects,
}) => {
  const allFeedsName = 'Feeds'

  React.useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'

    var auth = Cookies.get('accessToken')
    var userId = Cookies.get('userId')

    fetch(`${apiUrl}/v4/users/${userId}/filters?auth=${auth}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setFilters(result.data.filters)
        setSelectedJournals(result.data.journals)
        setSelectedSubjects(result.data.subjects)
      })
      .catch((error) => console.log('error', error))
  }, [])

  //this should update the missing name once filters have downloaded
  React.useEffect(() => {
    if (activeFeed && filters && filters.length > 0) {
      if (activeFeed.value === -1) {
        setActiveFeed({name: allFeedsName, value: -1, type: 'filter'})
        return
      }
      if (activeFeed.value === -2) {
        setActiveFeed({name: 'Open Access', value: -2, type: 'filter'})
        return
      }
      var f = filters.filter((item) => item.id === activeFeed.value)
      if (f && f[0]) {
        setActiveFeed({name: f[0].name, value: f[0].id, type: 'filter'})
      }
    }
  }, [filters])

  //When user clicks the home button, change the dropdown value to match -1
  React.useEffect(() => {
    if (filter && filter === -1) {
      setActiveFeed({name: allFeedsName, value: -1, type: 'filter'})
    }
  }, [filter])

  const items = []
  items.push({
    name: 'My Feeds',
    type: 'underline',
    id: -1,
  })
  if (showOpenAccess) {
    items.push({
      name: filter === -2 ? <strong>Open Access</strong> : 'Open Access',
      id: -2,
      type: 'indent',
    })
  }
  if (filters) {
    filters.forEach((item) => {
      var copy = {...item}
      if (copy.id === filter) copy.name = <strong>{copy.name}</strong>
      copy.type = 'indent'
      items.push(copy)
    })
  }

  const history = useHistory()

  const isActive =
    (history.location.pathname.includes('mine') ||
      history.location.pathname === `/feed/${filter}`) &&
    !history.location.pathname.includes('journal')

  return (
    <HeaderListItem
      // predicate={() => a.id === area}
      noPadding
      withoutOverflow
      isActive={isActive}
    >
      <Dropdown
        scrollable={true}
        height={420}
        width="200px"
        bold={false}
        items={items}
        title={allFeedsName}
        addButton={
          <div
            css={`
              position: absolute;
              bottom: 0px;
              background: #323232;
              transform: translateY(calc(100% - 3px));
              width: 100%;
              height: 52px;
              display: flex;
              align-items: center;
              padding-left: 20px;
              padding-right: 20px;
              justify-content: space-between;
              border-top: 1px solid #555;
            `}
            onClick={editFilterClick}
          >
            Manage Feeds <img src={AddIcon} alt="" />
          </div>
        }
        onChange={(data) => {
          switch (data.value) {
            case -1:
              // history.push('/feed/all')
              history.push('/feed/mine')
              break
            case -2:
              history.push('/feed/open-access')
              break
            default:
              history.push('/feed/' + data.value)
              break
          }
          setActiveFeed(data)
        }}
        // onLabelClick={() => {

        // }}
      />
    </HeaderListItem>
  )
}

const SubscribedJournalsDropdownItem = ({
  journals,
  setJournals,
  openJournalModal,
}) => {
  const generateMenu = (journals) => {
    const j = []
    j[''] = [
      {
        name: 'My Publications',
        id: -4,
        type: 'all',
        cover_image_url: MyPublicationLogo,
      },
    ]
    journals.forEach((item) => {
      if (item.content_type && item.content_type.name) {
        const key = item.content_type.name
        if (key in j) {
          j[key].push(item)
        } else {
          j[key] = [item]
        }
      } else {
        if ('Other' in j) {
          j['Other'].push(item)
        } else {
          j['Other'] = [item]
        }
      }
    })
    return j
  }

  React.useEffect(() => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'

    var auth = Cookies.get('accessToken')

    fetch(
      `${apiUrl}/v4/journals?subscribed=true&limit=500&auth=${auth}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setJournals(result.data.journals)
      })
      .catch((error) => console.log('error', error))
  }, [])

  const items = []
  if (journals) {
    var jDesign = generateMenu(journals)
    var prevTitle = null
    Object.keys(jDesign).forEach((key) => {
      const arr = jDesign[key]
      if (key !== prevTitle) {
        items.push({
          name: key,
          type: 'header',
          id: -1,
        })
      }
      arr.forEach((item) => items.push(item))
      prevTitle = key
    })
  }

  const history = useHistory()

  const isActive =
    history.location.pathname.includes('publications') ||
    history.location.pathname.includes('journal')

  return (
    <HeaderListItem
      // predicate={() => a.id === area}
      noPadding
      withoutOverflow
      isActive={isActive}
    >
      <Dropdown
        scrollable={false}
        height={420}
        width="200px"
        bold={false}
        items={items}
        title={'Publications'}
        includeAvatar={true}
        addButton={
          <div
            css={`
              background: #323232;
              transform: translateY(-3px);
              width: 100%;
              height: 52px;
              display: flex;
              align-items: center;
              padding-left: 20px;
              padding-right: 20px;
              justify-content: space-between;
              border-top: 1px solid #555;
            `}
            onClick={() => {
              openJournalModal()
            }}
          >
            Add Publications <img src={AddIcon} alt="" />
          </div>
        }
        onChange={(data) => {
          if (data.value === -4) {
            history.push('/feed/publications')
          } else {
            history.push('/feed/journal/' + data.value)
          }
        }}
        // onLabelClick={() => {

        // }}
      />
    </HeaderListItem>
  )
}

const findJournal = (id, list) => {
  if (!list.length) return null
  var filtered = list.filter((journal) => journal.id === id)
  return filtered[0]
}

const JournalHeader = ({
  journalData,
  setJournalData,
  errors,
  journals,
  setJournals,
}) => {
  const [loading, setLoading] = React.useState(false)

  const onClick = () => {
    setLoading(true)
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({journal_ids: [journalData.id]})

    var requestOptions = {
      method: journalData.subscribed ? 'DELETE' : 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'

    var auth = Cookies.get('accessToken')
    var userId = Cookies.get('userId')

    fetch(`${apiUrl}/v4/users/${userId}/journals?auth=${auth}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var subbed = !journalData.subscribed
        setJournalData({...journalData, subscribed: subbed})
        if (subbed) {
          setJournals([...journals, {...journalData, subscribed: subbed}])
        } else {
          var filtered = journals.filter((j) => j.id !== journalData.id)
          setJournals(filtered)
        }

        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  return (
    <>
      <Card
        css={`
          margin: 20px 42px -10px 42px;
          padding: 20px 30px;
        `}
      >
        <h2
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            {journalData && (
              <Image
                src={journalData.cover_image_url}
                width={60}
                height={60}
                clipping="circle"
              />
            )}
            <span
              css={`
                padding-left: 13px;
              `}
            >
              {errors
                ? 'Journal not found'
                : journalData
                ? journalData.name
                : 'Loading...'}
            </span>
          </div>
          {journalData && (
            <>
              {journalData.subscribed ? (
                <Button
                  secondary
                  onClick={onClick}
                  disabled={loading}
                  css={`
                    min-width: 0;
                    width: 100px;
                  `}
                >
                  Unfollow
                </Button>
              ) : (
                <Button
                  primary
                  css={`
                    min-width: 0;
                    width: 100px;
                  `}
                  disabled={loading}
                  onClick={onClick}
                >
                  Follow
                </Button>
              )}
            </>
          )}
        </h2>
      </Card>
    </>
  )
}

const PaperFeed = ({
  filters,
  filter,
  setFilters,
  editFilterClick,
  journals,
  setJournals,
  openJournalModal,
  openManageFeedModal,
  setSelectedJournals,
  setSelectedSubjects,
  activeFilter,
}) => {
  const {openAccess: showOpenAccess} = useSelector((state) => state.feed)
  const [selectedJournal, setSelectedJournal] = React.useState(null)

  let pathname = window.location.pathname
  let journal_id = pathname.toLowerCase().includes('journal')
    ? pathname.replace(/^\/feed\/journal\//, '')
    : null
  if (journal_id) {
    journal_id = journal_id.replace(/\//g, '')
    journal_id = parseInt(journal_id)
  }

  const isError = journal_id !== null && isNaN(journal_id)
  const [errors, setErrors] = React.useState(isError)

  let filter_id = pathname.replace(/^\/feed\//, '')
  filter_id = filter_id.replace(/^\/journal\//, '')
  if (!journal_id) {
    filter_id = filter_id.replace(/\//g, '')

    if (filter_id === 'all') filter_id = -1
    else if (filter_id === 'open-access') filter_id = -2
    else if (filter_id === 'mine') filter_id = -3
    else if (filter_id === 'publications') filter_id = -4
    else filter_id = parseInt(filter_id)
  }

  const fetchJournal = async (id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'

    var auth = Cookies.get('accessToken')

    var journal = null
    await fetch(`${apiUrl}/v4/journals/${id}?auth=${auth}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        try {
          setErrors(false)
          journal = result.data.journals[0] || null
        } catch (err) {
          setErrors(true)
        }
      })
      .catch((error) => console.log('error', error))
    return journal
  }
  if (!journal_id && !errors) {
    if (selectedJournal) setSelectedJournal(null)
  } else {
    if (journals && journals.length) {
      if (
        !selectedJournal ||
        (selectedJournal && selectedJournal.id !== journal_id)
      ) {
        var journal = findJournal(journal_id, journals)
        if (journal) setSelectedJournal(findJournal(journal_id, journals))
      }
    }
  }

  React.useEffect(() => {
    const task = async () => {
      if (journal_id && journals && journals.length) {
        if (
          !selectedJournal ||
          (selectedJournal && selectedJournal.id !== journal_id)
        ) {
          var journal = findJournal(journal_id, journals)
          if (!journal) setSelectedJournal(await fetchJournal(journal_id))
          else setSelectedJournal(findJournal(journal_id, journals))
        }
      }
    }
    task()
  }, [journals])

  const [activeFeed, setActiveFeed] = React.useState(
    activeFilter
      ? activeFilter
      : {
          value: journal_id ? journal_id : filter_id,
          type: journal_id ? 'journal' : 'filter',
        }
  )

  React.useEffect(() => {
    if (activeFilter) {
      setActiveFeed(activeFilter)
    }
  }, [activeFilter])

  const history = useHistory()

  const displayAllFeeds = () => {
    history.push('/feed/all')
  }

  const isAllActive = history.location.pathname.includes('all')

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
      `}
    >
      <Header
        rightItems={
          <>
            {/* <HeaderListItem title="Edit Filter" onClick={() => editFilterClick()}>
            <img alt="Edit Filter" src={FilterIcon} width={21} height={24} />
          </HeaderListItem> */}

            <muiBtn
              css={`
                display: flex !important;
                color: #323232 !important;
                font-size: 16px !important;
                font-family: Replica !important;
                text-transform: none !important;
              `}
              variant="text"
              //   color="primary"
              onClick={openManageFeedModal}
            >
              Manage feeds &nbsp;&nbsp;
              <img src="/static-images/add_icon.svg" alt="add" />
            </muiBtn>
          </>
        }
        css={`
          z-index: 2;
          padding-right: 130px;
          ${StyledDropdown} {
            border-bottom: none;
            height: 100%;

            input::placeholder {
              color: #323232;
              font-weight: bold;
              font-size: 15px;
              white-space: pre-line;
            }

            span {
              height: 100%;
              color: #323232;
              font-weight: bold;
              font-size: 15px;
              white-space: pre-line;
            }

            &::after {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #323232;
            }
          }
        `}
      >
        {/* border-bottom: ${isAllActive ? '2px solid #f94687' : null}; */}
        <p
          css={`
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding-left: 25px;
            padding-right: 25px;
            border-bottom: ${isAllActive ? '2px solid #f94687' : null};
          `}
          onClick={displayAllFeeds}
        >
          All
        </p>
        <AllFeedsDropdownItem
          activeFeed={activeFeed}
          setActiveFeed={setActiveFeed}
          setFilters={setFilters}
          filters={filters}
          showOpenAccess={showOpenAccess}
          editFilterClick={editFilterClick}
          filter={filter_id}
          setSelectedJournals={setSelectedJournals}
          setSelectedSubjects={setSelectedSubjects}
        />
        <SubscribedJournalsDropdownItem
          journals={journals}
          setJournals={setJournals}
          openJournalModal={openJournalModal}
        />
      </Header>
      {!errors && (
        <div
          css={`
            margin-top: 32px;
            margin-left: 42px;
          `}
        >
          {filter_id === -1 ? (
            <>All feeds&nbsp;&nbsp;{'>'}</>
          ) : !journal_id && filter_id != -4 ? (
            <>
              {activeFeed &&
              activeFeed.name &&
              activeFeed.name !== 'My Feeds' ? (
                <NavigationLink to="/feed/mine">My Feeds</NavigationLink>
              ) : (
                'My Feeds'
              )}{' '}
              &nbsp;&nbsp;{'>'}&nbsp;&nbsp;
              {activeFeed && activeFeed.name && activeFeed.name !== 'My Feeds'
                ? activeFeed.name
                : ''}
            </>
          ) : (
            <>
              {selectedJournal && selectedJournal.subscribed ? (
                <>
                  <NavigationLink to="/feed/publications">
                    My Publications
                  </NavigationLink>
                  &nbsp;&nbsp;{'>'}&nbsp;&nbsp;
                  {selectedJournal && selectedJournal.name
                    ? selectedJournal.name
                    : ''}
                </>
              ) : (
                <>
                  My Publications&nbsp;&nbsp;{'>'}&nbsp;&nbsp;
                  {selectedJournal && selectedJournal.name
                    ? selectedJournal.name
                    : ''}
                </>
              )}
            </>
          )}
        </div>
      )}
      {(journal_id || errors) && (
        <JournalHeader
          journalData={selectedJournal}
          setJournalData={setSelectedJournal}
          setJournals={setJournals}
          journals={journals}
          errors={errors}
        />
      )}
      {filter_id === -1 && !journal_id && <LiveEventsWidget />}
      {!errors ? (
        <MainFeed
          key={journal_id ? 'journal_' + journal_id : filter_id}
          filterId={filter_id}
          journalId={journal_id}
          journals={journals}
          setJournals={setJournals}
          withAds
        />
      ) : (
        <div
          css={`
            padding: 42px;
          `}
        >
          We were unable to find the requested journal{' '}
        </div>
      )}
    </div>
  )
}

const AppHome = () => {
  const [mainFiltersModalOpen, setMainFilterModalOpen] = React.useState(false)
  const [createFiltersModalOpen, setCreateFilterModalOpen] = React.useState(
    window.location.pathname.includes('/feed/new') || false
  )
  const [editFiltersModalOpen, setEditFilterModalOpen] = React.useState(false)
  const [journalModalOpen, setJournalModalOpen] = React.useState(false)

  const [editingFilter, setEditingFilter] = React.useState({})
  const [filters, setFilters] = React.useState([])
  const [journals, setJournals] = React.useState([])
  const [selectedJournals, setSelectedJournals] = React.useState([])
  const [selectedSubjects, setSelectedSubjects] = React.useState([])

  const [isManageFeedModalOpen, setIsManageFeedModalOpen] = useState(false)

  const [activeFilter, setActiveFilter] = React.useState(null)

  const closeMainModal = () => setMainFilterModalOpen(false)
  // const openMainModal = () => setMainFilterModalOpen(true)
  const openMainModal = () => {
    setIsManageFeedModalOpen(true)
  }

  const closeManageFeedModal = () => {
    setIsManageFeedModalOpen(false)
  }

  const openManageFeedModal = () => {
    manageFeedButtonClick() // ToDO: uncomment it when merged to staging
    setIsManageFeedModalOpen(true)
  }

  const closeCreateModal = (id) => {
    if (id && typeof id === 'string') {
      window.location = '/feed/' + id
    } else {
      setCreateFilterModalOpen(false)
    }
  }
  const openCreateModal = () => {
    setCreateFilterModalOpen(true)
  }

  const closeEditModal = (id) => {
    if (id && typeof id === 'number') {
      window.location = '/feed/' + id
    } else {
      setEditFilterModalOpen(false)
    }
  }

  const openEditModal = (filter) => {
    setEditingFilter(filter)
    setEditFilterModalOpen(true)
  }

  const openJournalModal = () => {
    setJournalModalOpen(true)
  }

  const closeAddJournalModal = () => {
    setJournalModalOpen(false)
    window.location = window.location.pathname
  }

  const handleSetUserFilters = (updatedFilters) => {
    setFilters(updatedFilters)
  }

  return (
    <>
      <MainFilterModal
        isOpen={mainFiltersModalOpen}
        onClose={closeMainModal}
        openCreateModal={openCreateModal}
        openEditModal={openEditModal}
        filters={filters}
        setFilters={setFilters}
      />

      {editFiltersModalOpen && (
        <CreateEditFilterModal
          isOpen={editFiltersModalOpen}
          onClose={closeEditModal}
          filter={editingFilter}
        />
      )}

      {createFiltersModalOpen && (
        <CreateEditFilterModal
          isOpen={createFiltersModalOpen}
          onClose={closeCreateModal}
          nextSortIndex={filters ? filters.length + 1 : 0}
        />
      )}

      <AddJournalModal
        isOpen={journalModalOpen}
        onClose={closeAddJournalModal}
      />

      {isManageFeedModalOpen && (
        <FeedManagerContainerWrapper
          filters={filters}
          setUserFilters={handleSetUserFilters}
          handleClose={closeManageFeedModal}
          journals={selectedJournals}
          selectedSubjects={selectedSubjects}
          setSelectedJournals={(updatedJournals) =>
            setSelectedJournals(updatedJournals)
          }
          setSelectedSubjects={(updatedSubjects) =>
            setSelectedSubjects(updatedSubjects)
          }
          setActiveFilter={(data) => setActiveFilter(data)}
        />
      )}

      <Route
        path="/feed"
        children={({location}) => {
          let filter = location.pathname
          let openAccess = false
          filter = filter.replace(/^\/feed\//, '')
          filter = filter.replace(/\//g, '')
          if (filter === 'all') filter = undefined
          if (filter === 'open-access') openAccess = true
          return (
            <PaperFeed
              openAccess={openAccess}
              addFilterClick={openCreateModal}
              editFilterClick={openMainModal}
              filter={filter}
              filters={filters}
              journals={journals}
              openJournalModal={openJournalModal}
              setJournals={setJournals}
              setFilters={setFilters}
              selectedJournals={selectedJournals}
              setSelectedJournals={setSelectedJournals}
              openManageFeedModal={openManageFeedModal}
              setSelectedSubjects={setSelectedSubjects}
              activeFilter={activeFilter}
            />
          )
        }}
      ></Route>

      <Route
        exact
        path="/feed"
        render={() => <Redirect to="/feed/all" />}
      ></Route>
    </>
  )
}

const AppPageHome = () => {
  return (
    <AppLayout>
      <AppHome />
    </AppLayout>
  )
}

export default AppPageHome
