import React from 'react'
import theme from 'lib/theme'
import {css} from 'styled-components'
import {Link, Route} from 'react-router-dom'

const HeaderListItem = ({
  disabled,
  predicate,
  title,
  path,
  match,
  onClick,
  children,
  noPadding,
  withoutOverflow,
  isActive = false,
}) => {
  const [matchPath, matchPredicate] = path
    ? match || [path, ({match}) => !!match]
    : [null, () => false]

  const pageMatches = predicate || matchPredicate

  return (
    <Route
      path={matchPath}
      children={(location) => {
        let label = children

        return (
          <li
            css={`
              &:not(:first-of-type) {
                margin-left: 15px;
              }

              display: flex;
              align-items: center;
              cursor: default;
              color: ${theme.colors.blacka};
              ${pageMatches(location) || isActive
                ? `border-bottom: 2px solid ${theme.colors.pink}`
                : `border-bottom: 2px solid transparent`};

              & > a,
              & > button,
              & > div {
                font-size: 0.85rem;
                width: 100%;
                height: 100%;
                padding: ${noPadding ? '0' : '25px 30px'};
                ${noPadding && 'padding-right: 10px;'}
                ${!disabled && 'cursor: pointer;'}
                ${disabled && 'opacity: 0.6;'}
                color: #000000;
                background: none;
                font-family: 'Replica';
                max-width: 240px;
                justify-content: center;
                display: flex;
                ${!withoutOverflow &&
                css`
                  text-overflow: ellipsis;
                  overflow: hidden;
                `}

                ${!disabled &&
                css`
                  &:hover {
                    background: ${theme.colors.light.paper};
                  }
                `}
              }
            `}
          >
            {path ? (
              <Link to={path}>{label}</Link>
            ) : onClick ? (
              <button disabled={disabled} title={title} onClick={onClick}>
                {label}
              </button>
            ) : (
              <div>{label}</div>
            )}
          </li>
        )
      }}
    ></Route>
  )
}

const Header = ({
  css: extraCss,
  className,
  height = 67,
  rightItems,
  leftItems,
  children,
  scrollable = true,
  zIndex = 99,
}) => {
  return (
    <div
      css={`
        position: sticky;
        background: hsl(0, 0%, 96.5%);
        padding: 0px 60px;
        padding-right: 0;
        border-bottom: 1px solid ${theme.colors.metal};
        display: flex;
        font-family: 'Replica';
        width: 100%;
        z-index: ${zIndex};
        top: 0;

        ${extraCss}
      `}
      className={className}
    >
      {leftItems && (
        <ul
          css={`
            display: flex;
            height: ${height - 1}px;
            font-size: 14px;

            a {
              text-decoration: none;
            }

            li {
              height: ${height}px;
              border-bottom: 0;
            }
          `}
        >
          {leftItems}
        </ul>
      )}

      <ul
        css={`
          display: flex;
          flex: 1;
          ${scrollable && 'overflow-y: auto;'}

          a {
            text-decoration: none;
          }

          li {
            white-space: nowrap;
          }
        `}
      >
        {children}
      </ul>

      {rightItems && (
        <ul
          css={`
            display: flex;
            height: ${height - 1}px;
            font-size: 14px;
            align-items: center;
            justify-content: center;

            a {
              text-decoration: none;
            }

            li {
              height: ${height}px;
              border-bottom: 0;
            }
          `}
        >
          {rightItems}
        </ul>
      )}
    </div>
  )
}

export {Header, HeaderListItem}
