import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export enum BtnType {
  SUBMIT = 'submit',
  BUTTON = 'button',
}

export enum BtnVariant {
  Text = 'text',
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
}
type CustomButtonProps = {
  isDisabled?: boolean
  onClick: (children: React.ReactNode | string) => void
  children: React.ReactNode | string
  width?: string
  height?: string
  btnVariant?: BtnVariant
  backgroundColor?: string
  color?: string
  type?: BtnType
  isBold?: boolean
}

export const CustomButton = ({
  isDisabled = false,
  children,
  onClick,
  width,
  height,
  btnVariant,
  backgroundColor,
  color,
  type = BtnType.SUBMIT,
  isBold = false,
}: CustomButtonProps) => (
  <Button
    type={type ?? BtnType.SUBMIT}
    form="submit-form"
    variant={btnVariant ?? BtnVariant.CONTAINED}
    disabled={isDisabled}
    onClick={() => onClick(children)}
    style={{
      // height: width || '50px',
      // width: height || '145px',
      height: height || '50px',
      width: width || '145px',
      background: isDisabled ? null : backgroundColor || '#F94687',
      color: color,
      textTransform: 'none',
      border: btnVariant === BtnVariant.Text ? '' : '1px solid #979797',
      borderRadius: '3px',
      fontFamily: 'Replica',
      fontWeight: isBold ? 'bold' : undefined,
    }}
  >
    {children}
  </Button>
)
