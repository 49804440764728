import {
  COMMON_SET_RESEARCH_AREAS_AND_SUBJECTS,
  SET_USER_FILTERS,
} from '../mutation-types'

const getInitialState = () => {
  const initialState = {
    researchAreasAndSubjects: null,
    userFilters: [],
  }
  return initialState
}

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_USER_FILTERS:
      return {...state, userFilters: action.userFilters}
    case COMMON_SET_RESEARCH_AREAS_AND_SUBJECTS:
      return {...state, researchAreasAndSubjects: [...action.payload.values]}
    default:
      return state
  }
}
