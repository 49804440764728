import {Typography} from '@material-ui/core'
import {
  ActiveFeed,
  Filter,
  LogicFlow,
  Term,
} from 'components/FeedV2/interfaces/api'
import {ConfigurableChipWrapper} from 'components/FeedV2/shared/ConfigurableChipWrapper/ConfigurableChipWrapper'
import {Dropdown} from 'components/FeedV2/shared/Dropdown/Dropdown'
import {
  getIsClosingParenthesisVisible,
  getIsOpeningParenthesisVisible,
  getTransformedQueries,
} from 'components/FeedV2/util/util'
// import Dropdown, {StyledDropdown} from 'components/DropdownNew'
import React from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Divider} from '../Settings/Settings'

type QueriesBuilderProps = {
  activeFeed: ActiveFeed
  onClick: (term: Term) => void
  onDelete: (term: Term) => void
  onOperatorChange: (val: LogicFlow, term: Term) => void
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
`

const Brackets = styled.p`
  width: 36.6px;
  color: #000000;
  font-family: Replica;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 41px;
  text-align: center;
`

const QueryMatchFieldAndValue = (props) => (
  <>
    <Typography
      component="span"
      style={{
        color: '#ABABAB',
        fontFamily: 'Replica',
        fontSize: '10px',
        fontWeight: '500',
        letterSpacing: '0.12px',
        textTransform: 'capitalize',
        display: 'block',
      }}
    >
      {props.matchField === 'abstract' ? 'Keyword' : props.matchField}
    </Typography>
    {/* <p
      style={{
        color: '#ABABAB',
        fontFamily: 'Replica',
        fontSize: '10px',
        fontWeight: '500',
        letterSpacing: '0.12px',
        textTransform: 'capitalize',
      }}
    >
      {props.matchField === 'abstract' ? 'Keyword' : props.matchField}
    </p> */}
    <Typography
      component="span"
      style={{
        color: '#000000',
        fontFamily: 'Replica',
        fontSize: '15px',
        fontWeight: '500',
        letterSpacing: '0.18px',
        textTransform: 'capitalize',
        display: 'block',
      }}
    >
      {props.value.join(' ')}
    </Typography>
    {/* <p
      style={{
        color: '#000000',
        fontFamily: 'Replica',
        fontSize: '15px',
        fontWeight: '500',
        letterSpacing: '0.18px',
      }}
    >
      {props.value.join(' ').toUpperCase()}
    </p> */}
  </>
)

export const QueriesBuilder = ({
  activeFeed,
  onClick,
  onDelete,
  onOperatorChange,
}: QueriesBuilderProps) => {
  const terms = getTransformedQueries(activeFeed.inclusions)

  const handleSelectChange = (val, term) => {
    onOperatorChange(val, term)
  }

  const buildChipContent = (term, index) => {
    return (
      <>
        {/* {getIsOpeningParenthesisVisible(index, terms) ? (
          <Brackets>&#40;</Brackets>
        ) : null} */}
        {term?.isOpeningParenthesis ? <Brackets>&#40;</Brackets> : null}
        <ConfigurableChipWrapper
          children={
            <QueryMatchFieldAndValue
              matchField={term.match_field}
              value={term.terms}
            />
          }
          isClickable
          filter={term}
          onClick={onClick}
          onDelete={onDelete}
          minwidth={'226px'}
          isBoxShadow
          height={'85px'}
          backgroundColor={'#F8F8F8'}
          isSmallDeleteIcon
        />
        {/* {getIsClosingParenthesisVisible(index, terms) ? (
          <Brackets>&#41;</Brackets>
        ) : null} */}
        {term?.isClosingParenthesis ? <Brackets>&#41;</Brackets> : null}
      </>
    )
  }

  return (
    <>
      <div style={{width: '60%', margin: '0 auto'}}>
        <Divider backgroundColor={'#D8D8D8'} />
      </div>

      <Container>
        {terms &&
          terms.length > 0 &&
          terms.map((term, index) => {
            return (
              <>
                {index !== 0 && (
                  <Dropdown
                    value={term.logic_flow}
                    handleChange={(val) => handleSelectChange(val, term)}
                    items={[
                      {value: LogicFlow.AND, key: LogicFlow.AND},
                      {value: LogicFlow.OR, key: LogicFlow.OR},
                    ]}
                  />
                )}
                {buildChipContent(term, index)}
              </>
            )
          })}
      </Container>
    </>
  )
}
