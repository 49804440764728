import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
type Item = {
  key: string
  value: string
  id?: string
}
type DropdownProps = {
  value: string
  handleChange: (val) => void
  items: Item[]
}

const useStyles = makeStyles(() => ({
  formControl: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiInputBase-root': {
      color: '#000000',
      // borderColor: '#6EC177',
      // borderWidth: '1px',
      // borderStyle: 'solid',
      // borderRadius: '100px',
      minWidth: '120px',
      //  justifyContent: 'center',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '0px',
    },
  },
  // select: {
  //   width: 'auto',
  //   fontSize: '12px',
  //   '&:focus': {
  //     backgroundColor: 'transparent',
  //   },
  // },
  selectIcon: {
    position: 'absolute',
    color: '#F94687',
    fontSize: '45px',
    top: '-7px',
  },
  paper: {
    // borderRadius: 12,
    //  marginTop: 8,
  },
  list: {
    //  background: 'red',
    background: '#323232',
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      //    background: 'red',
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: '12px',
      color: '#FFFFFF',
    },
    '& li.Mui-selected': {
      color: '#FFFFFF',
      background: '#555555',
      border: '3px solid #323232',
    },
    '& li.Mui-selected:hover': {
      background: '#555555',
    },
    '& li:hover': {
      background: '#555555',
    },
  },
}))
export interface PopoverOrigin {
  vertical: 'top' | 'center' | 'bottom' | number
  horizontal: 'left' | 'center' | 'right' | number
}

enum Position {
  bottom = 'bottom',
  top = 'top',
  center = 'center',
}

export const Dropdown = ({value, handleChange, items}: DropdownProps) => {
  const classes = useStyles()

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    // anchorOrigin: {
    //   vertical: Position.bottom,
    //   horizontal: Position.center,
    // },
    // transformOrigin: {
    //   vertical: Position.top,
    //   horizontal: Position.center,
    // },
    getContentAnchorEl: null,
  }
  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        disableUnderline
        IconComponent={ArrowDropDownIcon}
        MenuProps={menuProps}
        classes={{
          //  select: classes.select,
          icon: classes.selectIcon,
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            {item.key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
