import React from 'react'
import Lottie from 'lottie-react-web'

import * as spinnerData from '../../../public/spinner.json'

const Spinner = () => {
  const options = {
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: spinnerData.default,
  }

  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Lottie width={48} height={48} options={options} />
    </div>
  )
}

export default Spinner
