// COMMON
export const COMMON_SET_RESEARCH_AREAS_AND_SUBJECTS =
  'COMMON_SET_RESEARCH_AREAS_AND_SUBJECTS'

// common actions used in more than one place
export const SET_USER_FILTERS = 'SET_USER_FILTERS'

export const SET_SCROLL_POS = 'SET_SCROLL_POS'
export const SET_OPEN_ACCESS = 'SET_OPEN_ACCESS'
export const CHANGE_HISTORY = 'CHANGE_HISTORY'
export const REFETCH_FEED = 'REFETCH_FEED'

// Onboarding V3
export const ONBOARDING_SET_LOADING = 'ONBOARDING_SET_LOADING'
export const ONBOARDING_UNSET_LOADING = 'ONBOARDING_UNSET_LOADING'
export const ONBOARDING_STEP_UPDATE = 'ONBOARDING_STEP_UPDATE'
export const ONBOARDING_STEP_UPDATE_TWICE = 'ONBOARDING_STEP_UPDATE_TWICE'
export const ONBOARDING_GO_BACK = 'ONBOARDING_GO_BACK'
export const RESET_ONBOARDING_STEP = 'RESET_ONBOARDING_STEP'
export const ONBOARDING_UPDATE_SELECTION = 'ONBOARDING_UPDATE_SELECTION'
export const ONBOARDING_REMOVE_SELECTION = 'ONBOARDING_REMOVE_SELECTION'
export const ONBOARDING_SET_RESEARCH_AREAS = 'ONBOARDING_SET_RESEARCH_AREAS'
export const ONBOARDING_SET_ROLES = 'ONBOARDING_SET_ROLES'
export const ONBOARDING_INIT_PUBLICATIONS = 'ONBOARDING_INIT_PUBLICATIONS'
export const ONBOARDING_INIT_FEEDS = 'ONBOARDING_INIT_FEEDS'
export const ONBOARDING_INIT_TOPICS = 'ONBOARDING_INIT_TOPICS'
export const ONBOARDING_UPDATE_TOPICS = 'ONBOARDING_UPDATE_TOPICS'
export const ONBOARDING_SET_TOPICS_CURSOR = 'ONBOARDING_SET_TOPICS_CURSOR'
export const ONBOARDING_SET_TOPICS_FETCHING_NEXT =
  'ONBOARDING_SET_TOPICS_FETCHING_NEXT'
export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE'

// Feed manager V2
export const UPDATE_MANAGE_FEED_TYPE = 'UPDATE_MANAGE_FEED_TYPE'
export const UPDATE_FEED_CREATE_EDIT_STEP = 'UPDATE_FEED_CREATE_EDIT_STEP'
export const SET_FEEDS_LIST = 'SET_FEEDS_LIST'
export const UPDATE_SUBMIT_BTN_DISABLED_STATE =
  'UPDATE_SUBMIT_BTN_DISABLED_STATE'
export const SET_ACTIVE_FEED = 'SET_ACTIVE_FEED'
export const SET_KEYWORDS_TOPIC_LIST = 'SET_KEYWORDS_TOPIC_LIST'
export const DELETE_FEED_FROM_FEEDS_LIST = 'DELETE_FEED_FROM_FEEDS_LIST'
export const UPDATE_PRIMARY_BTN_STATE = 'UPDATE_PRIMARY_BTN_STATE'
export const INCLUDE_KEYWORDS_TOPICS_AUTHORS = 'INCLUDE_KEYWORDS_TOPICS_AUTHORS'
export const EXCLUDE_ITEM = 'EXCLUDE_ITEM'
export const UPDATE_FEED_NAME = 'UPDATE_FEED_NAME'
export const UPDATE_IS_ONLY_OPEN_ACCESS = 'UPDATE_IS_ONLY_OPEN_ACCESS'
export const UPDATE_IS_ONLY_PUBLICATIONS_FOLLOWED =
  'UPDATE_IS_ONLY_PUBLICATIONS_FOLLOWED'
export const REMOVE_EXCLUDED_ITEM = 'REMOVE_EXCLUDED_ITEM'
export const RESET_MANAGE_FEEDS_STATE = 'RESET_MANAGE_FEEDS_STATE'
export const UPDATE_INCLUDE_KEYWORD_TOPIC_AUTHOR =
  'UPDATE_INCLUDE_KEYWORD_TOPIC_AUTHOR'
export const UPDATE_CONDITION_BETWEEN_KEYWORDS_TOPICS_AUTHORS =
  'UPDATE_CONDITION_BETWEEN_KEYWORDS_TOPICS_AUTHORS'
export const REMOVE_INCLUDED_KEYWORDS_TOPICS_AUTHORS =
  'REMOVE_INCLUDED_KEYWORDS_TOPICS_AUTHORS'
export const ADD_FEED_IN_FEEDS_LIST = 'ADD_FEED_IN_FEEDS_LIST'
export const UPDATE_FEED_IN_FEEDS_LIST = 'UPDATE_FEED_IN_FEEDS_LIST'
export const SET_SELECTED_JOURNALS = 'SET_SELECTED_JOURNALS'

export const SET_SELECTED_SUBJECTS = 'SET_SELECTED_SUBJECTS'
