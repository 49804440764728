import React from 'react'
export const AuthorIcon = () => (
  <svg
    width="88px"
    height="88px"
    viewBox="0 0 88 88"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon_authors</title>
    <defs>
      <polygon
        id="path-1"
        points="0 0 87.7905022 0 87.7905022 87.789991 0 87.789991"
      ></polygon>
      <polygon
        id="path-3"
        points="0 87.7901371 87.7905022 87.7901371 87.7905022 0 0 0"
      ></polygon>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-31-Copy">
        <g id="Group-3">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Clip-2"></g>
          <path
            d="M69.0936736,8.41819131 C61.6975994,3.14951986 52.983901,0.364947958 43.894886,0.364947958 C35.5879023,0.364947958 27.5054514,2.72382026 20.5215684,7.18636417 L20.3247827,6.87895503 C27.3674459,2.37880644 35.5178043,-0.000146037598 43.894886,-0.000146037598 C53.0605708,-0.000146037598 61.8469229,2.80815698 69.3054281,8.1210048 L69.0936736,8.41819131 Z M5.66662391,65.4250631 C1.95945947,58.8380372 0,51.3930404 0,43.8951051 C0,35.0890379 2.60896169,26.5885544 7.54539761,19.3125961 L7.84769544,19.5170488 C2.95251514,26.7324014 0.365093996,35.1620567 0.365093996,43.8951051 C0.365093996,51.3302443 2.30812424,58.7135401 5.98498587,65.2458019 L5.66662391,65.4250631 Z M43.894886,87.789991 C35.9581076,87.789991 28.195114,85.6582072 21.4441609,81.6253789 L21.6318192,81.3113981 C28.3254526,85.3110028 36.0241896,87.4248971 43.894886,87.4248971 C67.8979907,87.4248971 87.4254082,67.8974796 87.4254082,43.8951051 C87.4254082,38.2339576 86.3494762,32.7232288 84.2275499,27.5169884 L84.5656269,27.379348 C86.7054428,32.6293996 87.7905022,38.1861302 87.7905022,43.8951051 C87.7905022,68.0990115 68.0987924,87.789991 43.894886,87.789991 L43.894886,87.789991 Z"
            id="Fill-1"
            fill="#424242"
            mask="url(#mask-2)"
          ></path>
        </g>
        <g id="Group-15">
          <path
            d="M61.6406447,22.2654034 L61.2755507,22.2654034 C61.2755507,19.2245355 58.8016738,16.7506586 55.7608059,16.7506586 L23.3660157,16.7506586 L23.3660157,16.3855646 L55.7608059,16.3855646 C59.0028406,16.3855646 61.6406447,19.0230036 61.6406447,22.2654034"
            id="Fill-4"
            fill="#424242"
          ></path>
          <path
            d="M20.3404453,75.8889125 L58.8180666,75.8889125 C62.6709035,75.8889125 65.8056005,72.7542155 65.8056005,68.9013786 C65.8056005,65.0485416 62.6709035,61.9138446 58.8180666,61.9138446 L20.3404453,61.9138446 C23.2768963,62.8784229 25.4028386,65.6462005 25.4028386,68.9013786 C25.4028386,72.1561915 23.2768963,74.9239691 20.3404453,75.8889125 M58.8180666,76.2540065 L18.0505757,76.2540065 C13.9958418,76.2540065 10.6975827,72.9557474 10.6975827,68.9013786 L10.6975827,16.9284228 C10.6975827,12.8736889 13.9958418,9.57542975 18.0505757,9.57542975 C22.1045795,9.57542975 25.4028386,12.8736889 25.4028386,16.9284228 C25.4028386,20.1832358 23.2768963,22.9510134 20.3404453,23.9159568 L40.762343,23.9159568 L40.762343,24.2810508 L18.0505757,24.2810508 L18.0505757,23.9159568 C21.9030476,23.9159568 25.0377446,20.7812598 25.0377446,16.9284228 C25.0377446,13.0752208 21.9030476,9.94052375 18.0505757,9.94052375 C14.1973737,9.94052375 11.0626767,13.0752208 11.0626767,16.9284228 L11.0626767,68.9013786 C11.0626767,72.7542155 14.1973737,75.8889125 18.0505757,75.8889125 C21.9030476,75.8889125 25.0377446,72.7542155 25.0377446,68.9013786 C25.0377446,65.0485416 21.9030476,61.9138446 18.0505757,61.9138446 L18.0505757,61.5487506 L58.8180666,61.5487506 C62.8724354,61.5487506 66.1706945,64.8470097 66.1706945,68.9013786 C66.1706945,72.9557474 62.8724354,76.2540065 58.8180666,76.2540065"
            id="Fill-6"
            fill="#424242"
          ></path>
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-3"></use>
          </mask>
          <g id="Clip-9"></g>
          <polygon
            id="Fill-8"
            fill="#424242"
            mask="url(#mask-4)"
            points="58.6906853 61.7311881 59.0557793 61.7311881 59.0557793 36.1322575 58.6906853 36.1322575"
          ></polygon>
          <polygon
            id="Fill-10"
            fill="#424242"
            mask="url(#mask-4)"
            points="19.6756456 32.9665274 33.0001161 32.9665274 33.0001161 32.6014334 19.6756456 32.6014334"
          ></polygon>
          <polygon
            id="Fill-11"
            fill="#424242"
            mask="url(#mask-4)"
            points="19.6756456 38.5287344 33.0001161 38.5287344 33.0001161 38.1636405 19.6756456 38.1636405"
          ></polygon>
          <polygon
            id="Fill-12"
            fill="#424242"
            mask="url(#mask-4)"
            points="19.6756456 44.3311733 33.0001161 44.3311733 33.0001161 43.9660793 19.6756456 43.9660793"
          ></polygon>
          <polygon
            id="Fill-13"
            fill="#424242"
            mask="url(#mask-4)"
            points="19.6756456 55.9349558 33.0001161 55.9349558 33.0001161 55.5698618 19.6756456 55.5698618"
          ></polygon>
          <polygon
            id="Fill-14"
            fill="#424242"
            mask="url(#mask-4)"
            points="19.6756456 50.132882 30.1542084 50.132882 30.1542084 49.767788 19.6756456 49.767788"
          ></polygon>
        </g>
        <path
          d="M39.6601242,40.9069202 C37.1898983,44.7451534 34.9792541,49.466914 33.3250132,55.269718 L38.2629095,51.4431678 L39.6601242,40.9069202 Z M32.6780667,56.2332011 L32.8237392,55.7038148 C34.5948101,49.2894784 37.0376541,44.1639238 39.7711128,40.0712201 L39.7736685,40.0675691 C44.2577529,33.3549509 49.5245989,29.4214282 53.8911231,27.132654 C61.5142857,23.1381606 68.1367257,23.1695586 68.1892992,23.1575105 L68.1856483,23.5226045 C68.1297889,23.5211442 61.58913,23.5061753 54.0437324,27.4652546 C49.7392742,29.723726 44.5512885,33.5995639 40.121603,40.2048445 L38.6050026,51.6399535 L32.6780667,56.2332011 Z"
          id="Fill-16"
          fill="#424242"
        ></path>
        <g
          id="Group-30"
          transform="translate(36.969016, 15.867204)"
          fill="#424242"
        >
          <path
            d="M6.51601519,31.3122865 C4.60292265,31.3122865 3.10092596,30.6478155 2.16665042,29.3853204 L2.46055109,29.1677244 C3.35795213,30.3809317 4.78510456,30.9701935 6.65767166,30.9457322 C10.6160208,30.8759992 15.658699,27.9508661 17.3549257,25.4806401 C21.6853056,19.176197 26.5454369,15.5128439 32.2146164,14.2806516 C37.5216228,13.1262244 39.5026228,2.25701108 39.7950631,0.454907118 C38.3492909,0.715584231 30.9148818,1.96347551 21.1643165,1.96347551 C12.7156763,1.96347551 6.73945272,4.27963182 3.4021285,8.8473228 C-1.94686363,16.1670923 1.38206343,26.8847916 1.41638226,26.9921293 L1.06844768,27.103483 C1.03376375,26.9939548 -2.3495623,16.0995499 3.10713255,8.63191734 C6.51747557,3.96492079 12.5930047,1.59838151 21.1643165,1.59838151 C31.9594157,1.59838151 39.9009403,0.0649867312 39.9798006,0.0496527834 L40.2317155,0 L40.1959363,0.254105421 C40.1214571,0.787507748 38.2981777,13.3314072 32.2923815,14.6369834 C26.7184914,15.8487303 21.9310139,19.4631609 17.6561283,25.6876484 C15.9102488,28.2290677 10.7284697,31.2392677 6.66387826,31.3108262 C6.61459057,31.3115563 6.56530288,31.3122865 6.51601519,31.3122865"
            id="Fill-18"
          ></path>
          <path
            d="M4.89627568,13.0044656 C4.83749555,12.9120968 3.4662025,10.7051036 4.12592735,7.43678214 L4.48371946,7.50870566 C3.85429742,10.6266084 5.19090653,12.7872346 5.20441501,12.8087752 L4.89627568,13.0044656 Z"
            id="Fill-20"
          ></path>
          <path
            d="M13.4449881,7.50417849 C13.4150504,7.3336796 12.7319595,3.31362961 14.4625051,2.18585426 L14.6622115,2.49180303 C13.1310073,3.48960492 13.7976689,7.40195218 13.8042406,7.44138233 L13.4449881,7.50417849 Z"
            id="Fill-22"
          ></path>
          <path
            d="M21.3091128,4.92854988 L20.9469395,4.88291313 C20.9575272,4.79857642 21.2276968,2.79567076 22.9615282,1.61422659 L23.1670761,1.91579423 C21.5727106,3.00267906 21.3116684,4.909565 21.3091128,4.92854988"
            id="Fill-24"
          ></path>
          <path
            d="M15.8237945,26.2155744 C13.1210037,26.2155744 9.57155986,23.9523567 9.38609211,23.8326058 L9.58360796,23.5259269 C9.63873715,23.5609759 15.0717009,27.0268132 17.402461,25.4331779 L17.6087391,25.7351107 C17.1078301,26.0775688 16.4915515,26.2155744 15.8237945,26.2155744"
            id="Fill-26"
          ></path>
          <path
            d="M23.66762,18.4641177 C21.6030134,18.4641177 20.009013,15.8949512 19.934899,15.7744702 L20.2466892,15.5838912 C20.265309,15.6138289 22.1214469,18.6079647 24.238627,18.0205285 L24.3364722,18.372114 C24.1093837,18.4356404 23.8859462,18.4641177 23.66762,18.4641177"
            id="Fill-28"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
