import {css} from 'styled-components'
import theme from 'lib/theme'

const sectionStyle = {
  title: css`
    font-size: 4rem;
    @media (max-width: 750px) {
      font-size: 3rem;
    }
    @media (max-width: 500px) {
      font-size: 2rem;
    }
    margin-bottom: 20px;
  `,
  subtitle: css`
    font-size: 3rem;
    @media (max-width: 750px) {
      font-size: 2rem;
    }
    @media (max-width: 500px) {
      font-size: 1.5rem;
    }
    margin-bottom: 20px;
  `,
  textCentered: css`
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  `,
  journalBackground: css`
    z-index: -2;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -500px;
    right: -500px;
    background: ${theme.colors.purple};
  `
}

export default sectionStyle
