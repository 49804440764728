import React from 'react'
import whatIsImage from 'assets/images/manageFeed/what_is.png'

export const HelpDetails = () => (
  <img
    src={whatIsImage}
    alt="whatIsImage"
    style={{width: '50%', display: 'block', margin: '0 auto'}}
  />
)
