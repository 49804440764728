import {Filter} from 'components/FeedV2/interfaces/api'
import {deleteRequest, getRequest} from 'components/FeedV2/util/apiClient'
import {
  UPDATE_MANAGE_FEED_TYPE,
  UPDATE_FEED_CREATE_EDIT_STEP,
  SET_FEEDS_LIST,
  UPDATE_PRIMARY_BTN_STATE,
  UPDATE_SUBMIT_BTN_DISABLED_STATE,
  SET_ACTIVE_FEED,
  DELETE_FEED_FROM_FEEDS_LIST,
  INCLUDE_KEYWORDS_TOPICS_AUTHORS,
  EXCLUDE_ITEM,
  UPDATE_FEED_NAME,
  UPDATE_IS_ONLY_OPEN_ACCESS,
  UPDATE_IS_ONLY_PUBLICATIONS_FOLLOWED,
  REMOVE_EXCLUDED_ITEM,
  RESET_MANAGE_FEEDS_STATE,
  UPDATE_INCLUDE_KEYWORD_TOPIC_AUTHOR,
  UPDATE_CONDITION_BETWEEN_KEYWORDS_TOPICS_AUTHORS,
  REMOVE_INCLUDED_KEYWORDS_TOPICS_AUTHORS,
  ADD_FEED_IN_FEEDS_LIST,
  UPDATE_FEED_IN_FEEDS_LIST,
  SET_SELECTED_JOURNALS,
  SET_SELECTED_SUBJECTS,
} from '../mutation-types'
import Cookies from 'js-cookie'
import axios from 'axios'
import {getUserFilters} from './common'

declare const window: any

export const updateManageFeedType = (step: number) => {
  return {
    type: UPDATE_MANAGE_FEED_TYPE,
    step,
  }
}

export const updateCreateEditFeedStep = (step: number) => {
  return {
    type: UPDATE_FEED_CREATE_EDIT_STEP,
    step,
  }
}

export const setFilters = (filters: Filter[]) => {
  return {
    type: SET_FEEDS_LIST,
    filters,
  }
}

export const setJournals = (journals: any[]) => {
  return {
    type: SET_SELECTED_JOURNALS,
    journals,
  }
}

export const setSubjects = (subjects: any[]) => {
  return {
    type: SET_SELECTED_SUBJECTS,
    subjects,
  }
}

export const setActiveFeed = (activeFeed) => {
  return {
    type: SET_ACTIVE_FEED,
    activeFeed,
  }
}

export const updateSubmitBtnDisabledState = (isDisabled: boolean) => {
  return {
    type: UPDATE_SUBMIT_BTN_DISABLED_STATE,
    isDisabled,
  }
}

export const updatePrimaryBtnState = (isDisabled: boolean) => {
  return {
    type: UPDATE_PRIMARY_BTN_STATE,
    isDisabled,
  }
}

export const deleteUserFilter = (userId, feedIdToDelete) => {
  return (dispatch) => {
    let url = `v4/users/${userId}/filters/${feedIdToDelete}?`
    return deleteRequest(`${url}`)
      .then((response) => {
        dispatch({
          type: DELETE_FEED_FROM_FEEDS_LIST,
          id: feedIdToDelete,
        })

        //   dispatch(getUserFilters())
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        console.log('Finally')
        return Promise.resolve()
      })
  }
}

export const includeKeywordsTopicsAuthors = (data) => {
  return {
    type: INCLUDE_KEYWORDS_TOPICS_AUTHORS,
    data,
  }
}

export const updateIncludeKeywordTopicAuthor = (data) => {
  return {
    type: UPDATE_INCLUDE_KEYWORD_TOPIC_AUTHOR,
    data,
  }
}
export const excludeItem = (data, arrayName) => {
  return {
    type: EXCLUDE_ITEM,
    data,
    arrayName,
  }
}

export const updateFeedName = (name) => {
  return {
    type: UPDATE_FEED_NAME,
    name,
  }
}
export const updateIsOnlyOpenAccess = (isChecked) => {
  return {
    type: UPDATE_IS_ONLY_OPEN_ACCESS,
    isChecked,
  }
}
export const updateIsOnlyPublicationsFollowed = (isChecked) => {
  return {
    type: UPDATE_IS_ONLY_PUBLICATIONS_FOLLOWED,
    isChecked,
  }
}

export const removeExcludedItem = (arrayName, arrayIndex) => {
  return {
    type: REMOVE_EXCLUDED_ITEM,
    arrayName,
    arrayIndex,
  }
}

export const resetManagedFeedState = () => {
  return {
    type: RESET_MANAGE_FEEDS_STATE,
  }
}

export const updateConditionBetweenKeywordsTopicsAuthors = (val, term) => {
  return {
    val,
    term,
    type: UPDATE_CONDITION_BETWEEN_KEYWORDS_TOPICS_AUTHORS,
  }
}

export const removeIncludedKeywordsTopicsAuthors = (term) => {
  return {
    term,
    type: REMOVE_INCLUDED_KEYWORDS_TOPICS_AUTHORS,
  }
}

export const addFeedInFeedList = (data) => {
  return {
    data,
    type: ADD_FEED_IN_FEEDS_LIST,
  }
}

export const updateFeedInFeedList = (data) => {
  return {
    data,
    type: UPDATE_FEED_IN_FEEDS_LIST,
  }
}
const apiUrl = window.env
  ? window.env.API_URL
  : process.env.API_URL
  ? process.env.API_URL
  : 'https://api-staging.researcher-app.com'
