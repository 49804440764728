import {TextField, Switch, makeStyles, IconButton} from '@material-ui/core'
import {
  getIsDuplicateFeedName,
  getIsFeedNameValid,
  TranslationKeys,
} from 'components/FeedV2/util/util'

import React, {useState} from 'react'
import {useReducer} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  updateFeedName,
  updateIsOnlyOpenAccess,
  updateIsOnlyPublicationsFollowed,
  updatePrimaryBtnState,
} from 'store/actions/feedManager'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import {useEffect} from 'react'

type SettingProps = {
  title: string
}

type DividerProps = {
  backgroundColor?: string
}

export const Divider = styled.hr<DividerProps>`
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#979797'};
  height: 1.3px;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 30px;
`

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export const Settings = () => {
  let {activeFeed, filters} = useSelector((state: any) => state.feedManager)
  const dispatch = useDispatch()
  const [isDuplicateName, setIsDuplicteName] = useState(false)

  const classes = useStyles()
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const label = {inputProps: {'aria-label': 'Switch demo'}}

  const clearSearch = () => {
    dispatch(updateFeedName(''))
  }

  useEffect(() => {
    const isDuplicate = getIsDuplicateFeedName(
      activeFeed.name,
      filters,
      activeFeed.id
    )
    if (!activeFeed.name || isDuplicate) {
      dispatch(updatePrimaryBtnState(true))
    }
    setIsDuplicteName(isDuplicate)
  }, [activeFeed, activeFeed.name, dispatch, filters])

  return (
    <form
      id="submit-form"
      className={classes.formContainer}
      onSubmit={handleSubmit}
    >
      <div className={classes.row}>
        {TranslationKeys.name}
        <div style={{width: '90%'}}>
          <TextField
            id="standard-error"
            type="search"
            multiline
            fullWidth
            color="secondary"
            autoComplete="off"
            error={isDuplicateName}
            helperText={
              isDuplicateName ? TranslationKeys.duplicateFeedNameError : null
            }
            // label={placeholder}
            name="name"
            value={activeFeed.name}
            onChange={
              (e) => dispatch(updateFeedName(e.target.value))
              // formDispatch({
              //   type: 'input',
              //   name: e.target.name,
              //   value: e.target.value,
              // })
            }
            InputProps={{
              endAdornment: activeFeed.name && (
                <IconButton
                  size="small"
                  aria-label="close"
                  onClick={clearSearch}
                >
                  <CloseIcon />
                </IconButton>
              ),
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <Divider />
      </div>

      <div className={classes.row}>
        {TranslationKeys.showOnlyOpenAccessPapers}
        <Switch
          {...label}
          checked={activeFeed.isOnlyOpenAccess}
          name="isOnlyOpenAccess"
          onChange={
            (e) => dispatch(updateIsOnlyOpenAccess(e.target.checked))
            // formDispatch({
            //   type: 'checkbox',
            //   name: e.target.name,
            //   checked: e.target.checked,
            // })
          }
        />
      </div>
      <div className={classes.row}>
        <Divider />
      </div>

      <div className={classes.row}>
        {TranslationKeys.showPapersOnlyFromFollowedPublications}
        <Switch
          {...label}
          checked={activeFeed.isOnlyFollowedPublications}
          name="isOnlyFollowedPublications"
          onChange={
            (e) => dispatch(updateIsOnlyPublicationsFollowed(e.target.checked))
            // formDispatch({
            //   type: 'checkbox',
            //   name: e.target.name,
            //   checked: e.target.checked,
            // })
          }
        />
      </div>
      <div className={classes.row}>
        <Divider />
      </div>
    </form>
  )
}
