import React from 'react'
import styled from 'styled-components'
import {withStyles} from '@material-ui/core/styles'
import MuiDialogActions from '@material-ui/core/DialogActions'
import {CustomButton} from '../shared/CustomButton/CustomButton'
import {ModalSize} from '.'
import Spinner from 'components/shared/Spinner'
import {CircularProgress} from '@material-ui/core'

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: (props) =>
      props.size === ModalSize.sm ? '#FFFFFF' : '#F7F7FA',
  },
}))(MuiDialogActions)

export const CustomModalFooter = (props) => {
  const {handleAction, primaryBtnConfig, secondaryBtnConfig, size} = props
  return (
    <DialogActions size={size}>
      {secondaryBtnConfig && (
        <CustomButton
          btnVariant="text"
          isDisabled={
            secondaryBtnConfig.isDisabled || primaryBtnConfig.isLoading
          }
          onClick={() => handleAction(secondaryBtnConfig.text)}
          backgroundColor="#F7F7FA"
          color="#F94687"
          fontFamily="ReplicaBold"
          isBold
        >
          {secondaryBtnConfig.text}
        </CustomButton>
      )}

      <CustomButton
        btnVariant="contained"
        isDisabled={primaryBtnConfig.isDisabled || primaryBtnConfig.isLoading}
        onClick={() => handleAction(primaryBtnConfig.text)}
        backgroundColor="#F94687"
        color="#FFFFFF"
        fontFamily="ReplicaBold"
        isBold
      >
        {primaryBtnConfig.isLoading ? (
          <CircularProgress style={{color: '#f94687'}} />
        ) : (
          primaryBtnConfig.text
        )}
      </CustomButton>
    </DialogActions>
  )
}
