import {Match} from '../shared/CreateEditKeyword.tsx/CreateEditKeyword'

export enum MatchField {
  TOPIC = 'topic',
  KEYWORD = 'abstract',
  AUTHOR = 'author',
}

export enum LogicFlow {
  AND = 'AND',
  OR = 'OR',
}

interface Settings {
  isOnlyOpenAccess: boolean
  isOnlyPublicationsFollowed: boolean
}

interface Excludes {
  match_field: string
  match_type?: string
  ids?: number[]
  terms?: string[]
}

export interface Term {
  id: string
  logic_flow: LogicFlow
  match_field: string
  match_type: string
  terms: string[]
  // excludes: Excludes[]
  sort_index: number
}

export interface Filter {
  id: number
  name: string
  all_journals: boolean
  open_only: boolean
  terms: Term[]
  excludes: Excludes[]
}

interface GenericPayload {
  id: number
  name: string
}

interface Author {
  firstName?: string
  lastName: string
}
export interface ActiveFeed {
  name: string
  settings: Settings
  isOnlyOpenAccess: boolean
  isOnlyFollowedPublications: boolean
  inclusions: Term[]
  exclusions: {
    keywordsTopics: {
      field: MatchField
      terms: string
    }[]
    subjects: GenericPayload[]
    publications: GenericPayload[]
    articleTypes: GenericPayload[]
    authors: Author[]
  }
}

/**
 *  Interfaces related to get filters
 */
interface GetFiltersAPIResponse {
  data: {
    filters: Filter[]
  }
}

/**
 *  interface for create filter API
 */
interface CreateFilter {
  all_journals: boolean
  terms: Term[]
  open_only: boolean
  excludes: Excludes[]
  name: string
  sort_index: number
}
