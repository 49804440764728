import {css} from 'styled-components'
import React from 'react'
import theme from 'lib/theme'
import {Link as ReactRouterLink} from 'react-router-dom'

const commonStyle = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 20px;
  margin: -10px -20px;
  min-width: 0;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const primaryStyle = css`
  color: ${theme.colors.pink};
  font-family: 'Replica', Arial, sans-serif;
  font-weight: bold;
`

const secondaryStyle = css`
  color: ${theme.colors.blacka};
  font-family: 'Replica', Arial, sans-serif;
`

const Link = ({primary, to, external, newTab, children, isSelf = false}) => {
  if (external) {
    return (
      <a
        href={to}
        css={`
          ${commonStyle}
          ${primary ? primaryStyle : secondaryStyle}
        `}
        target={isSelf ? '_self' : '_blank'}
        rel="noopener noreferrer nofollow"
      >
        {children}
      </a>
    )
  }
  return (
    <ReactRouterLink
      to={to}
      css={`
        ${commonStyle}
        ${primary ? primaryStyle : secondaryStyle}
      `}
    >
      {children}
    </ReactRouterLink>
  )
}

export default Link
