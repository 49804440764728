import {CustomModalHeader} from './CustomModalHeader'

export enum ModalSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xs = 'xs',
}

export {CustomModalHeader}
