import React from 'react'
// import {desktop} from './LandingPageCommercial'
const desktop = 'min-width:1600px'
export const Description = ({description, isBackgroundDark}) => {
  return (
    <p
      css={`
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 24px;
        color: ${isBackgroundDark ? '#FFFFFF' : '#4A4A4A'};
        /* @media (${desktop}) {
          font-size: 0.885rem;
        } */
      `}
    >
      {description}
    </p>
  )
}
