import {css} from 'styled-components'
import React from 'react'
import useUserAgent from 'lib/useUserAgent'
import theme from 'lib/theme'
const appleSrc = '/static-images/landingDownloadAppleStore.png'
const googleSrc = '/static-images/landingDownloadGoogleStore.png'
const appInChinaSrc = '/static-images/huaweiStore.png'

const Download = ({imgSrc, imgWidth, alt, to, paperId, type}) => {
  // Will return current environment
  const getEnvironment = () => {
    if (window.env.IS_STAGING === 'true') {
      return 'test'
    } else {
      if (
        window.location.href.indexOf('pr-') == -1 &&
        window.location.href.indexOf('localhost') == -1
      ) {
        return 'production'
      } else {
        return 'local'
      }
    }
  }

  // Called when buttons clicked, will handle segments
  const handleSegmentsOnButtonClick = (type, galleryType) => {
    try {
      const eventData = {
        event_source: 'researcher.web',
        schema_version: 2.2,
        event_page: 'sign_up_pop_up',
        event_action: type, //sign_up
        event_label: parseInt(paperId),
        event_details: {download_type: galleryType},
        environment: getEnvironment(),
      }
      console.log(eventData)
      // Track segments event
      window.analytics.track('logged.out.interactions', eventData)
    } catch (err) {
      console.log('Error while tracking:' + err)
    }
  }
  return (
    <li
      css={`
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        transition: transform 200ms;

        a {
          background-color: ${theme.colors.dark.black};
          display: inline-block;
          border-radius: 6px;
          height: 50px;
          width: 170px;
        }

        img {
          width: ${imgWidth};
        }

        &:hover {
          transform: scale(1.1);
        }
      `}
    >
      <a href={to} target="_blank" rel="noopener noreferrer">
        <div
          onClick={() => handleSegmentsOnButtonClick('download', type)}
          css={`
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
          `}
        >
          <img src={imgSrc} alt={alt} />
        </div>
      </a>
    </li>
  )
}

export const DownloadButtons = ({
  vertical,
  adaptive,
  css: extraCss,
  className,
  redirectLink,
  paperId,
}) => {
  const userAgent = useUserAgent()

  let downloadButtons

  console.log({redirectLink})
  const apple = (
    <Download
      paperId={paperId}
      type={'apple_app_store'}
      imgSrc={appleSrc}
      imgWidth="126px"
      alt="Download from App Store"
      to={redirectLink || 'https://app.adjust.net.in/o843s6d'}
    />
  )
  const google = (
    <Download
      paperId={paperId}
      type={'google_play_store'}
      imgSrc={googleSrc}
      imgWidth="125px"
      alt="Download from Google Play"
      to={
        redirectLink ||
        'https://app.adjust.net.in/lfh99mf?fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dio.fusetech.stackademia%26hl%3Den_GB'
      }
    />
  )
  const appInChina = (
    <Download
      paperId={paperId}
      type={'huawei_app_gallery'}
      imgSrc={appInChinaSrc}
      imgWidth="137px"
      alt="Download from AppInChina"
      to={'https://appgallery.huawei.com/#/app/C100347653'}
    />
  )

  if (userAgent.os === 'Mac OS' || userAgent.os === 'iOS') {
    downloadButtons = (
      <>
        {apple}
        {google}
        {appInChina}
      </>
    )
  } else {
    downloadButtons = (
      <>
        {google}
        {apple}
        {appInChina}
      </>
    )
  }

  const verticalStyle = css`
    flex-direction: column;
  `
  const adaptiveStyle = css`
    @media (max-width: 750px) {
      flex-direction: column;
    }
  `

  return (
    <ul
      css={`
        margin-top: 20px;
        margin-bottom: 20px;

        display: flex;

        justify-content: center;
        align-items: center;

        ${vertical && verticalStyle}
        ${adaptive && adaptiveStyle}

        ${extraCss}
      `}
      className={className}
    >
      {downloadButtons}
    </ul>
  )
}

export default DownloadButtons
