import React from 'react'
export const SubjectIcon = () => (
  <svg
    width="88px"
    height="87px"
    viewBox="0 0 88 87"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon_sublects</title>
    <defs>
      <polygon id="path-1" points="0 0 65 0 65 11.9998 0 11.9998"></polygon>
      <polygon id="path-3" points="0 0 1 0 1 11.9998 0 11.9998"></polygon>
      <polygon id="path-5" points="0 0 1 0 1 11.9998 0 11.9998"></polygon>
      <polygon id="path-7" points="0 0 1 0 1 11.9998 0 11.9998"></polygon>
      <polygon id="path-9" points="0 0 1 0 1 11.9998 0 11.9998"></polygon>
      <polygon
        id="path-11"
        points="0 86.996 87.34 86.996 87.34 0 0 0"
      ></polygon>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icon_sublects">
        <path
          d="M77.5023,15.9803 C83.5483,23.4523 87.1703,32.9663 87.1703,43.3263 C87.1703,67.3503 67.6943,86.8263 43.6703,86.8263 C19.6463,86.8263 0.1703,67.3503 0.1703,43.3263 C0.1703,34.3163 2.9083,25.9473 7.5993,19.0043"
          id="Stroke-1"
          stroke="#545454"
          strokeWidth="0.34"
        ></path>
        <g id="Group-38">
          <path
            d="M79.2084,32.9002 L18.2084,32.9002 C17.1074,32.9002 16.2084,33.8002 16.2084,34.9002 L16.2084,47.9002 C16.2084,49.0002 17.1074,49.9002 18.2084,49.9002 L79.2084,49.9002 C80.3084,49.9002 81.2084,49.0002 81.2084,47.9002 L81.2084,34.9002 C81.2084,33.8002 80.3084,32.9002 79.2084,32.9002 M79.2084,33.2402 C80.1234,33.2402 80.8684,33.9842 80.8684,34.9002 L80.8684,47.9002 C80.8684,48.8152 80.1234,49.5602 79.2084,49.5602 L18.2084,49.5602 C17.2924,49.5602 16.5484,48.8152 16.5484,47.9002 L16.5484,34.9002 C16.5484,33.9842 17.2924,33.2402 18.2084,33.2402 L79.2084,33.2402"
            id="Fill-3"
            fill="#545454"
          ></path>
          <path
            d="M74.2084,49.9002 L13.2084,49.9002 C12.1074,49.9002 11.2084,50.8002 11.2084,51.9002 L11.2084,64.9002 C11.2084,66.0002 12.1074,66.9002 13.2084,66.9002 L74.2084,66.9002 C75.3084,66.9002 76.2084,66.0002 76.2084,64.9002 L76.2084,51.9002 C76.2084,50.8002 75.3084,49.9002 74.2084,49.9002 M74.2084,50.2402 C75.1234,50.2402 75.8684,50.9842 75.8684,51.9002 L75.8684,64.9002 C75.8684,65.8152 75.1234,66.5602 74.2084,66.5602 L13.2084,66.5602 C12.2924,66.5602 11.5484,65.8152 11.5484,64.9002 L11.5484,51.9002 C11.5484,50.9842 12.2924,50.2402 13.2084,50.2402 L74.2084,50.2402"
            id="Fill-5"
            fill="#545454"
          ></path>
          <line
            x1="27.3725"
            y1="32.9002"
            x2="27.3725"
            y2="49.9002"
            id="Stroke-7"
            stroke="#545454"
            strokeWidth="0.34"
          ></line>
          <line
            x1="70.2084"
            y1="32.9002"
            x2="70.2084"
            y2="49.9002"
            id="Stroke-9"
            stroke="#545454"
            strokeWidth="0.34"
          ></line>
          <line
            x1="68.4809"
            y1="49.9002"
            x2="68.4809"
            y2="66.9002"
            id="Stroke-11"
            stroke="#545454"
            strokeWidth="0.34"
          ></line>
          <line
            x1="63.2084"
            y1="49.9002"
            x2="63.2084"
            y2="66.9002"
            id="Stroke-13"
            stroke="#545454"
            strokeWidth="0.34"
          ></line>
          <line
            x1="23.4809"
            y1="49.9002"
            x2="23.4809"
            y2="66.9002"
            id="Stroke-15"
            stroke="#545454"
            strokeWidth="0.34"
          ></line>
          <g id="Group-19" transform="translate(11.170300, 0.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-18"></g>
            <path
              d="M63,-0.0002 L2,-0.0002 C0.899,-0.0002 0,0.8998 0,1.9998 L0,9.9998 C0,11.0998 0.899,11.9998 2,11.9998 L63,11.9998 C64.1,11.9998 65,11.0998 65,9.9998 L65,1.9998 C65,0.8998 64.1,-0.0002 63,-0.0002 M63,0.3398 C63.915,0.3398 64.659,1.0848 64.659,1.9998 L64.659,9.9998 C64.659,10.9158 63.915,11.6598 63,11.6598 L2,11.6598 C1.084,11.6598 0.34,10.9158 0.34,9.9998 L0.34,1.9998 C0.34,1.0848 1.084,0.3398 2,0.3398 L63,0.3398"
              id="Fill-17"
              fill="#545454"
              mask="url(#mask-2)"
            ></path>
          </g>
          <g id="Group-22" transform="translate(25.942800, 0.000000)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g id="Clip-21"></g>
            <line
              x1="0.5"
              y1="-0.0002"
              x2="0.5"
              y2="11.9998"
              id="Stroke-20"
              stroke="#545454"
              strokeWidth="0.34"
              mask="url(#mask-4)"
            ></line>
          </g>
          <g id="Group-25" transform="translate(20.670300, 0.000000)">
            <mask id="mask-6" fill="white">
              <use xlinkHref="#path-5"></use>
            </mask>
            <g id="Clip-24"></g>
            <line
              x1="0.5"
              y1="-0.0002"
              x2="0.5"
              y2="11.9998"
              id="Stroke-23"
              stroke="#545454"
              strokeWidth="0.34"
              mask="url(#mask-6)"
            ></line>
          </g>
          <g id="Group-28" transform="translate(66.524800, 0.000000)">
            <mask id="mask-8" fill="white">
              <use xlinkHref="#path-7"></use>
            </mask>
            <g id="Clip-27"></g>
            <line
              x1="0.5"
              y1="-0.0002"
              x2="0.5"
              y2="11.9998"
              id="Stroke-26"
              stroke="#545454"
              strokeWidth="0.34"
              mask="url(#mask-8)"
            ></line>
          </g>
          <g id="Group-31" transform="translate(61.252300, 0.000000)">
            <mask id="mask-10" fill="white">
              <use xlinkHref="#path-9"></use>
            </mask>
            <g id="Clip-30"></g>
            <line
              x1="0.5"
              y1="-0.0002"
              x2="0.5"
              y2="11.9998"
              id="Stroke-29"
              stroke="#545454"
              strokeWidth="0.34"
              mask="url(#mask-10)"
            ></line>
          </g>
          <mask id="mask-12" fill="white">
            <use xlinkHref="#path-11"></use>
          </mask>
          <g id="Clip-33"></g>
          <polygon
            id="Stroke-32"
            stroke="#545454"
            strokeWidth="0.34"
            mask="url(#mask-12)"
            points="34.241 44.847 63.208 44.847 63.208 37.214 34.241 37.214"
          ></polygon>
          <path
            d="M54.85,57.8226 C54.85,59.9306 49.944,61.6386 43.892,61.6386 C37.839,61.6386 32.933,59.9306 32.933,57.8226 C32.933,55.7146 37.839,54.0066 43.892,54.0066 C49.944,54.0066 54.85,55.7146 54.85,57.8226 Z"
            id="Stroke-34"
            stroke="#545454"
            strokeWidth="0.34"
            mask="url(#mask-12)"
          ></path>
          <path
            d="M21.4809,11.826 C15.6149,11.826 10.8589,16.582 10.8589,22.449 C10.8589,28.315 15.6149,33.071 21.4809,33.071"
            id="Stroke-35"
            stroke="#545454"
            strokeWidth="0.34"
            mask="url(#mask-12)"
          ></path>
          <path
            d="M72.1195,11.8319 C73.2655,11.8319 74.1955,12.7619 74.1955,13.9089 C74.1955,15.0559 73.2655,15.9849 72.1195,15.9849 L70.5605,15.9859 L22.6285,15.9899 C19.0605,15.9899 16.1705,18.8819 16.1705,22.4489 C16.1705,26.0149 19.0605,28.9069 22.6285,28.9069 L72.1195,28.9059 C73.2655,28.9059 74.1955,29.8349 74.1955,30.9819 C74.1955,32.1289 73.2655,33.0589 72.1195,33.0589"
            id="Stroke-36"
            stroke="#545454"
            strokeWidth="0.34"
            mask="url(#mask-12)"
          ></path>
          <line
            x1="70.2084"
            y1="28.9056"
            x2="70.2084"
            y2="15.9856"
            id="Stroke-37"
            stroke="#545454"
            strokeWidth="0.34"
            mask="url(#mask-12)"
          ></line>
        </g>
      </g>
    </g>
  </svg>
)
