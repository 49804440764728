import 'react-datepicker/dist/react-datepicker.css'
import 'assets/reset.css'
import 'assets/fonts.css'
import 'assets/typography.css'
import loadable from '@loadable/component'
import React from 'react'
import gql from 'graphql-tag'
import {Switch, Route as ReactRouterRoute, Redirect} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import qs from 'query-string'
import AppLayout from 'components/shared/AppLayout'
import LandingPagePaper from 'pages/LandingPagePaper'
import AppPageHome from 'pages/AppPageHome'
const AppPageProfile = loadable(() => import('pages/AppPageProfile'))
const AppPageJournals = loadable(() => import('pages/AppPageJournals'))
const AppPagePaper = loadable(() => import('pages/AppPagePaper'))
const AppPageSearch = loadable(() => import('pages/AppPageSearch'))
const AppPageBookmarks = loadable(() => import('pages/AppPageBookmarks'))
const AppPageTrending = loadable(() => import('pages/AppPageTrending'))
const AppPageSettings = loadable(() => import('pages/AppPageSettings'))
const AppPageSettingsData = loadable(() => import('pages/AppPageSettingsData'))
const AppPageSettingsManger = loadable(() =>
  import('pages/AppPageSettingManger')
)
const AppPageSettingsDelete = loadable(() =>
  import('pages/AppPageSettingsDelete')
)
const LandingPageDiscover = loadable(() => import('pages/LandingPageDiscover'))
const LandingPageAboutUs = loadable(() => import('pages/LandingPageAboutUs'))
const LandingPagePublishers = loadable(() =>
  import('pages/LandingPagePublishers')
)
const LandingPageAuthors = loadable(() => import('pages/LandingPageAuthors'))
const LandingPageClaimJournal = loadable(() =>
  import('pages/LandingPageClaimJournal')
)
const LandingPageWiley = loadable(() => import('pages/LandingPageWiley'))
const LandingPageFaq = loadable(() => import('pages/LandingPageFaq'))

const AppPageTerms = loadable(() => import('pages/LegalPageTerms'))
const AppPagePrivacy = loadable(() => import('pages/LegalPagePrivacy'))
const AppPagePrivacyCn = loadable(() => import('pages/LegalPagePrivacyCn'))
const AppPageCookies = loadable(() => import('pages/LegalPageCookies'))
const AppPage404 = loadable(() => import('pages/AppPage404'))
const Loading = loadable(() => import('components/shared/FormLoading'))
const LandingPageMain = loadable(() => import('pages/LandingPageMain'))
const AuthPageSelectAuth = loadable(() => import('pages/AuthPageSelectAuth'))
const AuthPageLogin = loadable(() => import('pages/AuthPageLogin'))
const AuthPageSignup = loadable(() => import('pages/AuthPageSignup'))
const AuthPageTerms = loadable(() => import('pages/AuthPageTerms'))
const AuthPageResetPasswordEmail = loadable(() =>
  import('pages/AuthPageResetPasswordEmail')
)
const AuthPageResetPassword = loadable(() =>
  import('pages/AuthPageResetPassword')
)
const OnboardingPageLayout = loadable(() =>
  import('pages/OnboardingPageLayout')
)
const FormRenderer = loadable(() => import('pages/FormRenderer'))

const LandingPageCommercial = loadable(() =>
  import('pages/Commercial/LandingPageCommercial')
)

// web sections:
//   onboarding
//   landing
//   publishers admin
//   app
//   logged out content

const assertRoute = ({loggedIn, loggedOut, component}) => {
  if (component && (loggedIn || loggedOut)) {
    throw new Error('')
  }
}

const LOGGED_IN = gql`
  query LoggedIn {
    loggedIn
  }
`

const RedirectToLogin = ({location}) => {
  return (
    <Redirect
      to={{
        pathname: '/login',
        search: '?redirect=' + location.pathname,
        state: location.state,
      }}
    />
  )
}
const RedirectToFeed = ({location}) => {
  const redirect = qs.parse(location.search).redirect || '/feed/all'
  return <Redirect to={{pathname: redirect, state: location.state}} />
}

const Route = ({loggedIn, loggedOut, component, ...props}) => {
  assertRoute({loggedIn, loggedOut, component})

  if (loggedIn && !loggedOut) loggedOut = RedirectToLogin
  if (!loggedIn && loggedOut) loggedIn = RedirectToFeed

  const {data, loading} = useQuery(LOGGED_IN)

  if (component) {
    // do nothing
    // component = component
  } else if (loading || !data) {
    component = Loading
  } else {
    component = data.loggedIn ? loggedIn : loggedOut
  }

  const patch = typeof component === 'object' ? {...component} : {component}

  props = {...props, ...patch}

  return <ReactRouterRoute {...props} />
}

const CrashPage = () => {
  const performCrash = () => {
    throw new Error(`Performed crash at: ${new Date().toString()}`)
  }

  return <button onClick={performCrash}>Crash App</button>
}

const App = () => {
  return (
    <Switch>
      {/* <Route path="/news" component={LandingPagePR} /> */}
      <Route path="/form/:id" component={FormRenderer} />
      <Route path="/crash" component={CrashPage} />
      <Route exact path="/" component={LandingPageMain} />
      <Route path="/faq" component={LandingPageFaq} />
      <Route path="/(advertise|publishers)" component={LandingPagePublishers} />
      <Route path="/discover" component={LandingPageDiscover} />
      <Route path="/about" component={LandingPageAboutUs} />
      <Route path="/authors" component={LandingPageAuthors} />
      <Route path="/commercial" component={LandingPageCommercial} />
      <Route path="/claim-journal" component={LandingPageClaimJournal} />
      <Route path="/24-days-of-stem-cells" component={LandingPageWiley} />

      <Route exact path="/(login|signup)" loggedOut={AuthPageSelectAuth} />
      <Route path="/login/email" loggedOut={AuthPageLogin} />
      <Route path="/signup/email" loggedOut={AuthPageSignup} />
      {/* send the reset email */}
      <Route path="/reset-password" loggedOut={AuthPageResetPasswordEmail} />
      {/* clicked the reset email, and now changing the password */}
      <Route path="/reset" loggedOut={AuthPageResetPassword} />
      <Route path="/signup/terms" component={AuthPageTerms} />
      <Route path="/onboarding" loggedIn={OnboardingPageLayout} />
      <Redirect from="/journal/:id" to="/feed/journal/:id" />
      <Route path="/feed" loggedIn={AppPageHome}></Route>
      <Route exact path="/feed/new" loggedIn={AppPageHome}></Route>
      <Route path="/profile/me" loggedIn={AppPageProfile}></Route>
      <Route path="/journals" loggedIn={AppPageJournals}></Route>
      <Route path="/trending" loggedIn={AppPageTrending}></Route>
      <Route path="/search" loggedIn={AppPageSearch}></Route>
      <Route path="/library" loggedIn={AppPageBookmarks}></Route>
      <Route exact path="/settings" loggedIn={AppPageSettings}></Route>
      <Route exact path="/settings/data" loggedIn={AppPageSettingsData}></Route>
      <Route
        exact
        path="/settings/reference-managers"
        loggedIn={AppPageSettingsManger}
      ></Route>
      <Route
        exact
        path="/settings/data/delete"
        loggedIn={AppPageSettingsDelete}
      ></Route>

      <Route
        path="/paper/:id"
        loggedIn={AppPagePaper}
        loggedOut={LandingPagePaper}
      />

      <Route exact path="/terms" component={AppPageTerms} />
      <Route exact path="/privacy" component={AppPagePrivacy} />
      <Route exact path="/privacy/cn" component={AppPagePrivacyCn} />
      <Route path="/cookies" component={AppPageCookies} />

      <Route
        loggedIn={{
          render: () => (
            <AppLayout>
              <AppPage404 />
            </AppLayout>
          ),
        }}
        loggedOut={AppPage404}
      />
    </Switch>
  )
}

export default App
