import React, {useEffect, useState} from 'react'
import CustomModal from '../CustomDialog/CustomModal'
import {FeedManagerContainer} from './FeedManagerContainer/FeedManagerContainer'
import {useSelector, useDispatch} from 'react-redux'
import {
  buildCreateFeedPayload,
  getFeedManagerTitle,
  getPrimaryBtnConfig,
  getSecondaryBtnConfig,
  getUserId,
  shouldPrimaryBtnDisable,
} from '../util/util'
import {CreateEditFeed} from './FeedManagerContainer/CreateEditFeed/CreateEditFeed'
import {CustomModalHeader} from '../CustomDialog'
import {
  addFeedInFeedList,
  resetManagedFeedState,
  setFilters,
  setJournals,
  setSubjects,
  updateCreateEditFeedStep,
  updateFeedInFeedList,
  updateManageFeedType,
  updatePrimaryBtnState,
} from 'store/actions/feedManager'
import {CustomModalFooter} from '../CustomDialog/CustomModalFooter'
import {Filter} from '../interfaces/api'
import {submitCreatedFeed, updateCreatedFeed} from '../util/api'
import {BtnText} from '../interfaces/types'
import {useHistory} from 'react-router'
import {feedCreateEdit} from '../util/segmentEvents'

type FeedManagerContainerWrapperProps = {
  filters: Filter[]
  setUserFilters: (filters: Filter[]) => void
  handleClose: () => void
  journals: any[]
  selectedSubjects: any[]
  setSelectedJournals: (journals) => void
  setSelectedSubjects: (subjects) => void
  setActiveFilter: (data) => void
}

// Leave comments as much as possible :)
export const FeedManagerContainerWrapper = (
  props: FeedManagerContainerWrapperProps
) => {
  const {
    manageFeedType,
    createEditStep,
    isPrimaryBtnDisable,
    filters,
    activeFeed,
    subjects,
    journals,
  } = useSelector((state: any) => state.feedManager)

  const history = useHistory()

  const [shouldUpdateFilters, setShouldUpdateFilters] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldUpdateFilters) {
      props.setUserFilters(filters)
      setShouldUpdateFilters(false)
    }
  }, [filters, props, shouldUpdateFilters])

  useEffect(() => {
    dispatch(setFilters(props.filters))
    dispatch(setJournals(props.journals))
    dispatch(setSubjects(props.selectedSubjects))
  }, [dispatch, props.filters, props.journals, props.selectedSubjects])

  // useEffect(() => {
  //   if (journals.length === 0 && props.journals?.length > 0) {
  //     dispatch(setJournals(props.journals))
  //   }
  // }, [dispatch, journals.length, props])

  // useEffect(() => {
  //   if (subjects.length === 0 && props.selectedSubjects?.length > 0) {
  //     dispatch(setSubjects(props.selectedSubjects))
  //   }
  //   dispatch(setSubjects(props.selectedSubjects))
  // }, [dispatch, subjects.length, props])

  // useEffect(() => {
  //   props.setUserFilters(filters)
  // }, [filters, props])

  // useEffect(() => {
  //   props.setSelectedJournals(journals)
  // }, [journals, props])

  // useEffect(() => {
  //   props.setSelectedSubjects(subjects)
  // }, [subjects, props])

  const handleDelete = () => {
    setShouldUpdateFilters(true)
  }

  const componentsMapping = {
    0: (
      <FeedManagerContainer
        journals={journals}
        selectedSubjects={subjects}
        onDeleted={handleDelete}
      />
    ),
    1: <CreateEditFeed />,
  }

  const handleAction = async (btnText) => {
    if (btnText === BtnText.SAVE) {
      props.handleClose()
    }
    if (btnText === BtnText.OK) {
      closeCustomModal()
    }
    if (btnText === BtnText.DONE) {
      const payload = buildCreateFeedPayload(activeFeed, filters.length)
      const feedId = activeFeed.id
      setIsLoading(true)
      if (feedId) {
        await updateFeedFilter(feedId, payload)
      } else {
        await CreateFeedFilter(payload)
      }
      dispatch(updateCreateEditFeedStep(0))
      dispatch(updateManageFeedType(0))
      // dispatch(resetManagedFeedState())
      //  props.handleClose()
      setIsLoading(false)
    } else if (btnText === BtnText.NEXT) {
      dispatch(updateCreateEditFeedStep(createEditStep + 1))
    } else if (btnText === BtnText.PREVIOUS) {
      if (createEditStep !== 0) {
        dispatch(updateCreateEditFeedStep(createEditStep - 1))
      } else {
        dispatch(updateManageFeedType(0))
      }
    }
  }

  const closeCustomModal = () => {
    dispatch(resetManagedFeedState())
    props.handleClose()
    if (history.location.search) {
      history.push('/feed/mine')
      history.go(0)
    }
  }

  useEffect(() => {
    if (createEditStep !== 2) {
      const isPrimaryBtnDisabledNow = shouldPrimaryBtnDisable(
        manageFeedType,
        filters,
        activeFeed
      )
      dispatch(updatePrimaryBtnState(isPrimaryBtnDisabledNow))
    }
  }, [
    activeFeed,
    createEditStep,
    dispatch,
    filters,
    isPrimaryBtnDisable,
    manageFeedType,
  ])

  const CreateFeedFilter = async (payload) => {
    const res: any = await submitCreatedFeed(getUserId(), payload)
    feedCreateEdit(payload)
    dispatch(addFeedInFeedList(res))
    setShouldUpdateFilters(true)
    props.setSelectedJournals(res.journals)
    props.setSelectedSubjects(res.subjects)
    props.handleClose()
    props.setActiveFilter({
      name: res.filters[0].name,
      value: -1,
      type: 'filter',
    })
    history.push('/feed/' + res.filters[0].id)
  }

  const updateFeedFilter = async (id, payload) => {
    const res: any = await updateCreatedFeed(getUserId(), id, payload)
    feedCreateEdit(payload, id)
    dispatch(updateFeedInFeedList(res))
    setShouldUpdateFilters(true)
    props.setSelectedJournals(res.journals)
    props.setSelectedSubjects(res.subjects)
    props.handleClose()
    props.setActiveFilter({
      name: res.filters[0].name,
      value: -1,
      type: 'filter',
    })
    history.push('/feed/' + res.filters[0].id)
    //  history.go(0)
    // window.location.reload(true)
  }

  return (
    <CustomModal
      header={
        <CustomModalHeader
          title={getFeedManagerTitle(manageFeedType)}
          handleClose={closeCustomModal}
        />
      }
      body={componentsMapping[manageFeedType]}
      footer={
        <CustomModalFooter
          primaryBtnConfig={getPrimaryBtnConfig(
            manageFeedType,
            createEditStep,
            isPrimaryBtnDisable,
            isLoading
          )}
          secondaryBtnConfig={getSecondaryBtnConfig(
            manageFeedType,
            createEditStep
          )}
          handleAction={handleAction}
        />
      }
      handleClose={closeCustomModal}
      // footer prop which takes component?
      // primaryBtnConfig={getPrimaryBtnConfig(manageFeedType, createEditStep)}
      // secondaryBtnConfig={getSecondaryBtnConfig(manageFeedType, createEditStep)}
      // handleAction={handleAction}
    />
  )
}
