import React, {useState, useRef, useEffect, useCallback} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import TextField from '@material-ui/core/TextField'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import {IconButton} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {TranslationKeys} from 'components/FeedV2/util/util'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    maxHeight: 300,
    overflowY: 'auto',
    backgroundColor: '#fff',
    boxShadow: `0 1px 2px 0 rgba(0, 0, 0, 0.15)`,
    position: 'absolute',
    zIndex: 1,
    width: '60%',
    whiteSpace: 'pre-wrap',
  },

  option: {
    whiteSpace: 'pre-wrap',
  },
}))

const MuiAutoComplete = ({
  options,
  handleChange,
  handleSelect,
  inputValue,
  clearSearch,
  isSearching,
  placeholder,
}) => {
  const classes = useStyles()

  const listRef = useRef(null)
  // const [isMatched, setMatched] = useState([])
  const [count, setCount] = useState(0)
  const [isOpen, setOpen] = useState(false)

  // Handle keyup
  const handleKeyUp = ({keyCode}) => {
    setOpen(true)
    // Handle arrow up and down
    switch (keyCode) {
      // Down arrow
      case 40:
        if (count < options.length - 1) {
          setCount((prev) => prev + 1)
          // Set focus to the first child in the list
          listRef.current && listRef.current.firstChild.focus()
        }
        break
      // Up arrow
      case 38:
        if (count > 0) {
          setCount((prev) => prev - 1)
          // Set focus to the last child
          listRef.current && listRef.current.lastChild.focus()
        }
        break
      // Escape key
      case 27:
        setOpen(false)
        break

      default:
        break
    }
  }

  const handleMenuClick = (match) => {
    handleSelect(match)
    setOpen(false)
  }

  /* 
    - 'onClickAway' is an material ui listener to detect if a click event happened outside of an element
    - https://material-ui.com/components/click-away-listener/#click-away-listener
  */

  return (
    <>
      <TextField
        fullWidth
        color="secondary"
        autoComplete="off"
        label={placeholder}
        value={inputValue}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyPress={(e) =>
          e.key === TranslationKeys.enter && e.preventDefault()
        }
        InputProps={{
          endAdornment: inputValue && (
            <IconButton size="small" aria-label="close" onClick={clearSearch}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
      {!!inputValue && isOpen && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          {options.length > 0 ? (
            <MenuList role="listbox" ref={listRef} className={classes.menuItem}>
              {options.map((match) => {
                // Just add "Bold" the the first char
                // const suggest = match.name
                //   .toLowerCase()
                //   .replace(inputValue, `<b>${inputValue}</b>`)
                return (
                  <MenuItem
                    role="option"
                    className={classes.option}
                    key={match.displayValue}
                    onClick={() => handleMenuClick(match)}
                  >
                    <span
                      dangerouslySetInnerHTML={{__html: match.displayValue}}
                    />
                  </MenuItem>
                )
              })}
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem>
                {isSearching ? 'Loading...' : 'No result found!'}
              </MenuItem>
            </MenuList>
          )}
        </ClickAwayListener>
      )}
    </>
  )
}

export default MuiAutoComplete
