import {CustomModalHeader, ModalSize} from 'components/FeedV2/CustomDialog'
import CustomDialog from 'components/FeedV2/CustomDialog/CustomDialog'
import CustomModal from 'components/FeedV2/CustomDialog/CustomModal'
import {CreateEditAuthor} from 'components/FeedV2/shared/CreateEditAuthor/CreateEditAuthor'
import {CreateEditKeyword} from 'components/FeedV2/shared/CreateEditKeyword.tsx/CreateEditKeyword'
import {
  BtnVariant,
  CustomButton,
} from 'components/FeedV2/shared/CustomButton/CustomButton'
import {Stepper} from 'components/FeedV2/shared/Stepper/Stepper'
import {
  getFormModalTitle,
  getFormSubmitBtnConfig,
  headerMapping,
  TranslationKeys,
} from 'components/FeedV2/util/util'

import React from 'react'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {ExcludedSection} from './ExcludedSection/ExcludedSection'
import {HelpSection} from './HelpSection/HelpSection'
import {IncludedSection} from './IncludedSection/IncludedSection'
import {Settings} from './Settings/Settings'

type CreateEditFeedProps = {
  title: string
}

const Container = styled.div`
  width: 100%;
  text-align: center;
`

const Heading = styled.p`
  color: #000000;
  font-family: Replica;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.18px;
  margin-top: 48px;
  margin-bottom: 37px;
`

const componentsMapping = {
  0: <IncludedSection />,
  1: <ExcludedSection />,
  2: <Settings />,
}

export const CreateEditFeed = () => {
  const {createEditStep} = useSelector((state: any) => state.feedManager)

  // const dispatch = useDispatch()

  return (
    <Container>
      <Stepper step={createEditStep} />
      <Heading>{headerMapping[createEditStep]}</Heading>
      {componentsMapping[createEditStep]}
    </Container>
  )
}
