import React from 'react'
import Image from 'components/shared/Image'

const CloseIcon = '/images/closeIcon.svg'

// TODO: Figure out how to remove the "components/Modal" component
// Turn this into a reusable modal
const ModalGeneric = ({
  contentWidth,
  onClose,
  icon,
  isOpen,
  title,
  footer,
  children,
  isFooter = true,
  isSimpleHeader = false,
  noMargin = false,
  bgImage = '',
  isMobile = false,
  maxWidth = '940px',
  ...rest
}) => {
  if (!isOpen) {
    return null
  }

  const CloseBtn = () => (
    <button
      css={`
        cursor: pointer;
        background: none;

        &:active {
          outline: none;
        }
      `}
      onClick={onClose}
    >
      <Image src={CloseIcon} width={14} height={14} />
    </button>
  )

  return (
    <div
      {...rest}
      css={`
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        overflow-y: scroll;
        padding: ${isMobile ? '5px' : '30px 15px'};
        ::-webkit-scrollbar {
          width: ${isMobile && '0'};
        }
      `}
    >
      <div
        role="link"
        tabIndex="-1"
        css={`
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.56);
          z-index: -1;
        `}
        onKeyPress={onClose}
        onClick={onClose}
      ></div>
      <div
        css={`
          max-width: ${maxWidth};
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          box-shadow: 0 30px 60px -3px rgba(50, 50, 50, 0.4);
          background: white url(${bgImage});
          background-size: cover;
          @media (max-width: 940px) {
            width: 100%;
            height: 100%;
            overflow-y: auto;
          }
        `}
      >
        <div
          css={`
            display: flex;
            background: ${!isSimpleHeader && '#f7f7fa'};
            padding: ${isMobile ? '12px' : '22px 40px'};
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom: ${!isSimpleHeader && '1px solid #d7d7e0'};
            align-items: center;

            h1 {
              flex: 1;
              font-size: 22px;
              margin-bottom: 0;
            }

            & > div:first-of-type {
              margin-right: 11px;
            }
          `}
        >
          {!!icon && icon}
          <h1>{title}</h1>
          <CloseBtn />
        </div>

        <div
          css={`
            flex: 1;

            & > * {
              max-width: ${contentWidth || '600px'};
              margin: 0 auto;
              margin-top: ${!noMargin && '70px'};
              margin-bottom: ${!noMargin && '30px'};
            }
          `}
        >
          {children}
        </div>

        {isFooter && (
          <div
            css={`
              height: 90px;
              width: 100%;
              background: #f7f7fa;
              display: flex;
              padding: 20px;
              justify-content: flex-end;
              border-top: 1px solid #d7d7e0;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            `}
          >
            {footer}
          </div>
        )}
      </div>
    </div>
  )
}

export default ModalGeneric
