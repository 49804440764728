import React from 'react'
import {Route} from 'react-router-dom'

import ScrollToTop from 'components/ScrollToTop'
import Header from 'components/LandingHeader'
import Footer from 'components/LandingFooter'
import * as ribbonShape from 'assets/images/commercial/ribbonShape.svg'

const LandingPageLayout = ({isCommercial, children}) => {
  return (
    <div
      css={`
        background-image: url('${ribbonShape}');
        //   background-size: contain;
        background-repeat: no-repeat;
      `}
    >
      <ScrollToTop />
      <Route
        render={({location}) => {
          return (
            <Header
              publishers={location.pathname.startsWith('/advertise')}
              isCommercial={isCommercial}
            />
          )
        }}
      />
      <div
        css={`
          overflow: hidden;
          padding-top: 40px;
        `}
      >
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default LandingPageLayout
