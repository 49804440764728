import React from 'react'
import styled from 'styled-components'
import * as PlayButton from 'assets/media/video_pplay.svg'
import * as AudioPlayButton from 'assets/media/audio_play.svg'
import * as AudioPauseButton from 'assets/media/audio_pause.svg'

import * as PauseButton from 'assets/media/video_pause.svg'
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeMuteOff from '@material-ui/icons/VolumeOff';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const RenderHTML = ({ html }) => (<span dangerouslySetInnerHTML={{ __html: html }}></span>)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 16px;
`

const VideoStyled = styled.video`
  width: 100%;
  height: 500px;
  background: black;
`
const BottomBar = styled.div`
  height: 42px;
  width: 100%;
  background-color: rgba(0,0,0,0.59); 
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`

const Overlay = styled.div`
  height: calc(100% - 42px);
  width: 100%;
  position: absolute;
  top:0;
  bottom: 42px;
  left: 0px;
  display: flex;
`

const HoverImg = styled.img`
  cursor: pointer;
`

const Timer = styled.div`
  color: #FFFFFF;
  font-family: Replica;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
  margin-left: 16px;
  width: ${props => props.canShowSlider ? '74px' : '38px'};
  ${props => props.type === "audio" && "color: #323232"}
`

const AudioStyled = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 120px;
  background-color: #F0F0F0;
  padding: 32px;
  margin-bottom: 16px;
`

const theme = createTheme({
  palette: {
    primary: {
      main: "#f94687"
    },
    white: {
      main: "#fff"
    },
    inactive: {
      main: "#CACACA",
    },
    text: {
      main: '#323232'
    }
  },
  overrides: {
    MuiSlider: {
      root: {
        width: '72px',
        margin: '8px'
      }
    }
  }
});

const AudioVideoPlayer = ({ src, type = "video" }) => {
  const ref = React.useRef()
  const [playing, setPlaying] = React.useState(false)

  const play = () => {
    setPlaying(true)
    ref.current.play()
  }

  const pause = () => {
    setPlaying(false)
    ref.current.pause()
  }

  const getTime = (time) => {
    const media = ref.current
    if (!media)
      return "00:00"
    const rawTime = time || media.currentTime
    let minutes = Math.floor(rawTime / 60);
    let seconds = Math.floor(rawTime - minutes * 60);
    let minuteValue;
    let secondValue;

    if (minutes < 10) {
      minuteValue = '0' + minutes;
    } else {
      minuteValue = minutes;
    }

    if (seconds < 10) {
      secondValue = '0' + seconds;
    } else {
      secondValue = seconds;
    }

    return minuteValue + ':' + secondValue;
  }

  const [time, setTime] = React.useState(getTime())
  const [trackProgress, setTrackProgress] = React.useState(getTime())
  const [currentTime, setCurrentTime] = React.useState(0)
  const [maximum, setMaximum] = React.useState(0)
  const [volume, setVolume] = React.useState(100)

  React.useEffect(() => {

    ref.current.addEventListener('progress', () => {
      try {
        var range = 0;
        var bf = ref.current.buffered;
        var time = ref.current.currentTime;

        while (!(bf.start(range) <= time && time <= bf.end(range))) {
          range += 1;
        }
        var loadStartPercentage = bf.start(range) / ref.current.duration;
        var loadEndPercentage = bf.end(range) / ref.current.duration;
        var loadPercentage = loadEndPercentage - loadStartPercentage;
        setTrackProgress(loadPercentage)
      } catch (err) {

      }
    });

    ref.current.addEventListener("timeupdate", () => {
      setTime(getTime())
      setCurrentTime(ref.current.currentTime)
    })

    ref.current.addEventListener('ended', () => {
      setPlaying(false)
    })

    //Fires when the audio/video has been started or is no longer paused
    ref.current.addEventListener('play', () => {
      setPlaying(true)
    })

    //Fires when the audio/video is playing after having been paused or stopped for buffering
    ref.current.addEventListener('playing', () => {
      setPlaying(true)
    })

    //Fires when the audio/video has been paused
    ref.current.addEventListener('pause', () => {
      setPlaying(false)
    })


    ref.current.addEventListener("loadedmetadata", (e) => {
      setMaximum(e.target.duration)
    })

  }, [])

  React.useEffect(() => {
    if (ref && ref.current)
      ref.current.volume = volume / 100
  }, [volume])


  const playpause = () => {
    if (playing) {
      pause()
    } else {
      play()
    }
  }

  const getVolumeButton = () => {
    if (volume >= 75 && volume <= 100) {
      return <VolumeUp style={{ fill: type === "video" ? "white" : "#1D1D1B" }} />
    } else if (volume <= 74 && volume >= 25) {
      return <VolumeDown style={{ fill: type === "video" ? "white" : "#1D1D1B" }} />
    } else if (volume <= 24 && volume >= 1) {
      return <VolumeMuteIcon style={{ fill: type === "video" ? "white" : "#1D1D1B" }} />
    } else {
      return <VolumeMuteOff style={{ fill: type === "video" ? "white" : "#1D1D1B" }} />
    }
  }

  const canShowSlider = maximum && maximum !== Infinity

  const BottomControl = <div style={{ width: 200, display: 'flex', alignItems: 'center' }}>
    {getVolumeButton()}
    <Slider color="primary" aria-label="Volume" value={volume} onChange={(e, val) => { setVolume(val) }} />
    <Timer type={type} canShowSlider={canShowSlider}>{time} {canShowSlider ? ` / ${getTime(maximum)} ` : ''}</Timer>
  </div>

  const slider = canShowSlider ? <Slider
    size="small"
    style={{ width: "100%", margin: 16 }}
    value={currentTime}
    max={maximum}
    onChange={(e, val) => {
      pause()
      setCurrentTime(val)
      setTime(getTime(val))
    }}
    onChangeCommitted={(e, val) => {
      ref.current.currentTime = val;
      play()
    }}
    aria-label="Small"
  /> : <div style={{ width: "100%", margin: 16 }} />

  return <MuiThemeProvider theme={theme}>
    <>
      {type === "video" ?
        <Wrapper>
          <VideoStyled src={src} ref={ref} />
          {maximum && <BottomBar>
            {!playing ? <HoverImg src={PlayButton} onClick={play} alt="Play" /> : <HoverImg src={PauseButton} alt="Pause" onClick={pause} />}
            {slider}
            {BottomControl}
          </BottomBar>}
          <Overlay onClick={playpause} />
        </Wrapper> :
        <AudioStyled>
          <audio src={src} ref={ref} />
          {!playing ? <HoverImg src={AudioPlayButton} onClick={play} alt="Play" /> : <HoverImg src={AudioPauseButton} alt="Pause" onClick={pause} />}
          {slider}
          {/* {maximum && <VideoSeekSlider
            max={maximum}
            currentTime={currentTime}
            progress={trackProgress}
            onChange={(time) => {
              if (time !== null)
                ref.current.currentTime = time
              setTime(getTime())
            }}
            secondsPrefix="00:00:"
            minutesPrefix="00:"
          />} */}
          {BottomControl}
        </AudioStyled>}
    </>
  </MuiThemeProvider>
}


export default function Media({ url, mime_type, html }) {
  const mimeTypeSplit = mime_type ? mime_type.split("/") : null
  const part1 = mimeTypeSplit && mimeTypeSplit.length ? mimeTypeSplit[0] : null

  switch (part1) {
    case "video":
      return <AudioVideoPlayer src={url} type="video" />
    case "audio":
      return <AudioVideoPlayer src={url} type="audio" />

    default:
      return <RenderHTML html={html} />

  }
}
