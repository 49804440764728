import React from 'react'
import style from 'lib/landingSectionStyle'

const HeroSection = ({ title, children, css: extraCss, paper = false, ...props }) => {
  return (
    <>
      <section
        css={`
          padding-top: 70px;
          text-align: center;
          margin: 0 auto;
          ${extraCss}
        `}
        {...props}
      >
        <div
          css={`
            ${style.textCentered}
          `}
        >
          {paper ? <div
            css={`
              ${style.title}
            `}>
            {title}
          </div> : <h1
            css={`
              ${style.title}
            `}>
            {title}
          </h1>}

          {children}
        </div>
      </section>
    </>
  )
}

export default HeroSection
