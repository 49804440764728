import React from 'react'
import { css } from 'styled-components'
import theme from 'lib/theme'
import { to64 } from 'lib/base64'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const assert = ({ src, width, height, maxWidth, aspectRatio }) => {
  if (
    (width && typeof width !== 'number') ||
    (height && typeof height !== 'number') ||
    (maxWidth && typeof maxWidth !== 'number') ||
    (aspectRatio && typeof aspectRatio !== 'number')
  ) {
    throw new Error(
      'Image(): "width", "height", "maxWidth" & "aspectRatio" must ' +
      'be numbers if specified'
    )
  }
  if (!!width + !!maxWidth !== 1) {
    throw new Error(
      'Image(): must specify *either* "width" or "maxWidth". ' +
      '"maxWidth"-based images adapt to the size of their container'
    )
  }
  if (!!height + !!aspectRatio !== 1) {
    throw new Error('Image(): must specify *either* "height" or "aspectRatio"')
  }
}

const IMAGE = gql`
  query Image($uri: String!) {
    image(uri: $uri) {
      base64
      baseURL
    }
  }
`

/**
 *
 * if "src" is a string, it must be provided statically
 *
 * for example, this is invalid (some props ommitted for brevity):
 *
 *     const src = '/image/avatar.jpg'
 *     return <Image src={src} />
 *
 * instead, you should do this:
 *
 *     return <Image src="/image/avatar.jpg" />
 *
 * the static string is discoverable at build-time, meaning it can be
 * resized / optimised as appropiate
 *
 * alternatively, you can pre-build images & provide a dynamic src object like:
 *
 *     const src = {
 *       base64: 'data:image/jpeg;base64,/9j/2wBDABAL...',
 *       baseURL: '/images/avatar.jpg'
 *     }
 *     return <Image src={src} />
 *
 * all images from the GraphQL endpoints are pre-built, and have
 * base64 / baseURL already
 *
 */
const Image = props => {
  assert(props)
  let {
    src,
    alt = '',
    width,
    height,
    maxWidth,
    aspectRatio,
    formats,
    quality = 95,
    cropCenter = 'center center',
    visible = true,
    withBackground,
    withBase64,
    clipping,
    css: extraCss,
    className,
    onClick
  } = props

  if (!formats) {
    const _svg = !!src && (src.baseURL || src)
    const svg = typeof _svg === 'string' && _svg.endsWith('svg')
    formats = svg ? ['svg'] : ['webp', 'jpg']
  }
  if (withBase64 === undefined) withBase64 = !formats.includes('svg')
  if (withBackground === undefined) withBackground = formats.includes('jpg')

  const { data: fullSrc } = useQuery(IMAGE, {
    variables: { uri: src },
    skip: !src || typeof src === 'object'
  })

  if (typeof src === 'string') {
    src = fullSrc ? fullSrc.image : { base64: null, baseURL: src }
  } else if (!src) {
    src = { base64: null, baseURL: '/' }
  }

  // "aspectRatio" is most-useful for fluid containers
  if (width && aspectRatio) {
    height = width * aspectRatio
    aspectRatio = undefined
  }

  const [loaded, setLoaded] = React.useState(!withBase64)

  const getSrc = ({ format, size }) => {
    if (src.baseURL.startsWith('data:image')) return src.baseURL
    const p_width = size * (width || maxWidth)
    const p_height = size * (aspectRatio ? p_width * aspectRatio : height)
    const params = {
      uri: src.baseURL,
      width: p_width,
      height: p_height,
      format: format,
      quality: quality,
      crop: cropCenter
    }
    return '/image/' + to64(JSON.stringify(params)) + '.' + format
  }

  return (
    <div
      onClick={onClick}
      css={`
        flex: none;
        position: relative;
        overflow: hidden;
        ${aspectRatio
          ? css`
                width: 100%;
              `
          : css`
                width: ${width}px;
                height: ${height}px;
              `
        }
        display: ${visible ? 'inline-block' : 'none'};
        ${clipping === 'researcher' &&
        `clip-path: polygon(0% 46.543%, 0% 73.276%, 36.598% 100%, 100% 53.449%, 100% 26.725%, 63.244% 0%)`};
        ${clipping === 'circle' && `border-radius: 100%`};

        ${extraCss}
      `}
      className={className}
    >
      <div
        css={`
          background: ${withBackground
            ? theme.colors.light.metal
            : 'transparent'};
          width: 100%;
          height: 100%;
          ${!!aspectRatio && `padding-bottom: ${aspectRatio * 100}%`};
        `}
      />
      {src.base64 && withBase64 && (
        <img
          src={src.base64}
          alt=""
          css={`
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: ${cropCenter};
            opacity: ${loaded ? 0 : 1};
            transition: opacity 500ms ease 0s;
            ${clipping === 'circle' &&
            `clip-path: ellipse(50% 50% at 50% 50%)`};
          `}
        />
      )}
      <picture>
        {[].concat(
          ...formats.map(format => {
            const typemap = {
              webp: 'image/webp',
              jpg: 'image/jpg',
              png: 'image/png',
              svg: 'image/svg+xml'
            }

            return (
              <source
                type={typemap[format]}
                key={format}
                srcSet={(format === 'svg' ? [1] : [1, 1.5, 2])
                  .map(size => getSrc({ format, size }) + ` ${size}x`)
                  .join(', ')}
              />
            )
          })
        )}
        <img
          css={`
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: ${cropCenter};
            opacity: ${loaded ? 1 : 0};
            transition: opacity 500ms ease 0s;
            ${clipping === 'circle' &&
            `clip-path: ellipse(50% 50% at 50% 50%)`};
          `}
          ref={img => {
            // TODO: run on first render only, for HTTP cached images
            if (img && img.complete && !loaded) setLoaded(true)
          }}
          onLoad={() => {
            if (!loaded) setLoaded(true)
          }}
          srcSet={(formats.slice(-1)[0] === 'svg' ? [1] : [1, 1.5, 2])
            .map(size => {
              return getSrc({ format: formats.slice(-1)[0], size }) + ` ${size}x`
            })
            .join(', ')}
          alt={alt}
          loading="lazy"
          src={getSrc({ format: formats.slice(-1)[0], size: 1 })}
        />
      </picture>
    </div>
  )
}

export default Image
