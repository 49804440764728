const {useState, useEffect} = require('react')
const _throttle = require('lodash.throttle')

let supportsPassive = false
try {
  const opts = Object.defineProperty({}, 'passive', {
    get() {
      return (supportsPassive = true)
    }
  })
  window.addEventListener('testPassive', null, opts)
  window.removeEventListener('testPassive', null, opts)
} catch (e) {}

let scrollPosition = {}
const listeners = {}

{
  const [date, value] = (
    window.sessionStorage.getItem('scrollPosition') || '0'
  ).split('$')
  if (+date > Date.now() - 2000) scrollPosition = JSON.parse(value)
  else scrollPosition = {x: window.pageXOffset, y: window.pageYOffset}
}

window.addEventListener(
  'scroll',
  _throttle(() => {
    scrollPosition = {x: window.pageXOffset, y: window.pageYOffset}
    for (const k in listeners) listeners[k]()
  }, 100),
  supportsPassive ? {passive: true} : false
)

// window.pageYOffset is 0 on refresh
window.addEventListener('beforeunload', event => {
  window.sessionStorage.setItem(
    'scrollPosition',
    `${Date.now()}$${JSON.stringify(scrollPosition)}`
  )
})

function useWindowScrollPosition() {
  let [position, setPosition] = useState(scrollPosition)

  useEffect(() => {
    const key = `${Math.random()},${Date.now()}`
    let _setPosition = setPosition
    listeners[key] = () => _setPosition(scrollPosition)
    return () => {
      _setPosition = () => {}
      delete listeners[key]
    }
  }, [])

  return position
}

export default useWindowScrollPosition
