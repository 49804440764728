import React, {useState} from 'react'
import Button from './shared/Button'
import ModalGeneric from './shared/ModalGeneric'

import {useMutation} from '@apollo/react-hooks'
import {triggerCustomEvent} from 'lib/trackEverythingStream'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import getValue from 'lib/getValue'
import jwt_decode from 'jwt-decode'
import crypto from 'crypto'

import gql from 'graphql-tag'
import {FormError, Wrapper} from './shared/ModalComponents'
import Spinner from './shared/Spinner'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {detect, devices, getIsMobileDevice} from 'lib/detectMobileDevice'
import DownloadButtons from './shared/LandingDownloadButtons'

// For segments
import Cookies from 'js-cookie'
import uuid from 'uuid/v4'

const device = detect()

const fbLogo = '/static-images/loggedOutPopup/'
const googleLogo = ''
const mailLogo = ''
const bgImage = `/static-images/loggedOutPopup/pop_up_ribbon_${
  device === devices.DESKTOP ? 'web' : 'mobile'
}.svg`

const SOCIAL_SIGNUP = gql`
  mutation SocialSignup(
    $authType: AuthType!
    $authId: String!
    $avatar: String
    $email: String
    $name: String!
  ) {
    createUserSocial(
      authType: $authType
      authId: $authId
      avatar: $avatar
      email: $email
      name: $name
    ) {
      status
      refetch {
        viewer {
          id
          university {
            id
          }
          occupation {
            id
          }
          journalSubscriptions {
            id
          }
        }
      }
    }
  }
`
const SOCIAL_LOGIN = gql`
  mutation SocialLogin(
    $authType: AuthType!
    $authId: String!
    $email: String!
  ) {
    loginUserSocial(authType: $authType, authId: $authId, email: $email) {
      status
      refetch {
        viewer {
          id
          university {
            id
          }
          occupation {
            id
          }
          journalSubscriptions {
            id
          }
        }
      }
    }
  }
`

const AuthModal = ({closeModal, paperId}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [signup] = useMutation(SOCIAL_SIGNUP)
  const [login] = useMutation(SOCIAL_LOGIN)
  const [networkLogin, setNetworkLogin] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const googleCallback = async (response) => {
    if (!response || !response.accessToken || !response.profileObj) {
      setLoading(false)

      return setError(response || {message: 'Something went wrong'})
    }

    const loginResponse = await signup({
      variables: {
        authType: 'GG',
        authId: response.profileObj.googleId,
        email: response.profileObj.email,
        avatar: response.profileObj.imageUrl,
        name: response.profileObj.name,
      },
    }).catch((e) => setError(e))

    return handleSocialResponse('GG', loginResponse)
  }

  const handleSocialResponse = (authType, response) => {
    if (!response) {
      setLoading(false)
      setError({message: 'Something went wrong'})

      return
    }

    window.localStorage.setItem('loggedInOnce', 'true')
    window.apolloClient.resetStore()

    if (
      !getValue(
        response,
        'data.createUserSocial.refetch.viewer.journalSubscriptions.length'
      ) &&
      authType !== 'AP'
    ) {
      history.push({
        pathname: '/signup/terms',
        search: '?redirect=' + location.pathname,
        state: location.state,
      })
      triggerCustomEvent({type: 'signup', detail: {authType}})
    }
    try {
      window.analytics.identify(
        response.data.createUserSocial.refetch.viewer.id,
        {
          user_id: response.data.createUserSocial.refetch.viewer.id,
          user_occupation_id:
            response.data.createUserSocial.refetch.viewer.occupation.id,
          user_institution_id:
            response.data.createUserSocial.refetch.viewer.university.id,
          user: JSON.stringify({user_login_type: authType}),
        }
      )
    } catch (err) {
      console.log(err)
    }
    triggerCustomEvent({type: 'login'})
  }

  const facebookCallback = async (response) => {
    if (!response || !response.accessToken) {
      setLoading(false)
      setError(response || {message: 'Something went wrong'})
      return
    }

    const loginResponse = await signup({
      variables: {
        authType: 'FB',
        authId: response.id,
        email: response.email,
        avatar:
          response.picture &&
          response.picture.data &&
          response.picture.data.url,
        name: response.name,
      },
    }).catch((e) => setError(e))

    return handleSocialResponse('FB', loginResponse)
  }

  const onAppleSigninSuccess = React.useCallback(
    async (data) => {
      if (!data.detail.authorization || !data.detail.authorization.id_token) {
        setLoading(false)
        setError({message: 'Something went wrong'})
        return
      }

      const decodedToken = jwt_decode(data.detail.authorization.id_token)

      if (data.detail.user) {
        const name = `${data.detail.user.name.firstname} ${data.detail.user.name.lastname}`

        const signupResponse = await signup({
          variables: {
            authType: 'AP',
            authId: decodedToken.sub,
            email: decodedToken.email,
            name: name,
            avatar: `http://api.researcher-app.com/avatar?name=${encodeURIComponent(
              name
            )}`,
          },
        }).catch((e) => setError(e))

        // history.push('/signup/terms')
        triggerCustomEvent({type: 'signup', detail: {authType: 'AP'}})

        return handleSocialResponse('AP', signupResponse)
      } else {
        const loginResponse = await login({
          variables: {
            authType: 'AP',
            authId: decodedToken.sub,
            email: decodedToken.email,
          },
        }).catch((e) => setError(e))

        return handleSocialResponse('AP', loginResponse)
      }
    },
    [handleSocialResponse, login, signup]
  )

  // const onAppleSigninSuccess = async (data) => {
  //   if (!data.detail.authorization || !data.detail.authorization.id_token) {
  //     setLoading(false)
  //     setError({message: 'Something went wrong'})
  //     return
  //   }

  //   const decodedToken = jwt_decode(data.detail.authorization.id_token)

  //   if (data.detail.user) {
  //     const name = `${data.detail.user.name.firstname} ${data.detail.user.name.lastname}`

  //     const signupResponse = await signup({
  //       variables: {
  //         authType: 'AP',
  //         authId: decodedToken.sub,
  //         email: decodedToken.email,
  //         name: name,
  //         avatar: `http://api.researcher-app.com/avatar?name=${encodeURIComponent(
  //           name
  //         )}`,
  //       },
  //     }).catch((e) => setError(e))

  //     // history.push('/signup/terms')
  //     triggerCustomEvent({type: 'signup', detail: {authType: 'AP'}})

  //     return handleSocialResponse('AP', signupResponse)
  //   } else {
  //     const loginResponse = await login({
  //       variables: {
  //         authType: 'AP',
  //         authId: decodedToken.sub,
  //         email: decodedToken.email,
  //       },
  //     }).catch((e) => setError(e))

  //     return handleSocialResponse('AP', loginResponse)
  //   }
  // }

  function onAppleSigninFailure(err) {
    setLoading(false)
  }

  // This will be called first time on load
  // Will be used to send a segment event
  // Event - User was showed the popup
  React.useEffect(() => {
    try {
      const env = getEnvironment()
      console.log('Segments Event: User was showed the popup')
      // Cookies not needed as anonymous id already captured.
      // Prepare segments event
      const eventData = {
        event_source: 'researcher.web',
        schema_version: 2.2,
        event_page: 'sign_up_pop_up',
        event_action: 'view',
        event_label: parseInt(paperId),
        environment: env,
      }
      console.log(eventData)
      // Track segments event
      window.analytics.track('logged.out.interactions', eventData)
    } catch (err) {
      console.log('Error while tracking:' + err)
    }
  }, [])

  React.useEffect(() => {
    AppleID.auth.init({
      clientId: 'io.fusetech.researcher.service',
      scope: 'name email',
      redirectURI:
        window.location.origin === 'https://www-staging.researcher-app.com'
          ? 'https://www-staging.researcher-app.com/siwa-redirect'
          : 'https://www.researcher-app.com/siwa-redirect',
      state: crypto.randomBytes(16).toString('base64'),
      usePopup: true,
    })

    document.addEventListener('AppleIDSignInOnSuccess', onAppleSigninSuccess)
    document.addEventListener('AppleIDSignInOnFailure', onAppleSigninFailure)

    return () => {
      document.removeEventListener(
        'AppleIDSignInOnSuccess',
        onAppleSigninSuccess
      )
      document.removeEventListener(
        'AppleIDSignInOnFailure',
        onAppleSigninFailure
      )
    }
  }, [onAppleSigninSuccess])

  const logo = '/static-images/loggedOutPopup/logo.svg'

  // const redirectUrl = `https://m8qh.adj.st/feed?paper_id=${parseInt(
  //   paperId
  // )}&adjust_t=dwo5agx&adj_deeplink=researcher://feed?paper_id=${parseInt(
  //   paperId
  // )}&adj_deeplink_js=1`
  const redirectUrl = `https://m8qh.adj.st/feed?paper_id=${paperId}&adjust_t=dwo5agx&adjust_deeplink=researcher%3A%2F%2Ffeed%3Fpaper_id%3D${paperId}&adjust_deeplink_js=1`

  // const redirectUrl = `https://app.adjust.net.in/jsr?url=https://m8qh.adj.st/feed%3Fpaper_id=${paperId}?adjust_t=dwo5agx&adjust_deeplink=researcher%3A%2F%2Ffeed?paper_id=${paperId}`

  console.log({redirectUrl})

  const MobileSection = ({paperId}) => {
    return (
      <>
        <p
          css={`
            color: #000000;
            font-family: ReplicaLight;
            font-size: 15px;
            text-align: center;
            letter-spacing: 0.18px;
            margin-bottom: 35px;
            width: 295px;
          `}
        >
          It looks like you’re accessing the Researcher website on a mobile
          device.
        </p>
        <p
          css={`
            color: #000000;
            font-family: ReplicaLight;
            font-size: 15px;
            text-align: center;
            letter-spacing: 0.18px;
            margin-bottom: 35px;
            width: 295px;
          `}
        >
          <p>
            To get the best Researcher experience, please download the app
            instead.
          </p>
        </p>

        <DownloadButtons
          vertical
          redirectLink={redirectUrl}
          paperId={paperId}
        />
      </>
    )
  }

  const getEnvironment = () => {
    if (window.env.IS_STAGING === 'true') {
      return 'test'
    } else {
      if (
        window.location.href.indexOf('pr-') == -1 &&
        window.location.href.indexOf('localhost') == -1
      ) {
        return 'production'
      } else {
        return 'local'
      }
    }
  }
  // Function will be called to on close of modal
  const handleCloseOfModal = () => {
    try {
      const env = getEnvironment()
      console.log('Segments: User closed the popup')
      const eventData = {
        event_source: 'researcher.web',
        schema_version: 2.2,
        event_page: 'sign_up_pop_up',
        event_action: 'close',
        event_label: parseInt(paperId),
        environment: env,
      }
      console.log(eventData)
      // Track segments event
      window.analytics.track('logged.out.interactions', eventData)
    } catch (err) {
      console.log('Error while tracking:' + err)
    }
    closeModal()
  }

  // Called when buttons clicked, will handle segments
  const handleSegmentsOnButtonClick = (type, socialMediaType) => {
    try {
      const eventData = {
        event_source: 'researcher.web',
        schema_version: 2.2,
        event_page: 'sign_up_pop_up',
        event_action: type, //sign_up
        event_label: parseInt(paperId),
        event_details: {login_type: socialMediaType},
        environment: getEnvironment(),
      }
      console.log(eventData)
      // Track segments event
      window.analytics.track('logged.out.interactions', eventData)
    } catch (err) {
      console.log('Error while tracking:' + err)
    }
  }

  return (
    <ModalGeneric
      isOpen={true}
      onClose={handleCloseOfModal}
      isFooter={false}
      isSimpleHeader={true}
      noMargin={true}
      bgImage={bgImage}
      isMobile={getIsMobileDevice()}
      maxWidth={'750px'}
    >
      <div
        css={`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
        `}
      >
        <div
          css={`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: ${device === devices.MOBILE ||
              device === devices.TABLET
                ? '37.5px'
                : '28px'};
            `}
          >
            <img src={logo} alt="researcher_logo" />
          </div>

          <h3
            css={`
              color: #323232;
              font-size: ${device === devices.TABLET ? '30px' : '22px'};
              text-align: center;
              letter-spacing: 0;
              width: 300px;
              margin-bottom: ${device === devices.MOBILE ||
              device === devices.TABLET
                ? '42.5px'
                : '20px'};
            `}
          >
            Stay Up To Date With The Latest Research
          </h3>

          <div
            css={`
              height: 3px;
              width: ${getIsMobileDevice() ? '95%' : '460px'};
              background: #ededf5;
              margin-bottom: ${device === devices.MOBILE ||
              device === devices.TABLET
                ? '30.5px'
                : '28px'};
            `}
          ></div>

          {getIsMobileDevice() ? (
            <MobileSection paperId={paperId} />
          ) : (
            <>
              <p
                css={`
                  color: #000000;
                  font-family: ReplicaLight;
                  font-size: ${device === devices.TABLET ? '23px' : '15px'};
                  text-align: center;
                  letter-spacing: 0.18px;
                  margin-bottom: ${device === devices.MOBILE ||
                  device === devices.TABLET
                    ? '35px'
                    : '18px'};
                  width: 64%;
                `}
              >
                You can discover the latest research, join discussions with
                other scientists, follow topics, keywords or authors, and
                bookmark papers to read later
              </p>
              <div
                css={`
                  height: 3px;
                  width: ${getIsMobileDevice() ? '95%' : '460px'};
                  background: #ededf5;
                  margin-bottom: ${device === devices.MOBILE ||
                  device === devices.TABLET
                    ? '42.5px'
                    : '28px'};
                `}
              ></div>
              <p
                css={`
                  color: #000000;
                  font-size: ${device === devices.TABLET ? '23px' : '15px'};
                  font-weight: 300;
                  letter-spacing: 0.18px;
                  text-align: center;
                  font-family: ReplicaLight;
                  margin-bottom: ${device === devices.MOBILE ||
                  device === devices.TABLET
                    ? '32.5px'
                    : '13px'};
                `}
              >
                Sign up or Login for free
              </p>
              <footer
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: ${getIsMobileDevice() ? '14.85px' : '23.5px'};
                `}
              >
                <GoogleLogin
                  clientId={
                    '402869283331-msi8oidakhg7fmhd55a5qh5k2593ckrv.apps.googleusercontent.com'
                  }
                  onSuccess={googleCallback}
                  onFailure={googleCallback}
                  render={({onClick}) => {
                    return (
                      <Button
                        css={`
                          background: #ebebeb;
                          min-width: 64px;
                          cursor: pointer;
                          height: 64px;
                          margin-right: 10px;
                          border-radius: 0px;
                        `}
                        background="#ebebeb"
                        foreground="#000000"
                        noBorderColorAndHoverEffect
                        onClick={(e) => {
                          setLoading(true)
                          setNetworkLogin('Google')
                          handleSegmentsOnButtonClick('sign_up', 'google')
                          return onClick(e)
                        }}
                        hover={{cursor: 'pointer'}}
                        noMargin={true}
                      >
                        <img src="/static-images/logo_google.svg" />
                      </Button>
                    )
                  }}
                />
                <Link
                  onClick={() => {
                    handleSegmentsOnButtonClick('sign_up', 'email')
                  }}
                  to={{
                    pathname: '/signup/email',
                    search: '?redirect=' + location.pathname,
                    state: location.state,
                  }}
                  css={`
                    text-decoration: none;
                    display: block;
                    button {
                      max-width: 340px;
                    }
                  `}
                >
                  <Button
                    css={`
                      background: #ebebeb;
                      min-width: 64px;
                      cursor: pointer;
                      height: 64px;
                      margin-right: 10px;
                      border-radius: 0px;
                    `}
                    background="#ebebeb"
                    foreground="#000000"
                    noBorderColorAndHoverEffect
                    hover={{cursor: 'pointer'}}
                    noMargin={true}
                  >
                    <img src="/static-images/logo_email.svg" />
                  </Button>
                </Link>
                <FacebookLogin
                  appId={'1084553838356470'}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={facebookCallback}
                  render={({onClick}) => {
                    return (
                      <Button
                        css={`
                          background: blue;
                          min-width: 64px;
                          cursor: pointer;
                          height: 64px;
                          margin-right: 10px;
                          border-radius: 0px;
                        `}
                        onClick={(e) => {
                          setLoading(true)
                          setNetworkLogin('Facebook')
                          handleSegmentsOnButtonClick('sign_up', 'facebook')
                          return onClick(e)
                        }}
                        noBorderColorAndHoverEffect
                        minWidth="40px"
                        hover={{cursor: 'pointer'}}
                        noMargin={true}
                      >
                        <img src="/static-images/logo_facebook.svg" />
                      </Button>
                    )
                  }}
                />
                <div
                  onClick={() => {
                    handleSegmentsOnButtonClick('sign_up', 'apple')
                    setLoading(true)
                  }}
                  className="apple-signin-button"
                  id="appleid-signin"
                  data-type="sign in"
                  data-mode="logo-only"
                  style={{
                    cursor: 'pointer',
                    marginRight: '10px',
                    backgroundColor: 'black',
                  }}
                ></div>
              </footer>
              <p
                css={`
                  color: #000000;
                  font-size: ${device === devices.TABLET ? '23px' : '15px'};
                  font-weight: 300;
                  letter-spacing: 0.18px;
                  text-align: center;
                  margin-bottom: 37px;
                  font-family: ReplicaLight;
                `}
              >
                It takes less than 2 minutes
              </p>
              {
                <Wrapper
                  css={`
                    padding: 100px;
                    text-align: center;
                    ${!loading && `display: none;`}
                  `}
                >
                  <Spinner />
                  <div css="margin-top: 15px;">
                    Waiting for {networkLogin} to log you in
                  </div>
                </Wrapper>
              }
              {/* {error && (
            <div
              css={`
                text-align: center;
                padding-bottom: 20px;
              `}
            >
              <FormError>{error}</FormError>
            </div>
          )} */}
            </>
          )}
        </div>
      </div>
    </ModalGeneric>
  )
}
export default AuthModal
