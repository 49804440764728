import {css} from 'styled-components'
import React from 'react'
import theme from 'lib/theme'

const commonStyle = css`
  min-width: 140px;
  min-height: 50px;
  padding: 0 15px;
  text-align: center;
  margin: 10px 15px;
  border-radius: 4px;
  font-family: 'Replica', Arial, sans-serif;
  font-weight: bold;
  font-size: 1rem;
`

const commonStyleWithoutMargin = css`
  min-width: 140px;
  min-height: 50px;
  padding: 0 15px;
  text-align: center;
  border-radius: 4px;
  font-family: 'Replica', Arial, sans-serif;
  font-weight: bold;
  font-size: 1rem;
`

const primaryStyle = {
  _: css`
    background-color: ${theme.colors.pink};
    color: ${theme.colors.white};
  `,
  hover: css`
    ${theme.shadows.lox};
    cursor: pointer;
    background-color: ${theme.colors.dark.pink};
  `,
  active: css`
    outline: none;
    box-shadow: none;
    background-color: ${theme.colors.dark.pink};
  `,
  focus: css`
    outline: none;
    box-shadow: 0px 0px 3px 5px ${theme.colors.focus},
      inset 0px 0px 2px 1px ${theme.colors.white};
  `,
  disabled: css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `,
}

const v2PrimaryStyle = {
  _: css`
    background-color: ${theme.colors.pink};
    color: ${theme.colors.white};
    height: 24px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    border-radius: 0;
    line-height: 24px;
  `,
  hover: css`
    ${theme.shadows.lox};
    cursor: pointer;
    background-color: ${theme.colors.dark.pink};
  `,
  active: css`
    outline: none;
    box-shadow: none;
    background-color: ${theme.colors.dark.pink};
  `,
  focus: css`
    outline: none;
    box-shadow: 0px 0px 3px 5px ${theme.colors.focus},
      inset 0px 0px 2px 1px ${theme.colors.white};
  `,
  disabled: css`
    background-color: #f3afc8;
    cursor: not-allowed !important;
  `,
}

const secondaryStyle = {
  _: css`
    border: 2px solid ${theme.colors.pink};
    background-color: ${theme.colors.light.pink};
    color: ${theme.colors.dark.pink};
  `,
  hover: css`
    ${theme.shadows.lox};
    cursor: pointer;
    border: 2px solid ${theme.colors.dark.pink};
  `,
  active: css`
    border: 2px solid ${theme.colors.pink};
    color: ${theme.colors.pink};
  `,
  focus: css`
    outline: none;
    box-shadow: 0px 0px 3px 5px ${theme.colors.focus};
  `,
  disabled: css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `,
}

const Button = ({
  children,
  css: extraCss,
  primary,
  hover,
  active,
  focus,
  v2Primary,
  noBorderColorAndHoverEffect = false,
  noMargin = false,
  ...props
}) => {
  const style = v2Primary
    ? v2PrimaryStyle
    : primary
    ? primaryStyle
    : secondaryStyle
  const pseudoStyle = css`
    &:hover {
      ${style.hover}
    }
    &:active {
      ${style.active}
    }
    &:focus {
      ${style.focus}
    }
    &:disabled {
      ${style.disabled}
    }
  `
  return (
    <button
      css={`
        ${noMargin ? commonStyleWithoutMargin : commonStyle}
        ${!noBorderColorAndHoverEffect && style._}
        
        ${!noBorderColorAndHoverEffect && hover && style.hover}
        ${active && style.active}
        ${focus && style.focus}
        
        ${!hover && !focus && !active && pseudoStyle}
        
        ${extraCss};
      `}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
