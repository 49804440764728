import React from 'react'
import QuestionIcon from 'assets/images/manageFeed/question_icon.svg'
import {useSelector} from 'react-redux'
import {createEditFeedQuestionMarkIconClick} from 'components/FeedV2/util/segmentEvents'

export const QuestionMarkIcon = ({onClick, isAbsolutePosition}) => {
  const {activeFeed} = useSelector((state: any) => state.feedManager)
  const handleClick = () => {
    createEditFeedQuestionMarkIconClick(activeFeed.id)
    onClick()
  }
  return (
    <>
      <img
        src={QuestionIcon}
        alt="questionMark"
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          position: isAbsolutePosition ? 'absolute' : undefined,
        }}
      />
    </>
  )
}
