import {combineReducers} from 'redux'
import feed from './feed'
import history from './history'
import onboarding from './onboarding'
import feedManager from './feedManager'
import common from './common'

const rootReducer = combineReducers({
  feed,
  history,
  onboarding,
  feedManager,
  common,
})

export default rootReducer
