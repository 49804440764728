import {getSubjects} from 'components/FeedV2/util/api'
import {
  transformSubjectResponse,
  TranslationKeys,
} from 'components/FeedV2/util/util'
import React, {useState} from 'react'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  excludeItem,
  updateSubmitBtnDisabledState,
} from 'store/actions/feedManager'
import AutoComplete from '../autocomplete/autoComplete'
import {useDebounce} from 'use-debounce'
import {makeStyles} from '@material-ui/core'
import {ConfigurableChipWrapper} from '../ConfigurableChipWrapper/ConfigurableChipWrapper'
import {
  ExclusionArrayName,
  MatchField,
  Matchtype,
} from 'components/FeedV2/interfaces/types'
import {TemporaryExcludedItemList} from '../TemporaryExcludedItemList/TemporaryExcludedItemList'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(4),
    },
  },
  createEditPublication: {
    margin: '0 auto',
    overflowY: 'auto',
    height: '300px',
    width: '100%',
    position: 'relative',
  },
  title: {
    padding: theme.spacing(3, 0),
  },

  autoCompleteForm: {
    width: '60%',
    margin: '0 auto',
  },

  formGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5%',
  },

  addedSuggestions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),
    marginLeft: 'auto',
    marginRight: 'auto',
    rowGap: theme.spacing(5),
    width: '90%',
  },
}))

type CreateEditSubjectProps = {
  title?: string
  subtitle?: string
}

export const CreateEditSubject = ({title}: CreateEditSubjectProps) => {
  const {activeFeed} = useSelector((state: any) => state.feedManager)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  // Searching status (whether there is pending API request)
  const [isSearching, setIsSearching] = useState(true)
  const [debounceInputValue] = useDebounce(inputValue, 500)
  const [suggestions, setSuggestions] = useState([])
  const [addedSuggestions, setAddedSuggestions] = useState([])

  // Handle Change
  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  // Effect for API call
  useEffect(
    () => {
      const getSubjectList = async () => {
        if (debounceInputValue) {
          setIsSearching(true)
          const results = await getSubjects(debounceInputValue)
          setSuggestions(transformSubjectResponse(results))
          setIsSearching(false)
        } else {
          // setResults([])
          //  setIsSearching(false)
        }
      }

      getSubjectList() // run it, run it

      return () => {
        // this now gets called when the component unmounts
      }
    },
    [debounceInputValue] // Only call effect if debounced search term changes
  )

  // Handle Select
  const handleSelect = (selectedData) => {
    //   setInputValue(selectedData.key)
    setInputValue('')
    const findSuggestedItemInList = activeFeed.exclusions.subjects
      .concat(addedSuggestions)
      .find(
        (addedSuggestion) =>
          addedSuggestion.displayValue === selectedData.displayValue
      )
    if (!findSuggestedItemInList) {
      const {key, ...rest} = selectedData
      let payload = {
        match_field: MatchField.subjectId,
        match_type: Matchtype.EXACT,
        terms: [key],
        ids: [],
        ...rest,
      }
      setAddedSuggestions([payload, ...addedSuggestions])
    }
  }

  useEffect(() => {
    // const isInValid = !inputValue || addedSuggestions.length === 0
    dispatch(updateSubmitBtnDisabledState(addedSuggestions.length === 0))
  }, [inputValue, dispatch, addedSuggestions])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(excludeItem(addedSuggestions, ExclusionArrayName.subjects))
  }

  const handeleDelete = (data, index) => {
    setAddedSuggestions(
      addedSuggestions.filter((addedSuggestion, ind) => ind !== index)
    )
  }

  return (
    // <Paper className={classes.paper}>
    <div className={classes.createEditPublication}>
      <form
        id="submit-form"
        className={classes.autoCompleteForm}
        onSubmit={handleSubmit}
      >
        <AutoComplete
          options={suggestions}
          inputValue={inputValue}
          handleChange={handleChange}
          handleSelect={handleSelect}
          clearSearch={() => setInputValue('')}
          isSearching={isSearching}
          placeholder={`${TranslationKeys.searchForSubjects}`}
        />
      </form>

      <TemporaryExcludedItemList
        addedSuggestions={addedSuggestions}
        onDelete={(data, index) => handeleDelete(data, index)}
      />
      {/* 
      <div className={classes.addedSuggestions}>
        {addedSuggestions.map((addedSuggestion) => (
          <React.Fragment key={`CreateEditSubject ${addedSuggestion.key}`}>
            <ConfigurableChipWrapper
              children={addedSuggestion.displayValue}
              isClickable
              filter={addedSuggestion.displayValue}
              onClick={() => {}}
              onDelete={() => {}}
              width={'145px'}
            />
          </React.Fragment>
        ))}
      </div> */}
    </div>
  )
}
