import {
  buildCreateFeedName,
  getIsFeedNameValid,
} from 'components/FeedV2/util/util'
import {
  UPDATE_MANAGE_FEED_TYPE,
  UPDATE_FEED_CREATE_EDIT_STEP,
  SET_FEEDS_LIST,
  UPDATE_PRIMARY_BTN_STATE,
  UPDATE_SUBMIT_BTN_DISABLED_STATE,
  SET_ACTIVE_FEED,
  DELETE_FEED_FROM_FEEDS_LIST,
  INCLUDE_KEYWORDS_TOPICS_AUTHORS,
  EXCLUDE_ITEM,
  UPDATE_IS_ONLY_OPEN_ACCESS,
  UPDATE_FEED_NAME,
  UPDATE_IS_ONLY_PUBLICATIONS_FOLLOWED,
  REMOVE_EXCLUDED_ITEM,
  RESET_MANAGE_FEEDS_STATE,
  UPDATE_INCLUDE_KEYWORD_TOPIC_AUTHOR,
  UPDATE_CONDITION_BETWEEN_KEYWORDS_TOPICS_AUTHORS,
  REMOVE_INCLUDED_KEYWORDS_TOPICS_AUTHORS,
  ADD_FEED_IN_FEEDS_LIST,
  UPDATE_FEED_IN_FEEDS_LIST,
  SET_SELECTED_SUBJECTS,
  SET_SELECTED_JOURNALS,
} from '../mutation-types'

const initialState = {
  isManageFeedsFlow: false,
  manageFeedType: 0,
  createEditStep: 0,
  isSubmitBtnDisable: true,
  isPrimaryBtnDisable: true,
  activeFeed: {
    id: null,
    // details for the feed in picture
    // in case of edit flow it would be an existing feeds data
    name: '', // the feed name
    isOnlyOpenAccess: false,
    isOnlyFollowedPublications: false,
    inclusions: [],
    exclusions: {
      keywordsTopics: [],
      subjects: [],
      publications: [],
      articleTypes: [],
      authors: [],
    },
  },
  // thinking of a better name
  // will be used to store data that would be fetched from the api calls

  feedsListCursor: 0, //cursor to hold pagination info
  filters: [],
  articleTypes: [], // used in article type exclusions
  subjects: [], // used in subjects exclusions, questionable though
  journals: [],
}

const getInitialState = () => {
  return {...initialState}
}

export default (state = getInitialState(), action) => {
  switch (action.type) {
    // GENERAL CASES
    case RESET_MANAGE_FEEDS_STATE:
      // When done or close, reset the store to have initial state
      // return getInitialState()
      return {
        ...getInitialState(),
        // filters: [...state.filters, ...action.filters],
        filters: state.filters,
        subjects: state.subjects,
        publications: state.publications,
      }
    case UPDATE_MANAGE_FEED_TYPE:
      // will be used to move from manage feeds to create feed or edit feed
      // by setting 'manageFeedType' to 1 or 2 respectively from 0
      return {...state, manageFeedType: action.step}
    case UPDATE_FEED_CREATE_EDIT_STEP:
      // will move forward or backward in the create or edit flow
      // by setting 'createEditStep' as 0 or 1 or 2
      return {...state, createEditStep: action.step}
    case SET_FEEDS_LIST:
      // will set the 'feedsList' with existing feeds coming from backend
      // it will be push operation as more feeds might be fetched from api for pagination
      // cursor update can happen here also, or a separate case or in local state
      return {
        ...state,
        // filters: [...state.filters, ...action.filters],
        filters: action.filters,
      }
    case DELETE_FEED_FROM_FEEDS_LIST:
      // This will be a delete on the UI, post the actual delete via the API
      // Might want to reduce the cursor by one
      return {
        ...state,
        filters: state.filters.filter((feed) => feed.id !== action.id),
      }
    case ADD_FEED_IN_FEEDS_LIST:
      // This will be an addition on the UI, post the actual update via the API
      // Might want to increase the cursor by one
      return {
        ...state,
        filters: [...state.filters, ...action.data.filters],
        journals: action.data.journals,
        subjects: action.data.subjects,
      }

    case UPDATE_FEED_IN_FEEDS_LIST:
      // This will be an update on the UI, post the actual update  via the API
      return {
        ...state,
        filters: state.filters.map((f) =>
          f.id === action.data.filters[0].id ? action.data.filters[0] : f
        ),
        journals: action.data.journals,
        subjects: action.data.subjects,
      }

    case SET_SELECTED_SUBJECTS:
      // will set the 'rasSubjects' based on an api call
      return {
        ...state,
        subjects: action.subjects,
      }

    case SET_SELECTED_JOURNALS:
      return {
        ...state,
        journals: action.journals,
      }
    case SET_ACTIVE_FEED:
      // will set the 'activeFeed' based on the data for the existing feed
      return {
        ...state,
        activeFeed: action.activeFeed,
      }

    case UPDATE_SUBMIT_BTN_DISABLED_STATE:
      return {
        ...state,
        isSubmitBtnDisable: action.isDisabled,
      }

    case UPDATE_PRIMARY_BTN_STATE:
      return {
        ...state,
        isPrimaryBtnDisable: action.isDisabled,
      }

    // INCLUSION CASES
    case INCLUDE_KEYWORDS_TOPICS_AUTHORS:
      const calculatedInclusions = [
        ...state?.activeFeed?.inclusions,
        ...action.data,
      ]

      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          name: buildCreateFeedName(calculatedInclusions),
          inclusions: calculatedInclusions,
        },
      }
    case REMOVE_INCLUDED_KEYWORDS_TOPICS_AUTHORS:
      const inclusionsAfterRemoval = state.activeFeed.inclusions.filter(
        (inclusion) => inclusion.sort_index !== action.term.sort_index
      )
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          name: buildCreateFeedName(inclusionsAfterRemoval),
          inclusions: inclusionsAfterRemoval,
        },
      }
    case UPDATE_INCLUDE_KEYWORD_TOPIC_AUTHOR:
      // will update the keyword/topic in 'inclusions'
      // splice
      console.log(action)
      const updatedInclusions = state.activeFeed.inclusions.map((inclusion) =>
        inclusion.sort_index === action.data.sort_index
          ? action.data
          : inclusion
      )
      console.log({updatedInclusions})
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          name: buildCreateFeedName(updatedInclusions),
          inclusions: updatedInclusions,
        },
      }
    case UPDATE_CONDITION_BETWEEN_KEYWORDS_TOPICS_AUTHORS:
      const inclusionsNow = state.activeFeed.inclusions.map((t) =>
        t.sort_index === action.term.sort_index
          ? {...t, logic_flow: action.val}
          : t
      )
      // will update the condition for the keyword/topic in 'inclusions'
      // splice
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          name: buildCreateFeedName(inclusionsNow),
          inclusions: inclusionsNow,
        },
      }

    // SETTINGS CASES
    case UPDATE_FEED_NAME:
      const isValid = getIsFeedNameValid(
        action.name,
        state.activeFeed,
        state.filters
      )
      // will set the 'name' as the name provided by the user
      return {
        ...state,
        isPrimaryBtnDisable: !isValid,
        activeFeed: {
          ...state.activeFeed,
          name: action.name,
        },
      }
    case UPDATE_IS_ONLY_OPEN_ACCESS:
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          isOnlyOpenAccess: action.isChecked,
        },
      }
    // will set the 'isOnlyOpenAccess' as true or false
    case UPDATE_IS_ONLY_PUBLICATIONS_FOLLOWED:
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          isOnlyFollowedPublications: action.isChecked,
        },
      }
    // will set the 'isOnlyPublicationsFollowed' as true or false

    // EXLUSION CASES
    case EXCLUDE_ITEM:
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          exclusions: {
            ...state.activeFeed.exclusions,
            [action.arrayName]: [
              ...action.data,
              ...state.activeFeed.exclusions[action.arrayName],
            ],
          },
        },
      }

    case REMOVE_EXCLUDED_ITEM:
      return {
        ...state,
        activeFeed: {
          ...state.activeFeed,
          exclusions: {
            ...state.activeFeed.exclusions,
            [action.arrayName]: state.activeFeed.exclusions[
              action.arrayName
            ].filter((elm, ind) => ind !== action.arrayIndex),
          },
        },
      }
    default:
      return initialState
  }
}
