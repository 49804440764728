import styled from 'styled-components'
import React from 'react'
import {Link as ReactRouterLink, useLocation} from 'react-router-dom'
import {useSpring, animated, config} from 'react-spring'
import {useQuery} from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Logo from 'components/shared/Logo'
import theme from 'lib/theme'
import scrollToElement from 'lib/scrollToElement'
import useWindowScrollPosition from 'lib/useWindowScrollPosition'
import useWindowSize from 'lib/useWindowSize'
import Button from 'components/shared/Button'
import Link from 'components/shared/Link'
import {ActionBtn} from 'pages/Commercial/ActionBtn'

const HeaderBaseContainer = styled(animated.header)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${(props) =>
    props.navBg ? theme.colors.white : 'transparent'};
`

const VIEWER_PROFILE = gql`
  query ViewerProfile {
    viewer {
      name
    }
  }
`

const HeaderContainer = ({css: extraCss, children, ...props}) => {
  const {y: windowScrollPosition} = useWindowScrollPosition()
  const [navBg, setNavBg] = React.useState(false)

  const height = 48
  const {transform, shadowOpacity} = useSpring({
    config: config.gentle,
    shadowOpacity: windowScrollPosition > 40 ? 1 : 0,
    transform: Math.min(windowScrollPosition / 10, 20),
  })

  const changeNavBg = () => {
    if (window.scrollY >= 26) {
      setNavBg(true)
    } else {
      setNavBg(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', changeNavBg)
    return () => {
      window.removeEventListener('scroll', changeNavBg)
    }
  }, [])

  return (
    <HeaderBaseContainer
      style={{
        boxShadow:
          navBg &&
          shadowOpacity.interpolate((o) => {
            const white = `0px 2px 2px 0px hsla(0, 0%, 100%, ${1 - 1 * o})`
            const black = `0px 2px 2px 0px hsla(255, 99.5%, 18.4%, ${0.19 * o})`
            return `${white}, ${black}`
          }),
        transform: navBg && transform.interpolate((t) => `translateY(-${t}px)`),
      }}
      navBg={navBg}
    >
      <div
        css={`
          width: 100%;
          height: 100%;
          overflow: hidden;
        `}
      >
        <div
          css={`
            margin: 0 auto;

            padding-left: 5vw;
            padding-right: 5vw;

            height: ${height + 30 + 20}px;
            padding-top: 25px;
            padding-bottom: 5px;

            ${extraCss}
          `}
          {...props}
        >
          {children}
        </div>
      </div>
    </HeaderBaseContainer>
  )
}

const Hamburger = () => (
  <span
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: ${theme.colors.pink};
      height: 50px;
      width: 50px;
      margin: 10px;
    `}
  >
    <svg width={16} height={12}>
      <g
        fillRule="nonzero"
        stroke={theme.colors.white}
        strokeWidth={2}
        fill="none"
        strokeLinecap="square"
      >
        <path d="M1 1h14M1 6h14M1 11h14" />
      </g>
    </svg>
  </span>
)

const Close = () => (
  <svg width={17} height={15}>
    <g stroke="#FFF" fill="none" fillRule="evenodd">
      <path d="M1.265.265l14.657 14.657M1.265 14.921L15.922.265" />
    </g>
  </svg>
)

const HeaderMobileExpanded = (props) => {
  console.log({props})
  const {isCommercial, children} = props
  const [mobileExpanded, setMobileExpanded] = React.useState(false)

  return (
    <>
      {children(setMobileExpanded)}
      {!!mobileExpanded && (
        <div
          css={`
            position: fixed;
            z-index: 3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${theme.colors.purple};
            & a {
              color: ${theme.colors.white};
              font-size: 2rem;
            }
          `}
        >
          <div
            css={`
              margin: 15px 5vw;
              display: flex;
              justify-content: space-between;
            `}
          >
            <ReactRouterLink to="/" onClick={() => setMobileExpanded(false)}>
              <Logo withText textColor={theme.colors.white} />
            </ReactRouterLink>
            <button
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                background: none;
              `}
              onClick={() => setMobileExpanded(false)}
            >
              <Close />
            </button>
          </div>
          <nav
            css={`
              margin-left: 42px;
            `}
          >
            <ul
              css={`
                list-style-type: none;
                margin: 0;
              `}
            >
              <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link to="/signup">Sign Up</Link>
              </li>
              <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link to="/discover">Discover</Link>
              </li>
              <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link to="/about">About</Link>
              </li>
              {/* <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link
                  to="https://advertising.researcher-app.com/marketing-solutions"
                  external
                  isSelf
                >
                  Advertise
                </Link>
              </li> */}
              {!isCommercial && (
                <li
                  css={`
                    margin: 15px 15px;
                  `}
                >
                  <Link to="/commercial">Commercial</Link>
                </li>
              )}

              <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link to="/authors">Authors</Link>
              </li>
              {/* <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link to="/leaderboard">Competition</Link>
              </li> */}
              <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link to="https://live.researcher-app.com" external>
                  Researcher Live
                </Link>
              </li>
              {/* <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link external to="https://blog.researcher-app.com/news">
                  Blogs
                </Link>
              </li> */}
              <li
                css={`
                  margin: 15px 15px;
                `}
              >
                <Link
                  external
                  to="https://live.researcher-app.com/researcher-insights"
                >
                  Researcher Insights
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  )
}

const HeaderLinks = ({isCommercial}) => {
  return (
    <ul
      css={`
        display: flex;
        justify-content: center;
      `}
    >
      {!isCommercial && (
        <>
          <li
            css={`
              display: inline-block;
              margin: 0 15px;
            `}
          >
            <Link to="/discover" black>
              Discover
            </Link>
          </li>
          <li
            css={`
              display: inline-block;
              margin: 0 15px;
            `}
          >
            <Link to="/about" black>
              About
            </Link>
          </li>
          <li
            css={`
              display: inline-block;
              margin: 0 15px;
            `}
          >
            <Link to="https://live.researcher-app.com" external black>
              Researcher Live
            </Link>
          </li>
        </>
      )}
      {isCommercial && (
        <>
          <li
            css={`
              display: inline-block;
              margin: 0 15px;
            `}
          >
            <Link to="/" black>
              For Researchers
            </Link>
          </li>
        </>
      )}
      <li
        css={`
          display: inline-block;
          margin: 0 15px;
        `}
      >
        <Link
          to="https://live.researcher-app.com/researcher-insights"
          external
          black
        >
          Researcher Insights
        </Link>
      </li>
      {!isCommercial && (
        <>
          <li
            css={`
              display: inline-block;
              margin: 0 15px;
            `}
          >
            <Link to="/commercial" black>
              Commercial
            </Link>
          </li>
        </>
      )}
      {/* <li
        css={`
          display: inline-block;
          margin: 0 15px;
        `}
      >
        <Link
          to="https://advertising.researcher-app.com/marketing-solutions"
          black
          external
          isSelf
        >
          Advertise
        </Link>
      </li> */}
      {/* <li
        css={`
          display: inline-block;
          text-align: center;
          margin: 0 15px;
        `}
      >
        <Link to="/authors" black>
          <span
            css={`
              @media (max-width: 950px) {
                display: none;
              }
            `}
          >
            For Authors
          </span>
        </Link>
      </li> */}
      {/* <li
        css={`
          display: inline-block;
          margin: 0 15px;

          a {
            color: hsl(337, 93.7%, 64.8%);
          }
        `}
      >
        <Link to="/leaderboard">Competition</Link>
      </li> */}
      {/* <li
        css={`
          display: inline-block;
          margin: 0 15px;
        `}
      >
        <Link to="https://blog.researcher-app.com/news" external black>
          Blogs
        </Link>
      </li> */}
    </ul>
  )
}

const NormalActions = ({setMobileExpanded}) => {
  const {data} = useQuery(VIEWER_PROFILE)
  const location = useLocation()

  return (
    <>
      {data && data.viewer ? (
        <a
          href="/feed/all"
          rel="nofollow"
          css={`
            cursor: pointer;
            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          <Button
            primary
            css={`
              width: 150px;
            `}
          >
            My Feed
          </Button>
        </a>
      ) : (
        <>
          <ReactRouterLink
            to={{
              pathname: '/login',
              search: '?redirect=/feed/all',
              state: location.state,
            }}
            css={`
              cursor: pointer;
              @media (max-width: 1150px) {
                display: none;
              }
            `}
          >
            <Button
              css={`
                width: 150px;
              `}
            >
              Log In
            </Button>
          </ReactRouterLink>
          <ReactRouterLink
            to={{
              pathname: '/signup',
              search: '?redirect=/feed/all',
              state: location.state,
            }}
            css={`
              cursor: pointer;
              @media (max-width: 500px) {
                display: none;
              }
            `}
          >
            <Button
              primary
              css={`
                width: 150px;
              `}
            >
              Sign Up
            </Button>
          </ReactRouterLink>
        </>
      )}
      <button
        css={`
          display: none;
          background: none;
          margin: 0;
          @media (max-width: 810px) {
            display: inline-block;
          }
        `}
        onClick={() => setMobileExpanded(true)}
      >
        <Hamburger />
      </button>
    </>
  )
}

const CommercialActions = ({setMobileExpanded}) => {
  return (
    <>
      {/* <Button
        primary
        onClick={() => {
          const contactForm = document.querySelector('#contact-form')
          scrollToElement(contactForm)
        }}
        css={`
          width: 150px;
        `}
      >
        Get in Touch
      </Button> */}
      <div
        css={`
          /* width: 168px !important; */
          @media (max-width: 640px) {
            display: none;
          }
        `}
      >
        <ActionBtn
          isSmallSize={true}
          url="https://calendly.com/speaktoresearcher/30min"
          ctaText="Speak to the Team"
        />
      </div>

      <button
        css={`
          display: none;
          background: none;
          margin: 0;
          @media (max-width: 640px) {
            display: inline-block;
          }
        `}
        onClick={() => setMobileExpanded(true)}
      >
        <Hamburger />
      </button>
    </>
  )
}

const PublisherActions = ({setMobileExpanded}) => {
  return (
    <>
      <Button
        primary
        onClick={() => {
          const contactForm = document.querySelector('#contact-form')
          scrollToElement(contactForm)
        }}
        css={`
          width: 150px;
        `}
      >
        Get in Touch
      </Button>
      <a
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://meetings.hubspot.com/hristina-kamburova/30-minute-demo-of-researcher-"
        css={`
          width: 150px;
          min-height: 50px;
          padding: 0 15px;
          text-align: center;
          margin: 10px 15px;
          border-radius: 4px;
          font-family: 'Replica', Arial, sans-serif;
          font-weight: bold;
          font-size: 1rem;
          background-color: hsl(337, 93.7%, 64.8%);
          color: hsl(0, 0%, 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;

          &:hover {
            box-shadow: 0px 8px 9px 1px hsl(255, 99.5%, 18.4%, 0.19);
            cursor: pointer;
            background-color: hsl(337, 82.6%, 54.3%);
          }
        `}
      >
        Book a Demo
      </a>

      <button
        css={`
          display: none;
          background: none;
          margin: 0;
          @media (max-width: 810px) {
            display: inline-block;
          }
          @media (max-width: 500px) {
            display: none;
          }
        `}
        onClick={() => setMobileExpanded(true)}
      >
        <Hamburger />
      </button>
    </>
  )
}

const Header = (props) => {
  const {isCommercial, publishers} = props

  const windowSize = useWindowSize()

  return (
    <HeaderMobileExpanded isCommercial={isCommercial}>
      {(setMobileExpanded) => (
        <HeaderContainer
          css={`
            display: grid;
            align-items: center;
            grid-template-columns: ${isCommercial
              ? '4fr auto 1fr'
              : '1fr auto 1fr'};
            @media (max-width: 810px) {
              grid-template-columns: 1fr 1fr;
            }
          `}
        >
          <div>
            <ReactRouterLink to="/">
              <Logo
                withText={windowSize.width > 500 || !publishers}
                title="Researcher: Home"
              />
            </ReactRouterLink>
          </div>
          <div>
            <nav
              css={`
                @media (max-width: 810px) {
                  display: none;
                }
              `}
            >
              <HeaderLinks isCommercial={props.isCommercial} />
            </nav>
          </div>

          <div
            css={`
              display: flex;
              justify-content: flex-end;
            `}
          >
            {publishers ? (
              <PublisherActions setMobileExpanded={setMobileExpanded} />
            ) : props.isCommercial ? (
              <CommercialActions setMobileExpanded={setMobileExpanded} />
            ) : (
              <NormalActions setMobileExpanded={setMobileExpanded} />
            )}
          </div>
        </HeaderContainer>
      )}
    </HeaderMobileExpanded>
  )
}

export default Header
