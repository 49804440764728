import React from 'react'
import {desktop} from './LandingPageCommercial'

type ActionBtnProps = {
  url: string
  ctaText: string
  isSmallSize: boolean
}

export const ActionBtn = (props: ActionBtnProps) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer nofollow"
      href={props.url}
      css={`
        width: ${props.isSmallSize ? '168px' : '203px'};
        min-height: 50px;
        padding: 0 15px;
        text-align: center;
        margin: 10px 15px;
        border-radius: 4px;
        font-family: 'ReplicaBold';
        font-size: 1rem;
        background-color: hsl(337, 93.7%, 64.8%);
        color: hsl(0, 0%, 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        /* @media (${desktop}) {
          font-size: 1.1rem;
        } */

        &:hover {
          box-shadow: 0px 8px 9px 1px hsl(255, 99.5%, 18.4%, 0.19);
          cursor: pointer;
          background-color: hsl(337, 82.6%, 54.3%);
        }
      `}
    >
      {props.ctaText}
    </a>
  )
}
