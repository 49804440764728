import React from 'react'
import * as highQualityLead from 'assets/images/commercial/highQualityLead.png'

import * as joinCommunity from 'assets/images/commercial/joinCommunity.png'
import * as profiledLead from 'assets/images/commercial/profiledLead.png'
// import {desktop} from './LandingPageCommercial'
const desktop = 'min-width:1600px'
export const Lead = ({iconName, title, description}) => {
  return (
    <div
      css={`
        border-radius: 4px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2rem;
        //    gap: 20px;
      `}
    >
      <div
        css={`
          display: flex;
          justify-content: center;
        `}
      >
        <img
          src={iconName}
          alt=""
          css={`
            width: 70px;
            height: 70px;
          `}
        />
      </div>
      <div
        css={`
          min-height: 35%;
        `}
      >
        <h3
          css={`
            color: #000000;
            font-family: ReplicaBold;
            font-size: 1.25rem;
            margin-top: 25px;
            //   margin-bottom: 10px;
            text-align: center;
            /* @media (${desktop}) {
              font-size: 1.35rem;
            } */
          `}
        >
          {title}
        </h3>
      </div>
      <div>
        <p
          css={`
            color: #888888;
            font-family: Replica;
            font-size: 0.875rem;
            text-align: center;
            /* @media (${desktop}) {
              font-size: 0.885rem;
            } */
          `}
        >
          {description}
        </p>
      </div>
    </div>
  )
}

export const Leads = (props) => {
  const LeadsData = [
    {
      iconName: highQualityLead,
      title: 'High quality lead generation',
      description:
        'Engage directly with our expert communities of scientists and researchers to generate the high-quality leads you need to drive your business forward.',
    },
    {
      iconName: joinCommunity,
      title: 'Content designed to drive science forward',
      description:
        'Engage researchers and scientists at the cutting edge of the conversations you want to help drive forwards through original content crafted to reach your campaign goals.',
    },
    {
      iconName: profiledLead,
      title: 'Profiled leads, not just a spreadsheet',
      description:
        'Build relationships and increase conversions by gaining deep insights on the leads generated using enriched data for each lead, including prospect journeys and research interests.',
    },
  ]
  return (
    <>
      <div
        css={`
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;
        `}
      >
        {LeadsData.map((leadItem, i) => (
          <div
            key={`leadItemComm${i}`}
            css={`
              display: flex;
              flex-direction: row;
              width: 28%;
              border-radius: 4px;
              justify-content: space-between;
              padding: 0.5%;
            `}
          >
            <Lead {...leadItem} />
          </div>
        ))}
      </div>
    </>
  )
}
