import {
  BtnVariant,
  CustomButton,
} from 'components/FeedV2/shared/CustomButton/CustomButton'
import {TranslationKeys} from 'components/FeedV2/util/util'
import React from 'react'
import {useDispatch} from 'react-redux'
import {
  resetManagedFeedState,
  updateManageFeedType,
} from 'store/actions/feedManager'
import styled from 'styled-components'
import {ManageFeeds} from './ManageFeeds/ManageFeeds'

type FeedManagerContainerProps = {
  // title: string
  // subtitle?: string
}

const Container = styled.div`
  text-align: center;
  margin-top: 75px;
`
const CustomBtnWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
`
const addIcon = '/static-images/add_icon.svg'

export const ButtonContent = () => (
  <div style={{display: 'flex', alignItems: 'center'}}>
    <p style={{fontSize: '16px', fontFamily: 'Replica'}}>
      {TranslationKeys.createNewFeed}
    </p>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <img src={addIcon} alt="add" />
  </div>
)
export const FeedManagerContainer = ({
  journals,
  selectedSubjects,
  onDeleted,
}) => {
  const dispatch = useDispatch()

  const openCreateNewFeed = () => {
    dispatch(resetManagedFeedState())
    dispatch(updateManageFeedType(1))
  }

  return (
    <Container>
      <p style={{fontFamily: 'ReplicaLight'}}>{TranslationKeys.createFeeds}</p>
      <p style={{fontFamily: 'ReplicaLight'}}>
        {TranslationKeys.mostReleventResearch}
      </p>
      <CustomBtnWrapper>
        <CustomButton
          children={<ButtonContent />}
          onClick={openCreateNewFeed}
          backgroundColor="#FFFFFF"
          width="234px"
          height="50px"
          btnVariant={BtnVariant.OUTLINED}
        />
      </CustomBtnWrapper>
      <ManageFeeds
        selectedJournals={journals}
        selectedSubjects={selectedSubjects}
        onDeleted={onDeleted}
      />
    </Container>
    // <div>
    //   <ManageFeeds title={'abcdsksksd'} />
    // </div>
  )
}
