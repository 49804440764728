import axios from 'axios'
import Cookies from 'js-cookie'

const windowAsAny = window as any

const baseUrl = windowAsAny.env
  ? windowAsAny.env.API_URL
  : process.env.API_URL
  ? process.env.API_URL
  : 'https://api-staging.researcher-app.com'

const axiosClient = axios.create({
  baseURL: baseUrl,
})

export const universalParserClient = axios.create({
  baseURL: windowAsAny.env
    ? windowAsAny.env.UNIVERSAL_PARSER_API
    : process.env.UNIVERSAL_PARSER_API
    ? process.env.UNIVERSAL_PARSER_API
    : 'https://universal-parser-staging.herokuapp.com',
})

// Add a request interceptor
universalParserClient.interceptors.request.use(
  function (config) {
    config.headers = {
      'Content-Type': 'application/json',
      'X-API-KEY': windowAsAny.env.UNIVERSAL_PARSER_API.includes('staging')
        ? 'LDsRlrEE45nll9VuUlCszeraV1rLWmyn'
        : '6gnqxuTZsyCBeyIgNoknbnMuLHfQxQnB',
    }
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    const auth = Cookies.get('accessToken')
    config.url = `${config.url}&auth=${auth}`
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data.data
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 2000

export function getRequest(URL) {
  return axiosClient.get(`${URL}`)
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload)
}

export function putRequest(URL, payload) {
  return axiosClient.put(`/${URL}`, payload)
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload)
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`)
}
