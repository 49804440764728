import React, {useEffect, useState} from 'react'
import {DownloadButtons} from 'components/shared/LandingDownloadButtons'
import FullWidthAbsolute from 'components/shared/LandingFullWidthAbsolute'
import BackgroundColor from 'components/shared/LandingBackgroundColor'
import BackgroundStripe from 'components/shared/LandingBackgroundStripe'
import LandingPageLayout from 'components/shared/LandingPageLayout'
import HeroSection from 'components/shared/LandingSectionHero'
import Spinner from 'components/shared/Spinner'
import PaperCard from '../components/shared/FeedCardPaperV2'
import theme from 'lib/theme'
import {Redirect} from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '../components/shared/FeedCardBasicV2'
import Fragments from 'lib/appFragments'

import gql from 'graphql-tag'
import {useQuery} from '@apollo/react-hooks'
import {Helmet} from 'react-helmet'
import {useMutation} from '@apollo/react-hooks'
import AuthModal from 'components/AuthModal'

const GET_PAPER = gql`
  query GetPaper($paper: ID!) {
    v4Paper(id: $paper) {
      id
      ...V4FeedPaper
      doi
      paperUrl
      htmlAbstract
      openAccessUrl
      headlineImage {
        base64
        baseURL
      }
      metrics {
        selectedCount
        viewedCount
        bookmarkedCount
      }
    }
  }
  ${Fragments.V4FeedPaper}
`
const LOGGED_OUT_POP_UP_DELAY_MS = window.env
  ? window.env.LOGGED_OUT_POP_UP_DELAY_MS
  : process.env.LOGGED_OUT_POP_UP_DELAY_MS
  ? process.env.LOGGED_OUT_POP_UP_DELAY_MS
  : '5000'

const Paper = ({paper: paperId, onBookmark}) => {
  const [withStats, setWithStats] = React.useState(false)
  const [recommended, setRecommended] = React.useState([])
  const [isDownloadResearcherModalOpen, setIsDownloadResearcherModalOpen] =
    useState(false)

  const {data, error, loading} = useQuery(GET_PAPER, {
    variables: {paper: paperId},
  })

  React.useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams
    setWithStats(searchParams.get('withStats') !== null)
  }, [])
  const paper = data?.v4Paper

  function wait(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds))
  }

  React.useEffect(() => {
    async function openLoggedOutPopup() {
      await wait(parseInt(LOGGED_OUT_POP_UP_DELAY_MS))
      setIsDownloadResearcherModalOpen(true)
    }

    if (paper) {
      if (paper.contentType === null) {
        openLoggedOutPopup()
      } else if (!paper.contentType.toLowerCase().includes('event')) {
        openLoggedOutPopup()
      }
    }
  }, [paper])

  const stripHtml = (input) => {
    return input.replace(/<[^>]*>?/gm, '')
  }

  const encode = (input) => {
    return stripHtml(input)
      .replace('"', encodeURI('"'))
      .replace('>', encodeURI('>'))
      .replace('<', encodeURI('<'))
      .replace(/(?:\r\n|\r|\n)/g, ' ')
      .replace(/ {2}/g, '')
      .substr(0, 500)
  }

  function dateToYMD(date) {
    var d = date.getDate()
    var m = date.getMonth() + 1
    var y = date.getFullYear()
    return y + '/' + (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d)
  }

  const fullTitle = encode(
    (paper?.title || '') + ' - Researcher | An App For Academics'
  )
  const simpleTitle = encode(paper?.title || '')

  React.useEffect(() => {
    const makeRequest = async () => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      }

      await fetch(`/api/related/${paperId}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          var resultPapers = result || []
          setRecommended(resultPapers)
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    if (paper && Object.keys(paper).length > 0) makeRequest()
  }, [paper, paperId])

  const limitText = (text, limit) => {
    if (text.length > limit) {
      return text.substr(0, limit)
    }
    return text
  }

  if (!loading && !paper?.title && !paper?.abstract)
    return (
      <Card css="height: 380px; margin: 0 auto">
        <CardHeader />
        <CardBody>
          <center>
            <strong>Paper not found</strong>
          </center>
        </CardBody>
        <CardFooter />
      </Card>
    )

  const paperImageUrl =
    paper?.headlineImage?.baseURL ||
    paper?.headlineImage?.uri ||
    paper?.image_url

  const closeModal = () => {
    setIsDownloadResearcherModalOpen(false)
  }
  return (
    <>
      <Helmet>
        <title>{limitText(fullTitle, 68)}</title>
        <meta
          property="og:url"
          content={`https://www.researcher-app.com/paper/${paper?.id}`}
        />
        <meta
          property="og:site_name"
          content="Researcher | An App for Academics"
        />
        <link
          rel="canonical"
          href={`https://www.researcher-app.com/paper/${paper?.id}`}
        />
        {paper?.title && <meta name="citation_title" content={simpleTitle} />}
        {paper?.title && <meta property="og:title" content={fullTitle} />}
        {paper?.title && <meta name="twitter:title" content={fullTitle} />}
        {/* {paper?.authors?.map(author => <meta name="citation_author" content={encode(author)} />) || <></>} */}
        {paper?.abstract?.length && (
          <meta
            name="description"
            content={limitText(encode(paper?.abstract), 140)}
          />
        )}
        {paper?.abstract?.length && (
          <meta name="twitter:description" content={encode(paper?.abstract)} />
        )}
        {paper?.abstract?.length && (
          <meta
            property="og:description"
            content={limitText(encode(paper?.abstract), 140)}
          />
        )}
        {(paper?.createdDate || paper?.created_date) && (
          <meta
            name="citation_publication_date"
            content={encode(
              dateToYMD(
                new Date(paper?.createdDate || paper.created_date * 1000)
              )
            )}
          />
        )}
        {paperImageUrl?.length && (
          <meta name="twitter:image" content={paperImageUrl} />
        )}
        {paperImageUrl?.length && (
          <meta property="og:image" content={paperImageUrl} />
        )}
        {paper?.journal?.name && (
          <meta
            name="citation_journal_title"
            content={encode(paper?.journal?.name)}
          />
        )}
      </Helmet>
      {error ? (
        <Redirect to="/400" />
      ) : (
        <main
          css={`
            margin: 0 auto;
            padding: 0 5vw;
            @media (max-width: 600px) {
              padding: 0;
            }
          `}
        >
          {loading ? (
            <Card css="height: 380px;">
              <CardHeader />
              <CardBody>
                <Spinner />
              </CardBody>
              <CardFooter />
            </Card>
          ) : (
            <>
              <article>
                <BackgroundColor
                  background="paperGradient"
                  stripe={{top: '250px', right: '30%'}}
                >
                  <FullWidthAbsolute right="15%" top="950px">
                    <BackgroundStripe />
                  </FullWidthAbsolute>
                  <div
                    css={`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding-bottom: 50px;
                      & > *:first-child {
                        ${theme.shadows.mid};
                      }
                    `}
                    key={paper.id}
                    x-track-id="fullPaper"
                    x-track-detail={JSON.stringify({relatedPaperId: paper.id})}
                  >
                    <PaperCard
                      paper={paper}
                      clickable={false}
                      onBookmark={onBookmark}
                      includeLinks={true}
                      includePDF
                      fullHeight
                      withStats={withStats}
                      halfWidth={false}
                    />

                    <div
                      css={`
                        width: 100%;
                        max-width: 100%;
                      `}
                    >
                      <div
                        css={`
                          text-align: center;
                          padding-top: 10px;
                          padding-bottom: 30px;
                        `}
                      >
                        You might also like
                      </div>
                      <div
                        css={`
                          display: flex;
                          flex-wrap: wrap;
                          & > * {
                            ${theme.shadows.lo};
                          }
                          justify-content: center;
                          @media (max-width: 1270px) {
                            max-width: 100%;
                            margin: 0 auto;
                            & > * {
                              margin-left: 0px;
                              margin-right: 0px;
                            }
                          }
                        `}
                        x-track-id="relatedPapers"
                      >
                        {recommended.map((paper) => (
                          <PaperCard
                            paper={paper}
                            key={paper.id}
                            halfWidth
                            onBookmark={onBookmark}
                            includeAbstract={false}
                            includePDF={false}
                            recommendedPaper={true}
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <HeroSection
                    title="Discover &amp; Discuss Important Research"
                    paper
                  >
                    <p>
                      Keeping up-to-date with research can feel impossible, with
                      papers being published faster than you'll ever be able to
                      read them. That's where Researcher comes in: we're
                      simplifying discovery and making important discussions
                      happen. With over 19,000 sources, including peer-reviewed
                      journals, preprints, blogs, universities, podcasts and
                      Live events across 10 research areas, you'll never miss
                      what's important to you. It's like social media, but
                      better. Oh, and we should mention - it's free.
                    </p>
                    <DownloadButtons
                      adaptive
                      css={`
                        margin-bottom: 0;
                        padding-bottom: 60px;
                      `}
                    />

                    <div
                      css={`
                        max-width: 940px;
                        margin: 0 auto;
                        padding: 20px;
                        text-align: center;
                        font-size: 12px;
                      `}
                    >
                      <p>
                        Researcher displays publicly available abstracts and
                        doesn’t host any full article content. If the content is
                        open access, we will direct clicks from the abstracts to
                        the publisher website and display the PDF copy on our
                        platform. Clicks to view the full text will be directed
                        to the publisher website, where only users with
                        subscriptions or access through their institution are
                        able to view the full article.
                      </p>
                    </div>
                  </HeroSection>
                </BackgroundColor>
              </article>
            </>
          )}
        </main>
      )}

      {isDownloadResearcherModalOpen && (
        <AuthModal closeModal={closeModal} paperId={paperId} />
      )}
    </>
  )
}

const LOGIN = gql`
  mutation LoginEmail($email: String!, $password: String!) {
    loginEmail(email: $email, password: $password) {
      status
      refetch {
        viewer {
          id
          university {
            id
          }
          occupation {
            id
          }
        }
      }
    }
  }
`

const LandingPagePaper = ({match, history}) => {
  const onBookmark = () => {
    const probablyHasAccount = !!window.localStorage.getItem('loggedInOnce')
    window.sessionStorage.setItem('logoutReferrer', 'true')
    window.sessionStorage.setItem('bookmarkPaper', match.params.id)

    if (probablyHasAccount) {
      history.push({
        pathname: '/login',
        search: '?redirect=/paper/' + match.params.id,
        state: {bookmarkOnOpen: true},
      })
    } else {
      history.push('/signup')
    }
  }

  // Adding an effect to assign anonymous id to the user
  // for segments tracking
  useEffect(()=>{
    try {
      // calling identify so that anonymous id gets created for the user in segments db (if not done already)
      if(window && window.analytics){
        if(!window.analytics.user() || !window.analytics.user().anonymousId()){
          console.log("User not captured yet anonymously. Will capture.");
          window.analytics.identify({ subscribedUser: 'no' }); 
          console.log(window.analytics.user().anonymousId());
        }
        else{
          console.log("User captured already.");
          console.log(window.analytics.user().anonymousId());
        }
      }
    }
    catch(err){
      console.log(err);
    }
  },[])

  return (
    <>
      <LandingPageLayout>
        <Paper paper={match.params.id} onBookmark={onBookmark} />
      </LandingPageLayout>
    </>
  )
}

export default LandingPagePaper
