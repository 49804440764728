export const devices = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
}

export var detect = function (s) {
  var ua = navigator.userAgent.toLowerCase()

  if (s === undefined) {
    s = ua
  } else {
    ua = s.toLowerCase()
  }

  if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      ua
    )
  ) {
    return devices.TABLET
  } else if (
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
      ua
    )
  ) {
    return devices.MOBILE
  } else {
    return devices.DESKTOP
  }
}

export const getIsMobileDevice = () => {
  if (!navigator) return false

  const device = detect()

  return device !== devices.DESKTOP && device !== devices.TABLET
}
