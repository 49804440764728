import {
  BtnVariant,
  CustomButton,
} from 'components/FeedV2/shared/CustomButton/CustomButton'
import React from 'react'
import {ExistingFeeds} from '../CreateEditFeed/ExistingFeeds/ExistingFeeds'
import {useSelector, useDispatch} from 'react-redux'
import {updateManageFeedType} from 'store/actions/feedManager'
import {useEffect} from 'react'
import {TranslationKeys} from 'components/FeedV2/util/util'
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
  margin-top: 75px;
`
const CustomBtnWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;
`

const addIcon = '/static-images/add_icon.svg'
export const ButtonContent = () => (
  <div style={{display: 'flex', alignItems: 'center'}}>
    <p style={{fontSize: '16px', fontFamily: 'Replica'}}>
      {TranslationKeys.createNewFeed}
    </p>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <img src={addIcon} alt="add" />
  </div>
)

export const ManageFeeds = ({
  selectedJournals,
  selectedSubjects,
  onDeleted,
}) => {
  const {filters} = useSelector((state: any) => state.feedManager)

  useEffect(() => {
    // here call the API and get the data for existing feeds
  }, [])

  // const openCreateNewFeed = () => {
  //   dispatch(updateManageFeedType(1))
  // }

  return (
    <>
      {filters?.length > 0 && (
        <>
          <p
            style={{
              marginBottom: '26px',
              fontFamily: 'ReplicaLight',
            }}
          >
            {TranslationKeys.editExistingFeeds}
          </p>
          <ExistingFeeds
            filters={filters}
            selectedJournals={selectedJournals}
            selectedSubjects={selectedSubjects}
            onDeleted={onDeleted}
          />
        </>
      )}
    </>
  )

  // return (
  //   <Container>
  //     <p style={{fontFamily: 'ReplicaLight'}}>{TranslationKeys.createFeeds}</p>
  //     <p style={{fontFamily: 'ReplicaLight'}}>
  //       {TranslationKeys.mostRecentResearch}
  //     </p>
  //     <CustomBtnWrapper>
  //       <CustomButton
  //         children={<ButtonContent />}
  //         onClick={openCreateNewFeed}
  //         backgroundColor="#FFFFFF"
  //         height="234px"
  //         width="50px"
  //         btnVariant={BtnVariant.OUTLINED}
  //       />
  //     </CustomBtnWrapper>

  //     {filters?.length > 0 && (
  //       <>
  //         <p
  //           style={{
  //             marginBottom: '26px',
  //             fontFamily: 'ReplicaLight',
  //           }}
  //         >
  //           {TranslationKeys.editExistingFeeds}
  //         </p>
  //         <ExistingFeeds filters={filters} />
  //       </>
  //     )}
  //   </Container>
  // )
}
