import * as UAParser from 'ua-parser-js'
const userAgentStr = window.navigator.userAgent

const parser = new UAParser()
parser.setUA(userAgentStr)

const userAgent = {
  os: parser.getOS(),
  browser: parser.getBrowser(),
  cpu: parser.getCPU(),
  device: parser.getDevice(),
  engine: parser.getEngine()
}

const useUserAgent = () => userAgent

export default useUserAgent
