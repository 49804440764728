import {
  CHANGE_HISTORY,
} from '../mutation-types'

const initialState = {
  historyChanges: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_HISTORY:
      return { historyChanges: state.historyChanges + 1 }

    default:
      return state
  }
}
