import React from 'react'
import {withStyles} from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import {CustomButton} from '../shared/CustomButton/CustomButton'
import {ModalSize} from '.'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: (props) =>
      props.size === ModalSize.xl ? '#F7F7FA' : '#FFFFFF',
  },
}))(MuiDialogContent)

const CustomModal = (props) => {
  const {
    header,
    handleClose,
    // handleAction,
    // primaryBtnConfig,
    // secondaryBtnConfig,
    size,
    footer,
    isDividers = true,
  } = props

  return (
    <Dialog
      disableBackdropClick
      fullWidth
      maxWidth={size || ModalSize.lg}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open
      PaperProps={{
        style: {
          //  backgroundColor: 'red',
          fontFamily: 'Replica',
          overflow: 'hidden',
        },
      }}
    >
      {header}
      <DialogContent
        size={size}
        dividers={isDividers ? size !== ModalSize.sm : isDividers}
      >
        {props.body}
      </DialogContent>
      {footer}
    </Dialog>
  )
}

export default CustomModal
