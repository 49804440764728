import {ConfigurableChipWrapper} from 'components/FeedV2/shared/ConfigurableChipWrapper/ConfigurableChipWrapper'
import {getTermDisplayVal} from 'components/FeedV2/util/util'
import React from 'react'
import {dummyPlaceholder} from './DummyPlaceholder'

export const ExcludedItemList = ({list, onDelete}) => {
  const handleDelete = (data, index) => {
    onDelete(data, index)
  }
  return list.length > 0
    ? list.map((item, i) => (
        <div
          style={{marginBottom: '1em'}}
          key={i + item.terms + 'ExcludedItemList'}
        >
          <ConfigurableChipWrapper
            //   isClickable
            children={
              getTermDisplayVal(item)
              // Array.isArray(item.terms) && item.terms[1]
              //   ? item.terms[0] + ' ' + item.terms[1]
              //   : item.terms
            }
            filter={item}
            onDelete={(data) => handleDelete(data, i)}
            width={'145px'}
            isBoxShadow={false}
            isBorderRadius={false}
            isSmallDeleteIcon
            color={'#ABABAB'}
            isBold={false}
            fontSize={'12px'}
            fontFamily={'Replica'}
            isWordBreak

            //    borderValue={'1px solid #979797'}
          />
        </div>
      ))
    : dummyPlaceholder
}
