import React from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {isNullableType} from 'graphql'
import {makeStyles, Tooltip} from '@material-ui/core'

type ConfigurableChipWrapperProps = {
  children: string | JSX.Element
  isClickable?: boolean
  filter: any // change this to type later

  onClick?: (filter) => void
  onDelete: (filter) => void
  width?: string
  isBoxShadow?: boolean
  isBorderRadius?: boolean
  borderValue?: string
  isSmallDeleteIcon?: boolean
  color?: string
  isBold?: boolean
  fontSize?: string
  fontFamily?: string
  height?: string
  backgroundColor?: string
  minwidth?: string
  isWordBreak?: boolean
}

type StyledChipSectionProps = {
  isClickable: boolean
  width?: string
  isBoxShadow: boolean
  isBorderRadius: boolean
  borderValue: string
  color?: string
  height?: string
  backgroundColor?: string
  minwidth?: string
}
const StyledChipSection = styled.section<StyledChipSectionProps>`
  max-width: 10000px;
  min-width: ${(props) => (props.minwidth ? props.minwidth : undefined)};
  width: ${(props) => (props.width ? props.width : undefined)};
  border-radius: ${(props) => (props.isBorderRadius ? '5px' : undefined)};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
  box-shadow: ${(props) =>
    props.isBoxShadow ? '0 2px 5px 0 rgba(68, 55, 133, 0.2)' : undefined};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'not-allowed')};
  border: ${(props) => (props.borderValue ? props.borderValue : undefined)};
  color: ${(props) => (props.color ? props.color : undefined)};
  height: ${(props) => (props.height ? props.height : undefined)};
`
// margin-left: 19.5px; add these style at top
//   margin-bottom: 25px;
type TitleProps = {
  isBold: boolean
  fontFamily: string
  fontSize: string
  isWordBreak: boolean
}
const Title = styled.p<TitleProps>`
  text-overflow: ellipsis;
  white-space: ${(props) => (props.isWordBreak ? 'normal' : 'nowrap')};
  word-break: break-word;
  overflow: hidden;
  margin-left: 12px;
  font-family: ${(props) => (props.fontSize ? props.fontSize : undefined)};
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : undefined)};
  font-weight: ${(props) => (props.isBold ? 'bold' : undefined)};
`

const useStyles = makeStyles((theme) => ({
  smallDeleteIcon: {
    width: '0.6em',
    height: '0.6em',
  },
}))

export const ConfigurableChipWrapper = ({
  children,
  isClickable = false,
  filter,
  onClick,
  onDelete,
  width,
  isBoxShadow = true,
  isBorderRadius = true,
  borderValue = null,
  isSmallDeleteIcon = false,
  color,
  isBold = true,
  fontSize,
  fontFamily = 'Replica',
  height,
  backgroundColor,
  minwidth,
  isWordBreak = false,
}: ConfigurableChipWrapperProps) => {
  const classes = useStyles()

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    onDelete(filter)
  }

  const handleClick = () => {
    onClick && onClick(filter)
  }
  return (
    // <Tooltip title={children}>
    <StyledChipSection
      isClickable={isClickable}
      onClick={handleClick}
      width={width}
      isBoxShadow={isBoxShadow}
      isBorderRadius={isBorderRadius}
      borderValue={borderValue}
      color={color}
      height={height}
      backgroundColor={backgroundColor}
      minwidth={minwidth}
    >
      <Title
        isBold={isBold}
        fontFamily={fontFamily}
        fontSize={fontSize}
        isWordBreak={isWordBreak}
      >
        {/* dfskjfdsnjfsdbjhfdsbihfdsbhksdbfjhfsdkhbfhfsdkhcfhskdbfdshfdlbssd
        hsdbsdhbdshbdf fdsbfsbd */}
        {children}
      </Title>
      <IconButton aria-label="close" size="small" onClick={handleDeleteClick}>
        <CloseIcon
          className={isSmallDeleteIcon ? classes.smallDeleteIcon : undefined}
        />
      </IconButton>
    </StyledChipSection>
    // </Tooltip>
  )
}
