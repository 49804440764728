import React from 'react'
import gql from 'graphql-tag'
import {useMutation} from '@apollo/react-hooks'
import {useDispatch, useSelector} from 'react-redux'
import {enableOpenAccess, disableOpenAccess} from 'store/actions/feed'
import {
  Wrapper,
  Message,
  FilterTab,
  FilterTabName,
  InteractiveRegion,
  FilterTabs,
} from './styles'

import ModalGeneric from 'components/shared/ModalGeneric'
import ToggleSwitch from 'components/ToggleSwitch'
import Image from 'components/shared/Image'

const DELETE_FILTER = gql`
  mutation DeleteFilter($filter: ID!) {
    deleteFilter(filter: $filter) {
      status
      refetch {
        viewer {
          id
          filters {
            id
            name
          }
        }
      }
    }
  }
`

const AddIcon = '/static-images/addIcon.png'
const CloseIcon = '/static-images/closeIcon.svg'
const EditIcon = '/static-images/editIcon.svg'
const FiltersIcon = '/images/filtersIcon.svg'

const legacyMigration = (filter) => {
  return {...filter, term: filter.terms.join(' ')}
}

const getFilterName = (filter) => legacyMigration(filter).term.toUpperCase()

const filterNameString = (filter) => {
  let result = ''

  const moreThenOneOr =
    filter.terms.filter((term) => {
      return term.logicFlow === 'OR'
    }).length > 0

  if (filter.terms.length === 1) {
    const termCount =
      filter.terms[0].terms.length > 1
        ? ` +${filter.terms[0].terms.length - 1}`
        : ''

    result += getFilterName(filter.terms[0]) + termCount
  } else {
    for (let i = 0; i < filter.terms.length; i++) {
      const term = filter.terms[i]

      if (!getFilterName(term)) continue

      if (i === 0) {
        if (moreThenOneOr) {
          result += '('
        }

        result += getFilterName(term)
      } else {
        if (term.logicFlow === 'OR') {
          result += `) OR (${getFilterName(term)}`
        } else {
          result += ` ${term.logicFlow} ${getFilterName(term)}`
        }
      }
    }

    if (moreThenOneOr) {
      result += ')'
    }
  }

  return result
}

const FilterName = ({filter}) => {
  if (filterNameString(filter) !== filter.name) {
    return <span>{filter.name}</span>
  }

  const result = []

  const moreThenOneOr =
    filter.terms.filter((term) => {
      return term.logicFlow === 'OR'
    }).length > 0

  if (filter.terms.length === 1) {
    const termCount =
      filter.terms[0].terms.length > 1
        ? ` +${filter.terms[0].terms.length - 1}`
        : ''

    result.push(getFilterName(filter.terms[0]) + termCount)
  } else {
    for (let i = 0; i < filter.terms.length; i++) {
      const term = filter.terms[i]

      if (!getFilterName(term)) continue

      const termCount =
        term.terms.length > 1 ? ` +${term.terms.length - 1}` : ''

      if (i === 0) {
        if (moreThenOneOr) {
          result.push('(')
        }

        result.push(getFilterName(term) + termCount)
      } else {
        if (term.logicFlow === 'OR') {
          const gate = (
            <div
              key={i}
              css={`
                display: inline-block;
                font-weight: bold;
                color: #f94687;
              `}
            >
              OR
            </div>
          )

          result.push(') ')
          result.push(gate)
          result.push(` (${getFilterName(term)}${termCount}`)
        } else {
          const gate = (
            <div
              key={i}
              css={`
                display: inline-block;
                font-weight: bold;
                color: #f94687;
              `}
            >
              &nbsp;{term.logicFlow}&nbsp;
            </div>
          )

          result.push(gate)
          result.push(`${getFilterName(term)}${termCount}`)
        }
      }
    }

    if (moreThenOneOr) {
      result.push(')')
    }
  }

  return (
    <div
      css={`
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      `}
    >
      {result}
    </div>
  )
}

const EditTab = ({filter, onEdit, updateFilters}) => {
  const [deleting, setDeleting] = React.useState(false)
  const [deleteFilter, {loading}] = useMutation(DELETE_FILTER, {
    onCompleted() {
      updateFilters(filter.id)
    },
  })

  const legacyFilter = {
    ...filter,
    terms: filter.terms.map((item) => {
      return {...item, logicFlow: item.logic_flow}
    }),
  }
  return (
    <FilterTab deleting={deleting} deleted={loading}>
      {deleting ? (
        <>
          <div
            css={`
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
            `}
          >
            {filterNameString(legacyFilter)}
          </div>
          <InteractiveRegion
            disabled={loading}
            onClick={() => deleteFilter({variables: {filter: filter.id}})}
          >
            Delete
          </InteractiveRegion>
          <InteractiveRegion
            disabled={loading}
            onClick={() => setDeleting(false)}
          >
            Cancel
          </InteractiveRegion>
        </>
      ) : (
        <>
          <FilterName filter={filter} />
          <InteractiveRegion onClick={() => onEdit(filter)}>
            <img alt="Edit Filter" src={EditIcon} width="26" height="26" />
          </InteractiveRegion>

          <InteractiveRegion onClick={() => setDeleting(true)}>
            <img alt="Delete Filter" src={CloseIcon} width="20" height="20" />
          </InteractiveRegion>
        </>
      )}
    </FilterTab>
  )
}

const FilterListPage = ({
  filters,
  setFilters,
  onEditFilter,
  onCreateFilter,
}) => {
  const dispatch = useDispatch()
  const {openAccess} = useSelector((state) => state.feed)

  const openAccessChange = ({target}) => {
    if (target.checked) {
      dispatch(enableOpenAccess())
    } else {
      dispatch(disableOpenAccess())
    }
  }

  const updateFilters = (id) => {
    setFilters(filters.filter((f) => f.id !== id))
  }

  return (
    <Wrapper>
      <Message>Create feeds with only the most relevant research</Message>

      <InteractiveRegion onClick={onCreateFilter}>
        <Message>
          Create new feed
          <img
            src={AddIcon}
            width="23"
            height="23"
            alt="Create Feed"
            css={`
              margin-top: 8px;
            `}
          />
        </Message>
      </InteractiveRegion>

      <FilterTabs>
        {filters.map((filter) => {
          return (
            <EditTab
              key={filter.id}
              filter={filter}
              onEdit={onEditFilter}
              updateFilters={updateFilters}
            />
          )
        })}
      </FilterTabs>

      <p
        css={`
          margin-top: 30px;
        `}
      >
        Additional feeds
      </p>

      <FilterTab columns="1fr max-content">
        <FilterTabName>Open access</FilterTabName>
        <ToggleSwitch onChange={openAccessChange} checked={openAccess} />
      </FilterTab>
    </Wrapper>
  )
}

const MainFilterModal = ({
  isOpen,
  onClose,
  openCreateModal,
  openEditModal,
  filters,
  setFilters,
}) => {
  const onEditFilter = (filter) => {
    onClose()
    openEditModal(filter)
  }

  const createFilter = () => {
    onClose()
    openCreateModal()
  }

  return (
    <ModalGeneric
      x-track-id="createFilterModal"
      x-track-view="true"
      contentWidth="870px"
      title="Feeds"
      icon={<Image src={FiltersIcon} width={15} height={15} />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FilterListPage
        filters={filters}
        setFilters={setFilters}
        onEditFilter={onEditFilter}
        onCreateFilter={createFilter}
      />
    </ModalGeneric>
  )
}

export default MainFilterModal
