import React from 'react'
export const TermIcon = () => (
  <svg
    width="88px"
    height="87px"
    viewBox="0 0 88 87"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>add_keyword</title>
    <defs>
      <polygon
        id="path-1"
        points="0 0 64.9540119 0 64.9540119 30.5570759 0 30.5570759"
      ></polygon>
      <polygon id="path-3" points="0 87 87.71909 87 87.71909 0 0 0"></polygon>
    </defs>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-22">
        <g
          id="Group-5"
          transform="translate(0.000000, 21.513503)"
          fill="#4F4F4F"
        >
          <path
            d="M43.859545,65.4864975 C34.4663173,65.4864975 25.5090128,62.5603176 17.9569193,57.0237276 L18.1730492,56.7284721 C25.6625017,62.2181729 34.5447102,65.1201755 43.859545,65.1201755 C67.841911,65.1201755 87.3525849,45.6091353 87.3525849,21.6271356 C87.3525849,15.7549946 86.2030666,10.0590545 83.934801,4.69719995 L84.2725499,4.55470071 C86.5594978,9.96197915 87.7189068,15.7055411 87.7189068,21.6271356 C87.7189068,45.811345 68.0441207,65.4864975 43.859545,65.4864975"
            id="Fill-1"
          ></path>
          <path
            d="M17.9567361,57.0238741 C6.7128499,48.7823627 0,35.5497145 0,21.6269158 C0,14.0381901 1.96898053,6.55972732 5.69410853,0 L6.01280864,0.180963048 C2.318818,6.68574208 0.366321959,14.1015638 0.366321959,21.6269158 C0.366321959,35.4335905 7.0231246,48.5556094 18.1732324,56.7282523 L17.9567361,57.0238741 Z"
            id="Fill-3"
          ></path>
        </g>
        <g id="Group-21">
          <path
            d="M68.9211321,12.311092 C67.3353244,12.311092 66.0447721,13.6009116 66.0447721,15.1870857 C66.0447721,16.7728934 67.3353244,18.0627131 68.9211321,18.0627131 C70.5069399,18.0627131 71.7971258,16.7728934 71.7971258,15.1870857 C71.7971258,13.6009116 70.5069399,12.311092 68.9211321,12.311092 M68.9211321,18.429035 C67.1331147,18.429035 65.6784502,16.9743705 65.6784502,15.1870857 C65.6784502,13.3994345 67.1331147,11.94477 68.9211321,11.94477 C70.7087833,11.94477 72.1634478,13.3994345 72.1634478,15.1870857 C72.1634478,16.9743705 70.7087833,18.429035 68.9211321,18.429035"
            id="Fill-6"
            fill="#4F4F4F"
          ></path>
          <polygon
            id="Fill-8"
            fill="#4F4F4F"
            points="62.5623691 69.9055858 18.8436748 69.9055858 18.8436748 21.4104195 19.2099968 21.4104195 19.2099968 69.5392638 62.4107118 69.5392638 70.7419721 61.2080035 70.7419721 27.5129771 71.108294 27.5129771 71.108294 61.3596608"
          ></polygon>
          <g id="Group-12" transform="translate(12.215262, 0.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-11"></g>
            <path
              d="M51.9876798,30.5570759 L51.9305336,30.1951498 C59.2642992,29.041602 64.5876899,22.8082675 64.5876899,15.3730307 C64.5876899,7.09818397 57.8557913,0.366285327 49.5809445,0.366285327 C43.5362659,0.366285327 38.1084734,3.96393329 35.7537559,9.53166074 L35.706134,9.64338894 L6.88209065,9.64338894 L0.542889143,15.374496 L6.81944959,21.1686104 L12.3069525,16.736481 L16.9500834,21.1682441 L22.5903426,16.736481 L26.2960555,20.3839488 L35.2053719,20.4275411 L35.2449347,20.5553875 C36.0618326,23.1903413 37.6351854,25.4787546 39.7950197,27.1737263 L39.5689991,27.4620217 C37.3853539,25.7483676 35.7856259,23.4445688 34.9353926,20.7923978 L26.1451309,20.7495381 L22.5632347,17.2240556 L16.9262724,21.6525217 L12.2868048,17.2240556 L6.80333142,21.6521554 L0,15.3715654 L6.74105669,9.27706698 L35.4639952,9.27706698 C37.9015015,3.6371741 43.4289335,-3.66321959e-05 49.5809445,-3.66321959e-05 C58.0572683,-3.66321959e-05 64.9540119,6.89634057 64.9540119,15.3730307 C64.9540119,22.9899632 59.5009432,29.3753213 51.9876798,30.5570759"
              id="Fill-10"
              fill="#4F4F4F"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M25.1170117,36.6282396 L63.991098,36.6282396 L63.991098,30.5890558 C63.2628499,30.6930913 62.5254438,30.7458416 61.7960968,30.7458416 C58.0075951,30.7458416 54.564535,29.6241638 51.8343374,27.5009617 L25.1170117,27.5009617 L25.1170117,36.6282396 Z M64.3574199,36.9945616 L24.7506897,36.9945616 L24.7506897,27.1346397 L51.9603522,27.1346397 L52.010172,27.1734699 C54.6832233,29.2710294 58.0673056,30.3795197 61.7960968,30.3795197 C62.5774615,30.3795197 63.3679843,30.3172449 64.1456858,30.1948934 L64.3574199,30.1619244 L64.3574199,36.9945616 Z"
            id="Fill-13"
            fill="#4F4F4F"
          ></path>
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-3"></use>
          </mask>
          <g id="Clip-16"></g>
          <path
            d="M26.5938753,64.1916959 L41.5551967,64.1916959 L41.5551967,49.2303744 L26.5938753,49.2303744 L26.5938753,64.1916959 Z M26.227187,64.5580178 L41.9211524,64.5580178 L41.9211524,48.8640524 L26.227187,48.8640524 L26.227187,64.5580178 Z"
            id="Fill-15"
            fill="#4F4F4F"
            mask="url(#mask-4)"
          ></path>
          <path
            d="M47.9017247,55.4043647 L63.9913177,55.4043647 L63.9913177,49.2303744 L47.9017247,49.2303744 L47.9017247,55.4043647 Z M47.5354027,55.7706867 L64.3576397,55.7706867 L64.3576397,48.8640524 L47.5354027,48.8640524 L47.5354027,55.7706867 Z"
            id="Fill-17"
            fill="#4F4F4F"
            mask="url(#mask-4)"
          ></path>
          <polygon
            id="Fill-18"
            fill="#4F4F4F"
            mask="url(#mask-4)"
            points="24.9337041 43.3237991 30.8410121 43.3237991 30.8410121 42.9574772 24.9337041 42.9574772"
          ></polygon>
          <polygon
            id="Fill-19"
            fill="#4F4F4F"
            mask="url(#mask-4)"
            points="39.9129754 43.3237991 64.1744787 43.3237991 64.1744787 42.9574772 39.9129754 42.9574772"
          ></polygon>
          <polygon
            id="Fill-20"
            fill="#4F4F4F"
            mask="url(#mask-4)"
            points="62.0121535 69.7224248 61.6458315 69.7224248 61.6458315 61.1006712 70.9251331 61.1006712 70.9251331 61.4669931 62.0121535 61.4669931"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
)
