import React from 'react'
import gql from 'graphql-tag'
import {css} from 'styled-components'
import {useDispatch} from 'react-redux'
import {refetchFeed} from 'store/actions/feed'
import {useMutation, useQuery} from '@apollo/react-hooks'
import styled from 'styled-components'
import {useDebounce} from 'use-debounce'
import useForm from 'react-hook-form'
import uuidv4 from 'uuid/v4'

import TextInput, {StyledTextInput} from 'components/shared/TextInput'
import ModalGeneric from 'components/shared/ModalGeneric'
import {Button} from 'components/shared/ModalComponents'
import Image from 'components/shared/Image'
import Dropdown, {StyledDropdown} from 'components/shared/Dropdown'
import {triggerCustomEvent} from 'lib/trackEverythingStream'
import Cookies from 'js-cookie'

const AddFilterResponse = gql`
  fragment AddFilterResponse on MutationResponse {
    status
    refetch {
      filter_id
      viewer {
        id
        filters {
          id
          name
        }
      }
    }
  }
`

const UPDATE_FILTER = gql`
  mutation UpdateFilter($filter: UpdateFilterData) {
    updateFilter(filter: $filter) {
      ...AddFilterResponse
    }
  }

  ${AddFilterResponse}
`

const ADD_FILTER = gql`
  mutation AddFilter($filter: AddFilterData) {
    addFilter(filter: $filter) {
      ...AddFilterResponse
    }
  }

  ${AddFilterResponse}
`

const InputBox = styled.div`
  border-radius: 5px;
  margin: 16px;
  padding: 16px;
  position: relative;
  ${(props) => !props.inactive && 'background-color: #F7F7FA;'}
  ${(props) => props.inactive && 'border: 1px dashed #ABABAB; cursor: pointer;'}
`

const BlockingContainer = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
`

const useIsOpen = (initialState) => {
  const [isOpen, setIsOpen] = React.useState(initialState)
  const ref = React.useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return {ref, isOpen, setIsOpen}
}

const RemoveIcon = '/static-images/removeIcon.png'

const StyledFilterGroup = styled.div`
  position: relative;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
  width: 150px;

  label {
    font-size: 15px;
  }

  input[type='text'] {
    width: 150px;
    background-color: transparent;
    font-size: 15px;
    padding: 15px;
  }

  input:placeholder {
    color: rgba(0, 0, 0, 0.27);
  }
`

const FiltersIcon = '/images/filtersIcon.svg'

const SUGGEST_TERMS = gql`
  query SuggestFilterTerms($query: String, $auth: String) {
    suggestSimilar(query: $query, auth: $auth)
  }
`

const SUGGEST_AUTOCOMPLETE = gql`
  query SuggestAutocomplete($query: String, $auth: String) {
    suggestAutocomplete(query: $query, auth: $auth)
  }
`

const isFilterValid = (filter) =>
  filter.type === 'KEYWORD'
    ? filter.filter
    : filter.author.firstname && filter.author.lastname

const getFilterName = (filter) =>
  filter.type === 'KEYWORD'
    ? Array.isArray(filter.filter)
      ? filter.filter[0]
      : filter.filter
    : filter.author.lastname
    ? filter.author.firstname + ' ' + filter.author.lastname
    : filter.author.firstname

const filterNameString = (filters) => {
  let result = ''

  if (filters.length === 0 || !isFilterValid(filters[0])) {
    return null
  }

  const moreThenOneOr =
    filters.filter((filter) => {
      return filter.gate === 'OR'
    }).length > 0

  if (filters.length === 1) {
    const termCount =
      filters[0].suggested.length > 0 ? ` +${filters[0].suggested.length}` : ''

    result += getFilterName(filters[0]) + termCount
  } else {
    for (let i = 0; i < filters.length; i++) {
      const term = filters[i]

      if (!getFilterName(term)) continue

      const termCount =
        term.suggested.length > 0 ? ` +${term.suggested.length}` : ''

      if (i === 0) {
        if (moreThenOneOr) {
          result += '('
        }

        result += getFilterName(term) + termCount
      } else {
        if (term.gate === 'OR') {
          result += `) OR (${getFilterName(term)}${termCount}`
        } else {
          result += ` ${term.gate} ${getFilterName(term)}${termCount}`
        }
      }
    }

    if (moreThenOneOr) {
      result += ')'
    }
  }

  return result
}

const FilterName = ({filters}) => {
  const result = []

  if (filters.length === 0 || !isFilterValid(filters[0])) {
    return null
  }

  const moreThenOneOr =
    filters.filter((filter) => {
      return filter.gate === 'OR'
    }).length > 0

  if (filters.length === 1) {
    const termCount =
      filters[0].suggested.length > 0 ? ` +${filters[0].suggested.length}` : ''

    result.push(getFilterName(filters[0]) + termCount)
  } else {
    for (let i = 0; i < filters.length; i++) {
      const term = filters[i]

      if (!getFilterName(term)) continue

      const termCount =
        term.suggested.length > 0 ? ` +${term.suggested.length}` : ''

      if (i === 0) {
        if (moreThenOneOr) {
          result.push('(')
        }

        result.push(getFilterName(term) + termCount)
      } else {
        if (term.gate === 'OR') {
          const item = (
            <div key={i}>
              ) <div>OR</div> ({getFilterName(term)}
              {termCount}
            </div>
          )

          result.push(item)
        } else {
          const item = (
            <div key={i}>
              {' '}
              <span>{term.gate}</span> {getFilterName(term)}
              {termCount}
            </div>
          )

          result.push(item)
        }
      }
    }

    if (moreThenOneOr) {
      result.push(')')
    }
  }

  return result
}

const Autocomplete = ({searchTerm, terms, onSelect, visible}) => {
  if (!terms || terms.length === 0 || !visible) {
    return null
  }

  return (
    <ul
      css={`
        background: #f7f7fa;
        color: #9b9b9b;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.18px;
        list-style-type: none;
        padding: 10px 0;
        max-height: 200px;
        overflow-y: scroll;
        position: absolute;
        width: calc(100% + 32px);
        transform: translateX(-16px);
        z-index: 2;

        li {
          padding: 10px 15px;
          cursor: pointer;

          &:hover {
            background: #eaeaf1;
          }
        }
      `}
    >
      <li onClick={() => onSelect(searchTerm)}>{searchTerm}</li>
      {terms.map(
        (term, index) =>
          term.toLowerCase() !== searchTerm.toLowerCase() && (
            <li key={index} onClick={() => onSelect(term)}>
              {term}
            </li>
          )
      )}
    </ul>
  )
}

const SuggestedFilters = ({suggestions, enabled, filterChange, visible}) => {
  if (suggestions.length === 0 || !visible) {
    return null
  }

  return (
    <div
      css={`
        padding: 5px;
        display: flex;
        flex-direction: column;

        & > label {
          display: inline-block;
          position: relative;
          padding-left: 23px;
          cursor: pointer;
          user-select: none;
          margin: 5px;
        }

        & > label > span {
          position: absolute;
          top: 0;
          left: 0;
          height: 12px;
          width: 12px;
          background-color: #dddddd;
        }

        & > label input:checked ~ span {
          background-color: #f94687;
        }

        & > label > input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
      `}
      x-track-id="filterSuggestions"
    >
      <div
        css={`
          height: 1px;
          width: 100%;
          background: #dedee6;
          margin-bottom: 8px;
        `}
      />
      <div
        css={`
          color: #bdbdbd;
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 8px;
          letter-spacing: 0.18px;
          line-height: 20px;
        `}
      >
        Also include:{' '}
      </div>
      {suggestions.map((name, i) => {
        return (
          <label key={i}>
            {name}
            <input
              checked={enabled.indexOf(name.toUpperCase()) > -1}
              name={name}
              type="checkbox"
              onChange={filterChange}
            />
            <span></span>
          </label>
        )
      })}
    </div>
  )
}

const LogicGroup = ({logicGate, dataChange}) => {
  const [gate, setGate] = React.useState(logicGate)

  const startingGate = gate
    ? {
        id: gate,
        name: gate,
      }
    : {
        id: 'OR',
        name: 'OR',
      }

  const gateChange = ({value}) => {
    setGate(value)
  }

  React.useEffect(() => {
    dataChange({
      gate,
    })
  }, [gate])

  return (
    <div
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        ${StyledDropdown} {
          border-bottom: none;
          color: #f94687;
          height: initial;
          span {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.17px;
            line-height: 17px;
          }

          &:after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #d8d8d8;
          }
        }
      `}
      x-track-id="logicGroup"
    >
      <div
        css={`
          width: 100%;
          display: flex;
          flex-direction: column;

          ${StyledDropdown} {
            color: #000000;
            height: initial;
            span {
              font-size: 12px;
              font-weight: 500;
            }
          }
        `}
      >
        <Dropdown
          // disabled={index === 1 && disabled}
          onChange={gateChange}
          width="75px"
          startingValue={startingGate}
          items={[
            {
              id: 'OR',
              name: 'OR',
            },
            {
              id: 'AND',
              name: 'AND',
            },
            // {
            //   id: 'NOT',
            //   name: 'NOT'
            // }
          ]}
        />
      </div>
    </div>
  )
}

// TODO: move into seperate component
const FilterGroup = ({disabled, filter, index = 0, dataChange, onDelete}) => {
  const [filterName, setFilterName] = React.useState(filter.filter)
  const [suggested, setSuggested] = React.useState(filter.suggested)
  const [type, setType] = React.useState(filter.type)
  const [author, setAuthor] = React.useState(filter.author)
  const {isOpen, setIsOpen, ref: autocompleteRef} = useIsOpen(false)
  const [active, setActive] = React.useState(filter.active)

  const onDeleteGroup = (index) => {
    setFilterName('')
    setType('KEYWORD')
    setAuthor({
      firstname: '',
      lastname: '',
    })
    setSuggested([])
    setActive(false)
    onDelete(index)
  }

  const [debouncedFilter] = useDebounce(filterName, 400)

  const usableFilterName = !filterName
    ? filterName
    : type === 'KEYWORD'
    ? Array.isArray(filterName)
      ? filterName[0]
      : filterName
    : Array.isArray(filterName)
    ? filterName.join(' ')
    : filterName

  const stqr = useQuery(SUGGEST_TERMS, {
    variables: {query: usableFilterName, auth: Cookies.get('accessToken')},
    skip: !usableFilterName,
    ssr: false,
  })
  const suggestedFilters = stqr.data ? stqr.data.suggestSimilar : []

  const autocomplete = useQuery(SUGGEST_AUTOCOMPLETE, {
    variables: {
      query: usableFilterName.length >= 3 ? usableFilterName : '',
      auth: Cookies.get('accessToken'),
    },
    ssr: false,
  })
  const suggestAutocomplete = autocomplete.data
    ? autocomplete.data.suggestAutocomplete
    : []

  const inputChange = (e) => {
    if (type === 'KEYWORD') {
      setFilterName(e.target.value)
      setIsOpen(true)

      if (suggested.length > 0) {
        setSuggested([])
      }
    } else if (type === 'AUTHOR') {
      if (e.target.name === 'firstname') {
        setAuthor({
          ...author,
          firstname: e.target.value,
        })
      } else {
        setAuthor({
          ...author,
          lastname: e.target.value,
        })
      }
    }
  }

  const typeChange = ({value}) => {
    setType(value)

    if (value === 'KEYWORD') {
      setAuthor({firstname: '', lastname: ''})
    } else {
      setFilterName('')
    }
  }

  const inputSubmit = (e) => {
    e.preventDefault()
    setIsOpen(false)
  }

  const autoCompleteSelect = (term) => {
    setFilterName(term)
    setIsOpen(false)
  }

  const filterChange = ({target}) => {
    const name = target.name.toUpperCase()
    const checked = target.checked

    let newSuggested = []

    if (!checked) {
      const index = suggested.indexOf(name)
      suggested.splice(index, 1)
      newSuggested = [...suggested]
    } else {
      newSuggested = [...suggested, name]
    }

    setSuggested(newSuggested)
  }

  const handleInactiveClick = () => {
    filter.active = true
    setActive(true)
  }

  React.useEffect(() => {
    dataChange({
      index,
      suggested,
      type,
      author,
      active,
      filter: debouncedFilter,
      key: filter.key,
    })
  }, [debouncedFilter, suggested, author, type, active, filterName])

  return (
    <InputBox
      inactive={!filter.active}
      css={`
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        height: min-content;
        align-self: center;
        ${StyledDropdown} {
          border-bottom: none;
          color: #f94687;
          height: initial;
          span {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.17px;
            line-height: 17px;
          }

          ${index === 1 &&
          disabled &&
          css`
            color: #dddddd;
          `}

          &:after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #d8d8d8;
          }
        }
      `}
      x-track-id="filterGroup"
    >
      <div
        css={`
          width: 100%;
          display: flex;
          flex-direction: column;

          ${StyledDropdown} {
            color: #000000;
            height: auto;
            span {
              color: #ababab;
              font-size: 10px;
              font-weight: 500;
              letter-spacing: 0.12px;
            }

            ${index === 1 &&
            disabled &&
            css`
              color: #dddddd;
            `}
          }
        `}
      >
        <Dropdown
          name="filterType"
          disabled={disabled}
          onChange={typeChange}
          width="100px"
          startingValue={{
            id: filter.type,
            name: filter.type,
          }}
          items={[
            {
              id: 'KEYWORD',
              name: 'KEYWORD',
            },
            {
              id: 'AUTHOR',
              name: 'AUTHOR',
            },
          ]}
        />

        {type === 'KEYWORD' ? (
          <StyledFilterGroup ref={autocompleteRef}>
            <form method="POST" onSubmit={inputSubmit} autoComplete="off">
              <input
                css={`
                  &:focus {
                    outline: none;
                  }
                `}
                disabled={disabled}
                onChange={inputChange}
                name="filter"
                type="text"
                placeholder={`Filter term ${index + 1}`}
                value={
                  type === 'KEYWORD' && Array.isArray(filterName)
                    ? filterName[0]
                    : filterName
                }
              />
            </form>

            <Autocomplete
              visible={isOpen && filterName}
              terms={suggestAutocomplete}
              searchTerm={filterName}
              onSelect={autoCompleteSelect}
            />

            <SuggestedFilters
              enabled={suggested}
              suggestions={suggestedFilters}
              visible={!isOpen}
              filterChange={filterChange}
            />
          </StyledFilterGroup>
        ) : (
          <>
            <StyledFilterGroup>
              <form method="POST" onSubmit={inputSubmit} autoComplete="off">
                <input
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  disabled={disabled}
                  onChange={inputChange}
                  name="firstname"
                  type="text"
                  placeholder="First name"
                  value={author.firstname}
                />
              </form>

              {/* {filter.active && <SuggestedFilters
                suggestions={suggestedFilters}
                visible={!isOpen}
                filterChange={filterChange} />} */}
            </StyledFilterGroup>

            <StyledFilterGroup>
              <form method="POST" onSubmit={inputSubmit} autoComplete="off">
                <input
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  disabled={disabled}
                  onChange={inputChange}
                  name="lastname"
                  type="text"
                  placeholder="Last name"
                  value={author.lastname}
                />
              </form>

              {/* <SuggestedFilters
                suggestions={suggestedFilters}
                visible={!isOpen}
                filterChange={filterChange} /> */}
            </StyledFilterGroup>
          </>
        )}

        {index > 0 && active && (
          <img
            alt="Remove"
            css={`
              ${disabled
                ? 'opacity: 0.6;'
                : 'cursor: pointer;'} position: absolute;
              top: 8px;
              right: 8px;
            `}
            onClick={() => !disabled && onDeleteGroup(index)}
            src={RemoveIcon}
            width="23"
            height="23"
          />
        )}
      </div>
      {!active && <BlockingContainer onClick={handleInactiveClick} />}
    </InputBox>
  )
}

const FilterGateString = ({filters}) => {
  return (
    <div
      css={`
        margin-bottom: 15px;
        text-align: center;

        div {
          display: inline;

          & > div {
            display: block;
            font-weight: bold;
            color: #f94687;
            margin: 10px 0;
          }
        }

        span {
          color: #f94687;
        }
      `}
    >
      <FilterName filters={filters} />
    </div>
  )
}

const FilterPage = ({filters, setFilters}) => {
  React.useEffect(() => {
    const lastFilter = filters[filters.length - 1]
    const copy = filters.map((item) => {
      return {...item, active: true}
    })

    if (getFilterName(lastFilter)) {
      setFilters([...copy, new FilterModel()])
    }
  }, [filters, setFilters])

  const removeFilterGroup = (index) => {
    var newFilters = [...filters]
    newFilters.splice(index, 1)
    setFilters(newFilters)
  }

  return (
    <div
      css={`
        width: 100%;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        max-width: 700px;
        max-width: 100%;
        padding: 32px;
      `}
    >
      {(filters.length === 0 || !getFilterName(filters[0])) && (
        <div
          css={`
            color: #323232;
            font-size: 16px;
            width: 100%;
            line-height: 18px;
            margin-bottom: 30px;
            text-align: center;
          `}
        >
          Create filters to see feeds with only the most relevant research
        </div>
      )}

      <FilterGateString filters={filters} />

      <div
        css={`
          width: 100%;
          margin-top: 16px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        `}
      >
        {filters.map((filter, index) => {
          return (
            <React.Fragment key={filter.key}>
              {index > 0 && (
                <LogicGroup /**Logic comes before the filter input **/
                  index={index}
                  logicGate={filter.gate}
                  dataChange={(data) => {
                    filters[index] = {...filters[index], ...data}
                    setFilters([...filters])
                  }}
                />
              )}
              <FilterGroup
                index={index}
                filter={filter}
                disabled={index > 0 && !isFilterValid(filters[index - 1])}
                onDelete={removeFilterGroup}
                dataChange={(data) => {
                  filters[index] = {...filters[index], ...data}
                  setFilters([...filters])
                }}
              />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

const TitlePage = ({filters, register}) => {
  return (
    <div
      css={`
        width: 100%;
        margin-top: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        ${StyledTextInput} {
          margin: 30px 0;
        }
      `}
    >
      <FilterGateString filters={filters} />
      <p>Add a name for your new filter</p>
      <TextInput ref={register} placeholder="Filter name..." name="name" />

      {/* TODO: change these radio buttons */}
      <div>
        <label css="margin-right: 15px;">
          <input
            defaultChecked
            ref={register}
            type="radio"
            name="all_journals"
            value="true"
          />{' '}
          All Journals
        </label>

        <label>
          <input
            ref={register}
            type="radio"
            name="all_journals"
            value="false"
          />{' '}
          My Journals
        </label>
      </div>
    </div>
  )
}

function FilterModel(noGate) {
  return {
    active: false || noGate,
    filter: '',
    suggested: [],
    author: {
      firstname: '',
      lastname: '',
    },
    gate: noGate ? undefined : 'AND',
    type: 'KEYWORD',
    key: uuidv4(),
  }
}

const FILTERS = gql`
  query ViewerFilters {
    viewer {
      id
      filters {
        id
        name
        allJournals
        terms {
          term
          terms
          abstractMatch
          authorMatch
          logicFlow
          titleMatch
          sortIndex
        }
        sortIndex
      }
    }
  }
`

function formatFilter(filter) {
  return filter.terms.map((term, index) => {
    return {
      filter: term.terms,
      active: true,
      suggested: term.authorMatch ? [] : [...term.terms].splice(1),
      author: {
        firstname: term.terms[0],
        lastname: term.terms[1],
      },
      gate: term.logic_flow,
      type: term.authorMatch ? 'AUTHOR' : 'KEYWORD',
      key: uuidv4(),
    }
  })
}

const EditFilterModal = ({filter: filt, isOpen, onClose, nextSortIndex}) => {
  const isEditing = filt
  const [filters, setFilters] = React.useState(
    isEditing ? formatFilter(filt) : [new FilterModel(true), new FilterModel()]
  )
  const dispatch = useDispatch()
  const [page, setPage] = React.useState(0)
  const [updateFilter, {loading: l1}] = useMutation(UPDATE_FILTER)
  const [createFilter, {loading: l2}] = useMutation(ADD_FILTER)
  const [errors, setErrors] = React.useState(false)

  const {register, getValues} = useForm()
  const [inputInvalid, setInputInvalid] = React.useState(false)

  React.useEffect(() => {
    let invalid = false

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]

      if (i === filters.length - 1) {
        if (
          filter.type === 'KEYWORD' ||
          (filter.type === 'AUTHOR' && !filter.author.firstname)
        ) {
          break
        }
      }

      invalid = !isFilterValid(filter)

      if (invalid) {
        break
      }
    }

    setInputInvalid(invalid)
  }, [filters])

  React.useEffect(() => {
    if (!isOpen) {
      setPage(0)
    }
  }, [isOpen])

  const postFilter = async () => {
    let {name, all_journals} = getValues()
    const filtersCreating =
      filters.length > 1
        ? !getFilterName(filters[filters.length - 1]) &&
          filters.splice(0, filters.length - 1)
        : filters

    name = name || filterNameString(filtersCreating)

    const processFilterName = (name) => {
      if (Array.isArray(name)) return name.join('')
      else return name
    }

    const filter = {
      name,
      ...(isEditing && filt && filt.id && {id: filt.id}),
      allJournals: all_journals === 'true',
      sortIndex: isEditing ? filt.sort_index : nextSortIndex,
      terms: filtersCreating.map((filter, index) => {
        return {
          terms:
            filter.type === 'AUTHOR'
              ? [filter.author.firstname, filter.author.lastname]
              : [processFilterName(getFilterName(filter)), ...filter.suggested],
          logicFlow: filter.gate || 'AND',
          // TODO: add option to UI to choose whether the filter should match the title, abstract, author
          // FIXME: figure out what attributes to set to "true" for Author search
          titleMatch: filter.type === 'KEYWORD',
          abstractMatch: filter.type === 'KEYWORD',
          authorMatch: filter.type === 'AUTHOR',
          sortIndex: index,
        }
      }),
    }
    console.log(filter)

    const method = isEditing ? updateFilter : createFilter

    try {
      const response = await method({
        refetchQueries: [{query: FILTERS}],
        variables: {filter},
      })
      dispatch(
        refetchFeed({
          refetch: true,
          feedName: filter.id,
        })
      )

      console.log(response)

      const filterId =
        response.data &&
        response.data.addFilter &&
        response.data.addFilter.refetch &&
        response.data.addFilter.refetch.filter_id
          ? response.data.addFilter.refetch.filter_id
          : filter.id

      triggerCustomEvent({
        type: isEditing ? 'updateFilter' : 'createFilter',
        detail: {filterJSON: filter, filter: filterId},
        path: [],
      })

      onClose(filterId)
      setErrors(false)
    } catch (err) {
      console.log(err)
      setErrors(true)
    }
  }

  return (
    <ModalGeneric
      x-track-id={isEditing ? 'updateFilterModal' : 'createFilterModal'}
      x-track-view="true"
      title="Create new feed"
      icon={<Image src={FiltersIcon} width={15} height={15} />}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        page === 0 ? (
          <Button
            background="#F94687"
            disabled={inputInvalid}
            width="145px"
            key="next"
            onClick={() => setPage(1)}
            x-track-id="nextButton"
            x-track-click="true"
          >
            Next
          </Button>
        ) : (
          <div
            css={`
              display: flex;
              align-items: center;
              color: red;
            `}
          >
            {errors && <>A filter with this name already exists</>}
            <Button
              disabled={l1 || l2}
              background="#F94687"
              width="145px"
              css={`
                margin-left: 16px;
              `}
              key="save"
              onClick={() => {
                postFilter()
              }}
            >
              {l1 || l2 ? 'Saving' : 'Save'}
            </Button>
          </div>
        )
      }
    >
      {page === 0 ? (
        <FilterPage filters={filters} setFilters={setFilters} />
      ) : (
        <TitlePage register={register} filters={filters} />
      )}
    </ModalGeneric>
  )
}

export default EditFilterModal
