import stringifyStable from 'json-stable-stringify'
import getMetadata from 'lib/trackMetadata'
import uuid from 'uuid/v4'

const throttle = (f, ms) => {
  let lastCalled = 0
  const _f = (...args) => {
    const now = Date.now()
    if (now - lastCalled < ms) return
    lastCalled = now
    return f(...args)
  }
  return _f
}

const isVisible = (el) => {
  if (!el) return false

  const {top, right, bottom, left} = el.getBoundingClientRect()

  if (
    bottom <= 0 ||
    right <= 0 ||
    top >= window.innerHeight ||
    left >= window.innerWidth
  ) {
    return false
  }

  return true
}

const getTrackingPath = (target, path = []) => {
  const id = target.getAttribute('x-track-id')

  if (id) {
    path.unshift(target)
  }

  if (!target.parentElement) {
    return path
  }

  return getTrackingPath(target.parentElement, path)
}

const formatEvent = (e) => {
  const pathElements = getTrackingPath(e.target)
  if (!pathElements.length) return

  const detail = {...e.detail}
  const type = e.type
  const path = pathElements.map((el) => el.getAttribute('x-track-id'))
  let detailElements
  detailElements = pathElements[0].querySelectorAll('*[x-track-detail]')
  detailElements = Array.from(detailElements)
  // for top-level element, and to ensure those in path
  // have precedence over identical siblings
  pathElements.forEach((el) => {
    if (el.getAttribute('x-track-detail')) {
      detailElements.push(el)
    }
  })

  detailElements.forEach((el) => {
    const detailPatch = JSON.parse(el.getAttribute('x-track-detail'))
    let detailOn
    detailOn = el.getAttribute('x-track-detail-on') || ''
    detailOn = detailOn.split(',').map((d) => d.trim())
    detailOn = detailOn.filter((d) => d)
    detailOn.push(el.getAttribute('x-track-id'))
    if (!detailOn.some((d) => path.includes(d))) return
    Object.assign(detail, detailPatch)
  })

  if (e.type === 'submit') {
    detail.formSubmission = {}
    const inputs = Array.from(document.querySelectorAll('*[name]'))
    inputs.forEach((i) => {
      const key = i.getAttribute('name')
      const value = i.type === 'checkbox' ? i.checked : i.value
      detail.formSubmission[key] = value
    })
  }

  // TODO: return null for click on disabled element

  return {type, pathname: e.pathname, path, pathElements, detail, raw: e}
}

const lastCalledMap = {}
const isDuplicateEvent = (event) => {
  const key =
    event.type +
    '/' +
    (event.path || []).join(',') +
    '/' +
    stringifyStable(event.detail)

  const lastCalled = lastCalledMap[key] || 0
  if (Date.now() - lastCalled <= 500) return true
  lastCalledMap[key] = Date.now()
  return false
}

let listeners = []
const dispatchEvent = async (event) => {
  const metadata = await getMetadata()
  event = {...event, meta: metadata}
  if (!event.path) event.path = []
  if (!event.pathname) event.pathname = document.location.pathname
  const duplicate = isDuplicateEvent(event)
  if (duplicate) return

  listeners.forEach((l) => l(event))
}

let initialised = false
const initialiseEventStream = () => {
  const handleEvent = (e) => {
    const formatted = formatEvent(e)
    if (formatted) {
      dispatchEvent(formatted)
    }
  }

  const nodeList = new Set()
  const viewTargets = new Set()
  const viewTargetsActive = new Map()

  const addNode = (node) => {
    if (!node.getAttribute('x-track-id') || nodeList.has(node)) return
    nodeList.add(node)

    if (node.getAttribute('x-track-click')) {
      node.addEventListener('click', handleEvent)
    }
    if (node.getAttribute('x-track-focus')) {
      node.addEventListener('focus', handleEvent)
    }
    if (node.getAttribute('x-track-blur')) {
      node.addEventListener('blur', handleEvent)
    }
    if (node.getAttribute('x-track-input')) {
      node.addEventListener('input', handleEvent)
    }
    if (node.getAttribute('x-track-change')) {
      node.addEventListener('change', handleEvent)
    }
    if (node.getAttribute('x-track-submit')) {
      node.addEventListener('submit', handleEvent)
    }
    if (node.getAttribute('x-track-view')) {
      viewTargets.add(node)
      if (isVisible(node)) {
        const viewId = uuid()
        viewTargetsActive.set(node, {
          pathname: document.location.pathname,
          viewId,
        })
        handleEvent({type: 'viewStart', detail: {viewId}, target: node})
      }
    }
  }

  const removeNode = (node) => {
    if (!node.getAttribute('x-track-id')) return
    viewTargets.delete(node)
    if (viewTargetsActive.has(node)) {
      // pathname of view_end needs to match view
      const {pathname, viewId} = viewTargetsActive.get(node)
      handleEvent({type: 'viewEnd', pathname, detail: {viewId}, target: node})
      viewTargetsActive.delete(node)
    }
  }

  const getTrackableChildren = (el) => {
    return Array.from(el.querySelectorAll('*[x-track-id]'))
  }

  getTrackableChildren(document).forEach(addNode)

  new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      for (const added of mutation.addedNodes) {
        if (!added.tagName) continue
        addNode(added)
        getTrackableChildren(added).forEach(addNode)
      }
      for (const removed of mutation.removedNodes) {
        if (!removed.tagName) continue
        removeNode(removed)
        getTrackableChildren(removed).forEach(removeNode)
      }
    }
  }).observe(document, {childList: true, subtree: true})

  const onScroll = throttle(() => {
    for (const target of viewTargets) {
      const prevVisible = viewTargetsActive.has(target)
      const nextVisible = isVisible(target)
      if (!prevVisible && nextVisible) {
        const viewId = uuid()
        viewTargetsActive.set(target, {
          pathname: document.location.pathname,
          viewId,
        })
        handleEvent({type: 'viewStart', detail: {viewId}, target})
      }
      if (prevVisible && !nextVisible) {
        const {pathname, viewId} = viewTargetsActive.get(target)
        handleEvent({type: 'viewEnd', pathname, detail: {viewId}, target})
        viewTargetsActive.delete(target)
      }
    }
  }, 100)

  window.addEventListener('scroll', onScroll, true)
  window.addEventListener('resize', onScroll, true)

  const onVisibilityChange = () => {
    if (document.hidden) {
      triggerCustomEvent({type: 'inactive', path: [], detail: {}})
    } else {
      triggerCustomEvent({type: 'active', path: [], detail: {}})
    }
  }

  triggerCustomEvent({type: 'active', path: [], detail: {}})
  document.addEventListener('visibilitychange', onVisibilityChange, false)

  const onClosePage = () => {
    triggerCustomEvent({type: 'inactive', path: [], detail: {}})
  }
  window.addEventListener('beforeunload', onClosePage)

  let pathname = document.location.pathname
  window.addEventListener('navigate', () => {
    const formatted = {
      type: 'navigate',
      pathname,
      path: [],
      detail: {
        nextPathname: document.location.pathname,
        fullPathname: document.location.href,
      },
    }
    dispatchEvent(formatted)
    pathname = document.location.pathname
  })
}

const triggerCustomEvent = (event) => {
  if (typeof window === 'undefined') return
  dispatchEvent(event)
}

// TODO: fix duplicate event in development due to HMR
const subscribe = (callback) => {
  if (typeof window === 'undefined') return
  listeners.push(callback)
  if (!initialised) initialiseEventStream()
  initialised = true
}

export {triggerCustomEvent, formatEvent}
export default subscribe
