import {string} from 'yup'
import {ModalSize} from '../CustomDialog'
import {Filter, LogicFlow, Term} from '../interfaces/api'
import Cookies from 'js-cookie'
import {MatchField, matchFieldMap, Matchtype} from '../interfaces/types'
import {ActiveFeed} from '../interfaces/activeFeed'

export enum TranslationKeys {
  manageFeeds = 'Manage feeds',
  createFeed = 'Create feed',
  createNewFeed = 'Create New Feed',
  createNewFeedLowercase = 'Create new feed',
  editFeed = 'Edit feed',
  ok = 'OK',
  createFeeds = 'Create feeds',
  mostReleventResearch = 'with only the most relevent research',
  editExistingFeeds = 'Edit existing feeds',
  previous = 'Previous',
  next = 'Next',
  keywordTopic = 'Keyword/topic',
  add = 'Add',
  enter = 'Enter',
  edit = 'Edit',
  author = 'Author',
  save = 'Save',
  includedInThisFeed = 'Included in this feed',
  excludedInThisFeed = 'Excluded in this feed',
  settings = 'Settings',
  keywords = 'Keywords',
  topic = 'Topic',
  authors = 'Authors',
  subjects = 'Subjects',
  publications = 'Publications',
  articleTypes = 'Article Types',
  enterFeedTerm = 'Enter Feed Term',
  searchForKeywordsOrTopics = 'Enter term',
  exactMatch = 'Exact match',
  phraseMatch = 'Phrase match',
  firstName = 'First Name',
  lastName = 'Last Name',
  keywordsTopic = 'Keywords topic',
  exclude = 'Exclude',
  searchPublication = 'Search Publication',
  articleType = 'Article type',
  showOnlyOpenAccessPapers = 'Show only Open Access papers',
  showPapersOnlyFromFollowedPublications = 'Show papers only from the publications I follow',
  done = 'Done',
  name = 'Name',
  yes = 'Yes',
  cancel = 'Cancel',
  publication = 'Publication',
  subject = 'Subject',
  searchForA = 'Search for a',
  search = 'Search',
  searchForSubjects = 'Search for subjects',
  duplicateFeedNameError = 'Duplicate feed name',
  alreadySelected = 'Already in your exclusions.',
}

export const headerMapping = {
  0: TranslationKeys.includedInThisFeed,
  1: TranslationKeys.excludedInThisFeed,
  2: TranslationKeys.settings,
}

export const feedManagerTitleMapping = {
  0: TranslationKeys.manageFeeds,
  1: TranslationKeys.createNewFeedLowercase,
  2: TranslationKeys.editFeed,
}

export const feedManagerFooterButtonPrimaryText = {
  0: TranslationKeys.manageFeeds,
  1: TranslationKeys.createFeed,
  2: TranslationKeys.editFeed,
}

export const feedManagerFooterButtonSecondaryText = {
  0: TranslationKeys.manageFeeds,
  1: TranslationKeys.createFeed,
  2: TranslationKeys.editFeed,
}

type actionBtnConfigType = {
  text: string
  isDisabled: boolean
  isLoading: boolean
}

export const actionBtnConfig: actionBtnConfigType = {
  text: '', // may be a default value
  isDisabled: false,
  isLoading: false,
}

export const getFormSubmitBtnConfig = (isDisabled: boolean) => {
  const config = {...actionBtnConfig, isDisabled, text: TranslationKeys.save}
  return config
}

export const getConfirmDialogConfig = (btnText, isDisabled: boolean) => {
  const config = {...actionBtnConfig, isDisabled, text: btnText}
  return config
}

export const getFormModalTitle = (secondPart: string, isEdit?: boolean) => {
  let firstPart = TranslationKeys.enter
  if (isEdit) {
    firstPart = TranslationKeys.edit
  }
  return `${firstPart} ${secondPart}`
}

export const getFeedManagerTitle = (manageFeedType: number): string => {
  return feedManagerTitleMapping[manageFeedType]
}

export const getModalSize = (manageFeedType, createEditStep) => {
  let isSmallModal
  if (isSmallModal) {
    return ModalSize.sm
  }
  return ModalSize.lg
}

export const shouldPrimaryBtnDisable = (
  manageFeedType,
  filters,
  activeFeed
) => {
  // if (manageFeedType === 1 && filters.length > 0) {
  //   return false
  // } else if (activeFeed && activeFeed.inclusions.length > 0) {
  //   return false
  // }
  if (activeFeed && activeFeed.inclusions.length === 0) {
    return true
  }
  return false
}

export const getPrimaryBtnConfig = (
  manageFeedType: number,
  createEditStep: number,
  isDisabled: boolean,
  isLoading: boolean = false
): actionBtnConfigType => {
  const config = {...actionBtnConfig, isDisabled, isLoading}

  switch (manageFeedType) {
    case 0:
      return {...config, text: TranslationKeys.ok, isDisabled: false}
    case 1:
      if (createEditStep === 2) {
        return {...config, text: TranslationKeys.done}
      }
      return {...config, text: TranslationKeys.next}
    default:
      break
  }

  return config
}

export const getSecondaryBtnConfig = (
  manageFeedType: number,
  createEditStep: number
): actionBtnConfigType | null => {
  const config = {...actionBtnConfig}
  switch (manageFeedType) {
    case 1:
      // if (createEditStep === 2) {
      //   return null
      // }
      return {...config, text: TranslationKeys.previous}
    default:
      break
  }

  return null
}

export const buildTitle = (step: number): string => TranslationKeys.manageFeeds
export const buildSubtitle = (): string => 'buildSubtitle'

// revisit this and add appropriate type
export const buildCreateFeedPayload = (
  rawPayload: ActiveFeed,
  sortIndex
): any => {
  // const rawPayload = {
  //   name: 'dsflfsdnfdl',
  //   isOnlyOpenAccess: true,
  //   isOnlyFollowedPublications: false,
  //   inclusions: [
  //     {
  //       match_field: 'keyword',
  //       match_type: 'phrase',
  //       logic_flow: 'OR',
  //       sort_index: 0,
  //       terms: ['acl'],
  //     },
  //     {
  //       match_field: 'author',
  //       match_type: 'default',
  //       logic_flow: 'OR',
  //       sort_index: 0,
  //       terms: ['aasdsda', 'dsdffd'],
  //     },
  //   ],
  //   exclusions: {
  //     keywordsTopics: [
  //       {
  //         match_field: 'keyword',
  //         match_type: 'phrase',
  //         terms: ['acl'],
  //         ids: [],
  //         value: 'keyword',
  //         displayValue: 'acl (keyword)',
  //       },
  //     ],
  //     subjects: [
  //       {
  //         match_field: 'subject_id',
  //         match_type: 'default',
  //         terms: ['Computer Networks & Communications'],
  //         ids: [],
  //         value: 881,
  //         displayValue: 'Computer Networks & Communications',
  //       },
  //     ],
  //     publications: [
  //       {
  //         match_field: 'journal_id',
  //         match_type: 'default',
  //         terms: ['AAS Open Research'],
  //         ids: [],
  //         value: 24210,
  //         displayValue: 'AAS Open Research',
  //       },
  //     ],
  //     articleTypes: [],
  //     authors: [
  //       {
  //         match_field: 'author',
  //         match_type: 'default',
  //         terms: ['sdsdakj', 'abc'],
  //       },
  //     ],
  //   },
  // }

  const {
    name,
    isOnlyOpenAccess,
    isOnlyFollowedPublications,
    inclusions,
    exclusions,
  } = rawPayload

  const publications = {
    match_field: MatchField.journalId,
    ids: exclusions.publications.map(
      (publication) => publication.value || publication.id || []
    ),
  }
  const subjects = {
    match_field: MatchField.subjectId,
    ids: exclusions.subjects.map(
      (subject) => subject.value || subject.id || []
    ),
  }
  const exclusionArr = [
    ...exclusions.keywordsTopics,
    ...exclusions.authors,
    publications,
    subjects,
  ]

  const transformedPayload = {
    name,
    all_journals: !isOnlyFollowedPublications,
    terms: inclusions,
    open_only: isOnlyOpenAccess,
    excludes: exclusionArr,
  }

  if (sortIndex != null) {
    transformedPayload['sort_index'] = sortIndex
  }

  return transformedPayload
}

// revisit this and add appropriate type
export const tranformGetFiltersResponse = (): any => {}

export const getIsOpeningParenthesisVisible = (
  i: number,
  filterTerms: Term[]
) => {
  // before (
  // let isOrThere = false
  // for (let j = i; j < filterTerms.length; j++) {
  //   if (filterTerms[j].logic_flow !== LogicFlow.AND) {
  //     isOrThere = true
  //   }
  // }

  // if (
  //   (filterTerms[i + 1] &&
  //     filterTerms[i + 1].logic_flow === LogicFlow.AND &&
  //     filterTerms[i].logic_flow !== LogicFlow.AND) ||
  //   (filterTerms[i + 1] &&
  //     filterTerms[i + 1].logic_flow === LogicFlow.AND &&
  //     isOrThere)
  // ) {
  //   // outputString += '('
  //   // isOpeningBracket = true
  //   return true
  // }
  if (
    filterTerms[i + 1] &&
    filterTerms[i + 1].logic_flow === LogicFlow.AND &&
    filterTerms[i].logic_flow !== LogicFlow.AND
  ) {
    return true
  }

  return false
}

export const getIsClosingParenthesisVisible = (
  i: number,
  filterTerms: Term[]
) => {
  // after )
  // let isOpeningBracket = false
  // let isOrThere = false
  // for (let j = i; j < filterTerms.length; j++) {
  //   if (filterTerms[j].logic_flow !== LogicFlow.AND) {
  //     isOrThere = true
  //   }
  // }

  // if (
  //   (filterTerms[i + 1] &&
  //     filterTerms[i + 1].logic_flow === LogicFlow.AND &&
  //     filterTerms[i].logic_flow !== LogicFlow.AND) ||
  //   (filterTerms[i + 1] &&
  //     filterTerms[i + 1].logic_flow === LogicFlow.AND &&
  //     isOrThere)
  // ) {
  //   // outputString += '('
  //   isOpeningBracket = true
  // }
  // if (
  //   filterTerms[i].logic_flow === LogicFlow.AND &&
  //   i !== 0 &&
  //   filterTerms[i + 1] &&
  //   filterTerms[i + 1].logic_flow !== LogicFlow.AND
  // ) {
  //   if (isOpeningBracket) {
  //     // outputString += ')'
  //     isOpeningBracket = false
  //     return true
  //   }
  // } else {
  //   if (!filterTerms[i + 1]) {
  //     if (isOpeningBracket) {
  //       // outputString += ')'
  //       isOpeningBracket = false
  //       return true
  //     }
  //   }
  // }
  if (
    (filterTerms[i].logic_flow === LogicFlow.AND &&
      i !== 0 &&
      filterTerms[i + 1] &&
      filterTerms[i + 1].logic_flow !== LogicFlow.AND &&
      filterTerms[i - 1] &&
      filterTerms[i - 1].logic_flow !== LogicFlow.AND) ||
    (!filterTerms[i + 1] && filterTerms.length > 2)
  ) {
    return true
  }

  return false
}

// "excludes": [
//   {
//       "match_field": "abstract",
//       "match_type": "phrase",
//       "terms": [
//           "IMMUNOGLOBULIN"
//       ]
//   },
//   {
//       "match_field": "journal_id",
//       "ids": [
//           24114,
//           24085,
//           18967
//       ]
//   },
//   {
//       "match_field": "author",
//       "terms": [
//           "BO", "DENG"
//       ]
//   },
//   {
//       "match_field": "research_area_id",
//       "ids": [
//           7
//       ]
//   },
//   {
//       "match_field": "subject_id",
//       "ids": [
//           838
//       ]
//   }
// ],

export const getActiveFeed = (
  filter: Filter,
  selectedJournals: any[],
  selectedSubjects: any[]
) => {
  const {id, name, terms, all_journals, open_only, excludes} = filter
  const keywordsTopics = []
  const authors = []
  let subjects = []
  let publications = []
  const articleTypes = []

  const getSelectedJournals = (excludedJournalsData) => {
    const journals = []
    if (!selectedJournals) {
      return []
    }
    const excludedJournalsIds = excludedJournalsData.ids
    for (let index = 0; index < selectedJournals.length; index++) {
      const element = selectedJournals[index]
      if (excludedJournalsIds.includes(element.id)) {
        journals.push({
          id: element.id,
          terms: element.name,
        })
      }
    }

    return journals
  }

  const getSelectedSubjects = (excludedSubjectsData) => {
    const subjects = []
    if (!selectedSubjects) {
      return []
    }
    const excludedSubjectsIds = excludedSubjectsData.ids
    for (let index = 0; index < selectedSubjects.length; index++) {
      const element = selectedSubjects[index]
      if (excludedSubjectsIds.includes(element.id)) {
        subjects.push({
          id: element.id,
          terms: element.name,
        })
      }
    }
    return subjects
  }

  excludes &&
    excludes.forEach((element) => {
      const {match_field} = element
      if (['abstract', 'topic', 'default'].includes(match_field)) {
        keywordsTopics.push(element)
      } else if (match_field === MatchField.author) {
        authors.push(element)
      } else if (match_field === MatchField.subjectId) {
        subjects = getSelectedSubjects(element)
      } else if (match_field === MatchField.journalId) {
        publications = getSelectedJournals(element)
      } else if (match_field === MatchField.researchAreadId) {
        articleTypes.push(element)
      }
    })
  const activeFeed = {
    id,
    // details for the feed in picture
    // in case of edit flow it would be an existing feeds data
    name, // the feed name
    isOnlyOpenAccess: open_only ?? false,
    isOnlyFollowedPublications: !all_journals ?? true,
    inclusions: terms,
    exclusions: {
      keywordsTopics,
      subjects,
      publications,
      articleTypes,
      authors,
    },
  }
  return activeFeed
}

export const transformGetKeywordsTopicApiResponse = (termsData) => {
  if (!termsData || !termsData.terms || termsData.terms.length === 0) {
    return []
  }
  return termsData.terms.map((termObj) => ({
    key: `${termObj.term}`,
    value: termObj.term_type,
    displayValue: `${termObj.term} (${termObj.term_type})`,
  }))
}

export const transformGetJournalsApiResponse = (responseData) => {
  if (
    !responseData ||
    !responseData.journals ||
    responseData.journals.length === 0
  ) {
    return []
  }
  return responseData.journals.map((journal) => ({
    key: `${journal.name}`,
    value: journal.id,
    displayValue: journal.name,
  }))
}

export const transformSubjectResponse = (responseData) => {
  if (
    !responseData ||
    !responseData.subjects ||
    responseData.subjects.length === 0
  ) {
    return []
  }
  return responseData.subjects.map((subject) => ({
    key: `${subject.name}`,
    value: subject.id,
    displayValue: subject.name,
  }))
}

export const getUserId = () => {
  return Cookies.get('userId')
}

export const getTransformedQueries = (inputData) => {
  let isOpeningBracket = false
  let tranformedData = inputData.map((data, i) => {
    // previous condition
    // left bracket
    // before (

    let isOrThere = false
    for (let j = i; j < inputData.length; j++) {
      if (inputData[j].logic_flow !== LogicFlow.AND) {
        isOrThere = true
      }
    }

    if (
      (inputData[i + 1] &&
        inputData[i + 1].logic_flow === LogicFlow.AND &&
        inputData[i].logic_flow !== LogicFlow.AND) ||
      (inputData[i + 1] &&
        inputData[i + 1].logic_flow === LogicFlow.AND &&
        isOrThere)
    ) {
      isOpeningBracket = true

      return {
        ...data,
        isOpeningParenthesis: true,
      }
    }

    // after )
    if (
      inputData[i].logic_flow === LogicFlow.AND &&
      i !== 0 &&
      inputData[i + 1] &&
      inputData[i + 1].logic_flow !== LogicFlow.AND
    ) {
      if (isOpeningBracket) {
        isOpeningBracket = false
        return {
          ...data,
          isClosingParenthesis: true,
        }
      }
    } else {
      if (!inputData[i + 1]) {
        if (isOpeningBracket) {
          isOpeningBracket = false
          return {
            ...data,
            isClosingParenthesis: true,
          }
        }
      }
    }
    return data
  })
  return tranformedData
}

export const buildCreateFeedName = (inputData): string => {
  let outputString = ''
  for (let i = 0; i < inputData.length; i++) {
    if (i !== 0) {
      outputString += ' ' + inputData[i].logic_flow + ' '
    }
    outputString += inputData[i].terms[0].toUpperCase()
    if (inputData[i].terms[1]) {
      outputString += ' ' + inputData[i].terms[1].toUpperCase()
    }
  }

  return outputString
}

export const getIsDuplicateFeedName = (
  name: string,
  filters: Filter[],
  id?: number
) => {
  let allFilters = filters

  if (id) {
    allFilters = filters.filter((filter) => filter.id != id)
  }
  const duplicateFeedName = allFilters.find(
    (filter) => filter.name.trim() === name.trim()
  )
  return !!duplicateFeedName
}

export const getIsFeedNameValid = (
  name: string,
  activeFeed: any,
  filters: Filter[]
) => {
  const {id} = activeFeed

  if (!name) {
    return false
  }
  return !getIsDuplicateFeedName(name, filters, id)
}

export const getSuffix = (matchField) => {
  if (matchField) {
    return ` (${matchFieldMap[matchField]})`
  }
  return ''
}

export const getTempExcludedTermDisplayVal = (addedSuggestion: any) => {
  return addedSuggestion.terms + getSuffix(addedSuggestion.match_field)
}

export const getTermDisplayVal = (addedSuggestion: any) => {
  const termVal =
    Array.isArray(addedSuggestion.terms) && addedSuggestion.terms[1]
      ? addedSuggestion.terms[0] + ' ' + addedSuggestion.terms[1]
      : addedSuggestion.terms
  if (
    addedSuggestion.match_field === MatchField.keyword ||
    addedSuggestion.match_field === MatchField.topic
  ) {
    return termVal + getSuffix(addedSuggestion.match_field)
  }

  return termVal
}
