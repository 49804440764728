import {css} from 'styled-components'
import React from 'react'
import Logo from 'components/shared/Logo'
import Link from 'components/shared/Link'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faWeixin,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons'
import theme from 'lib/theme'
const blenheimLogoSrc = '/static-images/landingBlenheimLogo.svg'
const spotify_icon = '/static-images/spotify_icon.svg'

const listStyle = css`
  padding: 0 20px;
  width: 160px;

  ul {
    margin-top: 15px;

    li {
      margin-bottom: 15px;
    }
  }
`

const SocialIcon = ({icon, to, staticImage = false}) => (
  <a
    css={`
      padding: 5px;
      color: ${theme.colors.cyan};
    `}
    href={to}
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    {staticImage ? (
      <img src={icon} alt={to} />
    ) : (
      <FontAwesomeIcon icon={icon} color="rgb(249, 81, 146)" />
    )}
  </a>
)

const Footer = () => {
  return (
    <footer
      css={`
        padding-top: 60px;
        background: ${theme.colors.white};

        ul {
          list-style-type: none;
        }
      `}
    >
      <div
        css={`
          margin: 0 auto;
          padding 0 5vw;
          display: grid;
          grid-template-columns: 2fr 1fr 1fr 1fr;
          @media(max-width: 870px) {
            display: flex;
            grid-template-columns: inital;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
          }
          `}
      >
        <div
          css={`
            @media (max-width: 870px) {
              flex-basis: 100%;
              padding-bottom: 20px;
            }
          `}
        >
          <div
            css={`
              transform: translateY(-30%);
            `}
          >
            <Logo withText />
          </div>
          <p>
            Scale Space,
            <br /> 58 Wood Lane, <br />
            London, <br />
            W12 7RZ
            <br />
            <a
              css={`
                color: #f95192;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              `}
              href="mailto:info@researcher-app.com"
            >
              info@researcher-app.com
            </a>
          </p>
          <div
            css={`
              margin: 10px -5px;
            `}
          >
            <SocialIcon
              icon={faLinkedin}
              to="http://bit.ly/linkedinlandingres"
            />
            <SocialIcon icon={faTwitter} to="http://bit.ly/twitterlanding" />
            <SocialIcon
              icon={faFacebookSquare}
              to="http://bit.ly/facebooklandingres"
            />
            <SocialIcon
              icon={faInstagram}
              to="http://bit.ly/instalandingresearcher"
            />
            <SocialIcon
              icon={faWeixin}
              to="https://mp.weixin.qq.com/s/B6zUHp1AGesdwb-8fCRaMQ"
            />
            <SocialIcon
              icon={faSpotify}
              to="https://open.spotify.com/show/7c3snix401sRJCuMnR10DA?si=6d554c7e1e994766"
            />
          </div>
        </div>
        <div
          css={`
            ${listStyle}
          `}
        >
          <strong>Company</strong>
          <ul>
            <li>
              <Link to="/" primary>
                Homepage
              </Link>
            </li>
            <li>
              <Link to="/discover" primary>
                Discover
              </Link>
            </li>
            <li>
              <Link to="/about" primary>
                About Us
              </Link>
            </li>
            <li>
              <Link external to="https://blog.researcher-app.com/news" primary>
                News
              </Link>
            </li>
          </ul>
        </div>
        <div
          css={`
            ${listStyle}
          `}
        >
          <strong>Information</strong>
          <ul>
            <li>
              <Link to="/login" primary>
                Create Account
              </Link>
            </li>
            {/* <li>
              <Link
                to="https://live.researcher-app.com/careers"
                external
                primary
              >
                Careers
              </Link>
            </li> */}
          </ul>
        </div>
        <div
          css={`
            ${listStyle}
          `}
        >
          <strong>Legal</strong>
          <ul>
            <li>
              <Link to="/privacy" primary>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/cookies" primary>
                Cookie Policy
              </Link>
            </li>
            <li>
              <Link to="/terms" primary>
                Terms of Usage
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        css={`
          margin: 60px auto;
          padding: 5vw;
          display: flex;
          align-items: center;
          @media (max-width: 870px) {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        <div
          css={`
            width: 180px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 870px) {
              padding-bottom: 30px;
            }
          `}
        >
          <a
            href="http://blenheimchalcot.com"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img src={blenheimLogoSrc} alt="Blenheim Chalcot" />
          </a>
        </div>
        <p
          css={`
            max-width: 500px;
            display: inline-block;
            vertical-align: top;
          `}
        >
          <strong>
            <a
              href="/"
              rel="nofollow"
              css={`
                color: inherit;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              Researcher
            </a>{' '}
            is a{' '}
            <a
              href="http://blenheimchalcot.com"
              target="_blank"
              rel="noopener noreferrer nofollow"
              css={`
                color: inherit;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              Blenheim Chalcot
            </a>{' '}
            Company
          </strong>
          {/* <br />
          Blenheim Chalcot Is The UK's Leading Venture Builder with portfolio
          sales of over £300 million, over 3,000 employees and a successful
          track record of over 40 companies. */}
        </p>
      </div>
    </footer>
  )
}

export default Footer
