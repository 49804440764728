const colors = {
  pink: 'hsl(337, 93.7%, 64.8%)',
  purple: 'hsl(255, 37.7%, 46.6%)',
  cyan: 'hsl(169, 65.5%, 70.1%)',

  white: 'hsl(0, 0%, 100%)',
  paper: 'hsl(0, 0%, 97.3%)',
  metal: 'hsl(240, 9.3%, 83.5%)',
  metala: 'hsl(240, 6.4%, 67%)',
  black: 'hsl(0, 0%, 22.3%)',
  blacka: 'hsla(0, 0%, 0%, 0.805)',

  confirm: 'hsl(148.2, 80.5%, 63.7%)',
  focus: 'hsl(211.5, 91.9%, 56.5%)',
  error: 'hsl(353.5, 67.8%, 52.5%)',

  dark: {
    pink: 'hsl(337, 82.6%, 54.3%)',
    purple: 'hsl(255, 41.5%, 34.9%)',
    cyan: 'hsl(169, 56.2%, 57.6%)',

    paper: 'hsl(240, 26.8%, 93%)',
    metal: 'hsl(240, 6.4%, 67%)',
    black: 'hsl(240, 6.4%, 0%)'
  },

  light: {
    pink: 'hsl(337, 88.6%, 93.1%)',
    purple: 'hsl(255, 100%, 92.7%)',
    cyan: 'hsl(169, 63.6%, 87.1%)',

    paper: 'hsl(240, 23.1%, 97.8%)',
    metal: 'hsl(240, 12.7%, 87.9%)'
  }
}

const purpleShadow = 'hsl(255, 99.5%, 18.4%, 0.19)'

const shadows = {
  flat: `box-shadow: 0px 2px 2px 0px ${purpleShadow}`,
  lo: `box-shadow: 2px 8px 9px 1px ${purpleShadow}`,
  mid: `box-shadow: 4px 16px 16px 2px ${purpleShadow}`,
  hi: `box-shadow: 8px 32px 30px 4px ${purpleShadow}`,
  lox: `box-shadow: 0px 8px 9px 1px ${purpleShadow}`,
  midx: `box-shadow: 0px 16px 16px 2px ${purpleShadow}`,
  hix: `box-shadow: 0px 32px 30px 4px ${purpleShadow}`
}

export { colors, shadows }
export default { colors, shadows }
