const scrollToElement = el => {
  const top = window.pageYOffset + el.getBoundingClientRect().top

  window.scrollTo({
    top: top - 120,
    behavior: 'smooth'
  })
}

export default scrollToElement
