import React from 'react'
import memoize from 'memoize-one'
import styled, {css} from 'styled-components'
import {FixedSizeList as List, areEqual} from 'react-window'
import Image from 'components/shared/Image'
import Spinner from './shared/Spinner'

const SearchIcon = '/images/inputSearchIcon.svg'

export const StyledDropdown = styled.div`
  height: 24px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: end;
  user-select: none;
  transition: box-shadow 60ms;
  border-radius: 2px;
  padding: 0 5px;

  &:after {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #323232;
  }
`

export const DropdownItem = styled.li`
  padding: 16px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 250px;
  color: #ffffff;
  font-family: 'Replica';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  ${(props) => props.selected && 'background-color: #555555;'}
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.indent && `padding-left: 36px;`}
  ${(props) => props.underline && `border-bottom: 1px solid #555555;`}

  ${({selected, fixed, underlineNoclick}) =>
    !selected &&
    !fixed &&
    !underlineNoclick &&
    css`
      &:hover {
        background-color: #f94687;
        color: white;
      }
    `}

  ${({fixed}) =>
    fixed &&
    css`
      position: absolute;
      z-index: 10;
      background-color: #323232;
      margin-top: 5px;
    `}
`

export const SearchItem = styled.div`
  padding: 6px 10px;
  cursor: default;
  user-select: none;
  position: absolute;
  z-index: 10;
  background-color: #323232;
  margin-top: 5px;
  width: ${({width}) => width};
  display: flex;
  align-items: center;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  & + ul {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

const useIsOpen = (initialState) => {
  const [isOpen, setIsOpen] = React.useState(initialState)
  const ref = React.useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return {ref, isOpen, setIsOpen}
}

const Row = React.memo(({data, index, style}) => {
  const {items, selectedIndex, itemClick} = data
  const item = items[index]

  return item.type && item.type === 'header' ? (
    <div
      css={`
        color: #939393;
        font-size: 12px;
        margin: 8px 0px 8px 16px;
        align-self: center;
        line-height: 15px;
      `}
      style={{
        ...style,
        width: '100px',
      }}
    >
      {item.name}
    </div>
  ) : (
    <DropdownItem
      selected={selectedIndex === index}
      onClick={
        item.type === 'underline-noclick'
          ? undefined
          : (e) => {
              e.preventDefault()
              itemClick(index)
            }
      }
      indent={item.type === 'indent'}
      underline={item.type && item.type.includes('underline')}
      underlineNoclick={item.type === 'underline-noclick'}
      style={{
        ...style,
        width: `100%`,
      }}
      x-track-id="dropdownItem"
      x-track-detail={JSON.stringify({dropdownItem: item.id})}
      x-track-click="true"
    >
      {item.name}
    </DropdownItem>
  )
}, areEqual)

const createItemData = memoize(
  (items, selectedIndex, itemClick, itemWidth) => ({
    items,
    selectedIndex,
    itemClick,
    itemWidth,
  })
)

const Dropdown = React.forwardRef(
  (
    {
      name,
      items,
      width,
      margin,
      disabled,
      onChange,
      searchable,
      startingValue,
      containerProps,
      dropdownMargin,
      placeholder,
      changeValue = true,
      title = false,
      scrollable = true,
      selectWidth = width,
      height = 200,
      onLabelClick,
      bold = true,
      addButton = null,
      includeAvatar = false,
      ...rest
    },
    ref
  ) => {
    console.log({items})
    const [value, setValue] = React.useState(startingValue || {})
    const [searchValue, setSearchValue] = React.useState('')
    const {isOpen, setIsOpen, ref: wrapperRef} = useIsOpen(false)
    const [filteredItems, setFilteredItems] = React.useState(items)
    const [visibleCount, setVisibleCount] = React.useState(10)
    let inputRef = ref || React.useRef(null)

    React.useEffect(() => {
      let filteredItems = items
      if (searchValue) {
        filteredItems = items.filter((item) => {
          return item.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        })
      }
      setFilteredItems(filteredItems)
    }, [searchValue, items])

    React.useEffect(() => {
      if (!isOpen) {
        setSearchValue('')
        setFilteredItems([...items])
        setVisibleCount(8)
      }
    }, [isOpen, items])

    const onSelectClick = (e) => {
      e && e.preventDefault()

      if (!disabled) {
        setIsOpen(!isOpen)
      }
    }

    const itemClick = (index) => {
      const item = filteredItems[index]
      setValue(item)
      onChange({value: item.id, name: item.name})
      setIsOpen(false)
    }

    const labelClick = (e) => {
      e.stopPropagation()

      if (e.target === inputRef.current && typeof onLabelClick === 'function') {
        onLabelClick()
      } else {
        onSelectClick(e)
      }
    }

    const selectedIndex = filteredItems.findIndex(({id}) => id === value.id)
    const itemData = createItemData(
      filteredItems,
      selectedIndex,
      itemClick,
      selectWidth
    )

    const ITEM_HEIGHT = 46
    const selectHeight =
      items.length === 0
        ? 75
        : ITEM_HEIGHT * items.length + 6 < height
        ? ITEM_HEIGHT * items.length + 6
        : height

    return (
      <div
        ref={wrapperRef}
        css={`
          height: 100%;
          ${width && `width: ${width};`}
          ${margin && `margin: ${margin};`}
        `}
        {...containerProps}
      >
        <StyledDropdown open={isOpen} onClick={labelClick}>
          <span
            css={`
              font-size: 12px;
              width: 100%;
              height: 24px;
              padding-left: 10px;
              padding-right: 16px;
              align-items: center;
              display: flex;
              pointer-events: none;
            `}
          >
            <div
              css={`
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
              `}
            >
              {title
                ? title
                : changeValue
                ? value.name || placeholder
                : placeholder}
            </div>
          </span>
          <input
            readOnly
            css={`
              width: 0;
              height: 100%;
              background: none;
              cursor: pointer;
              ${value.name && 'opacity: 0;'}

              &:focus {
                outline: none;
              }
            `}
            onClick={labelClick}
            value={value.id || ''}
            ref={inputRef}
            type="text"
            name={name}
          />
        </StyledDropdown>

        {searchable && isOpen && (
          <SearchItem width={width}>
            <Image
              css={`
                position: absolute;
              `}
              src={SearchIcon}
              width={10}
              height={10}
            />
            <input
              css={`
                width: 100%;
                height: 26px;
                background: none;
                color: #ffffff;
                ${bold && 'font-weight: bold;'}
                font-size: 14px;
                margin-left: 18px;

                &:focus {
                  outline: none;
                }
              `}
              onChange={({target}) => setSearchValue(target.value)}
              placeholder="Search..."
              type="text"
              ref={(ref) => ref && ref.focus()}
            />
          </SearchItem>
        )}

        {scrollable ? (
          <>
            <ul
              css={`
                ${!isOpen && 'display: none;'}
                opacity: ${isOpen ? '1' : '0'};
                height: ${isOpen ? selectHeight : '0'}px;
                border-radius: 3px;
                background-color: #323232;
                color: #ffffff;
                font-size: 14px;
                font-family: 'Proxima Nova';
                position: absolute;
                margin-top: ${dropdownMargin || '5px'};
                margin-bottom: 15px;
                z-index: 1;
                ${searchable && 'margin-top: 42px;'}
              `}
              x-track-id="dropdownItems"
              x-track-click="true"
            >
              {!items.length && <Spinner />}

              <List
                height={selectHeight}
                itemCount={filteredItems.length}
                itemData={itemData}
                itemSize={ITEM_HEIGHT}
                width={280}
              >
                {Row}
              </List>
              {addButton && addButton}
            </ul>
          </>
        ) : (
          <>
            <ul
              css={`
                ${!isOpen && 'display: none;'}
                opacity: ${isOpen ? '1' : '0'};
                height: ${isOpen ? 'auto' : '0px'};
                max-height: ${selectHeight}px;
                border-radius: 3px;
                color: #ffffff;
                font-size: 14px;
                font-family: 'Proxima Nova';
                position: absolute;
                margin-top: ${dropdownMargin || '5px'};
                margin-bottom: 15px;
                z-index: 1;
                ${bold && 'font-weight: bold;'}
                ${searchable && 'margin-top: 42px;'}
              `}
              x-track-id="dropdownItems"
              x-track-click="true"
            >
              {!items.length && <Spinner />}

              <div
                css={`
                  display: grid;
                  grid-auto-flow: column;
                  grid-template-rows: repeat(8, auto);
                  background-color: #323232;
                  border-radius: 3px;
                  cursor: default;
                `}
              >
                {filteredItems.slice(0, visibleCount).map((item, index) => {
                  return item.type && item.type === 'header' ? (
                    item.name && (
                      <div
                        key={index}
                        css={`
                          color: #939393;
                          font-size: 12px;
                          margin: 8px 0px 8px 16px;
                          align-self: center;
                          line-height: 15px;
                        `}
                      >
                        {item.name}
                      </div>
                    )
                  ) : (
                    <DropdownItem
                      key={index}
                      indent={item.type === 'indent'}
                      underline={item.type === 'underline'}
                      onClick={(e) => {
                        e.preventDefault()
                        itemClick(index)
                      }}
                    >
                      <div
                        css={`
                          position: relative;
                          display: inline-flex;
                          align-items: center;
                          width: 100%;
                        `}
                      >
                        <>
                          {item.type &&
                          item.type === 'all' &&
                          !item.cover_image_url ? (
                            <></>
                          ) : (
                            <div
                              css={`
                                background-image: url('${item.cover_image_url}');
                                background-color: #ddd;
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                background-size: cover;
                                background-position: center;
                                margin-right: 16px;
                              `}
                            />
                          )}
                          <span
                            css={`
                              width: calc(100% - 30px - 16px);
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            `}
                          >
                            {item.name}
                          </span>
                        </>
                      </div>
                    </DropdownItem>
                  )
                })}

                {visibleCount < filteredItems.length ? (
                  <DropdownItem
                    key={-99}
                    color="#F94687"
                    onClick={(e) => {
                      e.preventDefault()
                      setVisibleCount(filteredItems.length)
                    }}
                  >
                    More
                  </DropdownItem>
                ) : (
                  filteredItems.length > 12 && (
                    <DropdownItem
                      key={-98}
                      color="#F94687"
                      onClick={(e) => {
                        e.preventDefault()
                        setVisibleCount(10)
                      }}
                    >
                      Less
                    </DropdownItem>
                  )
                )}
              </div>
              {addButton && addButton}
            </ul>
          </>
        )}
      </div>
    )
  }
)

export default Dropdown
