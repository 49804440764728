import {decodeHTML} from 'entities'
import React from 'react'
import {findAll} from 'highlight-words-core'
import {Card, CardHeader, CardBody, CardFooter} from './FeedCardBasicV2'
import theme from '../../lib/theme'
import dayjs from 'dayjs'
import Link from './Link'
import relativeTime from 'dayjs/plugin/relativeTime'
import styled, {css} from 'styled-components'
import Cookies from 'js-cookie'
import {to64} from '../../lib/base64'
import {IconButton, MenuItem, Menu, Divider} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {moveToFolder} from '../../lib/BookmarkUtils'
import Button from 'components/shared/Button'
import Media from 'components/Media'
import muiTheme from 'lib/muiTheme'
import {useHistory} from 'react-router'
import {useState} from 'react'
import convert from 'server/datasourceConvertPrimitive'
import uuid from 'uuid/v4'
import {
  getFeedType,
  getPagename,
  pathnameToFilter,
  sendEvent,
} from 'lib/segmentHelperMethods'
// import {triggerCustomEvent} from 'lib/trackEverythingStream'

dayjs.extend(relativeTime)

const BookmarkIconPink = '/static-images/bookmarkIconPink.svg'
const BookmarkIconTick = '/static-images/bookmarkIconTick.svg'
const AddIcon = '/static-images/addIcon.png'

const recursiveHighlight = (html, searchWords) => {
  if (!html || !html.length) return ''
  const textToHighlight = html

  const chunks = findAll({
    searchWords,
    textToHighlight,
  })

  const highlightedText = chunks
    .map((chunk) => {
      const {end, highlight, start} = chunk
      const text = textToHighlight.substr(start, end - start)
      if (highlight) {
        return `<mark>${text}</mark>`
      } else {
        return text
      }
    })
    .join('')
  return highlightedText
}

const StyledBookmarkButtton = styled.button`
  outline: none;
  border-radius: 100%;
  height: 42px;
  width: 42px;
  border: 1px solid #d7d7e0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(68, 55, 133, 0.2);
  pointer-events: cursor;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(68, 55, 133, 0.4);
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
`

const StyledMenuItem = React.forwardRef((props, ref) => {
  var modifiedProps = {...props}
  if (modifiedProps.header) modifiedProps.header = undefined

  return (
    <MuiThemeProvider theme={muiTheme}>
      <MenuItem ref={ref} {...modifiedProps}>
        {props.children}
      </MenuItem>
    </MuiThemeProvider>
  )
})

const StyledMenu = (props) => {
  var modifiedProps = {...props}
  if (modifiedProps.header) modifiedProps.header = undefined
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Menu {...modifiedProps}>{props.children}</Menu>
    </MuiThemeProvider>
  )
}

const JournalPreview = ({journal, highlight}) => {
  if (!journal) return <></>

  return (
    <Link
      to={`/feed/journal/${journal.id}`}
      extraCss={`
        display: flex;
        align-items: center;
        color: ${theme.colors.blacka};
        text-decoration: none;
        line-height: 18px;
        &:hover {
          text-decoration: underline;
        }
      `}
    >
      <img
        css={`
          border-radius: 100%;
          background: grey;
          flex: none;
        `}
        src={journal.image}
        width={30}
        alt={journal?.name}
        height={30}
      />
      <div
        css={`
          line-height: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 10px;
        `}
      >
        <RenderHTML html={recursiveHighlight(journal.name, highlight)} />
        {journal.promoted && (
          <div
            css={`
              color: #9b9ba7;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 24px;
            `}
          >
            Promoted
          </div>
        )}
      </div>
    </Link>
  )
}

const setBookmarked = (bookmarked, id) => {
  const apiUrl = window.env
    ? window.env.API_URL
    : process.env.API_URL
    ? process.env.API_URL
    : 'https://api-staging.researcher-app.com'
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  var raw = JSON.stringify({
    bookmark: bookmarked,
    user_id: parseInt(Cookies.get('userId')),
    paper_ids: [id],
  })

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  return fetch(
    `${apiUrl}/v3/bookmark?auth=${Cookies.get('accessToken')}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {})
    .catch((error) => {
      console.log('error', error)
    })
}

const BookmarkButton = ({paper, onClick}) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <StyledBookmarkButtton
      disabled={loading}
      onClick={
        onClick ||
        (async () => {
          setLoading(true)
          if (paper.bookmarked_date) {
            paper.bookmarked_date = undefined
            setBookmarked(false, paper.id)
              .then(() => {
                setLoading(false)
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          } else {
            paper.bookmarked_date = Date()
            setBookmarked(true, paper.id)
              .then(() => {
                setLoading(false)
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
          }
        })
      }
      x-track-id="bookmark"
      x-track-click="true"
      x-track-detail={JSON.stringify({bookmarked: paper.bookmarked_date})}
      x-track-detail-on="paper"
    >
      <img
        css={paper.bookmarked_date && 'display: none;'}
        src={BookmarkIconPink}
        formats={['svg']}
        width={14}
        alt="Unbookmark paper"
        height={16}
      />
      <img
        css={!paper.bookmarked_date && 'display: none;'}
        src={BookmarkIconTick}
        formats={['svg']}
        width="28px"
        alt="Bookmark paper"
        height="28px"
      />
    </StyledBookmarkButtton>
  )
}

const Tag = ({tagName, children, ...props}) =>
  React.createElement(tagName, props, children)

const MetalSpan = ({children, overflow}) => (
  <span
    css={`
      color: ${theme.colors.dark.metal};
      ${overflow &&
      css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: none;
        max-width: 100%;
      `}
    `}
  >
    {children}
  </span>
)

const RenderHTML = ({html}) => (
  <span dangerouslySetInnerHTML={{__html: html}}></span>
)

const PaperCard = ({
  paper,
  halfWidth = false,
  includeAbstract = true,
  fullHeight = false,
  includePDF = false,
  scrollable,
  includeLinks,
  includeHeadline = includeAbstract,
  highlight = [],
  footerItems,
  detail,
  clickable = true,
  folders = [],
  currentFolderId = -1,
  onBookmark,
  onRemovePaperFromList,
  includeBookmarkButton = true,
  withMenu = false,
  onCreateNewFolder,
  customUrl,
  withStats = false,
  recommendedPaper = false,
}) => {
  const history = useHistory()
  const [headlineImageLoaded, setHeadlineImageLoaded] = React.useState(true)
  const [moving, setMoving] = React.useState(false)

  //different type URLs to support old graphql model and new v4 model
  const url = paper?.paperUrl || paper?.url
  const contentType = paper?.contentType || paper?.content_type
  const actionButtonText = paper?.actionButton
    ? paper?.actionButton?.text
    : paper?.action_button?.text
  const paperImageUrl =
    paper?.headlineImage?.baseURL ||
    paper?.headlineImage?.uri ||
    paper?.image_url
  const paperOpenUrl = paper?.openAccessUrl || paper?.open_url

  const isLiveEvent =
    contentType === 'Live event' || contentType === 'Drop-in event'
  const isLiveEventOrEvent = isLiveEvent || contentType === 'Event'
  const isLiveEventWithStrippedFeatures =
    isLiveEventOrEvent &&
    paper.settings &&
    (!!paper?.settings?.noAbstractHeader ||
      !!paper?.settings?.no_abstract_header)
  const format = 'webp'

  const params = {
    uri: paperImageUrl,
    format: format,
    quality: 100,
    // width: 512,
    noCache: true,
  }
  var image_url = '/image/' + to64(JSON.stringify(params)) + '.' + format
  params.uri = paper.journal
    ? paper?.journal?.cover?.baseURL || paper.journal.cover_image_url
    : null

  var cover_image_url = '/image/' + to64(JSON.stringify(params)) + '.' + format

  var journal = paper.journal
    ? {
        id: paper.journal.id,
        name: paper.journal.name,
        image: cover_image_url,
        promoted: paper.campaignId,
      }
    : null

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClicked = async (item) => {
    if (typeof item === 'string') {
      if (item === 'Create') {
        if (onCreateNewFolder) onCreateNewFolder()
      } else if (item === 'Delete') {
        setMoving(true)
        setBookmarked(false, paper.id)
          .then(() => {
            if (onRemovePaperFromList) {
              onRemovePaperFromList(paper.id)
              setMoving(false)
            }
          })
          .catch((err) => {
            console.log(err)
            setMoving(false)
          })
      }
      handleClose()
    } else {
      var folder_id = item.id
      var id = paper.id
      setMoving(true)
      var auth = Cookies.get('accessToken')
      await moveToFolder(auth, id, folder_id)
      // onRemovePaperFromList(paper.id)
      //setMoving(false)
      handleClose()
      window.location = '/library/' + (folder_id === -1 ? 'new' : folder_id)
    }
  }

  const getRequiredHtml = () => {
    if (fullHeight) {
      if (paper.media) {
        if (
          (!paper.settings || !paper.settings.no_abstract_header) &&
          paper.media &&
          fullHeight
        )
          return (
            <>
              <Media {...paper.media} />{' '}
              <RenderHTML html={paper.htmlAbstract || paper.html_abstract} />
            </>
          )
        if (paper.settings && paper.settings.no_abstract_header) {
          return (
            <>
              <Media {...paper.media} />{' '}
              <RenderHTML html={paper.htmlAbstract || paper.html_abstract} />
            </>
          )
        } else {
          return <RenderHTML html={paper.htmlAbstract || paper.html_abstract} />
        }
      } else if (paper.htmlAbstract || paper.html_abstract) {
        return <RenderHTML html={paper.htmlAbstract || paper.html_abstract} />
      } else {
        return <RenderHTML html={paper.abstract} />
      }
    }
    return <RenderHTML html={paper.abstract} />
  }

  const handleCardClick = () => {
    const linkTo =
      !clickable || open || moving
        ? undefined
        : '/paper/' +
          paper.id +
          (paper.campaignId ? '?campaignId=' + paper.campaignId : '')
    let paperId = paper

    const articleViewEvent = convert.stripUndefinedValues({
      event_page: getPagename(history.location.pathname),
      event: 'article.view',
      event_view: uuid(),
      event_label: +paperId || undefined,
      event_filter: pathnameToFilter(history.location.pathname),
      event_action: 'select',
      event_details: {
        feed_type: getFeedType(history.location.pathname),
      },
    })
    sendEvent(articleViewEvent)
    history.push(linkTo)
  }

  const trackDetailsObj = {
    paper: paper.id,
    campaignId: paper.campaignId,
    feedType: getFeedType(history.location.pathname),
    filterId: pathnameToFilter(history.location.pathname),
  }

  return (
    <Card
      paper={paper}
      halfWidth={halfWidth}
      scrollable={scrollable}
      linkTo={
        !clickable || open || moving
          ? undefined
          : '/paper/' +
            paper.id +
            (paper.campaignId ? '?campaignId=' + paper.campaignId : '')
      }
      x-track-id="paper"
      x-track-detail={JSON.stringify(trackDetailsObj)}
      x-track-view="true"
      onClick={handleCardClick}
    >
      <CardHeader
        left={<JournalPreview journal={journal} highlight={highlight} />}
        right={
          <>
            {contentType && !halfWidth && (
              <div
                css={`
                  font-size: 16px;
                  line-height: 18px;
                  background: #e3e3e3;
                  padding: 8px;
                  width: 156px;
                  text-align: center;
                  color: #000000;
                `}
              >
                {contentType}
              </div>
            )}
            {withMenu && (
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {withMenu && open && (
              <StyledMenu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                style={{zIndex: 9}}
                onClose={handleClose}
              >
                <StyledMenuItem
                  header
                  disabled
                  css={`
                    font-weight: bold !important;
                  `}
                >
                  Actions
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => handleMenuItemClicked('Delete')}
                  value={'Delete'}
                >
                  Remove bookmark
                </StyledMenuItem>
                <Divider
                  style={{background: `#555555`, marginTop: 8, marginBottom: 8}}
                />
                {folders && folders.length > 0 && (
                  <StyledMenuItem
                    header
                    disabled
                    css={`
                      font-weight: bold !important;
                    `}
                  >
                    Move to folder
                  </StyledMenuItem>
                )}
                {currentFolderId !== -1 && (
                  <StyledMenuItem
                    onClick={() => handleMenuItemClicked({id: -1})}
                    key={-1}
                    value={{id: -1}}
                  >
                    New
                  </StyledMenuItem>
                )}
                {folders &&
                  folders.map((item, index) => {
                    return (
                      <StyledMenuItem
                        onClick={() => handleMenuItemClicked(item)}
                        key={index}
                        style={{
                          display:
                            currentFolderId !== item.id ? 'block' : 'none',
                        }}
                        value={item}
                      >
                        {item.name}
                      </StyledMenuItem>
                    )
                  })}
                {folders && folders.length > 0 && (
                  <Divider
                    style={{
                      background: `#555555`,
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  />
                )}
                <StyledMenuItem
                  onClick={() => handleMenuItemClicked('Create')}
                  value={'Create'}
                  button
                >
                  <>Create new folder</>
                  <img
                    src={AddIcon}
                    width="23"
                    height="23"
                    alt="Create New Folder"
                    css={`
                      margin-left: 8px;
                    `}
                  />
                </StyledMenuItem>
              </StyledMenu>
            )}
          </>
        }
      />
      <CardBody
        header={
          <>
            {(!isLiveEventWithStrippedFeatures || !fullHeight) && (
              <MetalSpan>
                {dayjs(
                  paper.createdDate
                    ? new Date(paper.createdDate)
                    : paper.created_date * 1000
                ).fromNow()}
              </MetalSpan>
            )}
            {(!isLiveEventWithStrippedFeatures || !fullHeight) && (
              <Tag
                tagName={recommendedPaper ? 'div' : 'h1'}
                title={(!includeAbstract && paper.title) || ''}
                className="paperTitle"
                css={`
                  padding-top: 20px;
                  padding-bottom: 20px;
                  font-size: 22px;
                  letter-spacing: 0;
                  line-height: 27px;
                  font-weight: 500;
                  ${halfWidth &&
                  css`
                    flex: none;
                    max-width: 100%;
                  `}
                `}
              >
                <RenderHTML html={recursiveHighlight(paper.title, highlight)} />
              </Tag>
            )}
          </>
        }
      >
        <div
          css={`
            width: 100%;
            ${!fullHeight
              ? 'display: flex; justify-content: space-between;'
              : ''}
          `}
        >
          {(includeAbstract || includeHeadline) &&
            !!paperImageUrl &&
            (!isLiveEventWithStrippedFeatures || !fullHeight) && (
              <a
                rel="noopener noreferrer nofollow"
                href={image_url}
                target="_blank"
                css={`
                  margin-right: 20px;
                  display: flex;
                  min-width: 0;
                  align-items: center;
                  ${!fullHeight ? `background: url('${image_url}')` : ''};
                  ${!fullHeight ? 'width: 256px;' : 'width: 100%'}
                  ${!fullHeight ? 'min-height: 194px;' : ''}
                background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  img {
                    border: 1px solid ${theme.colors.metal};
                  }

                  ${!headlineImageLoaded /** Hide until loaded */ &&
                  css`
                    opacity: 0;
                    pointer-events: none;
                  `}
                `}
              >
                {fullHeight && !paper.media && (
                  <img
                    height={fullHeight ? undefined : 0}
                    css={`
                      ${!fullHeight && 'height: 0px;'}
                      max-width: 80%;
                      margin: 0 auto;
                      max-height: 320px;
                    `}
                    alt={paper?.title}
                    src={image_url}
                  />
                )}
              </a>
            )}
          <div
            css={`
              display: flex;
              flex-direction: column;
              ${!fullHeight && includeHeadline && paperImageUrl
                ? 'width: calc(100% - 256px);'
                : 'width: 100%;'} ${paperImageUrl &&
              headlineImageLoaded &&
              fullHeight &&
              !paper.media &&
              'margin-top: 16px'}
            `}
          >
            {(!isLiveEventWithStrippedFeatures || !fullHeight) && (
              <div
                className="paperAuthors"
                css={`
                  padding-bottom: 12px;
                  display: flex;
                  font-size: 14px;
                `}
              >
                <MetalSpan overflow="ellipses">
                  <RenderHTML
                    html={recursiveHighlight(
                      (paper.authors || [])
                        .map((a) => {
                          return typeof a !== 'string'
                            ? a.name
                                .replace(/&amp;#/g, '&#')
                                .replace(/&[^;]+;/g, (m) => decodeHTML(m))
                            : a
                                .replace(/&amp;#/g, '&#')
                                .replace(/&[^;]+;/g, (m) => decodeHTML(m))
                        })
                        .join(', ') || <>&nbsp;</>,
                      highlight
                    )}
                  />
                </MetalSpan>
              </div>
            )}

            {!!includeAbstract && (
              <div
                css={`
                  font-size: 14px;
                  line-height: 24px;

                  ${(halfWidth || !fullHeight) &&
                  css`
                    overflow: hidden;
                    max-height: 168px;
                    font-family: 'Replica';
                    -webkit-font-smoothing: antialiased;
                    p {
                      line-height: 24px;
                    }
                  `}
                `}
              >
                {!!actionButtonText && fullHeight && (
                  <Button
                    v2Primary
                    css={`
                      margin: 16px;
                      margin-left: 50%;
                      transform: translateX(-50%);
                    `}
                    onClick={() => window.open(customUrl || url)}
                    x-track-id="paper"
                    x-track-click="true"
                    x-track-detail={JSON.stringify({
                      ...detail,
                      paper: paper.id,
                      campaignId: paper.campaignId,
                    })}
                  >
                    {actionButtonText}
                  </Button>
                )}
                {getRequiredHtml()}
              </div>
            )}
            {!!actionButtonText && (
              <Button
                v2Primary
                css={`
                  margin: 16px 0 0 0;
                  max-width: 200px;
                `}
                onClick={() => window.open(customUrl || url)}
                x-track-id="paper"
                x-track-click="true"
                x-track-detail={JSON.stringify({
                  ...detail,
                  paper: paper.id,
                  campaignId: paper.campaignId,
                })}
              >
                {actionButtonText}
              </Button>
            )}
          </div>
        </div>

        {!!includeLinks && (
          <div
            css={`
              margin-top: 40px;
              font-size: 14px;

              a {
                color: #f94687;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }

              p:not(:first-of-type) {
                margin-top: 5px;
              }
            `}
          >
            {!!url && !isLiveEventWithStrippedFeatures && (
              <p>
                Publisher URL:{' '}
                <a
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  href={customUrl || url}
                  x-track-id="link"
                  x-track-click="true"
                >
                  {url.split('?')[0]}
                </a>
              </p>
            )}
            {!!paperOpenUrl && !isLiveEvent && (
              <p>
                Open URL:{' '}
                <a
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  href={paperOpenUrl}
                  x-track-id="openAccess"
                  x-track-click="true"
                >
                  {paperOpenUrl.split('?')[0]}
                </a>
              </p>
            )}
            {!!paper.doi && !isLiveEvent && <p>DOI: {paper.doi}</p>}
          </div>
        )}
      </CardBody>
      <CardFooter
        left={
          <div
            css={`
              display: flex;
            `}
          >
            {footerItems}
            {!!paperOpenUrl && (
              <img
                css={`
                  margin-left: 10px;
                `}
                src="/static-images/lock.svg"
                width="16"
                alt={'Open access'}
                height="21"
              />
            )}
            {withStats && paper.metrics && (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div>
                  <span
                    css={`
                      font-weight: bold;
                    `}
                  >
                    Views:{' '}
                  </span>
                  {paper.metrics.viewed_count || paper.metrics.viewedCount || 0}
                </div>
                <div style={{marginLeft: 12}}>
                  <span
                    css={`
                      font-weight: bold;
                    `}
                  >
                    Clicks:{' '}
                  </span>
                  {paper.metrics.selected_count ||
                    paper.metrics.selectedCount ||
                    0}
                </div>
                <div style={{marginLeft: 12}}>
                  <span
                    css={`
                      font-weight: bold;
                    `}
                  >
                    Bookmarks:{' '}
                  </span>
                  {paper.metrics.bookmarked_count ||
                    paper.metrics.bookmarkedCount ||
                    0}
                </div>
                <Button
                  v2Primary={true}
                  css={`
                    margin-left: 20px;
                  `}
                  onClick={() =>
                    window.open(
                      'https://live.researcher-app.com/promote-your-paper?utm_source=researcher&utm_medium=referral&utm_campaign=author-stats-page'
                    )
                  }
                >
                  Increase your Reach
                </Button>
              </div>
            )}
          </div>
        }
        right={
          <>
            {!isLiveEventOrEvent && !!(paper.pdf_url && includePDF) && (
              <a
                css={`
                  border-radius: 3px;
                  background-color: #fcc7e6;
                  color: #f94687;
                  font-size: 16px;
                  font-weight: bold;
                  text-decoration: none;
                  padding: 7px 25px;
                  margin-right: 15px;

                  &:hover {
                    box-shadow: 0 2px 6px 0 rgba(68, 55, 133, 0.2);
                  }
                `}
                rel="noopener noreferrer nofollow"
                target="_blank"
                href={
                  customUrl ||
                  (isLiveEvent ? url : paperOpenUrl || paper.pdf_url)
                }
                x-track-id="pdf"
                x-track-click="true"
              >
                {isLiveEventOrEvent ? 'Listen Now' : 'Full Text'}
              </a>
            )}
            {includeBookmarkButton && (
              <BookmarkButton paper={paper} onClick={onBookmark} />
            )}
          </>
        }
      ></CardFooter>
      {moving && (
        <div
          css={`
            width: 100%;
            height: 100%;
            background: #00000088;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
          `}
          onClick={(e) => e.preventDefault()}
        ></div>
      )}
    </Card>
  )
}

export default PaperCard
