const apiUrl = window.env ? window.env.API_URL : process.env.API_URL ? process.env.API_URL : 'https://api-staging.researcher-app.com'
export async function moveToFolder(auth, paper_id, folder_id) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ "paper_id": paper_id, "folder_id": folder_id === -1 ? null : folder_id });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  var response = await fetch(`${apiUrl}/v3/library?auth=${auth}`, requestOptions)
  return await response
}
