import React from 'react'
import Cookies from 'js-cookie'
import {Link} from 'react-router-dom'
import {Card, CardBody, CardFooter} from 'components/shared/FeedCardBasic'
import {Header, HeaderListItem} from 'components/shared/AppHeader'
import AppLayout from '../components/shared/AppLayout'
import {triggerCustomEvent} from 'lib/trackEverythingStream'

export const SettingOption = ({
  onClick,
  withArrow = true,
  label,
  link,
  color,
}) => {
  const style = `
    padding: 15px 5px;
    margin-bottom: 10px;
    text-decoration: none;
    color: ${color ? color : '#323232'};
    font-weight: bold;
    display: flex;
    cursor: pointer;
    background: none;
    text-align: left;
    font-size: 100%;

    &:active {
      outline: none;
    }
  `

  const content = (
    <>
      <span css="flex: 1;">{label}</span>
      {withArrow && (
        <span
          css={`
            width: 16px;
            height: 16px;
            border-top: 1px solid #000000;
            border-right: 1px solid #000000;
            transform: rotate(45deg);
          `}
        >
          &nbsp;
        </span>
      )}
    </>
  )

  if (!link) {
    return (
      <button css={style} onClick={onClick}>
        {content}
      </button>
    )
  }

  return (
    <Link to={link} css={style}>
      {content}
    </Link>
  )
}

export const NavigationLink = ({to, children}) => {
  return (
    <Link
      css={`
        color: #323232;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      `}
      to={to}
    >
      {children}
    </Link>
  )
}

const Settings = ({history}) => {
  return (
    <div>
      <Header
        css={`
          margin: 0 auto;
        `}
      >
        <HeaderListItem path="/settings">Settings</HeaderListItem>
      </Header>

      <Card
        center
        css={`
          margin-top: 30px;
        `}
      >
        <CardBody>
          <div
            css={`
              display: flex;
              flex-direction: column;
              font-family: 'Replica';
              margin: 80px 90px;
            `}
          >
            <SettingOption label="Manage Data" link="/settings/data" />
            <SettingOption
              label="Reference managers"
              link="/settings/reference-managers"
            />
            <SettingOption
              color="#F94687"
              label="Log Out"
              withArrow={false}
              onClick={() => {
                Cookies.remove('userId')
                Cookies.remove('accessToken')
                Cookies.remove('accessTokenExpiry')
                Cookies.remove('refreshToken')
                window.apolloClient.resetStore()
                history.push('/')
                triggerCustomEvent({type: 'logout', path: ['settings']})
              }}
            />
          </div>
        </CardBody>

        <CardFooter
          center={
            <div
              css={`
                color: #9b9ba7;
                font-family: 'Replica';
              `}
            >
              Version 1.5.1{' '}
            </div>
          }
        />
      </Card>
    </div>
  )
}

const AppPageSettings = ({history}) => {
  return (
    <AppLayout>
      <Settings history={history} />
    </AppLayout>
  )
}

export default AppPageSettings
