import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useDispatch, useSelector } from 'react-redux'
import { setScrollPos, refetchFeed } from 'store/actions/feed'
import { useInView } from 'react-intersection-observer';

import getValue from 'lib/getValue'

const updateValueBase = (obj, key, f) => {
  if (typeof obj !== 'object' || !obj || !key.length) return obj
  const head = key[0]
  const tail = key.slice(1)
  if (obj instanceof Array) obj = [...obj]
  else obj = { ...obj }
  obj[head] = tail.length ? updateValueBase(obj[head], tail, f) : f(obj[head])
  return obj
}

// updateValue(
//   {
//     viewer: {
//       feed: []
//     }
//   },
//   'viewer.feed',
//   feed => feed.concat(newPapers)
// )
const updateValue = (obj, key, f) => updateValueBase(obj, key.split('.'), f)

const DefaultLoading = ({ data, infiniteScroll }) => {
  return !data || infiniteScroll ? (
    <div
      css={`
        width: 100%;
        text-align: center;
      `}
    >
      Loading...
    </div>
  ) : null
}

const DefaultError = ({ code, error }) => {
  switch (code) {
    case 'ERROR':
      return (error && error.message) || <Redirect to="/400" />
    case 'NOT_FOUND':
      return "Sorry - we can't find that feed"
    case 'EMPTY':
      return 'No results'
    default:
      return null
  }
}

const ItemComponentCache = new Map()

// Header, Loading, Error are invoked as functions to avoid re-mounting when
// defined inline
const Feed = ({
  name,
  query,
  extractPath,
  sortCriteria,
  options = ({ skip, limit, next_cursor }) => ({ variables: { skip, limit, next_cursor }, ssr: false }),
  batchSize = 10,
  loadThreshold = 9,
  infiniteScroll = true,
  Item,
  Loading = DefaultLoading,
  Error = DefaultError
}) => {
  const dispatch = useDispatch()
  const { performRefetch } = useSelector(state => state.feed)
  const [finished, setFinished] = React.useState(false)
  const [loadCount, setLoadCount] = React.useState(batchSize)
  const [loadCompleteCount, setLoadCompleteCount] = React.useState(batchSize)
  const [loadTriggerRef, loadTriggerVisible] = useInView()
  const { data, refetch, loading, error, fetchMore } = useQuery(
    query,
    options({ skip: 0, limit: batchSize })
  )
  if (ItemComponentCache.has(Item)) {
    Item = ItemComponentCache.get(Item)
  } else {
    const _Item = Item
    Item = React.memo(_Item, (a, b) => {
      for (let i in a) if (!(i in b)) return false
      for (let i in b) if (a[i] !== b[i]) return false
      return true
    })
    ItemComponentCache.set(_Item, Item)
  }

  React.useEffect(() => {
    setLoadCount(batchSize)
    setLoadCompleteCount(batchSize)
  }, [name])

  React.useEffect(() => {
    let isSubscribed = true
    if (performRefetch[name]) {
      refetch().then(() => {
        if (isSubscribed) {
          dispatch(refetchFeed({
            refetch: false,
            feedName: name,
          }))
        }
      })
    }

    return () => isSubscribed = false
  }, [performRefetch])

  if (error) return Error({ code: 'ERROR', error })
  if (loading) return Loading({ data, loading, infiniteScroll })

  if (typeof extractPath === 'string') {
    const _extractPath = extractPath
    extractPath = () => _extractPath
  }
  const extracted = data ? getValue(data, extractPath({ data })) : undefined

  if (!extracted) return Error({ code: 'NOT_FOUND' })
  if (!extracted.length) return Error({ code: 'EMPTY' })

  const loadMoreItems = async () => {
    const skip = loadCount
    const limit = batchSize
    setLoadCount(loadCount + batchSize)
    await fetchMore({
      ...options({ skip, limit, next_cursor: skip }),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        const path = extractPath({ data: prev })
        const newData = getValue(fetchMoreResult, path)
        if (!newData.length) {
          setFinished(true)
          return prev
        }
        const updated = updateValue(prev, path, data => {
          setLoadCompleteCount(loadCompleteCount + batchSize)

          const seen = {}
          let updated
          // append new items to bottom of feed
          updated = data.concat(newData)
          // remove duplicates
          updated = updated.filter(({ id }) => {
            if (seen[id]) return false
            seen[id] = true
            return true
          })
          return updated
        })
        return updated
      }
    })
  }

  if (
    !loading &&
    loadCount === loadCompleteCount &&
    loadTriggerVisible &&
    infiniteScroll &&
    !finished
  ) {
    loadMoreItems()

  }

  const elements = extracted.sort(sortCriteria).map(data => {
    return <Item data={data} key={data.id} />
  })

  elements.splice(
    extracted.length - loadThreshold,
    0,
    <span
      css={`
        position: relative;
      `}
      key="load-trigger"
    >
      <div
        css={`
          position: absolute;
          height: 10000px;
        `}
        ref={loadTriggerRef}
      />
    </span>
  )

  return (
    <>
      {performRefetch[name] && Loading({ data, loading, infiniteScroll })}
      {!performRefetch[name] && elements}
      {!finished && loading && Loading({ data, loading, infiniteScroll })}
    </>
  )
}

const FeedContainer = ({ Header, Footer, containerProps, ...props }) => {
  var memoProps = React.useMemo(() => props, [
    props.query, props.searchValue, props.name
  ])
  const dispatch = useDispatch()
  const { scrollPos } = useSelector(state => state.feed)

  const onScroll = ({ target }) => {
    dispatch(setScrollPos({
      scrollPos: target.scrollTop
    }))
  }

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        height: 100vh;
      `}
      {...containerProps}
    >
      {!!Header && Header()}
      <div
        css={`
          overflow-y: auto;
          flex: 1;
        `}
        onScroll={onScroll}
        ref={ref => {
          if (ref) ref.scrollTop = scrollPos
          return ref
        }}
      >
        <div
          css={`
            padding: 20px 20px;
            justify-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            height: 100%;
          `}
        >
          <Feed {...memoProps} />
        </div>
      </div>
      {!!Footer && Footer()}
    </div>
  )
}

export default FeedContainer
