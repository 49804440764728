import LandingPageLayout from 'components/shared/LandingPageLayout'
import React from 'react'
import * as CoverImg from 'assets/images/commercial/newAssets/cover.png'
import * as neuroScrienceImg from 'assets/images/commercial/newAssets/neuroScienceOfSleep.png'
import * as ReportImg from 'assets/images/commercial/newAssets/report.png'
import * as researcherLivemockup from 'assets/images/commercial/newAssets/campaign_goals.png'
import * as demoImage from 'assets/images/commercial/Group 2/Group 2.png'
import * as intentProLead from 'assets/images/commercial//newAssets/researcherLivemockup.png'
import * as pdf from 'assets/images/commercial/researcherMediaKit2023.pdf'
import * as speakerImage from 'assets/images/commercial/speakerImage.png'
import {Leads} from './Leads'
import {Title} from './Title'
import {Description} from './Description'
import {DetailedMsg, Heading} from './Heading'
import {ActionBtn} from './ActionBtn'
import {Partners} from './Partners'
import {DownloadMediaKit} from './DownloadMediaKit'
import * as Styled from './Commercial.styled'

export const desktop = 'min-width:1600px'

const LandingPageCommercial = () => {
  return (
    <LandingPageLayout isCommercial>
      {/* <DownloadMediaKit /> */}
      <div
        css={`
          width: 100%;
          padding-bottom: 50px;
          line-height: 0;
          // background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
        `}
      >
        <div
          css={`
            width: 76.3%;
            margin: 0 auto;
            max-width: 1200px;
            background-color: #ffffff;
            box-shadow: 50px 50px 100px 0 rgba(0, 0, 0, 0.3);
            border-radius: 5px;
          `}
        >
          <div
            css={`
              position: relative;
              width: 100%;
            `}
          >
            <img
              src={CoverImg}
              alt="CoverImg"
              css={`
                width: 100%;
              `}
            />
            <div
              css={`
                position: absolute;
                bottom: 15%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                padding: 0px 24px;
                border: none;
                cursor: pointer;
                border-radius: 5px;
                width: 80%;
                /* display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                 */
              `}
            >
              <Heading
                heading={`Generate high-quality leads through tailored content campaigns`}
                isBackgroundDark
              />
            </div>

            <div
              css={`
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                padding: 0px 24px;
                border: none;
                cursor: pointer;
                border-radius: 5px;
                /* display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%; */
              `}
            >
              <ActionBtn
                isSmallSize={true}
                url={`https://calendly.com/speaktoresearcher/30min`}
                ctaText="Speak to the Team"
              />
            </div>
          </div>
          {/* <div
          css={`
            width: 100%;
            height: 467px;

            @media (max-width: 640px) {
              height: 120px !important;
            }
            background-image: url('${CoverImg}');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          `}
        >
          <div
            css={`
              position: relative;
              display: flex;
              height: 90%;
              align-items: end;
              justify-content: center;
            `}
          >
            <ActionBtn
              url={`https://calendly.com/speaktoresearcher/30min`}
              ctaText="Speak to the Team"
            />
          </div>
        </div> */}
          <Partners />
          <Leads />
          <div
            css={`
              margin: 50px;
            `}
          >
            {/* <Heading
              heading={`How our results-driven campaigns work`}
              isBackgroundDark={false}
            /> */}
          </div>
          <div
            css={`
              display: block;
              margin: 0 auto;
              margin-top: 90px;
            `}
          >
            {' '}
            <Styled.HowItWorksSection isBackgroundDark>
              <div
                css={`
                  width: 40%;
                  display: flex;
                  justify-content: start;
                  margin-left: 50px;
                `}
              >
                <img
                  src={researcherLivemockup}
                  alt="researcherLivemockup"
                  style={{
                    // width: '375px',
                    // height: '430px',
                    width: '100%',
                    height: 'auto',
                    transform: 'translateY(57px)',
                  }}
                />
              </div>
              <div
                css={`
                  width: 60%;
                  margin-left: 9%;
                  margin-right: 7%;
                  margin-top: 50px;
                `}
              >
                <Title
                  title={'#1 Defining your campaign goals'}
                  isBackgroundDark
                />
                <Description
                  description={`We’ve set up our life sciences team to do the heavy lifting, so you don’t have to. Each campaign has a team dedicated to the successful delivery of profiled leads. Your campaign team will include our Managing Editor, an Account Manager and a Delivery Lead. Together, we’ll curate a campaign suited to your organisational needs. Once this has been defined, agreed and signed off, you can leave the rest to us.`}
                  isBackgroundDark
                />
              </div>
            </Styled.HowItWorksSection>
            <Styled.HowItWorksSection isBackgroundDark={false}>
              <div
                // css={`
                //   width: 60%;
                //   margin-left: auto;
                //   margin-right: auto;
                //   max-width: 100%;
                // `}
                css={`
                  width: 60%;
                  margin-left: 50px;
                  margin-right: 7%;
                  margin-top: 50px;
                `}
              >
                <Title
                  title={'#2 Content creation to fuel lead generation'}
                  isBackgroundDark={false}
                />
                <Description
                  description={`Our campaigns are keyed around a unique, custom-commissioned collection of insights from high-quality PIs and scientists. All content, delivered via a multi-channel dynamic campaign, is geared towards driving engagement and high-quality leads. Our lead product, Researcher Insights, is a report created by our Managing Editorial team. Each report includes first-party trend data, op-eds, studies and views from the lab. Every report has a registered DOI to ensure that contributors receive credit for their input; ensuring that it also flows into the wider pool of research dissemination.`}
                  isBackgroundDark={false}
                />
              </div>

              <div
                css={`
                  width: 40%;
                  display: flex;
                  justify-content: end;
                  margin-right: 6%;
                `}
              >
                <img
                  src={neuroScrienceImg}
                  alt="neuroScrienceImg"
                  style={{
                    width: '100%',
                    height: 'auto',
                    // width: '375px',
                    // height: '430px',
                  }}
                />
              </div>
            </Styled.HowItWorksSection>
            <div>
              <img
                src={ReportImg}
                alt="report"
                style={{width: '100%', height: 'auto'}}
              />
            </div>
            <Styled.HowItWorksSection isBackgroundDark>
              <div
                css={`
                  width: 60%;
                  display: flex;
                  justify-content: start;
                  margin-left: 50px;
                `}
              >
                <img
                  src={intentProLead}
                  alt="intentProLead"
                  style={{
                    width: '100%',
                    height: 'auto',
                    // width: '375px',
                    // height: '430px',
                    transform: 'translateY(57px)',
                  }}
                />
              </div>
              <div
                css={`
                  width: 100%;
                  /* margin-left: 10%;
                  margin-right: 10%; */
                  margin-left: 7%;
                  margin-right: 7%;
                  margin-top: 50px;
                `}
              >
                <Title
                  title={
                    '#3 Qualifying your audience, adding depth and delivering value'
                  }
                  isBackgroundDark
                />

                <Description
                  description={
                    'We don’t deliver cold leads in a spreadsheet but nurtured and engaged prospects, enhanced with profiling and engagement data. You’ll be able to see what content your leads have been reading, researching and engaging with. Your leads will be available in real-time on your own portal within the lead delivery platform. All leads are shared on a strictly opt-in basis.'
                  }
                  isBackgroundDark
                />
              </div>
            </Styled.HowItWorksSection>
          </div>

          <div
            css={`
              max-width: 100%;
              margin: 100px;
            `}
          >
            <div
              css={`
                margin: 50px;
              `}
            >
              <Heading heading={`Our Audience`} isBackgroundDark={false} />
            </div>
            <DetailedMsg
              detailedMsg={`Researcher is a community of over 2.5 million scientists and researchers worldwide. The platform helps scientists stay up-to-date with the latest research findings in different formats: peer-reviewed journals, preprints, scientific blogs, podcasts, reports and events. Researcher is simplifying discovery and make important discussions happen. We can help you position your products and services to your customer profile when they are in research and discover mode. Learn more about our audience in our Media Kit.`}
            />
            {/* <ActionBtn url={pdf} ctaText="Download Media Kit" /> */}
            <div
              css={`
                display: flex;
                justify-content: center;
                margin: 50px;
              `}
            >
              <DownloadMediaKit />
            </div>
          </div>

          <div
            css={`
              margin: 50px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-bottom: 50px;
            `}
          >
            <Heading heading={`Speak to the Team`} isBackgroundDark={false} />
            <div
              css={`
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 35px;

                border-radius: 5px;
                background-color: #ffffff;
                box-shadow: 30px 30px 50px 0 rgba(50, 50, 50, 0.1);
                padding: 20px;
              `}
            >
              <div
                css={`
                  width: 30%;
                  display: flex;
                  justify-content: end;
                `}
              >
                <img
                  src={speakerImage}
                  alt="speakerImage"
                  // width={'40%'}
                  // height={'auto'}
                  width={122}
                  height={122}
                />
              </div>

              <div
                css={`
                  width: 70%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                `}
              >
                <DetailedMsg
                  detailedMsg={`The team has a wealth of experience across marketing, digital media, content and events in the life sciences.`}
                />
                <DetailedMsg
                  detailedMsg={`
                Book a call with a member of the commercial team to find out more about the campaigns we offer. The team will also be able to suggest campaign recommendations based on the goals you're seeking to achieve.`}
                />
              </div>
            </div>
            <div
              css={`
                display: flex;
                justify-content: center;
                margin-top: 50px;
              `}
            >
              <ActionBtn
                isSmallSize={false}
                url={`https://calendly.com/speaktoresearcher/30min`}
                ctaText="Speak to the Team"
              />
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  )
}

export default LandingPageCommercial
