/**
    Copyright Jon Baker 2019, Researcher (jon.baker@researcher-app.com)
**/
import React from 'react'
import {Component} from 'react'
import FeedCard from './FeedCardPaperV2'
import FeedCardAd from '../FeedCardAd'
import axios from 'axios'
import Cookies from 'js-cookie'
import Spinner from './Spinner'
import SearchInput from './SearchInput'
import {withRouter} from 'react-router-dom'

async function getPapers(params) {
  const getUrl = () => {
    var addition = ''
    if (params.next_cursor) {
      addition = '&next_cursor=' + params.next_cursor
    }

    if (params.journalId && params.journalId >= 0) {
      addition = addition + '&journal_id=' + params.journalId
    } else if (params.filterId && params.filterId >= 0) {
      addition = addition + '&filter_id=' + params.filterId
    }
    if (params.filterId && params.filterId === -2) {
      addition = addition + '&open_only=true'
    }
    if (params.filterId && params.filterId === -1) {
      addition = addition + '&all=true'
    }
    if (params.filterId && params.filterId === -3) {
      addition = addition + '&filter_id=all'
    }
    if (params.searchTerm) {
      addition =
        addition +
        `&sort_by=relevance&search_keyword=${encodeURI(params.searchTerm)}`
    }
    if (params.auth) addition = addition + '&auth=' + params.auth

    const apiUrl = window.env
      ? window.env.API_URL
      : process.env.API_URL
      ? process.env.API_URL
      : 'https://api-staging.researcher-app.com'
    return `${apiUrl}/v4/papers?include_journal_info=true&limit=10${addition}`
  }
  var articles = []
  var next_cursor = null
  await axios
    .get(getUrl())
    .then((response) => {
      articles.push(...response.data.data.papers)
      next_cursor = response.data.next_cursor
    })
    .catch(function (error) {
      console.log(error)
    })
  return {papers: articles, next_cursor: next_cursor}
}

async function getAds(params) {
  var response = await axios.get(
    `/api/feed-extras/${params.userId}?auth=${params.auth}`
  )
  return response ? response.data : null
}

class MainFeed extends Component {
  state = {
    articles: [],
    sending: false,
    searchTerm: this.props.searchTerm || null,
    ads: [],
    interval: 6,
    startIndex: 3,
    adPositions: [],
  }
  app = this

  getAuth() {
    var auth = Cookies.get('accessToken')
    return auth
  }

  calcAdPositions = (startIndex, interval, ads) => {
    const firstPosition = startIndex - 1
    const positions = new Map()
    var adCounter = 0
    const max = startIndex + ads.length * interval + interval
    for (var i = firstPosition; i < max; i += interval) {
      if (ads[adCounter]) {
        positions.set(i, ads[adCounter++])
      }
    }
    return positions
  }

  refreshFeed = async (query = null) => {
    this.setState(
      {...this.state, next_cursor: null, searchTerm: query, articles: []},
      () => {
        this.app.fetchInitial(query)
      }
    )
  }

  async fetchInitial(query = null) {
    if (this.app.state.sending) return

    this.app.setState({...this.app.state, sending: true}, async () => {
      if (this.props.withAds) {
        const adResponse = await getAds({
          auth: this.app.getAuth(),
          userId: Cookies.get('userId'),
        })
        const ads = adResponse.content
        if (ads && ads.length) {
          const startIndex = adResponse.start_index
          const interval = adResponse.interval
          const validAds = ads.filter(
            (ad) =>
              ad.type === 'promoted_journal' ||
              ad.type === 'promoted_paper' ||
              ad.type === 'call_for_papers'
          )
          this.app.setState({
            ...this.app.state,
            startIndex: startIndex,
            interval: interval,
            adPositions: this.calcAdPositions(startIndex, interval, validAds),
          })
        }
      }
      this.app.fetchPapers(query)
    })
  }

  async fetchNextData() {
    if (
      this.app.state.sending ||
      !this.app.state.next_cursor ||
      this.state.outOfPapers
    )
      return
    this.app.setState({...this.app.state, sending: true})
    await this.fetchPapers()
  }

  async fetchPapers(query = null) {
    const paperResponse = await getPapers({
      next_cursor: this.app.state.next_cursor,
      auth: this.app.getAuth(),
      filterId: this.props.filterId,
      journalId: this.props.journalId,
      searchTerm: query ? query : this.state.searchTerm,
    })
    const papers = paperResponse.papers
    const next_cursor = paperResponse.next_cursor
    if (papers) {
      if (this.app.state.articles)
        this.app.setState({
          ...this.app.state,
          articles: [...this.app.state.articles, ...papers],
          sending: false,
          next_cursor: next_cursor,
        })
      else
        this.app.setState({
          ...this.app.state,
          articles: papers,
          sending: false,
          next_cursor: next_cursor,
        })
    } else {
      this.app.setState({...this.app.state, sending: false, outOfPapers: true})
    }
  }

  trackScrolling = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1000
    if (
      (this.app.state.articles !== undefined ||
        this.app.state.articles != null) &&
      bottom
    ) {
      this.fetchNextData()
    }
  }

  componentDidMount() {
    this.fetchInitial()
    document
      .getElementById('scrolling-child')
      .addEventListener('scroll', this.trackScrolling)
  }

  componentWillUnmount() {
    document
      .getElementById('scrolling-child')
      .removeEventListener('scroll', this.trackScrolling)
  }

  handleOpen = () => {
    this.app.setState({...this.state, open: true})
  }

  handleClose = () => {
    this.app.setState({...this.state, open: false})
  }

  onPaperSelected = (paper) => {
    //history.push()
  }

  render() {
    const buildPapersAdCombo = () => {
      const arr = this.state.articles.filter((item) => item.paper_type !== 'ad')
      this.state.adPositions.forEach((item, key) => {
        if (key <= arr.length) {
          item.paper_type = 'ad'
          arr.splice(key, 0, item)
        }
      })
      return arr.map((item, index) => {
        if (item.paper_type === 'ad') {
          return (
            <FeedCardAd
              key={index + '_ad'}
              ad={item}
              journals={this.props.journals}
              setJournals={this.props.setJournals}
            />
          )
        } else {
          return (
            <FeedCard
              highlight={
                this.props.searchTerm
                  ? [this.props.searchTerm]
                  : this.props.searchTerm
              }
              includeAbstract={true}
              paper={item}
              key={item.id}
              onPaperSelected={this.onPaperSelected}
            />
          )
        }
      })
    }

    return (
      <>
        {this.props.searchTerm && (
          <div
            css={`
              position: relative;
            `}
          >
            <SearchInput
              initialValue={this.props.searchTerm}
              onSubmit={(value) => {
                this.refreshFeed(value)
                if (value) {
                  this.props.history.push(`/search?query=${value}`)
                }
              }}
            />
          </div>
        )}
        <div
          css={`
            margin: 32px;
          `}
        >
          {this.state.articles == null ? (
            <>No papers found.</>
          ) : (
            <>
              {this.state.articles.length === 0 ? (
                <>
                  {this.state.sending ? (
                    <div css="height: 380px; background: white; border-radius: 5px; max-width: 940px;">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {this.props.searchTerm ? (
                        <>No search results found</>
                      ) : (
                        <>No papers are available in this feed</>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {this.state.adPositions.size > 0 ? (
                    <>{buildPapersAdCombo()}</>
                  ) : (
                    <>
                      {this.state.articles.map((paper, index) => (
                        <FeedCard
                          highlight={
                            this.props.searchTerm
                              ? [this.props.searchTerm]
                              : this.props.searchTerm
                          }
                          includeAbstract={true}
                          paper={paper}
                          key={paper.id}
                          onPaperSelected={this.onPaperSelected}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    )
  }
}

export default withRouter(MainFeed)
