import styled from 'styled-components'
import {ConfigurableChipWrapper} from 'components/FeedV2/shared/ConfigurableChipWrapper/ConfigurableChipWrapper'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Filter} from 'components/FeedV2/interfaces/api'
import {
  deleteUserFilter,
  setActiveFeed,
  updateManageFeedType,
} from 'store/actions/feedManager'
import {
  getActiveFeed,
  getConfirmDialogConfig,
  getFormSubmitBtnConfig,
  getUserId,
  TranslationKeys,
} from 'components/FeedV2/util/util'
import {useEffect} from 'react'
import GenericConfirmDialog from 'components/FeedV2/CustomDialog/CustomDialog'
import Typography from '@material-ui/core/Typography'
import CustomModal from 'components/FeedV2/CustomDialog/CustomModal'
import {CustomModalHeader, ModalSize} from 'components/FeedV2/CustomDialog'
import {CustomModalFooter} from 'components/FeedV2/CustomDialog/CustomModalFooter'
import {BtnText} from 'components/FeedV2/interfaces/types'
import {useHistory} from 'react-router-dom'

type ExistingFeedsProps = {
  filters: Filter[]
  selectedJournals: any[]
  selectedSubjects: any[]
  onDeleted: () => void
}

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const ConfigurableChipWrapperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  justify-content: flex-start;
  row-gap: 10px;
  gap: 19.5px;
  margin-left: auto;
  margin-right: auto;
`
// overflow-y: auto; put for scroll if needed
export const ExistingFeeds = ({
  filters,
  selectedJournals,
  selectedSubjects,
  onDeleted,
}: ExistingFeedsProps) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const [deleteFilterData, setDeleteFilterData] = useState(null)

  const handleDelete = (filter) => {
    setDeleteFilterData({
      id: filter.id,
      name: filter.name,
    })
    //  dispatch(deleteUserFilter(getUserId(), filter.id))
  }

  const onConfirm = async () => {
    await dispatch(deleteUserFilter(getUserId(), deleteFilterData.id))
    const filterId = history.location.pathname.split('/')[2]
    if (filterId == deleteFilterData.id) {
      // history.push('/feed/all')
      //  window.location.reload()
      setDeleteFilterData(null)
      onDeleted()
      history.replace({
        pathname: window.location.pathname,
        search: 'refresh',
      })
    } else {
      setDeleteFilterData(null)
      onDeleted()
    }
  }

  const onClose = () => {
    setDeleteFilterData(null)
  }

  const handleClick = (filter) => {
    dispatch(
      setActiveFeed(getActiveFeed(filter, selectedJournals, selectedSubjects))
    )
    dispatch(updateManageFeedType(1))
  }

  const handleAction = (btnText) => {
    if (btnText === BtnText.YES) {
      onConfirm()
    } else {
      onClose()
    }
  }

  const ConfirmDialogBody = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '275px',
      }}
    >
      <Typography variant="body1" style={{color: '000000', fontSize: '15px'}}>
        Are you sure you want to
      </Typography>
      <Typography variant="body1" style={{color: '000000', fontSize: '15px'}}>
        {/* delete "{deleteFilterData.name}" feed? */}
        delete this feed?
      </Typography>
    </div>
  )

  return (
    <Center>
      <ConfigurableChipWrapperContainer>
        {filters.map((feed) => (
          <React.Fragment key={feed.id}>
            <ConfigurableChipWrapper
              children={feed.name}
              isClickable
              filter={feed}
              onClick={handleClick}
              onDelete={handleDelete}
              width={'357px'}
              //   isBoxShadow
              height={'50px'}
              //    backgroundColor={'#F8F8F8'}
            />
          </React.Fragment>
        ))}

        {deleteFilterData && (
          // <GenericConfirmDialog
          //   minWidthPercentage={50}
          //   title="Delete post"
          //   open
          //   onConfirm={onConfirm}
          //   onClose={onClose}
          //   confirmBtnText="Yes"
          //   closeBtnText="Cancel"
          // >
          //   <div
          //     style={{
          //       display: 'flex',
          //       alignItems: 'center',
          //       justifyContent: 'center',
          //       flexDirection: 'column',
          //     }}
          //   >
          //     <Typography variant="body2" color="textSecondary">
          //       Are you sure you want to
          //     </Typography>
          //     <Typography variant="body2" color="textSecondary">
          //       delete DNA feed?
          //     </Typography>
          //   </div>
          // </GenericConfirmDialog>

          <CustomModal
            header={<CustomModalHeader title={null} size={ModalSize.sm} />}
            // Consideration for multiple props
            body={<ConfirmDialogBody />}
            size={ModalSize.sm}
            footer={
              <CustomModalFooter
                primaryBtnConfig={getConfirmDialogConfig(
                  TranslationKeys.yes,
                  false
                )}
                secondaryBtnConfig={getConfirmDialogConfig(
                  TranslationKeys.cancel,
                  false
                )}
                handleAction={handleAction}
                size={ModalSize.sm}
              />
            }
          />
        )}
      </ConfigurableChipWrapperContainer>
    </Center>
  )
}
