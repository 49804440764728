import {css} from 'styled-components'
import React from 'react'
import theme from 'lib/theme'

const backgroundStyle = {
  paperGradient: css`
    background: linear-gradient(
      to bottom,
      ${theme.colors.white} 0%,
      ${theme.colors.paper} 100%
    );
  `,
  purple: css`
    background: ${theme.colors.purple};
  `
}

const BackgroundColor = ({children, background, customCss}) => {
  return (
    <div
      css={`
        position: relative;
        ${customCss}
      `}
    >
      <div
        css={`
          z-index: -2;
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: -1500px;
          right: -1500px;
          ${backgroundStyle[background]}
        `}
      />
      {children}
    </div>
  )
}

export default BackgroundColor
