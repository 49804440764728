import styled, {css} from 'styled-components'

export const HowItWorksSection = styled.section<{isBackgroundDark}>`
  display: flex;
  justify-content: flex-start;
  // column-gap: 50px;
  align-items: flex-start;
  margin-bottom: 50px;
  // padding: 10px;
  background: ${(p) => (p.isBackgroundDark ? '#4a4a4a' : '#FFFFFF')};
`
