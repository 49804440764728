if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
  .then((registrations) => {
    if (registrations.length > 0) {
      for(let registration of registrations) {  
        registration.unregister()
      }
      
      document.location.href = 'https://www.researcher-app.com'
    }
  })
}
