import React from 'react'
import styled from 'styled-components'

type StepperProps = {
  step: number
}

const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

const StepperContainer = styled.ul`
  position: relative;
  z-index: 1;
`

const StepperList = styled.li`
  width: 0.5em;
  height: 0.5em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background: #f94687;
  margin: 0 5vw 0 5vw;
  display: inline-block;
  color: white;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0.2em;
    left: -10vw;
    width: 10vw;
    height: 0.2em;
    background: #f94687;
    z-index: -1;
  }
  &:nth-child(1):before {
    display: none;
  }

  &.active {
    background: #f94687;
  }

  &.active ~ li {
    background: #d8d8d8;
  }

  &.active ~ li:before {
    background: #d8d8d8;
  }
`

const Container = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  border-top: 5px solid #d8d8d8;
  padding-top: 15px;
  margin-top: 15px;
`

type CircleProps = {
  isReached: boolean
}
const Circle = styled.div<CircleProps>`
  background-color: ${(props) => (props.isReached ? `#F94687` : `#d8d8d8`)};
  font-weight: bold;
  margin-top: -25px;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 50%;
`

export const Stepper = ({step}: StepperProps) => {
  return (
    <StepperContainer>
      {[0, 1, 2].map((item, ind) => (
        <StepperList className={ind === step ? 'active' : undefined}>
          {/* {ind + 1} */}
        </StepperList>
      ))}
    </StepperContainer>
  )
}
