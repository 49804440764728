import {
  ONBOARDING_STEP_UPDATE,
  ONBOARDING_UPDATE_SELECTION,
  RESET_ONBOARDING_STEP,
  ONBOARDING_SET_RESEARCH_AREAS,
  ONBOARDING_REMOVE_SELECTION,
  ONBOARDING_SET_ROLES,
  ONBOARDING_INIT_PUBLICATIONS,
  ONBOARDING_INIT_FEEDS,
  ONBOARDING_SET_LOADING,
  ONBOARDING_UNSET_LOADING,
  ONBOARDING_STEP_UPDATE_TWICE,
  ONBOARDING_GO_BACK,
  ONBOARDING_INIT_TOPICS,
  ONBOARDING_UPDATE_TOPICS,
  ONBOARDING_SET_TOPICS_CURSOR,
  ONBOARDING_SET_TOPICS_FETCHING_NEXT,
  RESET_ONBOARDING_STATE
} from '../mutation-types'

const getInitialState = () => {
  const initialState = {
    stepsList: [
      {
        stepId: "type",
        stepName: "What kind of researcher are you?", stepDesc: "",
        options:[]
      },
      {
        stepId: "Role", 
        stepName: "What role describes you the best?",
        stepDesc: "",
        options:[]
      },
      {
        stepId: "Org",
        stepName: "Where do you work or study?",
        stepDesc: ""
      },
      {
        stepId: "ra",
        stepName: "Research Areas",
        stepDesc: "Select the research areas you are interested in",
        options: [

        ]
      },
      {
        stepId: "topics",
        stepName: "Topics",
        stepDesc: "Select the topics you are interested in",
        options: []
      },
      {
        stepId: "publications",
        stepName: "Publications",
        stepDesc: "We have selected some popular publications for you to follow",
        options: [],
      },
      {
        stepId: "feeds",
        stepName: "Feeds",
        stepDesc: "We have created this feed based on your interests, you can edit and add more from the side menu",
        options:[]
      },
    ],
    step: 1,
    loading: false,
    loadingText: "Loading...",
    selections: [
      {name:"type", selection:null, type:"single", mandatory:true},
      {name:"role",selection:null, type:"single", mandatory:true},
      {name:"work_study",selection:null, type:"single", mandatory:false},
      {name:"ra",selection:[], type:"multiple", mandatory:true},
      {name:"topics",selection:[], type:"multiple", mandatory:true},
      {name:"publications",selection:[], type:"multiple", mandatory:false},
      {name:"feeds",selection:[], type:"multiple", mandatory:false},
    ],
    topicsNextCursor: null, // response.data.next_cursor
    topicsFetchingNext: false
  }
  return initialState;
}

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_ONBOARDING_STATE: // When onboarding is done, reset the store to have initial state
      return getInitialState();
    case ONBOARDING_SET_TOPICS_CURSOR:
      return { ...state, topicsNextCursor:action.payload.nextCursor }
    case ONBOARDING_SET_TOPICS_FETCHING_NEXT:
      return { ...state, topicsFetchingNext:action.payload.fetchingNext }
    case ONBOARDING_SET_LOADING:
      return { ...state, loading: true, loadingText:action.payload.text?action.payload.text:state.loadingText }
    case ONBOARDING_UNSET_LOADING:
      return { ...state, loading: false, loadingText:"loading..." }
    case ONBOARDING_STEP_UPDATE:
      return { ...state, step: state.step + 1, previousStep: state.step }
    case ONBOARDING_STEP_UPDATE_TWICE:
      return { ...state, step: state.step + 2, previousStep: state.step }
    case ONBOARDING_GO_BACK:
      // Need to write logic to not use numbers
      // Adding condition to handle going from 3->1, by skipping 2, in case of single role selection
      if(state.selections[state.step-1].name == "work_study"){ // if step in university
        if(state.stepsList[state.step-2].options.length == 1){ // and if only one role option
          return { ...state, step: state.step - 2 } // go back two steps
        }
      }
      return { ...state, step: state.step - 1 } // go back single step
    case RESET_ONBOARDING_STEP:
        return  { ...state, step: null }
    case ONBOARDING_UPDATE_SELECTION:{
      let tempSelections = [...state.selections];
      let tempStepsList = [...state.stepsList]; // only step 1 case
      let selObj = tempSelections[state.step - 1];
      let optObj = tempStepsList[state.step - 1];
      if(selObj.type == "single"){
        selObj.selection = action.payload.value;

        // Handle update of role based on parent level
        if(tempStepsList[state.step - 1].stepId == "type"){
          // Inside if Role Parent step
          tempStepsList[state.step - 1].options.forEach((option)=>{
            if(option.id == action.payload.value){

              let newOptions = option.children.map((obj)=>{
                return {
                  id: obj.id,
                  name: obj.name,
                  image: obj.image_url,
                  imageSelected: obj.selected_image_url,
                }
              });
              
              // Update the options
              tempStepsList[state.step].options  = newOptions; 

              //Also update the selections if the length is only 1
              if(newOptions.length == 1){
                tempSelections[state.step].selection = newOptions[0].id
              }
              else{
                tempSelections[state.step].selection = null
              }
            }
          })
        }
      }
      else if(selObj.type == "multiple"){
        // Handling publications case when they would added from search as well
        if(tempStepsList[state.step - 1].stepId == "publications"){
          if(action.payload && action.payload.from && action.payload.from == "search"){
            
            // set extra prop to identify this option coming from search 
            action.payload.value["uiFromSearch"] = true;

            // In case of search below three cases need to be handled:
            // 1. Available in main list, and selected ** This will be handled in remove flow
            
            // 2. Available in main list, and not selected 
            let selPos;
            optObj.options.forEach((obj,i)=>{
                if(action.payload.value.id == obj.id){
                  selPos = i;
                }
            });
            if(!selPos) { // 2
              optObj.options.push(action.payload.value); // 3  
            }

            // 3. Not available in main list
            // optObj.options.push(action.payload.value); // 3
            
          }
        }
        selObj.selection.push(action.payload.value); // add value to selection
      }
      
      // handle the removal of selections and options if parent step updated
      if(optObj.stepId == "ra" || optObj.stepId == "topics"){
        if(optObj.stepId == "ra"){
          // empty topics
          tempStepsList[4].options = [];
          tempSelections[4].selection = [];    
        }
        // Empty Publications
        tempStepsList[5].options = [];
        tempSelections[5].selection = [];
        // Empty Feeds
        tempStepsList[6].options = [];
        tempSelections[6].selection = [];
      }

      return  { ...state, selections:[...tempSelections], stepsList: [...tempStepsList] }
    }
    case ONBOARDING_REMOVE_SELECTION:{
      let tempSelections = [...state.selections];
      let tempStepsList = [...state.stepsList];
      let selObj1 = tempSelections[state.step - 1];
      let stepObj1 = tempStepsList[state.step - 1];
      if(selObj1.type == "single"){
        selObj1.selection = null;
      }
      else if(selObj1.type == "multiple"){
        let pos;
        let currStep = tempStepsList[state.step - 1].stepId;
        selObj1.selection.forEach((obj,i)=>{
          if(currStep == "topics"){
            if(action.payload.value.topic == obj.topic){
              pos = i;
            }
          }
          else if(currStep == "publications"){
            if(action.payload.value.id == obj.id){
              pos = i;
            }
          }
          else{
            if(action.payload.value == obj){
              pos = i;
            }
          }
          
        });
        if(pos || pos==0 ) { selObj1.selection.splice(pos, 1) }

        // Handling publications case when they would remove from search as well
        if(tempStepsList[state.step - 1].stepId == "publications" && action?.payload?.from == "search"){
          // Remove the publication under picture from the options
          // But only if it was added via the popup
          let pos;
          stepObj1.options.forEach((obj,i)=>{
            if(action?.payload?.value?.id == obj?.id){
              pos = i;
            }
          });
          if(pos || pos==0 ) { 
            // This could not have been added from search as well
            // if yes, check and do nothing 
            if(stepObj1.options[pos] && stepObj1.options[pos]["uiFromSearch"]){
              stepObj1.options.splice(pos, 1) 
            }
          }
          
          // Remove the publication under picture from the selections
          let selPos;
          selObj1.selection.forEach((obj,i)=>{
            if(action?.payload?.value?.id == obj?.id){
              selPos = i;
            }
          });
          if(selPos || selPos==0 ) { 
            selObj1.selection.splice(selPos, 1) 
          }
        }

        // handle the removal of selections and options if parent step updated
        if(stepObj1.stepId == "ra" || stepObj1.stepId == "topics"){
          if(stepObj1.stepId == "ra"){
            // empty topics
            tempStepsList[4].options = [];
            tempSelections[4].selection = [];
          }
          // Empty Publications
          tempStepsList[5].options = [];
          tempSelections[5].selection = [];
          // Empty Feeds
          tempStepsList[6].options = [];
          tempSelections[6].selection = [];
        }
      }
      return  { ...state, selections:[...tempSelections], stepsList: [...tempStepsList] }
    }
    case ONBOARDING_SET_RESEARCH_AREAS: {// handles roles as well
      let tempStepsList = [...state.stepsList];
      tempStepsList[state.step-1]["options"] = action.payload.options;
      return  { ...state, stepsList:[...tempStepsList] }
    }
    case ONBOARDING_SET_ROLES:{
      let tempStepsList = [...state.stepsList];
      tempStepsList[state.step-1]["options"] = action.payload.options;
      return  { ...state, stepsList:[...tempStepsList] }
    }
    case ONBOARDING_INIT_PUBLICATIONS:{
      let tempStepsList = [...state.stepsList];
      let tempSelections = [...state.selections];
      tempStepsList[state.step-1]["options"] = [...action.payload.options];
      tempSelections[state.step-1]["selection"] = [...action.payload.options];
      return  { ...state, stepsList:[...tempStepsList], selections:[...tempSelections] }
    }
    case ONBOARDING_INIT_FEEDS:{
      let tempStepsList = [...state.stepsList];
      let tempSelections = [...state.selections];
      tempStepsList[state.step-1]["options"] = [...action.payload.options];
      tempSelections[state.step-1]["selection"] = [...action.payload.options];
      return  { ...state, stepsList:[...tempStepsList], selections:[...tempSelections] }
    }
    case ONBOARDING_INIT_TOPICS:{
      let tempStepsList = [...state.stepsList];
      tempStepsList[state.step-1]["options"] = [...action.payload.options];
      return  { ...state, stepsList:[...tempStepsList] }
    }
    case ONBOARDING_UPDATE_TOPICS:{
      let tempStepsList = [...state.stepsList];
      let topicOptions = tempStepsList[state.step-1]["options"];
      tempStepsList[state.step-1]["options"] = [...topicOptions, ...action.payload.options];
      return  { ...state, stepsList:[...tempStepsList] }
    }
    default:
      return state
  }
}
