import React, {useEffect, useState} from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  TextField,
} from '@material-ui/core'
import AutoComplete from '../autocomplete/autoComplete'
import {useDebounce} from 'use-debounce'
import {getKeywordsTopicList} from 'components/FeedV2/util/api'
import {
  transformGetKeywordsTopicApiResponse,
  TranslationKeys,
} from 'components/FeedV2/util/util'
import {
  excludeItem,
  includeKeywordsTopicsAuthors,
  updateIncludeKeywordTopicAuthor,
  updateSubmitBtnDisabledState,
} from 'store/actions/feedManager'
import {useDispatch, useSelector} from 'react-redux'
import {LogicFlow} from 'components/FeedV2/interfaces/api'
import {ExclusionArrayName, Matchtype} from 'components/FeedV2/interfaces/types'
import {TemporaryExcludedItemList} from '../TemporaryExcludedItemList/TemporaryExcludedItemList'
import {BtnType, BtnVariant, CustomButton} from '../CustomButton/CustomButton'

export enum Match {
  EXACT = 'exact',
  PHRASE = 'phrase',
  DEFAULT = 'default',
}

enum MatchField {
  TOPIC = 'topic',
  KEYWORD = 'abstract',
}

// type? keyword / topic
// Whether phrase or exact both together?
// id?
type CreateEditKeywordProps = {
  searchedTerm?: string
  matchField?: string
  selectedMatchType?: Matchtype
  isFromExcluded?: boolean
  index?: number
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(4),
    },
  },
  createEditKeywordTopic: {
    margin: '0 auto',
    overflow: 'hidden',
    height: '300px',
    width: '100%',
    position: 'relative',
  },
  title: {
    padding: theme.spacing(3, 0),
  },

  formGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '5%',
  },
  autoCompleteForm: {
    width: '60%',
    margin: '0 auto',
  },
}))

export const CreateEditKeyword = ({
  searchedTerm,
  matchField,
  selectedMatchType,
  isFromExcluded = false,
  index,
}: CreateEditKeywordProps) => {
  const dispatch = useDispatch()
  const {activeFeed} = useSelector((state: any) => state.feedManager)
  const [selected, setSelected] = useState(null)

  const classes = useStyles()
  const [inputValue, setInputValue] = useState(searchedTerm || '')
  // Searching status (whether there is pending API request)
  const [isSearching, setIsSearching] = useState(true)
  const [debounceInputValue] = useDebounce(inputValue, 500)
  const [suggestions, setSuggestions] = useState([])
  const [matchType, setMatchType] = useState(
    selectedMatchType || Matchtype.DEFAULT
  )

  const [matchFieldName, setMatchFieldName] = useState(matchField || null)
  const [addedSuggestions, setAddedSuggestions] = useState([])

  let listToSend = []

  // Handle Change
  const handleChange = (e) => {
    setInputValue(e.target.value)
  }

  // Effect for API call
  useEffect(
    () => {
      const getKeywordsTopics = async () => {
        if (debounceInputValue) {
          setIsSearching(true)
          const results = await getKeywordsTopicList(debounceInputValue)
          setSuggestions(transformGetKeywordsTopicApiResponse(results))
          setIsSearching(false)
        } else {
          // setResults([])
          //  setIsSearching(false)
        }
      }

      getKeywordsTopics() // run it, run it

      return () => {
        // this now gets called when the component unmounts
      }
    },
    [debounceInputValue] // Only call effect if debounced search term changes
  )

  // Handle Select
  const handleSelect = (selectedData) => {
    if (isFromExcluded) {
      setSelected(selectedData)
      //  setInputValue('')
    }
    setInputValue(selectedData.key)
    //  setMatchFieldName(selectedData.value)
    setMatchFieldName(
      selectedData.value === MatchField.TOPIC
        ? MatchField.TOPIC
        : MatchField.KEYWORD
    )
    // if (selectedData.value === MatchField.TOPIC) {
    //   setMatchType(Matchtype.EXACT)
    // } else {
    //   setMatchType(Matchtype.PHRASE)
    // }

    // if (isFromExcluded) {
    //   const findSuggestedItemInList = activeFeed.exclusions.keywordsTopics
    //     .concat(addedSuggestions)
    //     .find(
    //       (addedSuggestion) =>
    //         addedSuggestion.displayValue === selectedData.displayValue
    //     )

    //   if (!findSuggestedItemInList) {
    //     const {key, ...rest} = selectedData
    //     let payload = {
    //       match_field: matchFieldName || MatchField.KEYWORD,
    //       // selectedData.value === MatchField.TOPIC
    //       //   ? MatchField.TOPIC
    //       //   : MatchField.KEYWORD,
    //       match_type: matchType,
    //       // selectedData.value === MatchField.TOPIC
    //       //   ? Matchtype.EXACT
    //       //   : Matchtype.PHRASE,
    //       terms: [key],
    //       ids: [],
    //       ...rest,
    //     }
    //     setAddedSuggestions([payload, ...addedSuggestions])
    //   }
    // }
  }

  const changeMatchType = (e) => {
    e.persist()
    const {checked, name} = e.target
    if (checked) {
      setMatchType(name)
      // setMatchFieldName(
      //   name === Matchtype.EXACT ? MatchField.TOPIC : MatchField.KEYWORD
      // )
    } else {
      setMatchType(Matchtype.DEFAULT)
    }
  }

  useEffect(() => {
    const isValid = !!inputValue
    if (isFromExcluded) {
      dispatch(updateSubmitBtnDisabledState(addedSuggestions.length === 0))
    } else {
      dispatch(updateSubmitBtnDisabledState(!isValid))
    }
  }, [inputValue, dispatch, isFromExcluded, addedSuggestions.length])

  const buildExcludePayload = () => {
    dispatch(excludeItem(addedSuggestions, ExclusionArrayName.KEYWORDSTOPIC))
  }

  const buildIncludePayload = () => {
    let payload = {
      match_field: matchFieldName || MatchField.KEYWORD,
      match_type: matchType,
      logic_flow: LogicFlow.AND,
      sort_index: 0,
      terms: [inputValue.toUpperCase()],
    }
    if (activeFeed && activeFeed?.inclusions?.length > 0) {
      payload = {
        ...payload,
        logic_flow: activeFeed.inclusions[index]
          ? activeFeed.inclusions[index].logic_flow
          : LogicFlow.OR,
        sort_index: index != null ? index : activeFeed?.inclusions?.length,
      }
    }
    listToSend = [...listToSend, payload]
    if (index != null) {
      dispatch(updateIncludeKeywordTopicAuthor(payload))
    } else {
      dispatch(includeKeywordsTopicsAuthors(listToSend))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (isFromExcluded) {
      buildExcludePayload()
    } else {
      buildIncludePayload()
    }

    // terms: [
    //   {
    //     terms: ['chemistry'],
    //     id: 13107,
    //     match_type: 'default',
    //     logic_flow: 'AND',
    //     sort_index: 0,
    //     match_field: 'topic',
    //   },
    // ],
  }

  const handeleDelete = (data, index) => {
    setAddedSuggestions(
      addedSuggestions.filter((addedSuggestion, ind) => ind !== index)
    )
  }

  const onClearInput = () => {
    setInputValue('')
    setMatchType(Matchtype.DEFAULT)
  }

  const addTerm = () => {
    let selectedTerm = selected
    if (selectedTerm == null) {
      selectedTerm = {
        displayValue: inputValue,
        key: inputValue,
        value: MatchField.KEYWORD,
      }
    }
    const findSuggestedItemInList = activeFeed.exclusions.keywordsTopics
      .concat(addedSuggestions)
      .find(
        (addedSuggestion) =>
          addedSuggestion.displayValue === selectedTerm.displayValue
      )

    if (!findSuggestedItemInList) {
      const {key, ...rest} = selectedTerm
      let payload = {
        match_field: matchFieldName || MatchField.KEYWORD,
        // selectedData.value === MatchField.TOPIC
        //   ? MatchField.TOPIC
        //   : MatchField.KEYWORD,
        match_type: matchType,
        // selectedData.value === MatchField.TOPIC
        //   ? Matchtype.EXACT
        //   : Matchtype.PHRASE,
        terms: [key],
        ids: [],
        ...rest,
      }
      setAddedSuggestions([payload, ...addedSuggestions])

      setInputValue('')
      setSelected(null)
      setMatchFieldName(null)
      setMatchType(Matchtype.DEFAULT)
    }
  }

  return (
    // <Paper className={classes.paper}>
    <div className={classes.createEditKeywordTopic}>
      <form
        id="submit-form"
        className={classes.autoCompleteForm}
        onSubmit={handleSubmit}
      >
        <AutoComplete
          options={suggestions}
          inputValue={inputValue}
          handleChange={handleChange}
          handleSelect={handleSelect}
          clearSearch={onClearInput}
          isSearching={isSearching}
          placeholder={TranslationKeys.searchForKeywordsOrTopics}
        />

        {isFromExcluded && (
          <div style={{position: 'absolute', right: '32px', top: '16px'}}>
            <CustomButton
              children={`${TranslationKeys.add}`}
              onClick={addTerm}
              backgroundColor="#F94687"
              color="#FFFFFF"
              height="30px"
              width="66px"
              btnVariant={BtnVariant.CONTAINED}
              type={BtnType.BUTTON}
              isDisabled={!!!inputValue}
            />
          </div>
        )}

        {matchFieldName !== MatchField.TOPIC && (
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              name={Matchtype.EXACT}
              control={<Checkbox />}
              label={TranslationKeys.exactMatch}
              onChange={changeMatchType}
              checked={matchType === Matchtype.EXACT}
              disabled={!!!inputValue}
            />
            <FormControlLabel
              name={Matchtype.PHRASE}
              control={<Checkbox />}
              label={TranslationKeys.phraseMatch}
              onChange={changeMatchType}
              checked={matchType === Matchtype.PHRASE}
              disabled={!!!inputValue}
            />
          </FormGroup>
        )}
      </form>
      <TemporaryExcludedItemList
        addedSuggestions={addedSuggestions}
        onDelete={(data, index) => handeleDelete(data, index)}
      />
    </div>
  )
}

export const MemoizedCreateEditKeyword = React.memo(CreateEditKeyword)
