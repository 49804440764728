import palette from './Palette';

export default {
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '0.39px',
    lineHeight: '40px'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '29px',
    letterSpacing: '0.26px',
    lineHeight: '32px'
  },
  h3: {
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '0.13px',
    lineHeight: '28px'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '18px',
    letterSpacing: '0.13px',
    lineHeight: '24px'
  },
  // h5: {
  //   color: palette.text.primary,
  //   fontWeight: 500,
  //   fontSize: '16px',
  //   letterSpacing: '-0.05px',
  //   lineHeight: '20px'
  // },
  h6: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '0.13px',
    lineHeight: '20px'
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '0.13px',
    lineHeight: '25px'
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '0.13px',
    lineHeight: '21px'
  },
  body1: {
    color: '#323232',
    fontFamily: 'Replica',
    fontSize: '15px',
    letterSpacing: '0.13px',
    lineHeight: '21px'
  },
  body2: {
    color: 'rgb(46, 56, 77)',
    fontSize: '15px',
    letterSpacing: '0.13px',
    lineHeight: '18px',
    fontFamily: `'ReplicaLight'`
  },
  button: {
    color: palette.text.primary,
    fontSize: '15px',
    letterSpacing: '0.23px',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '14px',
    letterSpacing: '0.33px',
    lineHeight: '13px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  }
};
