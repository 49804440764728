import {
  SET_SCROLL_POS,
  SET_OPEN_ACCESS,
  REFETCH_FEED,
} from '../mutation-types'

export const setScrollPos = ({scrollPos}) => {
  return {
    type: SET_SCROLL_POS,
    scrollPos,
  }
}

export const enableOpenAccess = () => {
  return {
    type: SET_OPEN_ACCESS,
    enabled: true
  }
}

export const disableOpenAccess = () => {
  return {
    type: SET_OPEN_ACCESS,
    enabled: false
  }
}

export const refetchFeed = ({refetch, feedName}) => {
  return {
    type: REFETCH_FEED,
    refetch: !!refetch,
    feedName
  }
}