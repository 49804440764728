import getValue from 'lib/getValue'

const convertInt = v => {
  v = parseInt(v, 10)
  return isNaN(v) ? undefined : v
}

const convertString = v => {
  if (typeof v === 'string') return v
  if (typeof v === 'number') return v.toString(10)
  return undefined
}

const convertDatestring = v => {
  if (typeof v === 'number' && new Date(v).getFullYear() < 1972) {
    v *= 1000
  }
  v = new Date(v)
  if (isNaN(v)) return undefined
  return v.toISOString()
}

const withArray = f => v => (!(v instanceof Array) ? undefined : v.map(f))

const stripUndefinedValues = o => {
  const next = {}
  for (const k in o) if (o[k] !== undefined) next[k] = o[k]
  return next
}

// convert._in(
//   'where.journal',
//   'where.journal_in',
//   convert.string # optional
// )(
//   {where: {journal: 50, journal_in: [51, 52]}}
// ) =>
// ['50', '51', '52']
const withIncludesModifier = (keyA, keyB, f) => v => {
  if (!v) return undefined
  const va = getValue(v, keyA)
  const vb = getValue(v, keyB)
  if (!va && !vb) return undefined
  let arr = vb || []
  if (va) arr = arr.concat(va)
  arr = Array.from(new Set(arr))
  if (f) arr = arr.map(f)
  return arr
}

const convert = {
  int: convertInt,
  intArray: withArray(convertInt),
  string: convertString,
  stringArray: withArray(convertString),
  datestring: convertDatestring,
  datestringArray: withArray(convertDatestring),
  stripUndefinedValues: stripUndefinedValues,
  _in: withIncludesModifier
}

export default convert
