import React from 'react'
import gql from 'graphql-tag'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {refetchFeed} from 'store/actions/feed'
import {useMutation, useQuery} from '@apollo/react-hooks'
import theme from 'lib/theme'
// import Image from 'components/Image'
import {isAncestorInteractive} from 'components/shared/FeedCardBasic'
import {css} from 'styled-components'
import getValue from 'lib/getValue'
import Fragments from 'lib/appFragments'
import {formatEvent, triggerCustomEvent} from 'lib/trackEverythingStream'
import Shiitake from 'shiitake'

const SubscribeResponse = gql`
  fragment SubscribeResponse on MutationResponse {
    status
    refetch {
      viewer {
        id
        journalSubscriptions {
          id
        }
      }
      journal(id: $journal) {
        id
        subscribed
      }
    }
  }
`

const ADD_JOURNAL_SUBSCRIPTION = gql`
  mutation AddJournalSubscription($journal: ID!) {
    addJournalSubscription(journal: $journal) {
      ...SubscribeResponse
    }
  }
  ${SubscribeResponse}
`

const REMOVE_JOURNAL_SUBSCRIPTION = gql`
  mutation RemoveJournalSubscription($journal: ID!) {
    removeJournalSubscription(journal: $journal) {
      ...SubscribeResponse
    }
  }
  ${SubscribeResponse}
`

const JournalCard = ({journal, noLink}) => {
  const journalEl = React.useRef(null)
  const AJS = ADD_JOURNAL_SUBSCRIPTION
  const RJS = REMOVE_JOURNAL_SUBSCRIPTION
  const [addJournalSubscription, {loading: l0}] = useMutation(AJS)
  const [removeJournalSubscription, {loading: l1}] = useMutation(RJS)
  const dispatch = useDispatch()
  const loading = l0 || l1
  // scrolling in the feed at the same time as subscribing to
  // journals can set loading=false prematurely, showing a flash
  // of the wrong state - so we manage it manually
  const [subscribed, setSubscribed] = React.useState(journal.subscribed)
  const journalCover = getValue(journal, 'cover.base64')
    ? journal.cover
    : '/images/placeholder_lighter.svg'

  const onError = (img) => {
    img.target.onerror = null
    if (img.target.src.indexOf('placeholder') === -1) {
      // only if it's not a placeholder
      img.target.src = '/images/placeholder.png'
    }
  }

  const imageRef = React.useRef(null)
  const cover = (
    <div
      css={`
        background-color: ${theme.colors.light.metal};
        position: relative;
      `}
    >
      <img
        ref={imageRef}
        src={journalCover.baseURL || journal?.cover_image_url}
        width="200px"
        height="236px"
        alt=""
        onError={onError}
        css={`
          object-fit: cover;
        `}
      />
    </div>
  )

  const coverStyle = css`
    display: flex;
    align-items: center;
    text-decoration: none;

    ${!noLink &&
    css`
      &:hover strong {
        text-decoration: underline;
      }
    `}
  `

  return (
    <div
      css={`
        padding: 20px;
        display: inline-block;
        vertical-align: top;
      `}
      x-track-id="journal"
      x-track-view="true"
      x-track-detail={JSON.stringify({journal: journal.id})}
    >
      <div
        ref={journalEl}
        css={`
          background-color: ${theme.colors.white};
          border-radius: 8px;
          ${theme.shadows.flat};
          cursor: pointer;
          overflow: hidden;

          ${noLink &&
          css`
            &:hover {
              text-decoration: underline;
            }
          `}
          color: ${subscribed ? '#9C9C9C' : '#F94687'};

          span {
            font-size: 14px;
            font-family: 'Replica';
            font-weight: bold;
          }
        `}
      >
        {noLink ? (
          <div css={coverStyle}>{cover}</div>
        ) : (
          <>
            <Link
              to={`/feed/journal/${journal.id}`}
              css={coverStyle}
              x-track-id="select"
              x-track-click="true"
            >
              {cover}
            </Link>
            <div
              onClick={async (e) => {
                const interactive = isAncestorInteractive(e.target, {
                  checkUntil: journalEl.current,
                })

                if (interactive) {
                  return
                }

                const trackingEvent = formatEvent(e)

                if (subscribed) {
                  trackingEvent.type = 'unfollowJournal'
                  triggerCustomEvent(trackingEvent)
                  await removeJournalSubscription({
                    variables: {journal: journal.id},
                  })
                  dispatch(
                    refetchFeed({
                      refetch: true,
                      feedName: 'allJournals',
                    })
                  )
                } else {
                  trackingEvent.type = 'followJournal'
                  triggerCustomEvent(trackingEvent)
                  await addJournalSubscription({
                    variables: {journal: journal.id},
                  })
                  dispatch(
                    refetchFeed({
                      refetch: true,
                      feedName: 'allJournals',
                    })
                  )
                }
                setSubscribed(!subscribed)
              }}
              css={`
                margin-top: -7px;
                height: 52px;
                border-radius: 6px;
                background-color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px;
                text-align: center;
                position: relative;
                color: ${theme.colors.pink};
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              {loading ? (
                'Loading'
              ) : subscribed ? (
                <div>
                  <span>Unfollow</span>
                </div>
              ) : (
                <div>
                  <span>Follow</span>
                </div>
              )}
            </div>
          </>
        )}
        <Link
          to={`/feed/journal/${journal.id}`}
          x-track-id="select"
          x-track-click="true"
        >
          <div
            css={`
              padding: 12px;
              height: 72px;
              text-align: center;
              position: relative;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            <div
              css={`
                position: absolute;
                bottom: 0;
                left: 50%;
                right: 0;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 90%;
                height: max-content;
                text-align: center;
                color: #323232;
                font-weight: bold;
                word-break: break-word;
              `}
            >
              <Shiitake lines={3}>{journal.name}</Shiitake>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

const JournalCardBasic = ({children}) => {
  return (
    <div
      css={`
        padding: 20px;
        display: inline-block;
        vertical-align: top;
      `}
    >
      <div
        css={`
          background-color: ${theme.colors.white};
          border-radius: 8px;
          ${theme.shadows.flat};
          cursor: pointer;
          overflow: hidden;
          width: 210px;
          height: 316px;
        `}
      >
        {children}
      </div>
    </div>
  )
}

export const MockCover = ({handleMockCardClick, fullScreen}) => {
  return (
    <div
      onClick={handleMockCardClick}
      css={`
        background-color: ${theme.colors.white};
        border-radius: 8px;
        ${theme.shadows.flat};
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f94687;
        width: 200px;
        height: 354px;
        margin: 20px;
        display: inline-flex;
        font-size: 16px;
        font-family: 'Replica';
        font-weight: bold;
      `}
    >
      {fullScreen ? 'Load More...' : 'See More...'}
    </div>
  )
}

const PROGRESSIVE_JOURNAL = gql`
  query JournalProgressive($journal: ID!) {
    journal(id: $journal) {
      ...FullJournal
    }
  }
  ${Fragments.FullJournal}
`

const JournalCardWithResolvers = ({journal, journalId, ...props}) => {
  const {data: journalData} = useQuery(PROGRESSIVE_JOURNAL, {
    variables: {journal: journalId},
    skip: !journalId || journal,
  })

  journal = journal || getValue(journalData, 'journal')

  if (!journal) return <JournalCardBasic />
  if (!journal.name) return null

  return <JournalCard journal={journal} {...props} />
}

export {JournalCardBasic}
export default JournalCardWithResolvers
