import { createTheme } from '@material-ui/core';

import palette from './Palette';
import typography from './Typography';

const theme = createTheme({
  palette,
  typography,
  overrides: {
    MuiFormHelperText: {
      root: {
        color: `#777`,
        margin: `0`,
        fontSize: `12px`,
        marginTop: 3,
        textAlign: `left`,
        fontFamily: 'ReplicaLight',
        lineHeight: `12px`,
        letterSpacing: `0.33px`
      }
    },
    MuiInputBase: {
      input: {
        color: '#323232',
        fontFamily: 'Replica',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '24px'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#8F8F8F',
        fontFamily: 'ReplicaLight',
        fontSize: '12px',
        letterSpacing: '0.11px',
        lineHeight: '12px'
      }
    },
    MuiChip: {
      root: {
        marginBottom: `4px !important`,
        border: `1px solid #15151554`,
        fontFamily: 'Replica'
      }
    },
    MuiListItemText: {
      primary: {
        color: '#646464',
        fontFamily: 'Replica',
        fontSize: '15px',
        letterSpacing: 0,
        lineHeight: '24px'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#959595'
      }
    },
    MuiAutocomplete: {
      option: {
        color: `#FFFFFF`,
        fontFamily: `Proxima Nova,Arial,sans-serif`,
        fontSize: `13px`,
        fontWeight: 500,
        letterSpacing: `0.12px`,
        lineHeight: `14px`,
        backgroundColor: `#323232`,
        '&[data-focus="true"]': {
          backgroundColor: palette.primary.light,
          color: '#323232'
        },
        '&[aria-selected="true"]': {
          backgroundColor: palette.primary.main,
          color: 'white'
        },
        "&.Mui-selected": {
          backgroundColor: palette.primary.light,
          color: '#323232',
          "&:hover": {
            backgroundColor: palette.primary.light,
            color: '#323232'
          }
        },
        "&:hover": {
          backgroundColor: palette.primary.light,
          color: '#323232'
        }
      },
      noOptions: {
        backgroundColor: "#323232",
        color: `#FFFFFF`
      },
      listbox: {
        backgroundColor: "#323232",
      }
    },
    MuiMenuItem: {
      root: {
        color: `#FFFFFF`,
        fontFamily: `Proxima Nova,Arial,sans-serif`,
        fontSize: `13px`,
        fontWeight: 500,
        letterSpacing: `0.12px`,
        lineHeight: `14px`,
        backgroundColor: `#323232`,
        "&.Mui-selected": {
          backgroundColor: palette.primary.main,
          "&:hover": {
            backgroundColor: palette.primary.main
          }
        },
        "&:hover": {
          backgroundColor: palette.primary.main
        }
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: "#323232"
      }
    },
    MuiCircularProgress:{
      circle: {
        color:"green"
      },
    }
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
