import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import {withStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import {ModalSize} from '.'
import {getFeedManagerTitle} from '../util/util'
import {useSelector} from 'react-redux'
import {QuestionMarkIcon} from '../shared/QuestionIcon/QuestionIcon'
import {useState} from 'react'
import CustomModal from './CustomModal'
import {HelpDetails} from '../shared/HelpDetails/HelpDetails'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#F7F7FA',
  },
  rootWithoutGreyBg: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  title: {
    color: '#323232',
    fontFamily: 'Replica',
    fontWeight: 'bold',
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    size,
    isHelpIcon,
    onClickHelpIcon,
    ...other
  } = props

  return (
    <MuiDialogTitle
      disableTypography
      className={
        size === ModalSize.sm ? classes.rootWithoutGreyBg : classes.root
      }
      {...other}
    >
      {isHelpIcon && (
        <QuestionMarkIcon isAbsolutePosition onClick={onClickHelpIcon} />
      )}
      <Typography variant="h6" className={classes.title} align="center">
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})

export const CustomModalHeader = (props) => {
  const {manageFeedType, createEditStep} = useSelector(
    (state) => state.feedManager
  )
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const toggleHelpDetailsModal = (val) => {
    setIsDetailsModalOpen(val)
  }

  const {handleClose, title, size, isHelpIcon} = props
  return (
    <>
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        size={size}
        isHelpIcon={isHelpIcon}
        onClickHelpIcon={() => toggleHelpDetailsModal(true)}
      >
        {title}
      </DialogTitle>
      {isDetailsModalOpen && (
        <CustomModal
          header={
            <CustomModalHeader
              title={null}
              handleClose={() => toggleHelpDetailsModal(false)}
              size={ModalSize.xl}
            />
          }
          body={<HelpDetails />}
          size={ModalSize.xl}
          footer={null}
          isDividers={false}
        />
      )}
    </>
  )
}
