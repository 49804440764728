import React from 'react'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import getValue from 'lib/getValue'
import Fragments from 'lib/appFragments'

const RESEARCH_AREAS = gql`
  query ResearchAreas {
    researchAreas {
      id
      name
      subjects {
        id
        name
      }
      artworkPink {
        baseURL
      }
      artworkGrey {
        baseURL
      }
    }
  }
`

const JOURNAL_SUBSCRIPTIONS = gql`
  query ViewerSubscriptions {
    viewer {
      id
      journalSubscriptions {
        id
      }
    }
  }
`

const LAZY_FULL_JOURNAL = gql`
  query MyJournalProgressive($journal: ID!) {
    journal(id: $journal) {
      ...FullJournal
    }
  }
  ${Fragments.FullJournal}
`

export const VIEWER_PROFILE = gql`
  query ViewerProfile {
    viewer {
      ...FullProfile
      library {
        id
      }
      tools{
        id
      }
    }
  }
  ${Fragments.FullProfile}
`

// load "my journals" ahead of time, for performance
const AheadOfTimeLoader = () => {
  const { data } = useQuery(JOURNAL_SUBSCRIPTIONS)
  const client = useApolloClient()

  const length = getValue(data, 'viewer.journalSubscriptions.length')

  React.useEffect(() => {
    const journals = getValue(data, 'viewer.journalSubscriptions') || []
    let stopped = false
      ; (async () => {
        for (const i in journals) {
          const journal = journals[i]
          if (stopped) return
          await client.query({
            query: LAZY_FULL_JOURNAL,
            variables: { journal: journal.id }
          })
        }
      })()
    return () => (stopped = true)
  }, [length])

  React.useEffect(() => {
    const timeout = setTimeout(async () => {
      // await client.query({ query: RESEARCH_AREAS })
      await client.query({ query: VIEWER_PROFILE })
    }, 500)
    return () => clearTimeout(timeout)
  }, [])

  return null
}

export default AheadOfTimeLoader
