import {ConfigurableChipWrapper} from 'components/FeedV2/shared/ConfigurableChipWrapper/ConfigurableChipWrapper'
import React from 'react'
export const dummyPlaceholder = (
  <div style={{visibility: 'hidden'}}>
    {' '}
    <ConfigurableChipWrapper
      children={`dummy`}
      isClickable
      filter={`dummy`}
      onClick={() => {}}
      onDelete={() => {}}
      width={'145px'}
    />
  </div>
)
